<template>
    <div class="bkv-editor">
        <el-button type="primary" @click="handleAddItem" class="add-item">{{$t('device.create')}}</el-button>
        <div class="clear-10"></div>
        <div v-for="(item, index) of itemList" :key="index">
            <div class="bkv-item">
                <el-select class="key" placeholder="Key" v-model="item.key" @change="updateKV">
                    <el-option v-for="itemKey in keyList" :key="itemKey.key" :label="'0x' + itemKey.key.toString(16).toUpperCase() + ' ' + itemKey.name" :value="itemKey.key"></el-option>
                </el-select>
                <el-input class="value ml-10" placeholder="Value" v-model="item.value" @keyup.native="updateKV" @change="updateKV" @blur="updateKV"></el-input>
                <span class="kv">{{ item.kv }}</span>
                <el-button class="ml-10" type="text" size="small" @click="handleDelItem(index)">{{$t('device.delete')}}</el-button>
            </div>
        </div>
        <div class="clear-10"></div>
        <div class="bkv" v-if="itemList.length > 0 && type == 0">BKV: {{ bkv }}</div>
        <div class="bkv" v-if="itemList.length > 0 && type == 1">
            BKV:  <el-input class="bkv-input" placeholder="Value" v-model="bkv" ></el-input>

        </div>
    </div>
</template>

<script>
    import BKV from '@/core/js/bkv'

    export default {
        name: "bkv-editor",
        props: {
            keyList: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            type: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                itemList: [],
                bkv: '',
            }
        },

        methods: {
            handleAddItem() {
                this.itemList = [...this.itemList, { key: '', value: '', kv: '', }]
            },

            handleDelItem(index) {
                let itemList = this.itemList;
                itemList.splice(index, 1);
                this.itemList = itemList;
            },

            getKeyDef(key) {
                key = parseInt(key);
                for (let i in this.keyList) {
                    let keyItem = this.keyList[i];
                    if (keyItem.key === key) {
                        return keyItem;
                    }
                }
            },

            parseNumberValueToBuffer(value, keyDef) {
                // value = value.toUpperCase();
                // if (value.indexOf('0X') === 0) {
                //     value = value.substring(2);
                //     value = BKV.hexToBuffer(value);
                //     return value;
                // }

                if (value === '') {
                    return new Uint8Array(0);
                }

                let type = keyDef.type;
                switch (type) {
                    case 'uint8': {
                        value = parseInt(value);
                        let buffer = new Uint8Array(1);
                        buffer[0] = value;
                        return buffer;
                    }

                    case 'int8': {
                        value = parseInt(value);
                        let buffer = new Uint8Array(1);
                        let dv = new DataView(buffer.buffer);
                        dv.setInt8(0, value);
                        return buffer;
                    }

                    case 'int16': {
                        value = parseInt(value);
                        let buffer = new Uint8Array(2);
                        let dv = new DataView(buffer.buffer);
                        dv.setInt16(0, value);
                        return buffer;
                    }

                    case 'uint16': {
                        value = parseInt(value);
                        let buffer = new Uint8Array(2);
                        let dv = new DataView(buffer.buffer);
                        dv.setUint16(0, value);
                        return buffer;
                    }

                    case 'int32': {
                        value = parseInt(value);
                        let buffer = new Uint8Array(4);
                        let dv = new DataView(buffer.buffer);
                        dv.setInt32(0, value);
                        return buffer;
                    }

                    case 'uint32': {
                        value = parseInt(value);
                        let buffer = new Uint8Array(4);
                        let dv = new DataView(buffer.buffer);
                        dv.setUint32(0, value);
                        return buffer;
                    }

                    case 'float32': {
                        value = parseFloat(value);
                        let buffer = new Uint8Array(4);
                        let dv = new DataView(buffer.buffer);
                        dv.setFloat32(0, value);
                        return buffer;
                    }

                    case 'float64': {
                        value = parseFloat(value);
                        let buffer = new Uint8Array(8);
                        let dv = new DataView(buffer.buffer);
                        dv.setFloat64(0, value);
                        return buffer;
                    }
                }

            },

            updateKV() {
                let bkv = new BKV.BKV();

                for (let i in this.itemList) {
                    let item = this.itemList[i];
                    item.kv = '';
                    if (!item.key) {
                        continue
                    }
                    let key = parseInt(item.key);
                    let keyDef = this.getKeyDef(key);
                    if (!keyDef) {
                        continue
                    }

                    let value = item.value;
                    value = this.parseNumberValueToBuffer(value, keyDef);
                    if (!value) {
                        continue
                    }

                    let kv = new BKV.KV(key, value);
                    item.kv = BKV.bufferToHex(kv.pack()).toUpperCase();
                    // console.log(item.kv, key.toString(16), keyDef, value);

                    bkv.add(kv);
                }

                this.bkv = BKV.bufferToHex(bkv.pack())
            },

            getBKV() {
                return this.bkv;
            }
        }
    }
</script>

<style scoped lang="scss">
    .bkv-editor {
        /*padding: 20px;*/

        .add-item {

        }

        .bkv-item {
            padding: 8px 0;

            .key {
                width: 200px;
            }

            .value {
                width: 200px;
            }

            .kv {
                font-family: Consolas, "Courier New", monospace;
                font-size: 14px;
                color: #555;
                padding-left: 10px;
            }
        }

        .bkv {
            font-family: Consolas, "Courier New", monospace;
            font-size: 14px;
            color: #555;
            word-break: break-all;
            .bkv-input {
                width: 465px;
            }
        }
    }

</style>
