import Const from './const'

function getKey(key) {
    return Const.DATA.KEY_PREFIX + key;
}

export function get(key) {
    if (key) {
        key = getKey(key);
        return JSON.parse(window.localStorage.getItem(key));
    }
}

export function set(key, val) {
    if (key) {
        key = getKey(key);
        return window.localStorage.setItem(key, JSON.stringify(val));
    }
}

export function remove(key) {
    if (key) {
        key = getKey(key);
        return window.localStorage.removeItem(key);
    }
}

export function setToken(token) {
    if (token) {
        let key = Const.DATA.KEY_TOKEN;
        return window.localStorage.setItem(key, token);
    }
}

export function getToken() {
    let key = Const.DATA.KEY_TOKEN;
    return window.localStorage.getItem(key);
}

export function removeToken() {
    let key = Const.DATA.KEY_TOKEN;
    return window.localStorage.setItem(key, "");
}

export function setUser(user) {
    let key = Const.DATA.KEY_USER;
    return set(key, user);
}

export function getUser() {
    let key = Const.DATA.KEY_USER;
    return get(key);
}

export function setUserType(type) {
    let key = Const.DATA.KEY_USER_TYPE;
    return set(key, type);
}

export function getUserType() {
    let key = Const.DATA.KEY_USER_TYPE;
    return get(key);
}

export function clearAuthData() {
    setToken('');
    setUser('');
}

export function setLang(lang) {
    let key = Const.DATA.KEY_LANG;
    return set(key, lang);
}

export function getLang() {
    let key = Const.DATA.KEY_LANG;
    let lang = get(key);
    return lang;
}

export function setAuthority(type) {
    let key = Const.DATA.KEY_AUTHORITY;
    return set(key, type);
}

export function getAuthority() {
    let key = Const.DATA.KEY_AUTHORITY;
    return get(key);
}
