<template>
    <div class="device-frame-history">
        <div class="device-frame-history-table mg-15">
            <el-row class="mg-15-1">
                <el-col :span="18">
                    <div class="search-item">
                        <label class="search-label">每页条数</label>
                        <el-select
                            v-model="pageSize"
                            filterable
                            default-first-option
                            clearable
                            class="search-value"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in pageSizeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>

                    <div class="search-item">
                        <label class="search-label">帧类型</label>
                        <el-select
                            v-model="frameType"
                            filterable
                            default-first-option
                            clearable
                            class="search-value"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in typeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>

                    <div class="clear-10"></div>

                    <div class="search-item">
                        <label class="search-label">时间</label>
                        <el-date-picker
                            class="mr-10"
                            v-model="selectTime"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        ></el-date-picker>
                    </div>

                    <div class="clear-10"></div>

                    <el-button type="primary" @click="pageChange(1)" class>查询</el-button>

                    <div class="clear-10"></div>
                </el-col>
            </el-row>
            <div class="clear-10"></div>
            <el-table
                v-loading="loading"
                :data="frameList"
                :row-class-name="tableRowClass"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" :selectable="checkboxSelect"></el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <div v-html="scope.row['content_html']"></div>
                    </template>
                </el-table-column>
                <el-table-column label=" 上报时间" width="180">
                    <template slot-scope="scope">{{ scope.row.time | timeFormat }}</template>
                </el-table-column>
                <el-table-column label="类型" width="100">
                    <template slot="header">
                        <span class="el-dropdown-link">帧类型</span>
                        <!--                                        <el-dropdown trigger="click" @command="handleCommand">-->

                        <!--                                            <el-dropdown-menu slot="dropdown">-->
                        <!--                                                <el-dropdown-item command="">全部</el-dropdown-item>-->
                        <!--                                                <el-dropdown-item command="1">心跳帧</el-dropdown-item>-->
                        <!--                                                <el-dropdown-item command="2">属性帧</el-dropdown-item>-->
                        <!--                                                <el-dropdown-item command="3">测试帧</el-dropdown-item>-->
                        <!--                                            </el-dropdown-menu>-->
                        <!--                                        </el-dropdown>-->
                    </template>

                    <template slot-scope="scope">{{ scope.row.frame | p20015FrameType }}</template>
                </el-table-column>
                <el-table-column label="事件时间" width="150">
                    <template slot-scope="scope">{{ scope.row.frame | frame2015EventTimeFormat }}</template>
                </el-table-column>
                <el-table-column label="地磁" width="100">
                    <template slot-scope="scope">{{ scope.row.frame | p20015ParkState }}</template>
                </el-table-column>
                <el-table-column label="x轴" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['x'] | frame2015ClientXYZ }}</template>
                </el-table-column>
                <el-table-column label="y轴" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['y'] | frame2015ClientXYZ }}</template>
                </el-table-column>
                <el-table-column label="z轴" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['z'] | frame2015ClientXYZ }}</template>
                </el-table-column>
                <el-table-column label="基础x轴" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['x_base'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="基础y轴" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['y_base'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="基础z轴" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['z_base'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="SI信号强度" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['csq'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="信号质量" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['snr'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="温度" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['temperature'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="电池电压" width="80">
                    <template
                        slot-scope="scope"
                    >{{ scope.row.frame['battery_voltage'] ? (scope.row.frame['battery_voltage'] + 'mv') : '-' }}</template>
                </el-table-column>
                <el-table-column label="电池电量" width="80">
                    <template
                        slot-scope="scope"
                    >{{ scope.row.frame['battery_level'] ? (scope.row.frame['battery_level'] + '%') : '-' }}</template>
                </el-table-column>
                <el-table-column label="事件序号" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['sn'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="流水号" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['frame_id'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="NB模块重启次数" width="120">
                    <template slot-scope="scope">{{ scope.row.frame['nb_reboot_count'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="MCU重启次数" width="120">
                    <template slot-scope="scope">{{ scope.row.frame['mcu_reboot_count'] || '-' }}</template>
                </el-table-column>

                <el-table-column label="设备状态" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['device_state'] || '' }}</template>
                </el-table-column>
                <el-table-column label="重启寄存器" width="80">
                    <template slot-scope="scope">{{ scope.row.frame['0x3C'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="conn_signal_strength" width="150">
                    <template slot-scope="scope">{{ scope.row.frame['conn_signal_strength'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="conn_link_quality" width="120">
                    <template slot-scope="scope">{{ scope.row.frame['conn_link_quality'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="conn_signal_ecl" width="120">
                    <!-- <template slot-scope="scope">{{ scope.row.frame['conn_signal_ecl'] || '-' }}</template> -->
                    <template slot-scope="scope">{{ scope.row.frame | getConnSignalEclShow }}</template>
                </el-table-column>
                <el-table-column label="conn_signal_cell_id" width="120">
                    <template slot-scope="scope">{{ scope.row.frame['conn_signal_cell_id'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="conn_signal_pci" width="120">
                    <template slot-scope="scope">{{ scope.row.frame['conn_signal_pci'] || '-' }}</template>
                </el-table-column>
                <el-table-column label="conn_signal_ear" width="120">
                    <template slot-scope="scope">{{ scope.row.frame['frequency_point'] }}</template>
                </el-table-column>
                <el-table-column label="debug_str" width="80">
                    <template slot-scope="scope">
                        <el-popover
                            ref="popover1"
                            placement="top"
                            trigger="hover"
                            :content="scope.row.frame['0xC0']"
                        ></el-popover>
                        <span v-popover:popover1>{{ scope.row.frame['0xC0'] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="debug_序列号" width="120">
                    <template slot-scope="scope">{{ scope.row.frame | p20015RawAttr(0xC2) }}</template>
                </el-table-column>
                <el-table-column label="扰乱时间" width="160">
                    <template
                        slot-scope="scope"
                    >{{ (scope.row.frame && scope.row.frame['raw_c1']) | timeFormat }}</template>
                </el-table-column>
                <el-table-column label="原始数据">
                    <template slot-scope="scope">
                        <a
                            class="link"
                            @click="showFrameDialog(scope.row.frame)"
                        >{{ scope.row.data }}</a>
                    </template>
                </el-table-column>
            </el-table>
            <div class="flex-footer">
                <div class="flex-footer-content">
                    <el-row>
                        <el-col :span="8" class="pt-3">
                            <el-button type="success" @click="handleCharts()">绘制图表</el-button>
                        </el-col>
                        <el-col :span="16" class="text-center" v-if="total > 0">
                            <el-pagination
                                @current-change="pageChange"
                                :current-page="currentPage"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total="total"
                            ></el-pagination>
                        </el-col>
                        <!--<el-col :span="8" class="text-center" v-if="total > 0"></el-col>-->
                    </el-row>
                </div>
            </div>
        </div>
        <div class="device-frame-charts-block">
            <DeviceFrameType11Charts :frames20015="multipleSelection" ref="DeviceFrameType11Charts"></DeviceFrameType11Charts>
        </div>
        <!--推送数据弹窗-->
        <el-dialog
            :title="$t('device.pushData')"
            :visible.sync="jsonDataDialogVisible"
            custom-class="json-dialog"
            width="600px"
        >
            <div class="content">
                <div class="pre-warp">
                    <pre>{{ jsonData }}</pre>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core'
import Vue from "vue";
import DeviceFrameType11Charts from '@/components/device-frame-type-11-charts'

export default {
    components: { DeviceFrameType11Charts },
    props: {
        space: String,
        uid: String,
    },
    data() {
        return {
            frameList: [],
            loading: false,
            // 分页配置
            total: 0,
            currentPage: 1,
            pageSize: 20,

            // 推送记录
            jsonDataDialogVisible: false,
            jsonData: '',
            selectTime: '',

            frameType: '',

            pageSizeList: [
                { value: 10, label: '10条' },
                { value: 20, label: '20条' },
                { value: 50, label: '50条' },
            ],
            multipleSelection: [],
        }
    },
    computed: {
        typeList() {
            let userType = Core.Data.getUserType()
            if (userType === Core.Const.USER_TYPE.ADMIN) {
                return [
                    { value: '', label: '全部' },
                    { value: 1, label: '事件帧' },
                    { value: 2, label: '心跳帧' },
                    { value: 3, label: '设置帧' },
                    { value: 4, label: '属性帧' },
                    { value: 5, label: '查询帧' },
                    { value: 8, label: '报警帧' },
                    { value: 11, label: '调试帧' },
                ]
            }
            return [
                { value: '', label: '全部' },
                { value: 1, label: '事件帧' },
                { value: 2, label: '心跳帧' },
                { value: 11, label: '调试帧' },
            ]
        }
    },
    mounted() {
        this.getFrameList();
    },
    watch: {
        uid: function (val) {
            this.refresh();
        }
    },

    methods: {
        handleCharts() {
            this.$refs.DeviceFrameType11Charts.initCharts()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val.sort((i, n) => i.debug_point.time - n.debug_point.time)
            console.log('multipleSelection', this.multipleSelection);
        },
        checkboxSelect(row, rowIndex) {
            try {
                let type = row.frame.type & 0x0F
                if (row.frame.type === 11 || type === 11) {
                    return true
                }
            } catch (e) {}
            return false // 禁用
        },
        async refresh() {
            this.loading = true;
            this.frameList = [];
            this.total = 0;
            await this.getFrameList();
        },

        async getFrameList() {
            this.loading = true;
            this.total = 0;
            if (!this.uid) {
                return
            }

            let beginTime = 0;
            let endTime = 0;
            if (Array.isArray(this.selectTime)) {
                if (this.selectTime[0]) {
                    beginTime = parseInt(this.selectTime[0].getTime() / 1000);
                    console.log('beginTime: ', beginTime);
                }
                if (this.selectTime[1]) {
                    endTime = parseInt(this.selectTime[1].getTime() / 1000);
                    console.log('endTime: ', endTime);
                }
            }
            // if(!beginTime) {  //endTime默认七天前
            //     let nowDay =  Date.parse(new Date()) / 1000;
            //     console.log('nowDay: ', nowDay);
            //     beginTime = nowDay - 86400 * 7
            // }
            // let res = await Core.Api.Frame.getFrameList(this.currentPage, this.pageSize, this.uid, this.space, this.frameType, this.frameType)

            let frameType = this.frameType;
            if (this.space !== '20015') {
                // frameType = (frameType | 0x80);
            }

            if (beginTime === 0) {
                beginTime = ""
            }

            if (endTime === 0) {
                endTime = ""
            }

            // 20220123 底层接口实际上使用的是p1参数，type未生效，等待修复后再更新此处
            await Core.Api.Frame.getFrameHistory(this.currentPage, this.pageSize, this.uid, this.space, frameType, beginTime, endTime)
                .then(res => {
                    console.log("res", res);
                    let frameList = [];
                    let frame_list = res.frame_list
                    for (let i in frame_list) {
                        let frame = frame_list[i];
                        frame['content_html'] = Vue.filter('frame20015Format')(frame);
                        frame['debug_point'] = Vue.filter('parseFrameType11')(frame);
                        frameList.push(frame);
                        console.log('20015-frame', frame);
                    }
                    this.frameList = frameList;
                    this.total = res.count;
                })
                .finally(() => {
                    this.loading = false
                })

        },
        pageChange(val) {
            this.currentPage = val;
            this.getFrameList();
        },

        showDataDialog(data) {
            this.jsonDataDialogVisible = true;
            this.jsonData = data
        },

        tableRowClass({ row, rowIndex }) {
            if (row['retry_count'] > 0) {
                return 'row warning';
            }
            return 'row';
        }
    }

}
</script>

<style lang="scss">
.device-frame-history {
    overflow: hidden;
    .device-frame-charts-block {
        margin-top: 15px;
        padding: 30px 15px 30px 15px;
        border-top: 1px solid;
    }
    .row.warning {
        /*opacity: 0.5;*/
        background: #f56c6c55;
    }
    .mg-15-1 {
        margin-top: 15px;
        margin-left: 15px;
    }

    .search-item {
        float: left;
        padding-right: 20px;
    }
}
</style>
