<template>
    <div class="relay-log-list">
        <div class="mg-15">
            <el-row class="mg-15-1">
                <el-col :span="18">
                    <el-date-picker
                        class="mr-10"
                        v-model="selectTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <el-button type="primary" @click="pageChange(1)" class="ml-10">查询</el-button>
                </el-col>
            </el-row>
            <div class="clear-10"></div>
            <el-table v-loading="loading" :data="logList" :row-class-name="tableRowClass">
                <el-table-column width="160" :label="$t('relayLog.createTime')">
                    <template slot-scope="scope">{{ scope.row.create_time | timeFormat }}</template>
                </el-table-column>
                <el-table-column width="160" :label="$t('relayLog.requestTime')">
                    <template slot-scope="scope">{{ (scope.row.request_time / 1000) | timeFormat }}</template>
                </el-table-column>
                <el-table-column :label="$t('relayLog.requestData')">
                    <template slot-scope="scope"><a class="link" @click="showDataDialog(scope.row.request_data)">{{
                            JSON.stringify(scope.row.request_data)
                        }}</a></template>
                </el-table-column>
                <el-table-column width="100" :label="$t('relayLog.URL')">
                    <template slot-scope="scope">
                        <el-popover
                            placement="top-start"
                            trigger="hover"
                            :content="scope.row.url">
                            <span slot="reference">{{ $t('device.lookUrl') }}</span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="Debug" width="80">
                    <template slot-scope="scope">
                        {{ !!scope.row.is_debug ? "是" : "" }}
                    </template>
                </el-table-column>
                <el-table-column width="180" :label="$t('relayLog.responseTime')">
                    <template slot-scope="scope">{{ (scope.row.response_time / 1000) | timeFormat }}</template>
                </el-table-column>
                <el-table-column width="100" :label="$t('relayLog.relayLogExecutionTime')">
                    <template slot-scope="scope">{{ scope.row | relayLogExecutionTime }}</template>
                </el-table-column>
                <el-table-column :label="$t('relayLog.responseData')">
                    <template slot-scope="scope"><a class="link" @click="showDataDialog(scope.row.response_data)">{{
                            JSON.stringify(scope.row.response_data)
                        }}</a></template>
                </el-table-column>
                <el-table-column width="220" :label="$t('relayLog.pushId')">
                    <template slot-scope="scope">{{ scope.row.muid }}</template>
                </el-table-column>
                <el-table-column width="100" :label="$t('relayLog.retryCount')">
                    <template slot-scope="scope">{{ scope.row.retry_count }}</template>
                </el-table-column>
            </el-table>
            <div class="flex-footer">
                <div class="flex-footer-content">
                    <el-row>
                        <el-col :span="24" class="text-center" v-if="total > 0">
                            <el-pagination
                                @current-change="pageChange"
                                :current-page="currentPage"
                                :page-size="pageSize"
                                layout="total, prev, pager, next, jumper"
                                :total="total">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <!--推送数据弹窗-->
        <el-dialog :title="$t('device.pushData')" :visible.sync="jsonDataDialogVisible" custom-class="json-dialog"
                   width="600px">
            <div class="content">
                <div class="pre-warp">
                    <pre>{{ jsonData }}</pre>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core'

export default {
    name: "relay-config",
    props: {
        uid: String,
    },
    data() {
        return {
            logList: [],
            loading: true,
            // 分页配置
            total: 0,
            currentPage: 1,
            pageSize: 20,

            // 推送记录
            jsonDataDialogVisible: false,
            jsonData: '',
            selectTime: '',
        }
    },
    mounted() {
        this.getRelayList();
    },
    watch: {
        uid: function (val) {
            this.refresh();
        }
    },

    methods: {
        async refresh() {
            this.loading = true;
            this.logList = [];
            this.total = 0;
            await this.getRelayList();
        },

        async getRelayList() {
            this.loading = true;
            this.total = 0;
            if (!this.uid) {
                return
            }

            let beginTime = 0;
            let endTime = 0;
            if (Array.isArray(this.selectTime)) {
                if (this.selectTime[0]) {
                    beginTime = parseInt(this.selectTime[0].getTime() / 1000);
                }
                if (this.selectTime[1]) {
                    endTime = parseInt(this.selectTime[1].getTime() / 1000);
                }
            }

            let res = await Core.Api.RelayLog.getLogList(this.currentPage, this.uid, beginTime, endTime);
            this.logList = res.log_list;
            this.total = res.count;
            this.loading = false
        },
        pageChange(val) {
            this.currentPage = val;
            this.getRelayList();
        },

        showDataDialog(data) {
            this.jsonDataDialogVisible = true;
            this.jsonData = data
        },

        tableRowClass({row, rowIndex}) {
            if (row['retry_count'] > 0) {
                return 'row warning';
            }
            return 'row';
        }
    }

}
</script>

<style lang="scss">
.relay-log-list {
    overflow: hidden;
    .row.warning {
        /*opacity: 0.5;*/
        background: #F56C6C55;
    }
    .mg-15-1 {
        margin-top: 15px;
        margin-left: 15px;
    }
}
</style>
