import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes.js';

Vue.use(VueRouter)

// 在vue项目中点击两次路由切换  多次点击跳转同一个路由是不被允许的  
// 解决
//push 
const VueRouterPush = VueRouter.prototype.push 
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

const router = new VueRouter({
	routes,
});

export default router;