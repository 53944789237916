import BKV from '../js/bkv'
import * as Util from "../js/util";
import Locutus from "../js/locutus";

export function p20015FrameType(frame, lang) {
    let typeName = '-';
    let type = frame['type'];
    if (!Number.isInteger(type)) {
        return '-'
    }

    type = parseInt(type);
    let frameType = type & 0x0F;

	if (lang === 'en') {
		switch (frameType) {
			case 1: typeName = 'Event Frame'; break;
			case 2: typeName = 'Heartbeat Frame'; break;
			case 3: typeName = 'Set Frame'; break;
			case 4: typeName = 'Attribute Frame'; break;
			case 6: typeName = 'OTA Frame'; break;
			case 8: typeName = 'Alarm Frame'; break;
			case 9: typeName = 'Test Frame'; break;
			default:
				typeName = "Data Frame-" + frameType
		}
	} else {
		switch (frameType) {
			case 1: typeName = '事件帧'; break;
			case 2: typeName = '心跳帧'; break;
			case 3: typeName = '设置帧'; break;
			case 4: typeName = '属性帧'; break;
			case 6: typeName = 'OTA帧'; break;
			case 8: typeName = '报警帧'; break;
			case 9: typeName = '测试帧'; break;
			default:
				typeName = "数据帧-" + frameType
		}
	}

    return typeName;
}

export function p20015ParkState(frame, lang) {
    let v = frame['state'];
    if (!Number.isInteger(v)) {
        return '-'
    }

    v = parseInt(v) & 0xFF;
    let result = '-';
    if (lang === 'en') {
	    switch (v) {
		    case 0: result = 'No Car'; break;
		    case 1: result = 'Car'; break;
		    case 2: result = 'Abnormal Sensor'; break;
		    case 3: result = 'Magnetic Interference'; break;
	    }
    } else {
	    switch (v) {
		    case 0: result = '无车'; break;
		    case 1: result = '有车'; break;
		    case 2: result = '传感器异常'; break;
		    case 3: result = '强磁干扰'; break;
	    }
    }

    return result;
}

/**
 * @param frame
 * @param {number} key
 * @return {*}
 */
export function p20015RawAttr(frame, key) {
    let v = frame['0x' + key.toString(16).toUpperCase()];
    if (v === undefined) {
        return '-'
    }

    return v;
}

/**
 * @param frame
 * @param {string} key
 * @return {*}
 */
export function p20015Attr(frame, key) {
    let v = frame[key];
    if (v === undefined) {
        return '-'
    }

    return v;
}

const frameRawKeyNaming = {
    0x01: '帧类型',
    0x02: '版本',
    0x03: 'IMEI',
    0x04: '事件时间',
    0x05: '流水号',
    0x06: '车位状态',
    0x07: 'x',
    0x08: 'y',
    0x09: 'z',
    0x0A: 'x基值',
    0x0B: 'y基值',
    0x0C: 'z基值',
    0x0D: '重传次数',
    0x0E: '事件序列号',
    0x0F: '',
    0x10: 'NB-SNR',
    0x11: '温度',
    0x12: '电池电压',

    0x22: '雷达有车频点2以上阈值',
    0x23: '有水的频点2有车阀值',
    0x24: '可能有水的有车阀值',
    0x25: '有水频点波峰的海拔高度有车判断阀值',
    0x26: '有水频点波峰的海拔高度有车判断阀值',
    0x27: '面积算法的I通道有车阀值',
    0x28: '面积算法的Q通道有车阀值',
    0x2F: '雷达信号强度',

    0x30: 'NB-RSRP',
    0x31: 'NB-RSRQ',
    0x32: 'NB-信号覆盖等级',
    0x33: 'NB-小区ID',
    0x34: 'NB-PCI',
    0x36: 'NB-频点',
    0x37: 'NB模组重启次数',
    0x38: 'MCU重启次数',
    0x39: '设备状态',
    0x3A: '电池电量',
    0x3B: '上线时间',
    0x3C: '设备重启寄存器',
    0x3D: '密钥',

    0x40: '雷达扰动时间',
    0x41: '雷达开启地磁阀值',
    0x42: '直接有车地磁阀值',
    0x43: '直接无车地磁阀值',
    0x44: '雷达地磁跟踪稳态时间',
    0x45: '雷达地磁跟踪百分比',
    0x46: '雷达无车判断最低地磁值',
    0x47: '雷达开启状态',
    0x48: '雷达开启总次数',
    0x49: '雷达有水阀值（频点1）',
    0x4A: '雷达有车阀值（频点2）',
    0x4B: '地磁稳态阀值',
    0x4C: '地磁边沿阀值',

    0x70: '',
    0x71: '',
    0x72: '',
    0x73: '',
    0x74: '有车决策时间',
    0x75: '无车决策时间',
    0x76: '决策灵敏度',
    0x77: '范围',
    0x78: '心跳时间',
    0x79: 'NB通讯波特率',
    0x7C: '',
    0x7D: '开启检测时间',
    0x7E: '关闭检测时间',
    0x7A: '',
    0x7B: '卡号',
    0x7F: '',
    0x80: '',
    0x81: 'NBAND配置',
    0x82: '',
    0x83: '',
    0x84: '',
    0x85: '',
    0x86: '',
    0x87: '',

    0x90: '稳态开启采集延时',
    0x91: '稳态开启采集次数',
    0x92: '稳态开启采集组数',
    0x93: '起效组数',
    0x94: '单轴阈值',
    0x95: '基值起效阈值',
    0x96: '权重系数',
    0x97: '自动校准功能开关',
    0x98: '自动校准拟合阈值',

    0xB0: '升级子帧类型',
    0xB1: '升级状态',
    0xB2: '升级文件偏移',
    0xB3: '升级数据长度',
    0xB4: '升级错误码',
    0xB5: '升级数据域',
    0xB6: '升级任务编号',

    0xC0: '测试',
    0xC1: '扰动时间',
    0xC2: '调试序列号',
    0xCA: '脏数据',
};

const frameKeyNaming = {

};

const schema = [
    { "key": 0x22, "value_type": "float32", "key_name": "雷达有车频点2以上阈值", },
    { "key": 0x23, "value_type": "float32", "key_name": "有水的频点2有车阀值", },
    { "key": 0x24, "value_type": "float32", "key_name": "可能有水的有车阀值", },
    { "key": 0x25, "value_type": "float32", "key_name": "有水频点波峰的海拔高度有车判断阀值", },
    { "key": 0x26, "value_type": "float32", "key_name": "有水频点波峰的海拔高度有车判断阀值", },
    { "key": 0x27, "value_type": "float32", "key_name": "面积算法的I通道有车阀值", },
    { "key": 0x28, "value_type": "float32", "key_name": "面积算法的Q通道有车阀值", },
]

export function frame20015Format(data) {
    if (!data || !data['frame']) {
        return ''
    }

    let frame = data['frame'];
    let rawData = data['data'];
    let itemList = [];
    for (let k in frame) {
        let keyName;
        let nk;
        if (k.indexOf("0x") === 0) {
            let hk = k.substring(2);
            hk = ('00' + hk).slice(-2).toUpperCase();
            nk = parseInt(hk, 16);
            keyName = frameRawKeyNaming[nk];
            keyName = `[${hk}] ${keyName}`
        } else {
            keyName = k
        }
        if (keyName === undefined) {
            continue
        }

        let v = frame[k];
        switch (nk) {
            case 0xC0: {
                console.log('---', v);
                let buf = BKV.hexToBuffer(v);
                v = String.fromCharCode.apply(null, buf);
            } break;
        }
        let content = `<div class="item">
                            <div class="name">${keyName}</div>
                            <div class="value">-> ${v}</div>
                       </div>`;
        itemList.push(content)
    }
    let itemHtml = itemList.join(' ');

    let frameType = p20015FrameType(frame);

    let buffer = BKV.hexToBuffer(rawData);
    console.log('bufferString', BKV.bufferToString(buffer));

    let compactString = BKV.bufferToString(buffer).toLowerCase();
    if (compactString.indexOf("fcfe") === 0) {
        buffer = BKV.hexToBuffer(compactString);
    }

    let result = BKV.BKV.unpack(buffer.subarray(4, buffer.length - 1));

    let bkvHtmlList = [];
    if (result.code === 0) {
        let bkv = result.bkv;
        let items = bkv.items();
        for (let i in items) {
            let b = items[i];
            let nk = b.key();
            let hexKeyName = nk.toString(16).toUpperCase();
            hexKeyName = ('00' + hexKeyName).slice(-2).toUpperCase();
            let keyName = frameRawKeyNaming[nk] || '';
            let v = bkv.parse(nk, schema);
            if (typeof v !== 'string') {
                v += `(${BKV.bufferToHex(b.value()).toUpperCase()})`
            }
            let content = `<span class="item bkv">
                            <span class="name">[${hexKeyName}] ${keyName}</span>
                            <span class="value">-> ${v}</span>
                       </span>`;
            bkvHtmlList.push(content)
        }
    }
    let bkvHtml = bkvHtmlList.join(' ');

    return `
          <div class="item title">${frameType}</div> 
          ${itemHtml}
          <div class="item raw">${rawData.toUpperCase()}</div>
          ${bkvHtml}
    `;
}

/**
 * 解析帧类型未11的调试帧数据
 * 1.将数据帧类型为11的帧内容中的磁数据进行图形绘制
 * 2.数据格式为时间戳（4字节）+ X轴（2字节）+ Y轴（2字节）+ Z轴（2字节）
 * 3.地磁数据小端格式
 * 4.只有前4个字节为时间戳
 * @param frame
 */
export function parseFrameType11(frame) {
    let rawData = frame['data'];
    let buffer = BKV.hexToBuffer(rawData);
    let compactString = BKV.bufferToString(buffer).toLowerCase();
    if (compactString.indexOf("fcfe") === 0) {
        buffer = BKV.hexToBuffer(compactString);
    }
    let result = BKV.BKV.unpack(buffer.subarray(4, buffer.length - 1));
    if (result.code !== 0) {
        return undefined;
    }

    let bkv = result.bkv;
    let items = bkv.items();
    let value = new Uint8Array(0);

    let type = 1
    for (let i in items) {
        let b = items[i];
        let k = b.key();
        if (k === 0x4E) {
            value = b.value();
            break;
        }
        if (k === 0x4F) {
            type = 2
            value = b.value();
            break;
        }
    }

    console.log('frame-11-debug', BKV.bufferToHex(value));

    if (value.length < 4) {
        console.log('frame-11-debug: length < 4')
        return undefined;
    }

    if (type === 1 && (value.length - 4) % 6 !== 0) {
        console.log('frame-11-debug: length invalid')
        return undefined;
    } else if (type === 2 && (value.length - 4) % 8 !== 0) {
        console.log('frame-11-debug: length invalid')
        return undefined;
    }

    let dataView = new DataView(value.buffer);
    let timestamp = dataView.getUint32(0, true)
    let pointCount = (value.length - 4) / 6
    let pointList = [];
    console.log('frame-11-debug type: ', type)
    if (type === 1) {
        for (let i = 0; i < pointCount; i++) {
            let x = dataView.getUint16(4 + i * 6, true);
            let y = dataView.getUint16(4 + i * 6 + 2, true);
            let z = dataView.getUint16(4 + i * 6 + 4, true);
            pointList.push([x, y, z]);
        }
    } else if (type === 2) {
        pointCount = (value.length - 4) / 8
        for (let i = 0; i < pointCount; i++) {
            let t = dataView.getUint16(4 + i * 8, true);
            let x = dataView.getUint16(4 + i * 8 + 2, true);
            let y = dataView.getUint16(4 + i * 8 + 4, true);
            let z = dataView.getUint16(4 + i * 8 + 6, true);
            console.log('i', i, ' x', x, ' y', y, ' z', z, ' t', t);
            pointList.push([x, y, z, t]);
        }
    }
    console.log('pointList', pointList);
    return {
        time: timestamp,
        point_list: pointList
    }

}

const KVDefinition = [
    {
        "key": 0x02,
        "key_name": "版本",
        "value_type": "uint8",
    },
    {
        "key": 0x03,
        "key_name": "IMEI",
        "value_type": "string",
    },
    {
        "key": 0x04,
        "key_name": "事件时间",
        "value_type": "uint32",
    },
    {
        "key": 0x05,
        "key_name": "",
        "value_type": "hex",
    },
    {
        "key": 0x06,
        "key_name": "车位状态",
        "value_type": "uint8",
        "value_mapping": {
            0: "无车",
            1: "有车",
            2: "传感器异常",
            3: "强磁干扰",
        }
    },
];

export function frame2015EventTimeFormat(frame) {
    let v = frame['time'];
    let format = 'Y-m-d H:i:s';
    return Locutus.date(format, v);
}

export function frame2015ClientXYZ(location) {
    if(location === 0) {
        return location
    }
    return location || '-'
}