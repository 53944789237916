import Const from './js/const'
import Api from './js/api'
import Locutus from './js/locutus'
import * as Data from './js/data'
import * as Util from './js/util'
import Config from './js/config'
import Log from './js/log'

const Core = {
  Config,
  Const,
  Log,
  Util,
  Api,
  Data,
  Locutus,
}
export default Core;
