import Const from "../js/const";

export function otaTaskStatus(status) {
    return Const.OTA_TASK_STATUS_TEXT[status + ''] || '--';
}

export function otaTaskMemberStatus(status) {
    return Const.OTA_TASK_MEMBER_STATUS_TEXT[status + ''] || '--';
}

