import Core from '@/core';

/* 设备类型 */
export const SPACE_TYPE = Core.Const.SPACE.TYPE;

// prettier-ignore
/* ========== 查询 ========== */
// 20000、20015、20023、20028、20030  等使用
export const QUERY_KEYS_LIST_20015 = [
    0x20, 0x21, 0x22, 0x23, 0x24, 0x25, 0x26, 0x27, 0x28,
    0x30, 0x31, 0x32, 0x3C, 0x34, 0x35, 0x36, 0x37, 0x38, 0x39, 0x3A, 0x3B, 0x3C,
    0x40, 0x41, 0x42, 0x43, 0x44, 0x45, 0x46, 0x49, 0x4A, 0x4B, 0x4C,
    0x70, 0x71, 0x72, 0x73, 0x74, 0x75, 0x76, 0x77, 0x78, 0x79, 0x7D, 0x7E,
    0x7A, 0x7B, 0x7F, 0x80, 0x81, 0x82, 0x83, 0x84, 0x85, 0x86, 0x87, 0x88,
    0x89
];

// 20029  等使用
export const QUERY_KEYS_LIST_20029 = [
    0x20, 0x21, 0x22, 0x23, 0x24, 0x25, 0x26, 0x27, 0x28,
    0x30, 0x31, 0x32, 0x3C, 0x34, 0x35, 0x36, 0x37, 0x38, 0x39, 0x3A, 0x3B, 0x3C,
    0x70, 0x71, 0x72, 0x73, 0x74, 0x75, 0x76, 0x77, 0x78, 0x79, 0x7D, 0x7E,
    0x7A, 0x7B, 0x7F, 0x80, 0x81, 0x82, 0x83, 0x84, 0x85, 0x86, 0x87, 0x88,
    0x89
];
/* ========== 查询 ========== */


/* ========== 设置 ========== */
// 20000、20015、20023、20028、20029  等使用
export const SET_KEYS_LIST_20015 = [
    0x20, 0x21, 0x22, 0x23, 0x24, 0x25, 0x26, 0x27, 0x28,
    0x40, 0x41, 0x42, 0x43, 0x44, 0x45, 0x46, 0x49, 0x4A, 0x4B, 0x4C,
    0x50, 0x51, 0x52, 0x53, 0x54, 0x55, 0x56, 0x57, 0x58,
    0x68, 0x69, 0x6A, 0x6B, 0x6C, 0x6D, 0x6E,
    0x73, 0x74, 0x75, 0x76, 0x77, 0x78, 0x79, 0x7D, 0x7E,
    0x89,
    0x90, 0x91, 0x92, 0x93, 0x94, 0x95, 0x96, 0x97, 0x98,
    0xC6, 0xC7, 0xC8, 0xC9, 0xCB, 0xCC, 0xCD, 0xCE, 0xCF,
];
// 20030  等使用
export const SET_KEYS_LIST_20030 = [
    0x20, 0x21, 0x22, 0x23, 0x24, 0x25, 0x26, 0x27, 0x28,
    0x40, 0x41, 0x42, 0x43, 0x44, 0x45, 0x46, 0x49, 0x4A, 0x4B, 0x4C,
    0x50, 0x51, 0x52, 0x53, 0x54, 0x55, 0x56, 0x57, 0x58,
    0x73, 0x74, 0x75, 0x76, 0x77, 0x78, 0x79, 0x7D, 0x7E,
    0x89,
    0x90, 0x91, 0x92, 0x93, 0x94, 0x95, 0x96, 0x97, 0x98
];
/* ========== 设置 ========== */