import Api from './base';
import { baseUrl } from './config';

const apiList = {
    // 别的url
    Tool: {
        exportTaskCreate: ['tool/export-task/create', 'device_id'],
        exportTaskRun: ['tool/export-task/run', 'task_id'],
    },
    Fegoit: {
        relay: ['device/relay', 'uid', 'space'],
    },
    Oc: {
        report: ['oc/report/list', 'uid', 'space'],
    },
    Admin: {
        login: ['admin/login', 'username', 'password'],
        save: ['admin/save', 'id', 'username', 'password'],
        delete: ['admin/delete', 'id'],
        list: ['admin/list', 'username', 'page'],
        updatePassword: ['admin/update-password', 'old_password', 'new_password']
    },
    User: {
        login: ['user/login', 'username', 'password'],
        list: ['user/list', 'username', 'page', 'desc', "company", 'device_online_status'],
        allList: ['user/all-list'],   // 只查一级用户
        save: ['user/save', 'id', 'username', 'relay_strategy', 'password', 'endpoint', 'remark', 'event_endpoint', 'desc', 'app_profile_id', 'company', 'address', 'device_active_time_threshold'],
        delete: ['user/delete', 'user_id'],
        resetPassword: ['user/reset-password', 'user_id', 'password'],
        detail: ['user/detail'],
        updatePassword: ['user/update-password', 'old_password', 'new_password'],
        getTargetDetail: ['user/detail', 'user_id'],
        listByStatus: ['user/list-device-status-count', 'page', 'page_size', 'username', 'desc'],

        // 二级用户列表
        listChild: ['user/list-child'],
        saveChild: ['user/save-child'],
        // 创建二级用户开启
        openChildUser: ['user/open-child-user', 'user_id', 'flag_child_user_enable'],

        // 是否允许用户修改灵敏度
        openSignalAdjust: ['user/open-signal-adjust', 'user_id', 'flag_signal_adjust'],

        // 是否允许查看告警列表
        openAlarm: ['user/open-alarm', 'user_id', 'flag_alarm_enable'],
        // 是否允许查看OTA任务列表
        openOtaTask: ['user/open-ota-task', 'user_id', 'flag_ota_task_enable'],
        // 是否允许查看下发参数任务列表
        openDeviceTask: ['user/open-device-task', 'user_id', 'flag_device_task_enable'],
    },

    Device: {
        batchDistribute: ['device/save-target-user-by-uid-list', 'uid_list', 'space', 'target_user_id', 'target_group_id'],
        triggerRegisterEvent: ['device/trigger-register-event', 'ids'],

        getMagnetDetectorList: ['device/magnet-detector/list', "uid", 'group_id', 'park_no', 'address', 'page', 'sort_type'],
        getMagnetLockList: ['device/magnet-lock/list', "uid", 'group_id', 'park_no', 'address', 'page', 'sort_type'],
        saveDevice: ['device/save', 'id', 'uid', 'space', 'park_no', "park_type", 'address', 'group_id', 'imsi'],
        removeDevice: ['device/remove', 'uid', 'space'],


        getMagnetDetectorListForAdmin: ['device/magnet-detector/list', 'space', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'xyz_status', 'page', 'sort_type', 'mac'],
        getMagnetLockListForAdmin: ['device/magnet-lock/list', 'space', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type'],

        getTempMagnetDetectorList: ['device/temp/magnet-detector/list', 'space', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'xyz_status', 'page', 'sort_type'],


        getRelayConfig: ['device/relay-config', 'uid', 'space'],

        batchUpdateParkNoOldToNew: ['device/update/park', 'ids'],


        deviceSaveLabel: ['device/save-label'],  // 保存设备标签
    },

    Device20003: {
        getDeviceList: ['device/20003/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20003/delete', 'id'],
        getDeviceDetail: ['device/20003/detail', 'uid'],
        updateBaseInfo: ['device/20003/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20003/save-target-user', 'uid', 'user_id', 'group_id', 'park_no']
    },
    Device20004: {
        getDeviceList: ['device/20004/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20004/delete', 'id'],
        getDeviceDetail: ['device/20004/detail', 'uid'],
        updateBaseInfo: ['device/20004/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20004/save-target-user', 'uid', 'user_id', 'group_id', 'park_no']
    },
    Device20006: {
        getDeviceList: ['device/20006/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20006/delete', 'id'],
        getDeviceDetail: ['device/20006/detail', 'uid'],
        updateBaseInfo: ['device/20006/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20006/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20006/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20007: {
        getDeviceList: ['device/20007/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20007/delete', 'id'],
        getDeviceDetail: ['device/20007/detail', 'uid'],
        updateBaseInfo: ['device/20007/update-base-info', 'id', 'park_no', 'address'],
        saveTargetUser: ['device/20007/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20007/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address'],
    },

    Device20009: {
        getDeviceList: ['device/20009/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20009/delete', 'id'],
        getDeviceDetail: ['device/20009/detail', 'uid'],
        updateBaseInfo: ['device/20009/update-base-info', 'id', 'park_no', 'address'],
        saveTargetUser: ['device/20009/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20009/save', 'id', 'uid', 'imsi', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },

    Device20011: {
        getDeviceList: ['device/20011/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20011/delete', 'id'],
        getDeviceDetail: ['device/20011/detail', 'uid'],
        updateBaseInfo: ['device/20011/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20011/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
    },
    Device20012: {
        getDeviceList: ['device/20012/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20012/delete', 'id'],
        getDeviceDetail: ['device/20012/detail', 'uid'],
        updateBaseInfo: ['device/20012/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20012/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
    },
    Device20014: {
        getDeviceList: ['device/20014/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20014/delete', 'id'],
        getDeviceDetail: ['device/20014/detail', 'uid'],
        updateBaseInfo: ['device/20014/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20014/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20014/save', 'id', 'uid', 'imsi', 'user_id', 'group_id', 'park_no', 'address'],
    },
    Device20015: {
        getDeviceList: ['device/20015/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20015/delete', 'id'],
        getDeviceDetail: ['device/20015/detail', 'uid'],
        updateBaseInfo: ['device/20015/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20015/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20015/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20017: {
        getDeviceList: ['device/20017/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20017/delete', 'id'],
        getDeviceDetail: ['device/20017/detail', 'uid'],
        updateBaseInfo: ['device/20017/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20017/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20017/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address'],
    },
    Device20018: {
        getDeviceList: ['device/20018/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20018/delete', 'id'],
        getDeviceDetail: ['device/20018/detail', 'uid'],
        updateBaseInfo: ['device/20018/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20018/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20018/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20020: {
        getDeviceList: ['device/20020/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20020/delete', 'id'],
        getDeviceDetail: ['device/20020/detail', 'uid'],
        updateBaseInfo: ['device/20020/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20020/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20020/save', 'id', 'uid', 'pin_code', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20021: {
        getDeviceList: ['device/20021/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20021/delete', 'id'],
        getDeviceDetail: ['device/20021/detail', 'uid'],
        updateBaseInfo: ['device/20021/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20021/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
    },
    Device20023: {
        getDeviceList: ['device/20023/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20023/delete', 'id'],
        getDeviceDetail: ['device/20023/detail', 'uid'],
        updateBaseInfo: ['device/20023/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20023/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20023/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20024: {
        getDeviceList: ['device/20024/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20024/delete', 'id'],
        getDeviceDetail: ['device/20024/detail', 'uid'],
        updateBaseInfo: ['device/20024/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20024/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20024/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'mac'],
    },
    Device20025: {
        getDeviceList: ['device/20025/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20025/delete', 'id'],
        getDeviceDetail: ['device/20025/detail', 'uid'],
        updateBaseInfo: ['device/20025/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20025/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20025/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20027: {
        getDeviceList: ['device/20027/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20027/delete', 'id'],
        getDeviceDetail: ['device/20027/detail', 'uid'],
        updateBaseInfo: ['device/20027/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20027/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20027/save', 'id', 'uid', 'imsi', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20028: {
        getDeviceList: ['device/20028/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20028/delete', 'id'],
        getDeviceDetail: ['device/20028/detail', 'uid'],
        updateBaseInfo: ['device/20028/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20028/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20028/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20029: {
        getDeviceList: ['device/20029/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20029/delete', 'id'],
        getDeviceDetail: ['device/20029/detail', 'uid'],
        updateBaseInfo: ['device/20029/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20029/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20029/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },

    Device20030: {
        getDeviceList: ['device/20030/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        saveDevice: ['device/20030/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type'],
        deleteDevice: ['device/20030/delete', 'id'],
        getDeviceDetail: ['device/20030/detail', 'uid'],
        updateBaseInfo: ['device/20030/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20029/save-target-user', 'uid', 'user_id', 'group_id', 'park_no']
    },
    Device20031: {
        getDeviceList: ['device/20031/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20031/delete', 'id'],
        getDeviceDetail: ['device/20031/detail', 'uid'],
        updateBaseInfo: ['device/20031/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20031/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20031/save', 'id', 'uid', 'imsi', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20032: {
        getDeviceList: ['device/20032/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20032/delete', 'id'],
        getDeviceDetail: ['device/20032/detail', 'uid'],
        updateBaseInfo: ['device/20032/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20032/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20032/save', 'id', 'uid', 'pin_code', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Device20033: {
        getDeviceList: ['device/20033/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20033/delete', 'id'],
        getDeviceDetail: ['device/20033/detail', 'uid'],
        updateBaseInfo: ['device/20033/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20033/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20033/save', 'id', 'uid', 'pin_code', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },


    // 虚拟分组
    Device20000: {
        getDeviceList: ['device/20000/list', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'page', 'sort_type', 'page_size'],
        deleteDevice: ['device/20000/delete', 'id'],
        getDeviceDetail: ['device/20000/detail', 'uid'],
        updateBaseInfo: ['device/20000/update-base-info', 'id', 'park_no', 'park_lot', 'address'],
        saveTargetUser: ['device/20000/save-target-user', 'uid', 'user_id', 'group_id', 'park_no'],
        saveDevice: ['device/20000/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type', 'mac'],
    },
    Fengine20000: {
        getClientDetail: ['fengine/20000/device/detail', 'uid'],
        getFrameList: ['fengine/20000/frame/list', 'id', 'space', 'page'],
        getCommandList: ['fengine/20000/device/command-list', 'page', 'uid'],
        triggerCalibrate: ['fengine/20000/cmd/trigger-calibrate', 'uid'],
        triggerHeartbeat: ['fengine/20000/cmd/trigger-heartbeat', 'uid'],
        reboot: ['fengine/20000/cmd/trigger-reboot', 'uid'],
        query: ['fengine/20000/cmd/query', 'uid', 'bkv'],
        set: ['fengine/20000/cmd/set', 'uid', 'bkv'],
        upgrade: ['fengine/20000/cmd/upgrade', 'uid', 'ota_id'],
        simulateRelay: ['fengine/20000/relay/simulate', 'uid', 'type', 'state'],
    },


    Fengine20006: {
        register: ['fengine/20006/register', 'uid'],
        unregister: ['fengine/20006/unregister', 'uid'],
        getFrameList: ['fengine/20006/frame-list', 'uid', 'p1', 'page'],
        getConfig: ['fengine/20006/cmd/get-config', 'uid'],
        getCOAPServer: ['fengine/20006/cmd/get-coap-server', 'uid'],
        getUdpServer: ['fengine/20006/cmd/get-udp-server', 'uid'],
        triggerCalibrate: ['fengine/20006/cmd/trigger-calibrate', 'uid'],
        triggerHeartbeat: ['fengine/20006/cmd/trigger-heartbeat', 'uid'],
        triggerCalibrateAndHeartbeat: ['fengine/20006/trigger-calibrate-heartbeat', 'uid'],
        reboot: ['fengine/20006/cmd/trigger-reboot', 'uid'],
        batchReboot: ['fengine/20006/cmd/trigger-reboot', 'uid_list'],
        setConfig: ['fengine/20006/cmd/set-config', 'uid', 'on_decision_time', 'off_decision_time', 'sensitivity', 'heartbeat_time'],
        batchSetConfig: ['fengine/20006/cmd/set-config-batch', 'uid_list', 'on_decision_time', 'off_decision_time', 'sensitivity', 'heartbeat_time', 'environment_track_time', 'frequency_point'],
        setEnvironmentTrackTimeAndFrequencyPoint: ['fengine/20006/cmd/set-environment-track-time-and-frequency-point', 'uid', 'environment_track_time', 'frequency_point'],
        setCOAPServer: ['fengine/20006/cmd/set-coap-server', 'uid', 'ip', 'port'],
        setUdpServer: ['fengine/20006/cmd/set-udp-server', 'uid', 'ip', 'port'],
        upgrade: ['fengine/20006/cmd/upgrade', 'uid', 'ota_id'],
        enableRadarReport: ['fengine/20006/cmd/enable-radar-report', 'uid', 'state'],
        set: ['fengine/20006/cmd/set', 'uid', 'bkv'],
    },

    Fengine20007: {
        register: ['fengine/20007/register', 'uid'],
        unregister: ['fengine/20007/unregister', 'uid'],
        getFrameList: ['fengine/20007/frame-list', 'uid', 'p1', 'page'],
        setConfig: ['fengine/20007/set-config', 'uid', 'on_decision_time', 'off_decision_time', 'sensitivity', 'heartbeat_time'],
        sendCmd: ['fengine/20007/send-cmd', 'uid', 'cmd_id'],
    },

    Fengine20009: {
        register: ['fengine/20009/register', 'uid', 'imsi'],
        unregister: ['fengine/20009/unregister', 'uid'],
        getOuterDeviceDetail: ['fengine/20009/get-outer-device-detail', 'uid'],
        writeResOffline: ['fengine/20009/res/write/offline', 'imei', 'obj_id', 'obj_inst_id', 'mode', 'timeout', 'trigger_msg', 'res_id', 'res_type', 'res_val'],
    },

    Fengine20011: {
        getClientDetail: ['fengine/20011/get-client-detail', 'uid']
    },
    Fengine20012: {
        sendCmd: ['fengine/20012/send-cmd', 'uid', 'cmd_id']
    },
    Fengine20014: {
        writeRes: ['fengine/20014/onenet/device/res/write', 'uid', 'obj_id', 'obj_inst_id', 'res_id', 'res_value']
    },
    Fengine20015: {
        getClientDetail: ['fengine/20015/device/detail', 'uid'],
        getFrameList: ['fengine/20015/frame/list', 'id', 'space', 'page', 'page_size'],
        getCommandList: ['fengine/20015/device/command-list', 'page', 'uid'],
        triggerCalibrate: ['fengine/20015/cmd/trigger-calibrate', 'uid'],
        triggerHeartbeat: ['fengine/20015/cmd/trigger-heartbeat', 'uid'],
        reboot: ['fengine/20015/cmd/trigger-reboot', 'uid'],
        query: ['fengine/20015/cmd/query', 'uid', 'bkv'],
        set: ['fengine/20015/cmd/set', 'uid', 'bkv'],
        upgrade: ['fengine/20015/cmd/upgrade', 'uid', 'ota_id'],
        simulateRelay: ['fengine/20015/relay/simulate', 'uid', 'type', 'state'],
    },
    Fengine20017: {
        getClientDetail: ['fengine/20017/device/detail', 'uid'],
        getFrameList: ['fengine/20017/frame/list', 'id', 'space', 'page'],
        route: ['fengine/20017/route'],
    },
    Fengine20018: {
        getClientDetail: ['fengine/20018/device/detail', 'uid'],
        getFrameList: ['fengine/20018/frame/list', 'id', 'space', 'page'],
        // getCommandList: ['fengine/20018/device/command-list', 'page', 'uid'],
        query: ['fengine/20018/cmd/query', 'uid', 'bkv'],
        set: ['fengine/20018/cmd/set', 'uid', 'bkv'],
        upgrade: ['fengine/20018/cmd/upgrade', 'uid', 'ota_id'],
    },
    Fengine20020: {
        getClientDetail: ['fengine/20020/get-client-detail', 'uid'],
        writeCmdControl: ['fengine/20020/write-cmd-control', 'uid','state','key','value'],
        writeCmdConfig:  ['fengine/20020/write-cmd-config', 'uid','config'],
    },
    Fengine20021: {
        sendCmd: ['fengine/20021/send-cmd', 'uid', 'cmd_id']
    },
    Fengine20022: {
        route: ['fengine/20022/route'],
        getWsEndpoint: ['fengine/20022/ws-endpoint'],
    },
    Fengine20023: {
        getClientDetail: ['fengine/20023/device/detail', 'uid'],
        getFrameList: ['fengine/20023/frame/list', 'id', 'space', 'page'],
        getCommandList: ['fengine/20023/device/command-list', 'page', 'uid'],
        triggerCalibrate: ['fengine/20023/cmd/trigger-calibrate', 'uid'],
        triggerHeartbeat: ['fengine/20023/cmd/trigger-heartbeat', 'uid'],
        reboot: ['fengine/20023/cmd/trigger-reboot', 'uid'],
        query: ['fengine/20023/cmd/query', 'uid', 'bkv'],
        set: ['fengine/20023/cmd/set', 'uid', 'bkv'],
        upgrade: ['fengine/20023/cmd/upgrade', 'uid', 'ota_id'],
        simulateRelay: ['fengine/20023/relay/simulate', 'uid', 'type', 'state'],
    },
    Fengine20024: {
        getClientDetail: ['fengine/20024/device/detail', 'uid'],
        getFrameList: ['fengine/20024/frame/list', 'id', 'space', 'page'],
    },

    Fengine20025: {
        register: ['fengine/20025/register', 'uid'],
        unregister: ['fengine/20025/unregister', 'uid'],
        getFrameList: ['fengine/20025/frame-list', 'uid', 'p1', 'page'],
        getConfig: ['fengine/20025/cmd/get-config', 'uid'],
        getCOAPServer: ['fengine/20025/cmd/get-coap-server', 'uid'],
        getUdpServer: ['fengine/20025/cmd/get-udp-server', 'uid'],
        triggerCalibrate: ['fengine/20025/cmd/trigger-calibrate', 'uid'],
        triggerHeartbeat: ['fengine/20025/cmd/trigger-heartbeat', 'uid'],
        triggerCalibrateAndHeartbeat: ['fengine/20025/trigger-calibrate-heartbeat', 'uid'],
        reboot: ['fengine/20025/cmd/trigger-reboot', 'uid'],
        batchReboot: ['fengine/20025/cmd/trigger-reboot', 'uid_list'],
        setConfig: ['fengine/20025/cmd/set-config', 'uid', 'on_decision_time', 'off_decision_time', 'sensitivity', 'heartbeat_time'],
        batchSetConfig: ['fengine/20025/cmd/set-config-batch', 'uid_list', 'on_decision_time', 'off_decision_time', 'sensitivity', 'heartbeat_time', 'environment_track_time', 'frequency_point'],
        setEnvironmentTrackTimeAndFrequencyPoint: ['fengine/20025/cmd/set-environment-track-time-and-frequency-point', 'uid', 'environment_track_time', 'frequency_point'],
        setCOAPServer: ['fengine/20025/cmd/set-coap-server', 'uid', 'ip', 'port'],
        setUdpServer: ['fengine/20025/cmd/set-udp-server', 'uid', 'ip', 'port'],
        upgrade: ['fengine/20025/cmd/upgrade', 'uid', 'ota_id'],
        enableRadarReport: ['fengine/20025/cmd/enable-radar-report', 'uid', 'state'],
    },
    Fengine20027: {
        getClientDetail: ['fengine/20027/device/detail', 'uid'],
        getFrameList: ['fengine/20027/frame/list', 'id', 'space', 'page'],
        // getCommandList: ['fengine/20027/device/command-list', 'page', 'uid'],
        query: ['fengine/20027/cmd/query', 'uid', 'bkv'],
        set: ['fengine/20027/cmd/set', 'uid', 'bkv'],
        upgrade: ['fengine/20027/cmd/upgrade', 'uid', 'ota_id'],
        simulateRelay: ['fengine/20027/relay/simulate', 'uid', 'type', 'state'],
    },
    Fengine20028: {
        getClientDetail: ['fengine/20028/device/detail', 'uid'],
        getFrameList: ['fengine/20028/frame/list', 'id', 'space', 'page'],
        getCommandList: ['fengine/20028/device/command-list', 'page', 'uid'],
        triggerCalibrate: ['fengine/20028/cmd/trigger-calibrate', 'uid'],
        triggerHeartbeat: ['fengine/20028/cmd/trigger-heartbeat', 'uid'],
        reboot: ['fengine/20028/cmd/trigger-reboot', 'uid'],
        query: ['fengine/20028/cmd/query', 'uid', 'bkv'],
        set: ['fengine/20028/cmd/set', 'uid', 'bkv'],
        upgrade: ['fengine/20028/cmd/upgrade', 'uid', 'ota_id'],
        simulateRelay: ['fengine/20028/relay/simulate', 'uid', 'type', 'state'],
    },
    Fengine20029: {
        getClientDetail: ['fengine/20029/device/detail', 'uid'],
        getFrameList: ['fengine/20029/frame/list', 'id', 'space', 'page'],
        // getCommandList: ['fengine/20029/device/command-list', 'page', 'uid'],
        triggerCalibrate: ['fengine/20029/cmd/trigger-calibrate', 'uid'],
        triggerHeartbeat: ['fengine/20029/cmd/trigger-heartbeat', 'uid'],
        reboot: ['fengine/20029/cmd/trigger-reboot', 'uid'],
        query: ['fengine/20029/cmd/query', 'uid', 'bkv'],
        set: ['fengine/20029/cmd/set', 'uid', 'bkv'],
        upgrade: ['fengine/20029/cmd/upgrade', 'uid', 'ota_id'],
        simulateRelay: ['fengine/20029/relay/simulate', 'uid', 'type', 'state', 'voltage'],
    },
    Fengine20030: {
        getClientDetail: ['fengine/20030/device/detail', 'uid'],
        getFrameList: ['fengine/20030/frame/list', 'id', 'space', 'page'],
        getCommandList: ['fengine/20030/device/command-list', 'page', 'uid'],
        triggerCalibrate: ['fengine/20030/cmd/trigger-calibrate', 'uid'],
        triggerHeartbeat: ['fengine/20030/cmd/trigger-heartbeat', 'uid'],
        reboot: ['fengine/20030/cmd/trigger-reboot', 'uid'],
        query: ['fengine/20030/cmd/query', 'uid', 'bkv'],
        set: ['fengine/20030/cmd/set', 'uid', 'bkv'],
        upgrade: ['fengine/20030/cmd/upgrade', 'uid', 'ota_id'],
        simulateRelay: ['fengine/20030/relay/simulate', 'uid', 'type', 'state'],
    },
    Fengine20031: {
        getClientDetail: ['fengine/20031/device/detail', 'uid'],
        getFrameList: ['fengine/20031/frame/list', 'id', 'space', 'page'],
        // getCommandList: ['fengine/20027/device/command-list', 'page', 'uid'],
        query: ['fengine/20031/cmd/query', 'uid', 'bkv'],
        set: ['fengine/20031/cmd/set', 'uid', 'bkv'],
        upgrade: ['fengine/20031/cmd/upgrade', 'uid', 'ota_id'],
        simulateRelay: ['fengine/20031/relay/simulate', 'uid', 'type', 'state'],
    },
    Fengine20032: {
        getClientDetail: ['fengine/20032/get-client-detail', 'uid'],
        writeCmdControl: ['fengine/20032/write-cmd-control', 'uid','state','key','value'],
        writeCmdConfig:  ['fengine/20032/write-cmd-config', 'uid','config'],
    },
    Fengine20033: {
        getClientDetail: ['fengine/20033/get-client-detail', 'uid'],
        simulateRelay: ['fengine/20033/relay/simulate', 'uid', 'type', 'state'],
    },        


    Frame: {
        getClientList: ['frame/client-list', 'space', 'page'],
        getClientDetail: ['frame/client-detail', 'space', 'id'],
        getOuterClientDetail: ['frame/client-detail-outer', 'space', 'id'],
        getFrameListForSpace: ['frame/frame-list-for-space', 'id', 'space', 'p1', 'page'],
        getFrameList: ['frame/list', 'page', 'page_size', 'id', 'space', 'type', 'p1', 'begin_time', 'end_time'],
        getFrameHistory: ['frame/history', 'page', 'page_size', 'id', 'space', 'type', 'begin_time', 'end_time'],
        getVersion: ['frame/version', 'id'],
        upgrade: ['frame/upgrade', 'id'],
        writeData: ['frame/write-data', 'id', 'data'],
    },
    RelayLog: {
        getLogList: ['relay-log/list', 'page', 'device_id','begin_time','end_time'],
    },
    eventLog: {
        getList: ['car-traffic-event/list', 'device_id', 'page', 'begin_time', 'end_time', 'level', 'type'],
    },
    Group: {
        save: ['group/group-save', 'id', 'name', 'space', 'space_config', 'relay_strategy', 'endpoint', 'user_id', 'relay_config', 'park_no_rule', 'app_profile_id', 'tjd_config', "type" , "virtual_config_strategy", "virtual_config", 'device_active_time_threshold'],
        list: ['group/group-list', 'relay_strategy', 'user_id', 'group_name', 'page'],
        list2: ['group/group-list2', 'relay_strategy', 'username', 'group_name', 'space', 'page', 'device_online_status'],
        allUserGroupList: ['group/all-user-group-list', 'group_name'],
        listForAdmin: ['group/group-list', 'user_id', 'relay_strategy', 'page'],
        delete: ['group/delete', 'id'],
        saveGroupMember: ['group/group-member-save', 'id', 'group_id', 'device_id', 'user_id'],
        getGroupMemberList: ['group/group-member-list', 'group_id'],
        deleteGroupMember: ['group/group-member-delete', 'id'],
    },
    UserGroup: {
        save: ['user-group/save'],
        allIdList: ['user-group/all_id_list'],  // 二级用户获取关联的分组id列表
    },
    Client: {
        getClientList: ['client/list'],
        getClientFrameListOfRecent: ['client/frame/list/recent', 'id', 'space'],
    },
    Export: {
        getExportListByDeviceId: ["export-task/list", "uid", "space", "page"],
        exportFrameFor200004: ["export-task/export/frame-20004-07", "uid", "begin_time", "end_time"],
        exportFrameFor200015: ["export-task/export/frame-20015", "uid", "begin_time", "end_time"],
        exportDetectorFrame: ["export-task/export/frame/detector", "space", "uid", "begin_time", "end_time","result_data"],

        // 设备导出
        exportCarCountTaskFrame: ["export-task/export/frame/car/in/and/out/count", "imei_list", "begin_time", "end_time", "result_data"],
        exportCarCountListFrame: ["export-task/car-in-and-out-count-task/list", "page"],
    },
    Test: {
        // getRelayConfig: ['test/relay-config', 'uid', 'space'],
    },
    Action: {
        list: ['action/list', 'page', 'key_word', 'user_id'],
    },

    Ota: {
        createTask: ['ota-task/create', 'space', 'name', 'type', 'target_version', 'begin_time', 'end_time'],
        editTask: ['ota-task/save', 'id', 'name'],
        taskList: ['ota-task/list', 'page', 'space'],
        startTask: ['ota-task/start', 'task_id'],
        pauseTask: ['ota-task/pause', 'task_id'],
        stopTask: ['ota-task/stop', 'task_id'],
        taskDetail: ['ota-task/detail', 'task_id'],
        addTaskMember: ['ota-task-member/add', 'task_id', 'device_ids'],
        deleteTaskMember: ['ota-task-member/delete', 'task_member_id'],
        startTaskMember: ['ota-task-member/start', 'task_member_id'],
        taskMemberList: ['ota-task-member/list', 'task_id', 'order_type', 'page', 'uid', 'park_no'],
        createTaskFile: ['ota-file/create', 'task_id', 'name', 'path', 'hash', 'version'],
        otaFileList: ['ota-file/list', 'space', 'version_number', 'page'],
        saveTaskFile: ['ota-file/save', 'space', 'name', 'path', 'hash', 'version', 'version_number'],
    },

    DeviceTask: {
        createTask: ['device-task/create', 'space', 'type', 'name', 'begin_time', 'end_time'],
        editTask: ['device-task/save', 'id','name'],
        setBkv: ['device-task/set-bkv', 'id', 'bkv'],
        taskList: ['device-task/list', 'page', 'space'],
        startTask: ['device-task/start', 'task_id'],
        pauseTask: ['device-task/pause', 'task_id'],
        stopTask: ['device-task/stop', 'task_id'],
        taskDetail: ['device-task/detail', 'task_id'],
        addTaskMember: ['device-task-member/add', 'task_id', 'device_ids'],
        deleteTaskMember: ['device-task-member/delete', 'task_member_id'],
        startTaskMember: ['device-task-member/start', 'task_member_id'],
        taskMemberList: ['device-task-member/list', 'task_id', 'page', 'type'],
    },

    Stat: {
        getHomeDeviceCount: ['stat/home/device-count'],
    },

    Park: {
        parkList: ['park/park-list', 'page', 'group_id', 'user_id', 'park_no', 'sort_type'],
        parkCusList: ['park/park-list', 'page', 'group_id', 'park_no'],
        getParkRecodeList: ['park/park-record-list', 'park_id', 'page'],
        parkDetail: ['park/park-detail', 'id']
    },
    Alarm: {
        alarmList: ['alarm/list','page','user_id', 'group_id','imei', 'space', 'alarm_type', 'status']
    },
    AppProfile: {
        list: ['app-profile/list', 'name', 'page'],
        save: ['app-profile/save', 'id', 'name', 'data'],
        delete: ['app-profile/delete', 'id'],

    },
    Screen: {
        list: ['monitor/list', 'name', 'user_id', 'page'],
        save: ['monitor/save', 'id', 'color', 'name', 'format', 'status', 'monitor_card_no', 'width', 'height','sort','user_id', 'font_size'],
        delete: ['monitor/delete', 'id'],
        detail: ['monitor/detail', 'id'],
        bindPark: ['monitor/bind-park','park_ids','monitor_id'],    //绑定车位
        idlePark: ['monitor/idle-park', 'monitor_id'],  // 空闲的车位
        bindParkList: ['monitor/monitor-park-list', 'monitor_id', 'page'],
        removePark: ['monitor/remove-park', 'park_ids', 'monitor_id'], //解除绑定
        sendStatus: ['park/update-park-status', 'device_uid', 'space', 'status'], //推送
    },

    // 标签
    Label: {
        list: ['label/list'],
        allList: ['label/all-list'],  // 获取全部标签列表  包含管理端创建的标签
        save: ['label/save'],
        delete: ['label/delete'],
    },
};

export default new Api(baseUrl, apiList);