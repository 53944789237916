<template>
    <div class="btn-span-content" @click="handleClick">
        <el-tooltip class="item" effect="dark" :content="tip" placement="top" popper-class="btn-tip">
                 <span class="btn-span" :class="type">
                </span>
        </el-tooltip>
    </div>

</template>

<script>
    export default {
        name: "span-btn",
        props: {
           tip: '',
           type: '',
        },
        methods: {
            handleClick() {
                this.$emit('click')
            }
        }
    }
</script>

<style scoped lang="scss">
    .btn-span-content {
        display: inline-block;
        & + .btn-span-content {
            margin-left: 12px;
        }
    }
    .btn-span {
        vertical-align: middle;
        height: 22px;
        width: 22px;
        overflow: hidden;
        display: inline-block;

        &.export {
            background: url("../assets/img/icon/icon-export.png") no-repeat center;
            background-size: 22px 22px;
            &:hover {
                background: url("../assets/img/icon/icon-export-active.png") no-repeat center;
                background-size: 22px 22px;
            }
        }

        &.distribute {
            background: url("../assets/img/icon/icon-distribute.png") no-repeat center;
            background-size: 22px 22px;
            &:hover {
                background: url("../assets/img/icon/icon-distribute-active.png") no-repeat center;
                background-size: 22px 22px;
            }
        }
        &.edit {
            background: url("../assets/img/icon/icon-edit.png") no-repeat center;
            background-size: 22px 22px;
            &:hover {
                background: url("../assets/img/icon/icon-edit-active.png") no-repeat center;
                background-size: 22px 22px;
            }
        }
        &.delete {
            background: url("../assets/img/icon/icon-delete.png") no-repeat center;
            background-size: 22px 22px;
            &:hover {
                background: url("../assets/img/icon/icon-delete-active.png") no-repeat center;
                background-size: 22px 22px;
            }
        }
    }
</style>
<style lang="scss">
    .btn-tip {
        padding: 2px 10px;
        color: white;
        font-size: 12px;
        background: rgba(0, 0, 0, .1);
    }
</style>
