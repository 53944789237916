<template>
    <div class="device-charts-type-11">
        <div v-if="isShowCharts" ref="device-frame-20015-charts" class="device-frame-20015-class"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import {Util} from "@antv/g2";
import Core from '@/core'

export default {
    props: {
        frames20015: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            myChart: null,
            isShowCharts: false,
        }
    },
    mounted() {
    },
    methods: {
        initFrames20015() {
            const timeout = 10 * 60
            let xData = [], yData = [], zData = [], timeData = []
            this.frames20015.forEach(frame => {
                let pointList = frame.debug_point.point_list
                let firstPointTime = frame.debug_point.time
                for (let i = 0; i < pointList.length; i++) {
                    let points = pointList[i]
                    xData.push({
                        dataIndex: i,
                        name: `X轴${i}`,
                        value: points[0] - 32768,
                    })
                    yData.push({
                        dataIndex: i,
                        name: `Y轴${i}`,
                        value: points[1] - 32768,
                    })
                    zData.push({
                        dataIndex: i,
                        name: `Z轴${i}`,
                        value: points[2] - 32768,
                    })
                    if (points[3]) {
                        timeData.push(Core.Locutus.date('Y-m-d H:i:s', firstPointTime + points[3]))
                    } else {
                        timeData.push(Core.Locutus.date('Y-m-d H:i:s', firstPointTime + timeout * i))
                    }
                }
            })
            return {
                length: this.frames20015.length,
                xData,
                yData,
                zData,
                timeData
            }
        },
        async initCharts() {
            let { xData, yData, zData, timeData, length } = this.initFrames20015()
            // console.log(xData, yData, zData);
            if (!length) {
                return this.$message.warning("请选择数据")
            }
            this.isShowCharts = true
            let chartDom = await this.getChartsDom()
            let myChart = this.myChart
            if (myChart == null) {
                myChart = echarts.init(chartDom)
                this.myChart = myChart;
            }
            let option = {
                title: {
                    text: '数据帧绘制图'
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: function(data) {
                        return  `点${data[0].dataIndex}：<br/>` +
                            ` ${data[0].axisValue} <br/>` +
                            ` ${data[0].marker} ${data[0].seriesName}: ${data[0].value} <br/>` +
                            ` ${data[1].marker} ${data[1].seriesName}: ${data[1].value} <br/>` +
                            ` ${data[2].marker} ${data[2].seriesName}: ${data[2].value} <br/>`
                            // + `${JSON.stringify(data)}`
                    }
                },
                legend: {
                    data: ['X轴','Y轴','Z轴',]
                },
                grid: {
                    left: '3%',
                    right: '6%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: timeData,
                    axisLabel: { minInterval: 1, rotate: -35 }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'X轴',
                        type: 'line',
                        stack: 'Total',
                        data: xData,
                    },
                    {
                        name: 'Y轴',
                        type: 'line',
                        stack: 'Total',
                        data: yData,
                    },
                    {
                        name: 'Z轴',
                        type: 'line',
                        data: zData,
                    },
                ]
            };
            option && myChart.setOption(option);
        },
        getChartsDom() {
            return new Promise(resolve => {
                let num = 0
                let interval = setInterval(() => {
                    let chartDom = this.$refs['device-frame-20015-charts']
                    if (num++ > 5) {
                        clearInterval(interval)
                    }
                    if (chartDom) {
                        clearInterval(interval)
                        return resolve(chartDom)
                    }
                }, 30)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.device-charts-type-11 {
    .device-frame-20015-class {
        width: calc(100vw - 320px);
        min-width: 650px;
        height: 500px;
    }
}
</style>
