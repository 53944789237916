let parseOutFrameCallback = null;

export function getBufHex(str) {
    let arr = str.split(',')
    console.log('arr.length', arr.length)
    if (arr.length === 1) {
        return str
    }
    let buffer = new Uint8Array(arr.length);
    for (let i = arr.length; i >= 0; i--) {
        buffer[i] = parseInt(arr[i],16)
    }
    console.log('arr buffer', buffer)
    let v = new DataView(buffer.buffer);
    let bufHex = v.getUint16(0, true)
    return bufHex
}

export function hexToArrayBuffer(hex) {
    if (typeof hex !== 'string') {
        throw new TypeError('Expected input to be a string')
    }

    if ((hex.length % 2) !== 0) {
        throw new RangeError('Expected string to be an even number of characters')
    }

    let array = new Uint8Array(hex.length / 2);

    for (let i = 0; i < hex.length; i += 2) {
        array[i / 2] = parseInt(hex.substring(i, i + 2), 16)
    }

    return array
}

export function stringToArrayBuffer(content) {
    let array = new Uint8Array(content.length);
    for (let i = 0; i < content.length; i++) {
        array[i] = content.charCodeAt(i)
    }
    return array
}

export function arrayBufferToString(buffer) {
    let content = '';
    for (let i = 0; i < buffer.length; i++) {
        content += String.fromCharCode(buffer[i]);
    }
    return content;
}

export function arrayBufferToHex(buffer) {
    let hex = '';
    for (let i = 0; i < buffer.length; i++) {
        let h = '00' + buffer[i].toString(16);
        hex += h.substr(-2);
    }
    return hex
}

export function makeFrame(cid, data) {
    let dataBuffer = data;
    if (typeof data === 'string') {
        dataBuffer = hexToArrayBuffer(data)
    }
    let buffer = new Uint8Array(4 + dataBuffer.length);
    buffer[0] = 0xFB;
    buffer[2] = cid;
    buffer[3] = dataBuffer.length;
    buffer.set(dataBuffer, 4);
    buffer[1] = calculateChecksum(buffer.slice(2));
    return buffer
}

function calculateChecksum(buffer) {
    let crc = buffer[0];
    for (let i = 1; i < buffer.length; i++) {
        crc = crc ^ buffer[i]
    }
    return crc
}


export function makeFrameSetStatus(status) {
    let buffer = new Uint8Array(2);
    buffer[0] = 0x85;
    buffer[1] = status;
    return makeFrame(0x10, buffer)
}



// sensitivity 0x10, 8E

export function makeFrameSetEnvTrackValue(value) {
    let buffer = new Uint8Array(5);
    buffer[0] = 0x8E;
    let view = new DataView(buffer.buffer);
    view.setUint32(1, parseInt(value), true);
    return makeFrame(0x10, buffer)
}
// 地锁 transport data 0x10, 86

export function makeDevice02FrameWrite(data) {
    let buffer = new Uint8Array(1 + data.length);
    buffer[0] = 0x86;
    buffer.set(stringToArrayBuffer(data), 1);
    return makeFrame(0x10, buffer)
}


// calibrate 0x10, 8A
export function makeFrameSetCalibrate(val) {
    let buffer = new Uint8Array(2);
    buffer[0] = 0x8A;
    buffer[1] = parseInt(val,16);
    return makeFrame(0x10, buffer)
}

// decision time 0x10, 8B
export function makeFrameSetDecisionTime(time) {
    let buffer = new Uint8Array(4);
    buffer[0] = 0x8B;
    let view = new DataView(buffer.buffer);
    view.setUint16(1, parseInt(time), true);
    return makeFrame(0x10, buffer)
}

// sensitivity 0x10, 8C
export function makeFrameSetSensitivity(sensitivity) {
    let buffer = new Uint8Array(2);
    buffer[0] = 0x8C;
    let view = new DataView(buffer.buffer);
    view.setInt8(1, parseInt(sensitivity));
    return makeFrame(0x10, buffer)
}

// decision time 0x10, 8D
export function makeFrameSetHeartbeatTime(time) {
    let buffer = new Uint8Array(4);
    buffer[0] = 0x8D;
    let view = new DataView(buffer.buffer);
    view.setUint16(1, parseInt(time), true);
    return makeFrame(0x10, buffer)
}

// decision time 0x10, 8E
export function makeFrameSetEnvironmentTracking(time) {
    let buffer = new Uint8Array(4);
    buffer[0] = 0x8E;
    let view = new DataView(buffer.buffer);
    view.setUint16(1, parseInt(time), true);
    return makeFrame(0x10, buffer)
}

// ncdp host 0x50, 10 / 90
export function makeFrameSetNcdpHost(host) {
    let buffer = new Uint8Array(1 + host.length);
    buffer[0] = 0x90;
    buffer.set(stringToArrayBuffer(host), 1);
    return makeFrame(0x50, buffer)
}

// udp host 0x50, 14 / 91
export function makeFrameSetNcdpPort(port) {
    let buffer = new Uint8Array(3);
    buffer[0] = 0x91;
    let view = new DataView(buffer.buffer);
    view.setUint16(1, parseInt(port), true);
    return makeFrame(0x50, buffer)
}

// udp host 0x50, 13 / 93
export function makeFrameSetUdpHost(host) {
    let buffer = new Uint8Array(1 + host.length);
    buffer[0] = 0x93;
    buffer.set(stringToArrayBuffer(host), 1);
    return makeFrame(0x50, buffer)
}

// udp host 0x50, 14 / 94
export function makeFrameSetUdpPort(port) {
    let buffer = new Uint8Array(3);
    buffer[0] = 0x94;
    let view = new DataView(buffer.buffer);
    view.setUint16(1, parseInt(port), true);
    return makeFrame(0x50, buffer)
}

export function makeFrameGetDeviceVersion() {
    return makeFrame(0x10, '00')
}
export function makeFrameGetBOOTVersion() {
    return makeFrame(0x10, '01')
}
export function makeFrameGetFirmwareVersion() {
    return makeFrame(0x10, '02')
}
export function makeFrameGetIMEI() {
    return makeFrame(0x10, '03')
}
export function makeFrameGetStatus() {
    return makeFrame(0x10, '05')
}
export function makeFrameGetWorkDesc() {
    return makeFrame(0x10, '07')
}
export function makeFrameCalibration() {
    return makeFrame(0x10, '0A')
}
export function makeFrameDecisionTime() {
    return makeFrame(0x10, '0B')
}
export function makeFrameSensitivity() {
    return makeFrame(0x10, '0C')
}
export function makeFrameHeartbeatTime() {
    return makeFrame(0x10, '0D')
}
export function makeFrameEnvironmentTracking() {
    return makeFrame(0x10, '0E')
}
export function makeFrameGetCarportState() {
    return makeFrame(0x10, '20')
}
export function makeFrameXYZBASE() {
    return makeFrame(0x10, '21')
}
export function makeFrameXYZ() {
    return makeFrame(0x10, '22')
}
export function makeFrameXYZFlow() {
    return makeFrame(0x10, '2C')
}
export function makeFrameVBAT() {
    return makeFrame(0x10, '23')
}
export function makeFrameTemperature() {
    return makeFrame(0x10, '24')
}
export function makeFrameDeviceActivation() {
    return makeFrame(0x10, '26')
}
// reset transport data 0x30, 8C

export function makeFrameReset() {
    let buffer = new Uint8Array(2);
    buffer[0] = 0x8C;
    buffer[1] = 0x00;
    return makeFrame(0x30, buffer)
}

export function makeFrameBluetoothHardwareVersion() {
    return makeFrame(0x30, '00')
}
export function makeFrameBluetoothBootVersion() {
    return makeFrame(0x30, '01')
}
export function makeFrameBluetoothFirmwareVersion() {
    return makeFrame(0x30, '02')
}



// 地磁 transport data 0x50, 86

export function makeDevice01FrameWrite(data) {
    let buffer = new Uint8Array(1 + data.length);
    buffer[0] = 0x86;
    buffer.set(stringToArrayBuffer(data), 1);
    return makeFrame(0x50, buffer)
}





export function makeFrameGetRssi() {
    return makeFrame(0x50, '0A')
}
export function makeFrameGetVersionMsg() {
    return makeFrame(0x50, '0C')
}
export function makeFrameGetError() {
    return makeFrame(0x50, '0D')
}
export function makeFrameGetSendStatus() {
    return makeFrame(0x50, '0E')
}
export function makeFrameGetModuleSignalStatus() {
    return makeFrame(0x50, '0F')
}
export function makeFrameGetAPN() {
    return makeFrame(0x50, '17')
}
export function makeFrameGetNCDPServer() {
    return makeFrame(0x50, '10')
}
export function makeFrameGetNCDPPort() {
    return makeFrame(0x50, '11')
}
export function makeFrameGetUDPServer() {
    return makeFrame(0x50, '13')
}
export function makeFrameGetUDPPort() {
    return makeFrame(0x50, '14')
}

export function parseFrame(buffer) {
    console.log("parse frame:", arrayBufferToHex(buffer));

    let cid = buffer[2];
    let rw = 1;
    if (cid & 0x80 !== 0) {
        rw = 2;
    }

    let reg = buffer[4];
    let data = buffer.slice(5);

    let frame = {};
    frame['reg'] = reg;
    frame['cid'] = cid;
    frame['_buffer'] = buffer;

    if (cid === 0x24) {

    } else {
        let codeBuffer = new Uint8Array(2);
        codeBuffer[0] = cid;
        codeBuffer[1] = reg;
        let codeHex = arrayBufferToHex(codeBuffer).toUpperCase();
        frame['code'] = codeHex;
        let desc = '';
        switch (codeHex) {
            case 'D00A': {
                frame['device_01_proxy_data_binary'] = data;
                frame['device_01_proxy_data_hex'] = arrayBufferToHex(data);
                frame['device_01_proxy_data_string'] = arrayBufferToString(data);
                desc = `透传1: ${frame['device_01_proxy_data_string']}`;
            } break;
            case 'D00C': {
                frame['device_02_proxy_data_binary'] = data;
                frame['device_02_proxy_data_hex'] = arrayBufferToHex(data);
                frame['device_02_proxy_data_string'] = arrayBufferToString(data);
                desc = `透传2: ${frame['device_02_proxy_data_string']}`;
            } break;
            case 'D00D': {
                let v = new DataView(data.buffer);
                frame['errorCode'] = v.getUint16(0, true);
                desc = `错误码: ${frame['errorCode']}`;
            } break;
            case 'D00E': {
                frame['device_01_proxy_data_binary'] = data;
                frame['device_01_proxy_data_hex'] = arrayBufferToHex(data);
                frame['device_01_proxy_data_string'] = arrayBufferToString(data);
                desc = `发送状态: ${frame['device_01_proxy_data_string']}`;
            } break;
            case 'D00F': {
                frame['device_01_proxy_data_binary'] = data;
                frame['device_01_proxy_data_hex'] = arrayBufferToHex(data);
                frame['device_01_proxy_data_string'] = arrayBufferToString(data);
                desc = `模块信号状态: ${frame['device_01_proxy_data_string']}`;
            } break;
            case 'D063': {
                frame['device_01_proxy_data_binary'] = data;
                frame['device_01_proxy_data_hex'] = arrayBufferToHex(data);
                frame['device_01_proxy_data_string'] = arrayBufferToString(data);
                desc = `透传1: ${frame['device_01_proxy_data_string']}`;
            } break;

            case 'D006': {
                frame['device_01_proxy_data_binary'] = data;
                frame['device_01_proxy_data_hex'] = arrayBufferToHex(data);
                frame['device_01_proxy_data_string'] = arrayBufferToString(data);
                desc = `透传1: ${frame['device_01_proxy_data_string']}`;
            } break;

            case 'D010': {
                frame['ncdp_server'] = arrayBufferToString(data);
                desc = `ncdp server: ${frame['ncdp_server']}`;
            } break;

            case 'D011': {
                let v = new DataView(data.buffer);
                frame['ncdp_port'] = v.getUint16(0, true);
                desc = `ncdp port: ${frame['ncdp_port']}`;
            } break;

            case 'D013': {
                frame['udp_server'] = arrayBufferToString(data);
                desc = `udp host: ${frame['udp_server']}`;
            } break;
            case 'D014': {
                let v = new DataView(data.buffer);
                frame['udp_port'] = v.getUint16(0, true);
                desc = `udp Port: ${frame['udp_port']}`;
            } break;

            case 'B000':{
                frame['v'] = data.join(',');
                if (frame['v']) {
                    desc = `蓝牙硬件版本: ${frame['v']}`;
                } else {
                    frame['reset'] = true;
                    desc = `重置完成`;
                }
            } break;

            case 'B001':{
                frame['version'] = '20' + data[0] + '-' + data[2] + '-' + data[1] + ' ' + data[3] + ':' + data[4] + ':' + data[5];
                desc = `蓝牙BOOT版本: ${frame['version']}`;
            } break;

            case 'B002':{
                frame['version'] = '20' + data[0] + '-' + data[2] + '-' + data[1] + ' ' + data[3] + ':' + data[4] + ':' + data[5];
                desc = `蓝牙固件版本: ${frame['version']}`;
            } break;
            // case '9001': {
            //     frame['imei'] = arrayBufferToString(data);
            //     if (data[0] === 0) {
            //         frame['imei'] = 'invalid'
            //     }
            //     desc = `IMEI: ${frame['imei']}`;
            // } break;

            case '9000': {
                frame['version'] = data[0] + ',' + data[1]
                desc = `硬件版本: ${frame['version']}`;
            } break;

            case '9001': {
                frame['version'] = '20' + data[0] + '-' + data[2] + '-' + data[1] + ' ' + data[3] + ':' + data[4] + ':' + data[5];
                desc = `BL版本: ${frame['version']}`;
            } break;

            case '9002': {
                frame['version'] = '20' + data[0] + '-' + data[2] + '-' + data[1] + ' ' + data[3] + ':' + data[4] + ':' + data[5];
                desc = `固件版本: ${frame['version']}`;
            } break;

            case '9003': {
                frame['imei'] = arrayBufferToString(data);
                if (data[0] === 0) {
                    frame['imei'] = 'invalid'
                }
                desc = `IMEI: ${frame['imei']}`;
            } break;

            case '9005': {
                frame['status'] = data[0];
                desc = `设备工作状态: ${data[0]}`;
            } break;

            case '9006': {
                frame['device_02_proxy_data_binary'] = data;
                frame['device_02_proxy_data_hex'] = arrayBufferToHex(data);
                frame['device_02_proxy_data_string'] = arrayBufferToString(data);
                desc = `透传2: ${frame['device_02_proxy_data_string']}`;
            } break;

            case '9007': {
                frame['data_hex'] = arrayBufferToHex(data);
                frame['status_desc'] = arrayBufferToString(data);
                desc = `状态描述: ${frame['status_desc']}`;
            } break;

            case '900A': {
                frame['status'] = data[0] === 0 ? '未校准' : data[0] === 1 ? '校准中' : data[0] === 2 ? '已校准' : 'invalid';
                desc = `地磁校准状态: ${data[0]} ${frame['status']}`;
            } break;

            case '900B': {
                frame['decision_time'] = data.join(',');
                desc = `决策时间: ${frame['decision_time']}`;
            } break;

            case '900C': {
                let v = new DataView(data.buffer);
                frame['sensitivity'] = v.getInt8(0);
                desc = `灵敏度: ${frame['sensitivity']}`;
            } break;

            case '900D': {
                let v = new DataView(data.buffer);
                frame['decision_time'] = v.getUint16(0, true);
                desc = `心跳时间: ${frame['decision_time']}`;
            } break;

            case '900E': {
                let v = new DataView(data.buffer);
                frame['env_track_value'] = v.getUint32(0, true);
                desc = `环境跟踪值: ${frame['env_track_value']}`;
            } break;

            case '9020': {
                frame['carport_status'] = data[0];
                desc = `车位状态: ${frame['carport_status']}`;
            } break;

            case '9021': {
                let v1 = new DataView(data.buffer.slice(0, 4)).getInt32(0, true);
                let v2 = new DataView(data.buffer.slice(4, 8)).getInt32(0, true);
                let v3 = new DataView(data.buffer.slice(8, 12)).getInt32(0, true);
                frame['xyz_base'] = `${v1},${v2},${v3},`
                desc = `XYZBASE: ${frame['xyz_base']}`;
            } break;

            case '9022': {
                let v1 = new DataView(data.buffer.slice(0, 4)).getInt32(0, true);
                let v2 = new DataView(data.buffer.slice(4, 8)).getInt32(0, true);
                let v3 = new DataView(data.buffer.slice(8, 12)).getInt32(0, true);
                let v4 = new DataView(data.buffer.slice(12, 14)).getUint16(0, true);
                frame['xyz'] = `${v1},${v2},${v3},${v4},`
                desc = `xyz: ${frame['xyz']}`;
            } break;

            case '902C': {
                let x1 = new DataView(data.buffer.slice(0, 2)).getInt16(0, true);
                let y1 = new DataView(data.buffer.slice(2, 4)).getInt16(0, true);
                let z1 = new DataView(data.buffer.slice(4, 6)).getInt16(0, true);
                let v1 = new DataView(data.buffer.slice(6, 7)).getInt8(0);
                let x2 = new DataView(data.buffer.slice(7, 9)).getInt16(0, true);
                let y2 = new DataView(data.buffer.slice(9, 11)).getInt16(0, true);
                let z2 = new DataView(data.buffer.slice(11, 13)).getInt16(0, true);
                let v2 = new DataView(data.buffer.slice(13, 14)).getInt8(0);
                desc = `XYZ流量: ${x1},${y1},${z1},${v1},${x2},${y2},${z2},${v2}`;
            } break;

            case '9023': {
                let v = new DataView(data.buffer).getFloat32(0, true);
                frame['VBAT'] = `${v.toFixed(4)}`
                desc = `电池电压: ${frame['VBAT']}`;
            } break;

            case '9024': {
                let v = new DataView(data.buffer).getFloat32(0, true);
                frame['v'] = `${v.toFixed(4)}`
                desc = `温度: ${frame['v']}`;
            } break;
            case '9026': {
                frame['status'] = data[0];
                desc = `设备激活: ${frame['status']}`;
            } break;
        }
        frame['_desc'] = desc
    }

    return frame;
}

// 获取透传数据
export function getProxyDataString(frame) {
    if (typeof frame !== 'object') {
        return
    }

    if (frame['device_01_proxy_data_string'] !== undefined) {
        return frame['device_01_proxy_data_string'];
    }

    if (frame['device_02_proxy_data_string'] !== undefined) {
        return frame['device_02_proxy_data_string'];
    }
}

export function parseNumber(content) {
    let regexp = /\d+/i;
    let parts = regexp.exec(content);
    if (parts == null) {
        return null
    }

    return parts[0];
}

export function parseKeyValueString(content) {
    let regexp = /(([a-zA-Z]+)=(\d+))/g;
    let result = {};
    let matcher;
    while (true) {
        matcher = regexp.exec(content);
        if (!matcher) {
            break
        }
        let key = matcher[2];
        result[key] = matcher[3];
    }

    return result;
}
