import { SPACE_TYPE, QUERY_KEYS_LIST_20000 ,SET_KEYS_LIST_20000, SET_KEYS_LIST_20030 } from './const';

/* 获取查询的key列表 */
export function getQueryKeys(space = '') {
    if (!space) {
        throw {
            message: 'space为空'
        }
    };
    let list = [];
    switch (space) {
        case SPACE_TYPE.VIRTUAL_GROUPING: // 20000(虚拟分组设备)
        // case SPACE_TYPE.UDP_BKV: // 20015(UDP-BKV地磁)
        // case SPACE_TYPE.AEP: // 20023(AEP地磁)
        // case SPACE_TYPE.AEP_BKV: // 20028(AEP-BKV地磁-多租户)
        // case SPACE_TYPE.TING_JIAN_DAN_IOT_PLATFORM: // 20030(停简单物联网平台)
            list = QUERY_KEYS_LIST_20000;
            break;
        // case SPACE_TYPE.NEW_COAP_BKV: // 20029(新CoAP-BKV地磁)
        //     list = QUERY_KEYS_LIST_20029;
        //     break;
        default:
            break;
    }
    return list;
}

/* 获取设置的key列表 */
export function getSetKeys(space = '') {
    if (!space) {
        throw {
            message: 'space为空'
        }
    };
    let list = [];
    switch (space) {
        case SPACE_TYPE.VIRTUAL_GROUPING: // 20000(虚拟分组设备)
        // case SPACE_TYPE.UDP_BKV: // 20015(UDP-BKV地磁)
        // case SPACE_TYPE.AEP: // 20023(AEP地磁)
        // case SPACE_TYPE.AEP_BKV: // 20028(AEP-BKV地磁-多租户)
        // case SPACE_TYPE.NEW_COAP_BKV: // 20029(新CoAP-BKV地磁)
            list = SET_KEYS_LIST_20000;
            break;
        case SPACE_TYPE.TING_JIAN_DAN_IOT_PLATFORM: // 20030(停简单物联网平台)
            list = SET_KEYS_LIST_20030;
            break;
        default:
            break;
    }
    return list;
}