<template>
    <aside class="'enu-expanded">
        <div style="width:100%">
            <!--导航菜单-->
            <el-menu :default-active="$route.path"
                class="el-menu-vertical"
                text-color="#B9BFCB"
                active-text-color="#FFFFFF"
                unique-opened
                router
                @open="openMenu" @select="selectMenu" ref="menu"
            >
                <template v-for="(item, index) in authRouter">
                    <el-submenu :index="item.path+''" v-if="!item.is_leaf">
                        <template slot="title" style="padding: 0">
                            <div class="inner">
                                <div class="menu-inner">
                                    <em class="iconfont" :class="item.meta.icon"></em>
                                    <span class="name">{{item.meta.title}}</span>
                                </div>
                            </div>
                        </template>
                        <el-menu-item v-for="child in item.children"
                            :index="`${item.path}/${child.path}`"
                            :key="child.path"
                            v-if="showMenuItem(child)">
                            <div class="inner">
                                <div class="menu-inner child">
                                    <div class="active-space"></div>
                                    <span class="name">{{child.meta.title}}</span>
                                </div>
                            </div>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item v-if="item.is_leaf && item.children.length > 0" :index="item.path">
                        <div class="menu-inner">
                            <em class="iconfont" :class="item.meta.icon"></em>
                            <span class="name">{{item.children[0].meta.title}}</span>
                        </div>
                    </el-menu-item>
                </template>
            </el-menu>
        </div>
    </aside>
</template>

<script>
    import Core from '@/core'
    import routes from '@/router/routes.js';

    const USER_TYPE = Core.Const.USER_TYPE;

    export default {
        name: 'app-sidebar',
        props: {
            route: {
                type: Object
            },
            config: {
                type: Object
            },
            router: {
                type: Object
            },
        },
        data() {
            return {
                hiddenRoute: [],
                changeType:false,
                userType: Core.Data.getUserType(),
            }
        },
        computed: {
            authRouter() {
                let showList = routes.filter((item) => {
                    // console.log("showList  item:", item)
                    const meta = item.meta || {};
                    const roles = meta ? meta.roles : '';

                    const roles_flag = roles ? roles.includes(this.userType) : true;

                    let auth_flag = true;
                    switch (this.userType){
                        case USER_TYPE.USER:
                            auth_flag = this.$auth(...(meta.user_auth ? meta.user_auth : []))
                            break;
                        case USER_TYPE.ADMIN:
                            break;
                    }

                    return !meta.hidden && roles_flag && auth_flag;
                });

                showList = showList.map(item => {
                    const meta = item.meta || {};
                    item.is_leaf = meta.is_leaf || false;
                    item.title = meta.title || '';
                    item.auth = meta.auth || [];
                    return item;
                })

                return showList;
            },

        },
        watch: {
            '$route.path': (val) => {
                // this.openTargetSubMenu(val);
            }
        },
        mounted(){
            let path = this.$route.path;
            this.openTargetSubMenu(path);
        },
        methods: {
            showMenuItem(child) {
                const meta = child.meta || {};
                const hidden_flag = !meta.hidden;

                return hidden_flag;
            },
            openTargetSubMenu(path) {
                if (!path) { return }
                let arr = path.split('/');
                if (arr.length <= 2) {
                    return
                }
                this.$refs["menu"].open(`/${arr[1]}`)
            },

            openMenu(index, indexPath, menuItem) {
                console.log("open-menu")
            },

            selectMenu() {
                // 关掉二级菜单
                for (let i = 0; i < this.$refs.menu.$children.length; i++) {
                    let m = this.$refs.menu.$children[i];
                    if (!m.active && m.$children.length > 0) {
                        this.$refs.menu.close(m.index);
                    }
                }
            },

            closeMenu() {
                console.log('close menu')
            },

            handleClose() {

            },
        }
    }
</script>

<style scoped lang="scss">
    aside {
        overflow: auto;
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Chrome */
        display: flex;

        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;

        align-content: space-between;
        width: 200px;
        height: 100%;
        z-index: 9;

        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;

        background: #323943;
        &::-webkit-scrollbar {
            display: none;
            width: 0 !important;
        }
    }
    em.iconfont {
        position: relative;
        top: 0;
        opacity: 0.7;
        font-style: normal;
        font-size: 16px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
    }


    /**------------------------------------
        menu icon start
    ------------------------------------**
    em.icon {
        position: relative;
        top: 0;
        opacity: 0.7;
    }

    em.icon-home {
        font-style: normal;
        width: 14px;
        height: 14px;
        display: inline-block;
        background: url("../../assets/img/sidebar/icon-home@2x.png") no-repeat center;
        background-size: cover;
        margin-left: 19px;
    }

    em.icon-device {
        font-style: normal;
        width: 14px;
        height: 13px;
        display: inline-block;
        background: url("../../assets/img/sidebar/icon-device@2x.png") no-repeat center;
        background-size: cover;
        margin-left: 19px;
    }

    em.icon-user {
        font-style: normal;
        width: 14px;
        height: 14px;
        display: inline-block;
        background: url("../../assets/img/sidebar/icon-user.png") no-repeat center;
        background-size: cover;
        margin-left: 19px;
    }

    em.icon-group {
        font-style: normal;
        width: 14px;
        height: 14px;
        display: inline-block;
        background: url("../../assets/img/sidebar/icon-group.png") no-repeat center;
        background-size: cover;
        margin-left: 19px;
    }

    em.icon-cloud-debug {
        font-style: normal;
        width: 14px;
        height: 14px;
        display: inline-block;
        background: url("../../assets/img/sidebar/icon-cloud-debug.svg") no-repeat center;
        background-size: cover;
        margin-left: 19px;
    }

    em.icon-admin {
        font-style: normal;
        width: 14px;
        height: 14px;
        display: inline-block;
        background: url("../../assets/img/sidebar/icon-admin.png") no-repeat center;
        background-size: cover;
        margin-left: 19px;
    }

    em.icon-update {
        font-style: normal;
        width: 14px;
        height: 14px;
        display: inline-block;
        background: url("../../assets/img/sidebar/icon-update@2x.png") no-repeat center;
        background-size: cover;
        margin-left: 19px;
    }

    /**------------------------------------
        menu icon end
    ------------------------------------**/

</style>
