<template>
    <el-dialog
            class="delete-dialog"
            :title="$t('device.systemPrompt')"
            :visible.sync="dialogVisible"
            width="500px"
            >
       <div class="content">
            <img src="../assets/img/icon/icon-warning.png" class="icon-warn">
           <div class="text">
               <div class="title">{{titleText}}</div>
           </div>
       </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">{{$t('device.close')}}</el-button>
    <el-button type="danger" @click="confirm">{{$t('device.done')}}</el-button>
  </span>
    </el-dialog>
</template>

<script>
    import Core from "@/core";
    export default {
        name: "delete-dialog",
        data() {
            return {
	            titleText: ''
            }
        },
        props: {
            visible: '',
            propTitleText: ''
        },
        computed: {
            dialogVisible:  {
                get() {
                    return this.visible
                },
                set(val) {
                    this.$emit('update:visible', val)
                }
            }
        },
        mounted() {
            this.initLang()
            this.initTitleText()
        },
        methods: {
            initLang() {
                this.lang = Core.Data.getLang()
            },
            initTitleText() {
                if (this.titleText !== '') {
                    return
                }

                if (this.lang === 'en') {
                    this.titleText = "After The Device Is Removed, All Data For That Device On The Platform Is Cleared ？"
                } else {
                    this.titleText = this.propTitleText ? this.propTitleText : '确定删除该设备?'
                }
            },
            confirm() {
                this.$emit('confirm')
            },
        }
    }
</script>

<style scoped lang="scss">
    .delete-dialog {
        .content {
            text-align: center;
            padding: 40px;
        }
        .icon-warn {
            width: 52px;
            height: 52px;
            margin-right: 30px;
            vertical-align: middle;
        }
        .text {
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            max-width: 270px;
            .title {
                font-size: 16px;
                color: #34383E;
                margin-bottom: 10px;
            }
            .desc {
                font-size: 12px;
                color: #8E949C;
                word-break: break-all;
            }
        }
    }
</style>
