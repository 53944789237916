<template>
	<section class="content-container">
		<div class="content-header">
			<span class="title" v-if="$route.path === '/help'"> 对接信息 </span>
			<span class="title" v-else>
				{{ $route.meta.title || '' }}
			</span>
		</div>
		<div class="content-warp">
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</div>
	</section>
</template>

<script>
export default {
	name: 'app-content',
	data() {
		return {};
	},
};
</script>

<style scoped lang="scss">
.content-container {
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	.content-header {
		height: 40px;
		line-height: 40px;
		background: white;
		border-bottom: 1px solid #dce2e8;
		font-size: 0;
		.title {
			color: #34383e;
			font-size: 12px;
			padding-left: 20px;
			cursor: default;
		}
	}
	.content-warp {
		flex: 1;
	}
}
</style>
