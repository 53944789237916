import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Core from '@/core'
import eleLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n)

const DEFAULT_LANG = 'zh'
const LOCALE_KEY = 'localeLanguage'

let zh = require('./zh.json');
zh.el = zhLocale.el;
let en = require('./en.json');
en.el = enLocale.el
const locales = {
    zh: zh,
    en: en
}

const i18n = new VueI18n({
    locale: DEFAULT_LANG,
    messages: locales
})
// elements
eleLocale.i18n((key, value) => i18n.t(key, value))
export const setup = lang => {
    if(lang === undefined) {
        lang = Core.Data.getLang()
        if(locales[lang] === undefined) {
            lang = DEFAULT_LANG
        }
    }
    Core.Data.setLang(lang)

    Object.keys(locales).forEach(lang => {
        document.body.classList.remove(`lang-${lang}`)
    })
    document.body.classList.add(`lang-${lang}`)
    document.body.setAttribute('lang',lang)

    // 将当前语种存储到Vue的全局配置中,以便未来使用
    Vue.config.lang = lang
    i18n.locale = lang
}

setup()
window.i18n = i18n;// 2
export default i18n
