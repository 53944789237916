import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const state = {//要设置的全局访问的state对象
    count: 0,
    userList: [],
};

const mutations = {
    increment: state => state.count++,
    decrement: state => state.count--,
    setUserList(state, value) {
        state.userList = value
    }
};

const getters = {
    getCount: state => {
        return state.count
    },
    getUserList: state => {
        return state.userList
    }
};


const store = new Vuex.Store({
    state,
    mutations,
    getters
});


export default store
