import { render, staticRenderFns } from "./edit-label.vue?vue&type=template&id=2457c0c2&scoped=true"
import script from "./edit-label.vue?vue&type=script&lang=js"
export * from "./edit-label.vue?vue&type=script&lang=js"
import style0 from "./edit-label.vue?vue&type=style&index=0&id=2457c0c2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2457c0c2",
  null
  
)

export default component.exports