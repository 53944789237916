const aijoy = {
    // endpoint : 'http://118.31.40.16:7909',
    // endpoint : 'http://api.relay.fegiot.com:7909',
    endpoint: 'https://v2.api.airjoy.rebuildsoft.com',
}

const aijoydev = {
    // endpoint : 'https://api-dev.v2.airjoy.fegiot.com',
    endpoint: 'https://v2.api.airjoy.rebuildsoft.com',  // 线上
    // endpoint: 'http://10.0.0.122:8083',  // 徐伟
}

let config = aijoy;

let host = window.location.hostname;
console.log("host:", host);

if (host.includes("10.0.0.") || host.includes("localhost")) {
    // 本地
    // console.log('???dev7cjQqnB7ADFYs6FQ')
    console.log('本地');
    console.log('本地账号：===>', 'dev');
    console.log('本地密码：===>', '7cjQqnB7ADFYs6FQ');
    config = aijoydev;
} else if (host.includes("v2-dev.admin.airjoy.fegiot.com")) {
    console.log('dev');
    config = aijoydev;
} else {
    console.log('线上');
    config = aijoy;
}

export default config;