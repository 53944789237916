import Const from '../const'
import * as Util from "../util"

let baseUrl = Const.NET.END_POINT

// for demo
if (Util.isDemo()) {
    baseUrl = 'http://112.124.16.7:7909/'
}

export {
    baseUrl
}