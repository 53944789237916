<template>
    <div class="relay-info">
        <div class="row">
            <div class="strategy-holder">
                    <div class="title">{{$t('device.groupStrategy')}}:</div>
                    <div class="strategy">{{ groupRelayConfig | strategy(lang) }} </div>
            </div>
            <div class="content">
                <label class="content-title">{{$t('device.pushAddress')}}:</label>
                <img src="../assets/img/icon/icon-net.png" class="icon-net" />
                <label class="url">{{ groupRelayConfig | endpoint }}</label>
            </div>
        </div>


        <div class="row">
            <div class="strategy-holder">
                <div class="title">{{$t('device.userStrategy')}}:</div>
                <div class="strategy">{{ userRelayConfig | strategy(lang) }} </div>
            </div>
            <div class="content">
                <label class="content-title">{{$t('device.pushAddress')}}:</label>
                <img src="../assets/img/icon/icon-net.png" class="icon-net" />
                <label class="url">{{ userRelayConfig | endpoint }}</label>
            </div>
        </div>


        <div class="row">
            <div class="strategy-holder">
            <div class="title">{{$t('device.FinalStrategy')}}:</div>
            <div class="strategy">{{ finalRelayConfig | strategy(lang)}}</div>
            </div>
            <div class="content">
                <label class="content-title">{{$t('device.pushAddress')}}</label>
                <img src="../assets/img/icon/icon-net.png" class="icon-net" />
                <label class="url">{{ finalRelayConfig | endpoint }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Core from '@/core'
    export default {
        name: "relay-config",
        props: {
            uid: '',
            space: '',
        },
        data() {
            return {
                groupRelayConfig: {},
                userRelayConfig: {},
                finalRelayConfig: {},
                lang: 'zh',
            }
        },
        filters: {
            strategy: function (data,detail) {
                if (!data) {
                    return '';
                }

                if (!data.hasOwnProperty('strategy')) {
                    return '';
                }
	            return Vue.filter('strategyType')(data['strategy'],detail);
            },

            endpoint: function (data) {
                if (!data) {
                    return '';
                }

                if (!data.hasOwnProperty('endpoint')) {
                    return '';
                }

                return data['endpoint'];
            }
        },
        mounted: function() {
	        this.initLang()
            this.updateConfig();
        },
        watch: {
            uid: function (val) {
                this.updateConfig();
            }
        },

        methods: {
	        initLang() {
		        this.lang = Core.Data.getLang()
	        },
            updateConfig() {
                if (!this.uid) { return }
                Core.Api.Device.getRelayConfig(this.uid, this.space).then(res => {
                    // console.log('update relay config', res);
                    let relayConfig = res || {};
                    this.groupRelayConfig = relayConfig['group_relay_config'];
                    this.userRelayConfig = relayConfig['user_relay_config'];
                    this.finalRelayConfig = relayConfig['final_relay_config'];

                    this.$forceUpdate();
                })
            }
        }

    }
</script>

<style scoped lang="scss">
    .relay-info {
        overflow: hidden;
        .row {
            margin: 14px;
            overflow: hidden;
            .strategy-holder {
                float: left;
                vertical-align: middle;
            }
            .title {
                vertical-align: middle;
                display: inline-block;
                width: 100px;
                font-size: 12px;
                color: #8E949C;
                letter-spacing: 0;
                height: 30px;
                line-height: 30px;
            }

            .strategy {
                vertical-align: middle;
                display: inline-block;
                min-width: 100px;
                margin-right: 10px;
                font-size: 12px;
                color: #50555D;
                letter-spacing: 0;
                height: 30px;
                line-height: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .content {
                margin-left: 200px;
                height: 30px;
                line-height: 30px;
                background: #F7F8FA;
                padding: 0 10px;
                .content-title {
                    font-size: 12px;
                    color: #8E949C;
                    letter-spacing: 0;
                }
                .icon-net {
                    width: 12px;
                    height: 12px;
                    margin: 0 5px;
                    vertical-align: middle;
                    position: relative;
                    top: -1px;
                }
                .url {
                    font-size: 12px;
                    color: #08B76B;
                    letter-spacing: 0;
                }
            }
        }

    }

</style>
