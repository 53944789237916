import Const from "./const";
import Api from "./api"
import Locutus from "./locutus";
import * as Data from './data'
import Filter from "../filters";

let LOADING_FLAG = Const.SYSTEM.LOG_LEVEL_DEBUG;
let DateFormatNoTime = 'Y.m.d';
let DateFormatNoYear = 'm-d H:i';

export function createDeviceTypeApi(form, afterCreate) {
    let space = form['space'];
    switch (space) {
        case '20003':
            break;
        case '20006':
            Api.Device20006.saveDevice(0, form.uid, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20009':
            Api.Device20009.saveDevice(0, form.uid, form.imsi, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20011':
            break;
        case '20015':
            // saveDevice: ['device/20015/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address'],
            Api.Device20015.saveDevice(0, form.uid, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20018':
            // saveDevice: ['device/20018/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type'],
            Api.Device20018.saveDevice(0, form.uid, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20020':
            Api.Device20020.saveDevice(0, form.uid, form.pin_code, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20023':
            // saveDevice: ['device/20023/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type'],
            Api.Device20023.saveDevice(0, form.uid, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20024':
            // saveDevice: ['device/20023/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type'],
            Api.Device20024.saveDevice(0, form.uid, form.target_user_id, form.target_group_id, form.park_no, form.address, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20025':
            // saveDevice: ['device/20025/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type'],
            Api.Device20025.saveDevice(0, form.uid, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20027':
            // saveDevice: ['device/20027/save', 'id', 'uid', 'imsi', 'user_id', 'group_id', 'park_no', 'address', 'park_type'],
            Api.Device20027.saveDevice(0, form.uid, form.imsi, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20028':
            // saveDevice: ['device/20028/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type'],
            Api.Device20028.saveDevice(0, form.uid, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;
        case '20029':
            // saveDevice: ['device/20028/save', 'id', 'uid', 'user_id', 'group_id', 'park_no', 'address', 'park_type'],
            Api.Device20029.saveDevice(0, form.uid, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;

        case '20030':
            Api.Device20030.saveDevice(0, form.uid, form.target_user_id, form.target_group_id, form.park_no, form.mac).then(res => {
                afterCreate();
            })
            break;

        case '20031':
            // saveDevice: ['device/20031/save', 'id', 'uid', 'imsi', 'user_id', 'group_id', 'park_no', 'address', 'park_type'],
            Api.Device20031.saveDevice(0, form.uid, form.imsi, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;

        case '20032':
            Api.Device20032.saveDevice(0, form.uid, form.pin_code, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;

        case '20033':
            Api.Device20033.saveDevice(0, form.uid, form.pin_code, form.target_user_id, form.target_group_id, form.park_no, form.address, form.park_type, form.mac).then(res => {
                afterCreate();
            });
            break;

        default: break
    }
}

export function parseDate(date) {
    return parseInt(new Date(date).getTime() / 1000);
}

export function clearData(data) {
    Object.keys(data).forEach(function (key) {
        if (is('object', data[key])) {
            data[key] = {};
        }
        if (is('array', data[key])) {
            data[key] = [];
        }
        if (is('number', data[key])) {
            data[key] = "";
        }
        if (is('string', data[key])) {
            data[key] = "";
        }
    })
}

export function is(type, obj) {
    let item;
    item = Object.prototype.toString.call(obj).slice(8, -1);
    return obj !== undefined && obj !== null && item.toLowerCase() === type.toLowerCase()
}

/** 判断当前登录用户是否有某项权限
 * @param {String,String} keys 权限的关键字
 */
export function auth(...arr) {
    if (!arr.length) {
        return true;
    }
    const rolesMap = Data.getAuthority() || {};
    return arr.some((key) => {
        return rolesMap[key];
    });
}

/** 设置权限信息到本地 */
export function setAuthority(authority = {}) {
    let authorityMap = Data.getAuthority() || {};
    authorityMap = {
        ...authorityMap,
        ...authority,
    }
    Data.setAuthority(authorityMap);
}

/**
 * 判断传入的对象是否为空
 * @param {Object} obj
 */
export function isEmptyObj(obj) {
    if (!obj) {
        return true;
    }
    return !Object.keys(obj).length;
}

/**
 * 获取api路径
 * 是用户端还是管理员端
 */
export function getApiRoute() {
    let userType = Data.getUserType();
    const USER_TYPE = Const.USER_TYPE;
    let route = 'admin/1';
    switch (userType) {
        case USER_TYPE.ADMIN:
            route = 'admin/1';
            break;
        case USER_TYPE.USER:
            route = 'client/1';
            break;
    }
    
    return route;
}

// 拼接参数
export function stringify(obj) {
    // transformObjectToUrlencodedData
    let p = [];
    for (let key in obj) {
        if (obj[key] == null || obj[key] == undefined) {
            // p.push(`${encodeURIComponent(key)}=`);
            p.push(`${key}=`);
        } else {
            // p.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
            p.push(`${key}=${obj[key]}`);
        }
    }
    return p.join('&');
}

export function clone(obj) {
    let o;
    if (typeof obj === "object") {
        if (obj === null) {
            o = null;
        } else {
            if (obj instanceof Array) {
                o = [];
                for (let i = 0, len = obj.length; i < len; i++) {
                    o.push(clone(obj[i]));
                }
            } else {
                o = {};
                for (let j in obj) {
                    o[j] = clone(obj[j]);
                }
            }
        }
    } else {
        o = obj;
    }
    return o;
}

export function time() {
    return parseInt(new Date().getTime() / 1000, 10);
}

export function convertMoney(money) {
    if (!money) {
        return 0;
    }
    return parseInt(money) * 100;
}

export function get(obj, key) {
    return key.split(".").reduce(function (o, x) {
        return (typeof o === "undefined" || o === null) ? o : o[x];
    }, obj);
}

export function digitLength(data, length) {
    return Locutus.sprintf('%0' + length + 'd', data);
}

export function sortType(prop, order) {
    if (order === null) return ''
    let sortList = Const.DEVICE_SORT_PROP_LIST;
    let index = sortList.findIndex(item => item === prop);
    // 'ascending'  'descending'
    let temp = 0;
    if (order === 'descending') {
        temp = -1;
    }
    return index > -1 ? 2 * (index + 1) + temp : 1;
}

export function isOff(timestamp) {
    if (timestamp == 0) {
        return '离线';
    }

    let now = Date.parse(new Date()) / 1000;
    let difference = now - timestamp;

    if (difference > 86400) {
        return '离线';
    } else {
        return '在线';
    }
}

export function getStateType(device) {
    let state = device['status'];

    if (state !== undefined) {
        return Const.ONLINE_STATUS_TYPE[parseInt(state)];
    }
    let timestamp = device['last_active_time'];
    if (timestamp == 0 || timestamp === undefined) {
        return '离线';
    }

    let now = Date.parse(new Date());
    let difference = now - timestamp;
    if (difference < 86400) {
        return '在线';
    } else {
        return '离线';
    }
}

export function parkStateType(type, detail) {
    console.log(detail)
    if (detail === 'en') {
        if (type === undefined || !type) {
            return 'Non car'
        }
        if (type === 1) {
            return 'Car'
        }
        if (type === 2) {
            return 'Sensor Exception'
        }
        if (type === 1) {
            return 'OverflowError'
        }
    } else {
        if (type === undefined || !type) {
            return '无车'
        }
        if (type === 1) {
            return '有车'
        }
        if (type === 2) {
            return '传感器异常'
        }
        if (type === 1) {
            return '数据溢出'
        }
    }
}

export function isDemo() {
    // let host = window.location.hostname;
    let host = window.location.host;
    let demoHosts = ['v2.demo.admin.airjoy.fegiot.com', 'v2.demo.airjoy.fegiot.com', '112.124.16.7:8001', '112.124.16.7:8002', 'xdemo:8001', 'xdemo:8002'];
    if (demoHosts.indexOf(host) > 0) {
        return true
    }

    return false;
}

export function getUserType() {
    let host = window.location.host;
    let userHosts = ['v2.airjoy.fegiot.com', 'v2.ajaiot.com', 'v2.demo.airjoy.fegiot.com', '112.124.16.7:8002', 'xdemo:8002', '118.31.40.16:7911'];
    if (userHosts.indexOf(host) >= 0) {
        console.log("userType: user");
        return Const.USER_TYPE.USER;
    }

    console.log("userType: admin");

    return Const.USER_TYPE.ADMIN;
    // return Const.USER_TYPE.USER;
}

export function BindParkStatus(val) {
    let MAP = Const.BIND_PARK.STATUS_MAP
    switch (val) {
        case MAP.USING: {
            return '使用中'
        }; break;
        case MAP.NOTUSED: {
            return '未使用'
        }; break;
    }
}

export function getVirtualStrategySpace(val, to = 'space') {
    const MAP = Const.VIRTUAL_SPACE_TYPE_MAP;
    const ITEM = MAP[val + ''] || {};
    return ITEM[to] || '-';
}

// 设备在线状态
export function deviceOnlineStatusFilter(val, to = 'text') {
    const MAP = Const.DEVICE.ONLINE_STATUS_MAP;
    const ITEM = MAP[val + ''] || {};
    return ITEM[to] || '';
    // return ITEM[to] || '-';
}

// 离线判断时间阈值
export function deviceOnlineTimeThresholdFilter(val, symbol = '') {
    if (!val) return symbol ? Const.DEVICE.ACTIVE_TIME_THRESHOLD + symbol : Const.DEVICE.ACTIVE_TIME_THRESHOLD

    let h = parseInt(Number(val) / 3600)
    if (!h) return symbol ? Const.DEVICE.ACTIVE_TIME_THRESHOLD + symbol : Const.DEVICE.ACTIVE_TIME_THRESHOLD

    return symbol ? h + symbol : h
}

/**
 * 获取设备基本信息列表
 * @param {*} list 
 */
export function getInfoList(list = [], device = {}) {
    if (!list.length) return [];
    if (isEmptyObj(device)) return list;
    let infoList = [];

    let pushInfo = function (key, name, filter) {
        if (!device.hasOwnProperty(key)) {
            return
        }

        let value = '';
        if (filter) {
            value = Filter[filter](device[key]);
        } else {
            value = device[key] || '-';
        }

        infoList.push({
            name: name,
            value,
        })
    };

    for (var i = 0; i < list.length; i++) {
        let item = list[i];
        pushInfo(item.key, item.name, item.filter);
    }

    return infoList;
}

/**
 * 获取设备基本信息列表
 * @param {*} list 
 */
export function getFengineInfoList(list = [], device = {}) {
    if (!list.length) return [];
    if (isEmptyObj(device)) return list;
    let infoList = [];

    let pushInfo = function (name, filter, param = []) {
        let value = '';
        if (filter) {
            value = Filter[filter](device, ...param);
        } else {
            value = '-';
        }

        infoList.push({
            name: name,
            value,
        })
    };

    for (var i = 0; i < list.length; i++) {
        let item = list[i];
        pushInfo(item.name, item.filter, item.param);
    }

    return infoList;
}

/**
 * 导出
 * @param {*} path 路径
 * @param {*} form  
 */
export function handleExport(path = '', form = {}) {
    if (!path) throw { message: i18n.t('common.invalid') + 'path' };

    let token = Data.getToken();
    if (!token) throw { message: i18n.t('common.invalid') + 'token' };

    form = {
        ...form,
        token,
    }

    let data = stringify(form);

    window.open(`${Const.NET.END_POINT}/${getApiRoute()}/${path}?${data}`)
}

export function isInDebug() {
    return LOG_LEVEL === Const.SYSTEM.LOG_LEVEL_DEBUG;
}

export function getStrategyList() {
    if (isDemo()) {
        return Const.STRATEGY_TYPE_LIST_IN_DEMO;
    }

    return Const.STRATEGY_TYPE_LIST;
}

// 虚拟策略
export function getVirtualStrategyList() {
    // if (isDemo()) {
    //     return Const.STRATEGY_TYPE_LIST_IN_DEMO;
    // }

    return Const.VIRTUAL_STRATEGY_TYPE_LIST;
}

export function getKeysByStrategy(id) {
    if(id) {
        if(Const.STRATEGY_TYPE_JSON_KEY.find(item => item.id === id)) {
            return Const.STRATEGY_TYPE_JSON_KEY.find(item => item.id === id).strategy_keys
        }
    }
}

// 虚拟分组
export function getKeysByVirtualStrategy(id) {
    if(id) {
        if(Const.VIRTUAL_STRATEGY_TYPE_JSON_KEY.find(item => item.id === id)) {
            return Const.VIRTUAL_STRATEGY_TYPE_JSON_KEY.find(item => item.id === id).strategy_keys
        }
    }
}

/* ======== 通用方法 ========== */
/** 检测字符串是否符合预设的正则规则
	 * @param {String} str 需要检测的字符串
	 * @param {String} type 正则规则类型
	 * @param {RegExp} custom 当type为“custom”需传入自定义正则
	 */
export function regRuleTest(str = '', type, custom) {
    // console.log('regRuleTest str:', str, ' type:', type);
    str = str.toString();
    if (!str) {
        return false;
    }
    str.trim();
    let regRule = '';
    switch (type) {
        case 'int':
            regRule = /^[1-9]\d*$/;
            break; // 整数
        case 'float1':
            regRule = /^(\d+)(\.[0-9]{0,1})?$/;
            break; // 一位小数
        case 'float2':
            regRule = /^(\d+)(\.[0-9]{0,2})?$/;
            break; // 两位小数

        case '400':
            regRule = /^400([-]?)[0-9]{7}$/;
            break; // 400电话
        case '800':
            regRule = /^800([-]?)[0-9]{7}$/;
            break; // 800电话
        case 'phone':
            regRule = /^1[3456789]\d{9}$/;
            break; // 手机号
        case 'landline':
            regRule = /^(\d{1,3})([1-9]\d{1,2})([-]?)(\d{7,8})$/;
            break; // 固话
        case 'contact':
            regRule = /(^400([-]?)[0-9]{7}$)|(^800([-]?)[0-9]{7}$)|(^1[3456789]\d{9}$)|(^\d{1,3})([1-9]\d{1,2})([-]?)(\d{7,8}$)/;
            break; // 400正则 | 800正则 | 手机号 | 固话
        case 'email':
            regRule = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            break; // 邮箱

        case 'idcard18':  // 身份证 18位
            regRule = /^([1-6][1-9]|50)\d{4}(18|19|20|21)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
            break;
        case 'idcard15':  // 身份证 15位
            regRule = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
            break;
        case 'password': // 长度8-25位，且须包含大小写字母和特殊字符(如!@#$%^&*)
            regRule = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_]).{8,25}$/
            break;

        case 'custom':
            regRule = custom;
            break; // 手动传入正则规则
    }
    // console.log('regRule:', regRule);
    if (regRule) {
        return regRule.test(str);
    }
    return false;

    /*
    let regRule = /^(?=.+[A-Z])(?=.+[a-z])(?=.+[\\W_])(?=.*\d).{8,25}$/
    let str = '123@abAb7'
    console.log('regRule.test(str)', regRule.test(str));
    */
}
/* ======== 通用方法 ========== */
