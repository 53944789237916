<template>
    <div class="device-prop-list">
        <div class="info-row" v-for="(item,key) in itemList" :key="key">
            <div class="info-item">
                <label class="info-item-title">{{item[0].name}}:</label>
                <label class="info-item-value">{{item[0].value}}</label>
            </div>
            <div class="info-item">
                <div v-if="item.length > 1">
                    <label class="info-item-title">{{item[1].name}}:</label>
                    <label class="info-item-value">{{item[1].value}}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Core from '@/core'
    export default {
        name: "device-pro",
        props: {
            propList: Array,
        },
        data() {
            return {
                itemList: [],
            }
        },
        mounted: function() {
            this.parsePropList();
        },
        watch: {
            propList: function (val) {
                this.parsePropList();
            }
        },

        methods: {
            parsePropList() {
                // console.log('[parsePropList]', 'origin', this.propList);
                let itemList = [];
                let propCount = this.propList.length;
                let size = Math.floor(propCount / 2) + (propCount % 2);
                // console.log('[parsePropList]', 'size', size);
                for (let i = 0; i < size; i++) {
                    let item = [];
                    item.push(this.propList[i * 2]);
                    if (i * 2 + 1 < propCount) {
                        item.push(this.propList[i * 2 + 1]);
                    }
                    itemList.push(item)
                }
                this.itemList = itemList;
                // console.log('[parsePropList]', 'itemList', this.itemList);
            }
        }

    }
</script>

<style scoped lang="scss">
    .device-prop-list {
        .info-row {
            border-bottom: 1px solid #DCE2E8;
            font-size: 0;
            .info-item {
                width: 50%;
                display: inline-block;
                border-right: 1px solid #DCE2E8;
                padding: 0 20px;
                vertical-align: middle;
                & + .info-item {
                    border-right: none;
                }
                .info-item-title {
                    display: inline-block;
                    width: 150px;
                    font-size: 12px;
                    color: #8E949C;
                    height: 44px;
                    line-height: 44px;
                    vertical-align: middle;

                }
                .info-item-value {
                    display: inline-block;
                    font-size: 12px;
                    color: #50555D;
                    height: 44px;
                    line-height: 44px;
                    vertical-align: middle;
                }
            }
        }
    }

</style>
