import axios from 'axios';
import { Message } from 'element-ui';
import Const from '../const'
import Core from '../../index'
import router from '../../../router'

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const showMessage = (msg) => {
    Message.error(msg);
};

const errorHandle = (status, message = '未知错误', code) => {
    if (message.includes('invalid token')) {
        showMessage('无效的身份凭证，请重新登录');
        Core.Data.clearAuthData();
        router.push({ path: '/login' });
        return
    }
    if (message.includes('timeout')) {
        showMessage('请求超时');
        return
    }
    if (code === 5000) {
        return
    }
    showMessage(message);
};

var instance = axios.create({ timeout: 1000 * 15 });

instance.interceptors.request.use(
    (config) => {
        config.cancelToken = source.token
        return config
    },
    (error) => Promise.error(error)
);

instance.interceptors.response.use(
    res => {
        // let reg =  /^fengine\/\d+\/device\/detail$/
        // console.log('Response  res:', res)
        if (res.status === 200) {
            if (res.data.code === Const.ERROR.ERROR_TOKEN_INVALID) {
                errorHandle(res.data.code, res.data.message);
                return;
            } 
            if (res.data.code === 0) {
                if (res.data.error) {
                    errorHandle(res.data.code, res.data.error);
                    return Promise.reject(res);
                }
                return Promise.resolve(res.data.data);
            }

            // 错误
            return Promise.reject(res.data);

            // // uri 过滤掉 fengine/20015/device/detail
            // console.log("过滤",reg.test(api[0]));
            // if(!reg.test(api[0])) {
            //     handleError(body.message);
            // }
        } else {
            return Promise.reject(res);
        }
    },
    (error) => {
        console.log('Response error:', error)
        const { response } = error;

        // 主动取消
        if (axios.isCancel(error)) {
            return Promise.reject(response);
        }

        if (response) {
            errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            errorHandle('other', error.toString());
            if (window.navigator.onLine) {
                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        }
    }
);

export default instance;
