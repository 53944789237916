<template>
	<div class="login-container">
	</div>
</template>


<script>
	import Core from "@/core";

	export default {
		data() {
			return {
				loading: '',
				token: '',
			};
		},
		mounted() {
		},
		async created() {
			this.loading = this.$loading({
				lock: true,
				text: '请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			await this.getToken()
			this.loading.close()
		},
		methods: {
			async getToken() {
				let token = this.$route.query.token
				if (token) {
					this.token = token
					Core.Data.setToken(token)
					Core.Data.setUserType(Core.Const.USER_TYPE.USER);
					await this.getInfoByToken()
				} else {
					this.$router.replace({
						path: '/login'
					})
				}
			},
			async getInfoByToken() {
				let res = await Core.Api.User.detail()
				console.log('getInfoByToken', res)
				Core.Data.setUser(res.user)
				this.$router.push("home");
			},
		}


	};
</script>

<style scoped lang="scss">
</style>

<style lang="scss">
</style>
