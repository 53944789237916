<template>
    <div class="park-list">
        <div class="park-list-container">
            <div class="park-list-table">
                <el-table :data="parkList" ref="multipleTable"
                          @selection-change="handleSelectionChange"
                          :row-key="getKey"
                >
                    <el-table-column
                      type="selection"
                      width="55"
                      :reserve-selection="true">
                    </el-table-column>
                    <el-table-column label="所属分组" :reserve-selection="true">
                        <template slot-scope="scope">
                            {{scope.row.group ? scope.row.group.name : '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="车位号" min-width="150" prop="park_no" :reserve-selection="true"></el-table-column>
                    <el-table-column label="车位状态" min-width="150" prop="status" :reserve-selection="true">
                        <template slot-scope="scope">
                            {{BindParkStatus(scope.row.status)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="车位类型" min-width="150" prop="park_type" :reserve-selection="true">
                        <template slot-scope="scope">
                            {{scope.row | parkType}}
                        </template>
                    </el-table-column>
                    <el-table-column label="地址" prop="address" :reserve-selection="true" min-width="150"></el-table-column>
                    <el-table-column label="设备ID" min-width="150" prop="uid" :reserve-selection="true">
                        <template slot-scope="scope">
                            <router-link target="_blank" class="link" v-if="scope.row.magnet_detector"
                                :to="{path: '/device/' + scope.row.magnet_detector.space +'/detail' + '?uid=' + scope.row.magnet_detector.uid}">
                                <span
                                    class="link">{{ scope.row.magnet_detector ? scope.row.magnet_detector.uid : "-"}}</span>
                            </router-link>
                            <div v-else>-</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="在线状态" min-width="100" :reserve-selection="true">
                        <template slot-scope="scope">
                            <div class="line-status-span" v-if="scope.row.magnet_detector"
                                :class="scope.row.magnet_detector.status === 0 ? 'offline':'online'">
                                {{scope.row.magnet_detector | deviceStatus}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="解除绑定" width="150" fixed="right" :reserve-selection="true">
                        <template slot-scope="scope">
                            <el-button type="text" @click="removePark(1,scope.row.id)">解除绑定</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-footer h-80">
                <div class="flex-footer-content">
                    <el-row>
                        <el-col :span="16" class="text-center" v-if="total > 0">
                            <el-pagination class="text-right" :total="total" :current-page="currentPage"
                                :page-size="pageSize" @current-change="pageChange"
                                layout="total, prev, pager, next, jumper">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </div>
                <div class="btns">
                    <el-button type="primary" @click="removePark(2)" >解除绑定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
import Core from '@/core'
import spanBtn from '@/components/span-btn'
import deleteDialog from '@/components/delete-dialog'
export default {
    components: {
        spanBtn,
        deleteDialog
    },
    props: {
        id: {
            type: Number,
            default: 0,
        },
        userId: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            userType: Core.Data.getUserType(), // 2--admin 1--customer
            parkList: [],
            groupList: [],
            // 分页配置
            currentPage: 1,
            total: 0,
            pageSize: 10,
            searchForm: {
                user_id: '',
                group_id: '',
                park_no: ''
            },
            sort_type: 0, //车位号排序
            park_ids: [], //选中停车位
            screenId: 0,
            bindParkList: [], //绑定车位
            BindParkStatus: Core.Util.BindParkStatus,
        }
    },

    mounted() {
        this.screenId = this.id ? this.id : this.$route['query']['id']
        if(this.userType == 1) {
            // 用户端
            this.getUserId()
        }
        this.getBindParkList()
    },
    watch: {
        'this.bindParkList'(e) {
            console.log('this.bindParkList watch', e)
        }
    },
    methods: {
        getUserId() {
            let userInfo = Core.Data.getUser()
            console.log('userInfo: ', userInfo);
            this.searchForm.user_id = userInfo.id
        },
        async getBindParkList() {
            console.log('getBindParkList');
            let res = await Core.Api.Screen.bindParkList(this.screenId, this.currentPage)
            this.parkList = res.list.map(item => {
                return item.park
            })
            this.total = res.count
        },

        pageChange(val) {
            this.currentPage = val
            this.getBindParkList()
        },
        // 回显已绑定车位
        getTickPark() {
            let echoArr = []
            // if(!this.bindParkList.length) {
            //     this.bindParkList = Core.Data.get('bindList')
            // }
            console.log("---- this.bindParkList", this.bindParkList)
            if(!this.bindParkList.length) {
                return
            }
            this.parkList.forEach((item,index) => { // parkList: 所有车位
                this.bindParkList.forEach(item1 => {
                    if(item1 == item.id) {
                        echoArr.push(index)
                    }
                })
            })
            console.log('echoArr: ', echoArr);
            if(echoArr.length) {
                this.$nextTick(() => {
                    echoArr.map(item => {
                        this.$refs.multipleTable.toggleRowSelection(this.parkList[item], true)
                    })
                })
            }
        },

        async getGroupList() {
            await this.getGroupListAll(this.searchForm.user_id, 1)
        },

        async getGroupListAll(userId, groupType) {
            let page = 1
            let groupList = []

            // 为了防止队列过长 之前的队列与之后的队列冲突，  采取：任务开始时间进行时间赋值 每次请求结束对比时间，时间不一致则return
            let transactionId = new Date().getTime()
            this.checkGetGroupListTime = transactionId
            while (true) {
                let res = await Core.Api.Group.listForAdmin(userId, 0, page++)
                if (transactionId !== this.checkGetGroupListTime) {
                    return
                }
                groupList = [...groupList, ...res.group_list]
                if (groupType === 1) {
                    this.groupList = groupList
                } else if (groupType === 2) {
                    this.userGroupList = groupList
                } else if (groupType === 3) {
                    this.userGroupList = groupList
                }
                if (res.group_list.length === 0) {
                    return
                }
            }
        },

        // goToParkDetailPage(id) {
        //     console.log('****', id)
        //     this.$router.push(`park-list/detail?park_id=${id}`)
        // },
        simpleSearch() {
            this.currentPage = 1;
            this.getBindParkList()
        },

        // 监听选择项
        handleSelectionChange(e) {
            console.log("handleSelectionChange e", e)
            console.log('this.bindParkList: ', this.bindParkList);
            this.park_ids = e.map(item => {
                return item.id
            })
            if(this.bindParkList.length) {
                let deleteIndexs = []
                this.bindParkList.forEach((item,index) => {
                    this.park_ids.forEach(val => {
                        if(item == val) {
                            return
                        }
                        else {
                            deleteIndexs.push(index)
                        }
                    })
                })
                if(deleteIndexs.length) {
                    deleteIndexs.sort((a,b) => {
                        return b-a;
                    })
                    deleteIndexs.forEach(item => {
                        this.bindParkList.splice(item, 1)
                    })
                    // this.bindParkList.splice(deleteIndexs, 1)
                    console.log('this.bindParkList: ', this.bindParkList);
                }
            }
            console.log('park_ids: ', this.park_ids);
        },

        // 绑定车位
        bindPark() {
            if(!this.park_ids.length) {
                this.$message.warning("请至少勾选一项!")
                return
            }
            Core.Api.Screen.bindPark(this.park_ids, this.screenId)
                .then(() => {
                    this.$message.success("绑定成功！")
                    this.$emit('closeBindvisible')
                    this.$emit('getBindPark')
                    this.$emit('getIdlePark')
                })
        },
        //解除绑定
        removePark(index, val) { // index: 1.单个解绑，2,多个解绑
            let item
            switch(index) {
                case 1: item = val; break
                case 2: {
                    if(!this.park_ids.length) {
                        this.$message.warning("请至少勾选一个车位！")
                        return
                    }
                    item = this.park_ids.join(",")
                }; break
            }
            Core.Api.Screen.removePark(item,this.screenId)
                .then(() => {
                    this.$message.success("解除成功！")
                    this.getBindParkList()
                    this.$emit('getIdlePark')
                })
        },
        getKey(row) {
            return row.id
        },
    }
}
</script>

<style scoped lang="scss" scoped>
.park-list {
    height: 100%;
    .park-list-container {
        padding-top: 0px;
        height: 100%;
        .search-row {
                .search-info ::v-deep {
                    .search-label {
                    width: 70px;
                    .search-item {
                        display: flex;
                        align-items: center;
                    }
                }
            }

        }
        .park-list-table {
            background: #ffffff;
            position: relative;
            overflow: auto;
            .line-status-span {
                padding: 2px 10px;
                background: #f0f3f6;
                font-size: 12px;
                border-radius: 12px;
                display: inline-block;
                &.offline {
                    color: #50555d;
                }
                &.online {
                    color: #08b76b;
                }
            }
        }
        .flex-footer {
            border-top: 1px solid #e0e7ef;
            position: relative;
            .btns {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%)
            }
        }
        .flex-footer.h-80 {
            height: 80px;
        }
    }
}
</style>
