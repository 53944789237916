import Const from '@/core/js/const';
const USER_TYPE = Const.USER_TYPE;

import Login from '@/views/login'
import LoginRedirect from '@/views/login-redirect'

import NotFound from '@/views/404.vue';
import Index from '@/views/index'
import Home from '@/views/home/home'


// 管理端地磁详情
import Detail20000 from '@/views/device/detail-20000'
import Detail20003 from '@/views/device/detail-20003'
import Detail20004 from '@/views/device/detail-20004'
import Detail20006 from '@/views/device/detail-20006'
import Detail20007 from '@/views/device/detail-20007'
import Detail20009 from '@/views/device/detail-20009'
import Detail20011 from '@/views/device/detail-20011'
import Detail20012 from '@/views/device/detail-20012'
import Detail20014 from '@/views/device/detail-20014'
import Detail20015 from '@/views/device/detail-20015'
import Detail20017 from '@/views/device/detail-20017'
import Detail20018 from '@/views/device/detail-20018'
import Detail20020 from '@/views/device/detail-20020'
import Detail20021 from '@/views/device/detail-20021'
import Detail20023 from '@/views/device/detail-20023'
import Detail20024 from '@/views/device/detail-20024'
import Detail20025 from '@/views/device/detail-20025'
import Detail20027 from '@/views/device/detail-20027'
import Detail20028 from '@/views/device/detail-20028'
import Detail20029 from '@/views/device/detail-20029'
import Detail20030 from '@/views/device/detail-20030'
import Detail20031 from '@/views/device/detail-20031'
import Detail20032 from '@/views/device/detail-20032'
import Detail20033 from '@/views/device/detail-20033'

// 用户端地磁详情
import ClientDetail20003 from '@/views/client/device/detail-20003'
import ClientDetail20004 from '@/views/client/device/detail-20004'
import ClientDetail20006 from '@/views/client/device/detail-20006'
import ClientDetail20007 from '@/views/client/device/detail-20007'
import ClientDetail20009 from '@/views/client/device/detail-20009'
import ClientDetail20011 from '@/views/client/device/detail-20011'
import ClientDetail20012 from '@/views/client/device/detail-20012'
import ClientDetail20014 from '@/views/client/device/detail-20014'
import ClientDetail20015 from '@/views/client/device/detail-20015'
import ClientDetail20018 from '@/views/client/device/detail-20018'
import ClientDetail20020 from '@/views/client/device/detail-20020'
import ClientDetail20023 from '@/views/client/device/detail-20023'
import ClientDetail20024 from '@/views/client/device/detail-20024'
import ClientDetail20025 from '@/views/client/device/detail-20025'
import ClientDetail20028 from '@/views/client/device/detail-20028'
import ClientDetail20029 from '@/views/client/device/detail-20029'
import ClientDetail20030 from '@/views/client/device/detail-20030'
import ClientDetail20032 from '@/views/client/device/detail-20032'
import ClientDetail20033 from '@/views/client/device/detail-20033'

// 地磁详情 管理端
import MagnetDetectorDetail from '@/views/device/magnet-detector-detail'

// 地磁详情 用户端
import ClientMagnetDetectorDetail from '@/views/client/device/magnet-detector-detail'

// screen
import ScreenList from '@/views/screen-management/screenList.vue'
import ScreenDetail from '@/views/screen-management/screenDetail.vue'

// user
import SecondLevelUserList from '@/views/user/second-level-user-list.vue'

import i18n from "@/locales";

const routes = [
    {
        // 404
        path: '/404',
        component: NotFound,
        name: '404',
        meta: {
            title: '404',
            hidden: true,
        }
    },
    // { 
    //     path: '*', 
    //     meta: {
    //         hidden: true, 
    //     },
    //     redirect: { path: '/404' } 
    // },
    {
        path: '/',
        meta: {
            hidden: true,
        },
        redirect: {
            path: '/login'
        },
    },
    {
        // 登录
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'login',
            hidden: true,
        }
    },
    {
        // 测试
        path: '/test',
        name: 'Test',
        component: () => import('@/views/test.vue'),
        meta: {
            title: 'test',
            hidden: true,
        },
    },
    {
        // 登录重定向
        path: '/login-redirect',
        name: 'LoginRedirect',
        meta: {
            hidden: true,
            title: 'login-redirect',
        },
        component: LoginRedirect,
    },

    {
        // 首页
        path: '/home',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: 'icon-home',
            roles: [USER_TYPE.ADMIN, USER_TYPE.USER],
        },
        children: [
            {
                path: '',
                component: Home,
                name: 'Home',
                meta: {
                    title: i18n.t('home.home'),
                },
            },
        ]
    },

    {
        // 管理端-设备类型
        path: '/device',
        component: Index,
        meta: {
            title: i18n.t('device.deviceType'),
            icon: 'icon-shebeileixing',
            iconCls: 'el-icon-message',
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: 'magnet-detector',
                name: 'MagnetDetectorList',
                meta: {
                    title: i18n.t('device.detectorList'),
                },
                component: () => import('@/views/device/magnet-detector-list.vue'),
            },
            {
                path: 'magnet-lock',
                name: 'MagnetLockList',
                meta: {
                    title: i18n.t('device.lockList'),
                },
                component: () => import('@/views/device/magnet-lock-list.vue'),
            },
            // { 
            //     path: 'magnet-export', 
            //     name: 'MagnetExportList', 
            //     meta: {
            //         title: '设备导出列表',
            //     },
            //     component: () => import('@/views/device/export-task-list.vue'), 
            // },
            {
                path: '20000/detail',
                component: MagnetDetectorDetail,
                name: 'VirtualDeviceDetail20000',
                meta: {
                    hidden: true,
                    title: i18n.t('device.virtualDeviceDetail'),
                    space: '20000',
                },
            },
            {
                path: '20003/detail',
                component: Detail20003,
                name: 'GeomagneticDetail20003',
                meta: {
                    title: i18n.t('device.detectorDetail'),
                    hidden: true,
                },
            },
            {
                path: '20004/detail',
                component: Detail20004,
                name: 'FlowGeomagneticDetail20004',
                meta: {
                    title: i18n.t('device.flowDetectorDetail'),
                    hidden: true,
                },
            },
            {
                path: '20006/detail',
                component: Detail20006,
                name: 'CoAPGeomagneticDetail20006',
                meta: {
                    title: i18n.t('device.CoAPDetectorDetail'),
                    hidden: true,
                    space: '20006',
                },
            },
            {
                path: '20007/detail',
                component: Detail20007,
                name: 'CoAPGroundLockDetail20007',
                meta: {
                    title: i18n.t('device.CoAPLockDetail'),
                    hidden: true,
                },
            },
            {
                path: '20009/detail',
                component: Detail20009,
                name: 'OneNetGeomagneticDetail20009',
                meta: {
                    title: i18n.t('device.OneNetDetectorDetail'),
                    hidden: true,
                    space: '20009',
                },
            },
            {
                path: '20011/detail',
                component: Detail20011,
                name: 'LinkWanGeomagneticDetail20011',
                meta: {
                    title: i18n.t('device.LinkWanDetectorDetail'),
                    hidden: true,
                },
            },
            {
                path: '20012/detail',
                component: Detail20012,
                name: 'LinkWanGroundLockDetail20012',
                meta: {
                    title: i18n.t('device.LinkWanLockDetail'),
                    hidden: true,
                },
            },
            {
                path: '20014/detail',
                component: Detail20014,
                name: 'OneNetGroundLockDetail20014',
                meta: {
                    title: i18n.t('device.OneNetLockDetail'),
                    hidden: true,
                },
            },
            {
                path: '20015/detail',
                component: MagnetDetectorDetail,
                name: 'BKVGeomagneticDetail20015',
                meta: {
                    hidden: true,
                    title: i18n.t('device.BKVDetectorDetail'),
                    space: '20015',
                },
            },
            {
                path: '20017/detail',
                component: Detail20017,
                name: 'NewUDPGeomagneticDetail20017',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20017Detail'),
                },
            },
            {
                path: '20018/detail',
                component: Detail20018,
                name: 'CoAPGeomagneticDetail20018',
                meta: {
                    hidden: true,
                    title: i18n.t('device.CoAP-BKV'),
                },
            },
            {
                path: '20020/detail',
                component: Detail20020,
                name: 'LoRaGeomagneticDetail20020',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20020Detail'),
                },
            },
            {
                path: '20021/detail',
                component: Detail20021,
                name: 'LoRaGroundLockDetail20021',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20021Detail'),
                },
            },
            {
                path: '20023/detail',
                component: MagnetDetectorDetail,
                name: 'AEPGeomagneticDetail20023',
                meta: {
                    hidden: true,
                    title: i18n.t('device.AEPDetectorDetail'),
                    space: '20023',
                },
            },
            {
                path: '20024/detail',
                component: Detail20024,
                name: 'LoraWanGeomagneticDetail20024',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20024Detail'),
                },
            },
            {
                path: '20025/detail',
                component: Detail20025,
                name: 'TingjiandanCoAPMagnetDetectorDetail20025',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20025Detail'),
                    space: '20025',
                },
            },
            {
                path: '20027/detail',
                component: Detail20027,
                name: 'OneNet-BKVGeomagneticDetail20027',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20027Detail'),
                },
            },
            {
                path: '20028/detail',
                component: Detail20028,
                name: 'AEPGeomagneticDetail20028',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20028Detail'),
                    space: '20028',
                },
            },
            {
                path: '20029/detail',
                component: Detail20029,
                name: 'CoAPBKVGeomagneticDetail20029',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20029Detail'),
                    space: '20029',
                },
            },
            {
                path: '20030/detail',
                component: Detail20030,
                name: 'TingjiandanIOTPlatform20030',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20030Detail'),
                    space: '20030',
                },
            },
            {
                path: '20031/detail',
                component: Detail20031,
                name: 'NanpengOneNet',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20031Detail'),
                },
            },
            {
                path: '20032/detail',
                component: Detail20032,
                name: 'CLAAGeomagneticDetail20032',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20032Detail'),
                },
            },
            {
                path: '20033/detail',
                component: Detail20033,
                name: 'ChirpstackGeomagneticDetail20033',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20033Detail'),
                    space: '20033',
                },
            },
        ]
    },
    {
        // 用户端-设备列表
        path: '/client-device',
        component: Index,
        meta: {
            title: i18n.t('device.deviceList'),
            icon: 'icon-shebeileixing',
            roles: [USER_TYPE.USER],
        },
        children: [
            {
                path: 'magnet-detector',
                name: 'ClientMagnetDetectorList',
                meta: {
                    title: i18n.t('device.detectorList'),
                },
                component: () => import('@/views/client/device/magnet-detector-list.vue'),
            },
            {
                path: 'magnet-lock',
                name: 'ClientMagnetLockList',
                meta: {
                    title: i18n.t('device.lockList'),
                },
                component: () => import('@/views/client/device/magnet-lock-list.vue'),
            },
            {
                path: '20000/detail',
                component: ClientMagnetDetectorDetail,
                name: 'ClientVirtualDeviceDetail20000',
                meta: {
                    hidden: true,
                    title: i18n.t('device.virtualDeviceDetail'),
                    space: '20000'
                },
            },
            {
                path: '20003/detail',
                component: ClientDetail20003,
                name: 'ClientGeomagneticDetail20003',
                meta: {
                    hidden: true,
                    title: i18n.t('device.detectorDetail'),
                },
            },
            {
                path: '20004/detail',
                component: ClientDetail20004,
                name: 'ClientFlowGeomagneticDetail20004',
                meta: {
                    hidden: true,
                    title: i18n.t('device.flowDetectorDetail'),
                },
            },
            {
                path: '20006/detail',
                component: ClientDetail20006,
                name: 'ClientCoAPGeomagneticDetail20006',
                meta: {
                    hidden: true,
                    title: i18n.t('device.CoAPDetectorDetail'),
                    space: '20006',
                },
            },
            {
                path: '20007/detail',
                component: ClientDetail20007,
                name: 'ClientCoAPGroundLockDetail20007',
                meta: {
                    hidden: true,
                    title: i18n.t('device.CoAPLockDetail'),
                },
            },
            {
                path: '20009/detail',
                component: ClientDetail20009,
                name: 'ClientOneNetGeomagneticDetail20009',
                meta: {
                    hidden: true,
                    title: i18n.t('device.OneNetDetectorDetail'),
                    space: '20009',
                },
            },
            {
                path: '20011/detail',
                component: ClientDetail20011,
                name: 'ClientLinkWanGeomagneticDetail20011',
                meta: {
                    hidden: true,
                    title: i18n.t('device.LinkWanDetectorDetail'),
                },
            },
            {
                path: '20012/detail',
                component: ClientDetail20012,
                name: 'ClientLinkWanGroundLockDetail20012',
                meta: {
                    hidden: true,
                    title: i18n.t('device.LinkWanLockDetail'),
                },
            },
            {
                path: '20014/detail',
                component: ClientDetail20014,
                name: 'ClientOneNetGroundLockDetail20014',
                meta: {
                    hidden: true,
                    title: i18n.t('device.OneNetLockDetail'),
                },
            },
            {
                path: '20015/detail',
                component: ClientMagnetDetectorDetail,
                name: 'ClientBKVGeomagneticDetail20015',
                meta: {
                    hidden: true,
                    title: i18n.t('device.BKVDetectorDetail'),
                    space: '20015',
                },
            },
            {
                path: '20018/detail',
                component: ClientDetail20018,
                name: 'ClientCoAPGeomagneticDetail20018',
                meta: {
                    hidden: true,
                    title: i18n.t('device.CoAP-BKV'),
                },
            },
            {
                path: '20020/detail',
                component: ClientDetail20020,
                name: 'ClientLoRaGeomagneticDetail20020',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20020Detail'),
                },
            },
            {
                path: '20023/detail',
                component: ClientMagnetDetectorDetail,
                name: 'ClientAEPGeomagneticDetail20023',
                meta: {
                    hidden: true,
                    title: i18n.t('device.AEPDetectorDetail'),
                    space: '20023',
                },
            },
            {
                path: '20024/detail',
                component: ClientDetail20024,
                name: 'ClientLoraWanGeomagneticDetail20024',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20024Detail'),
                },
            },
            {
                path: '20025/detail',
                component: ClientDetail20025,
                name: 'ClientTingjiandanCoAPMagnetDetectorDetail20025',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20025Detail'),
                    space: '20025',
                },
            },
            {
                path: '20028/detail',
                component: ClientDetail20028,
                name: 'ClientAEPGeomagneticDetail20028',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20028Detail'),
                    space: '20028',
                },
            },
            {
                path: '20029/detail',
                component: ClientDetail20029,
                name: 'ClientCoAPBKVGeomagneticDetail20029',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20029Detail'),
                    space: '20029',
                },
            },
            {
                path: '20030/detail',
                component: ClientDetail20030,
                name: 'ClientTingjiandanIOTPlatform20030',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20030Detail'),
                    space: '20030',
                },
            },
            {
                path: '20032/detail',
                component: ClientDetail20032,
                name: 'ClientLora-CLAAMagnetDetectorDetail20032',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20032Detail'),
                },
            },
            {
                path: '20033/detail',
                component: ClientDetail20033,
                name: 'ClientChirpstackMagnetDetectorDetail20033',
                meta: {
                    hidden: true,
                    title: i18n.t('device.20033Detail'),
                    space: '20033',
                },
            },
        ]
    },

    {
        // 管理端-临时
        path: '/temp',
        name: 'temp',
        component: Index,
        meta: {
            title: '临时',
            icon: 'icon-linshi',
            iconCls: 'el-icon-message',
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: 'temp-magnet-detector',
                name: 'TempMagnetDetectorList',
                meta: {
                    title: '设备车位',
                },
                component: () => import('@/views/device/temp/magnet-detector-list.vue'),
            },
            // {
            //     path: '20003/detail',
            //     component: Detail20003,
            //     name: 'TempGeomagneticDetail20003',
            //     meta: {
            //         hidden: true,
            //         title: '地磁详情',
            //     },
            // },
            // {
            //     path: '20004/detail',
            //     component: Detail20004,
            //     name: 'TempFlowGeomagneticDetail20004',
            //     meta: {
            //         hidden: true,
            //         title: '流量地磁详情',
            //     },
            // },
            // {
            //     path: '20006/detail',
            //     component: Detail20006,
            //     name: 'TempCoAPGeomagneticDetail20006',
            //     meta: {
            //         hidden: true,
            //         title: 'CoAP地磁详情',
            //     },
            // },
            // {
            //     path: '20007/detail',
            //     component: Detail20007,
            //     name: 'TempCoAPGroundLockDetail20007',
            //     meta: {
            //         hidden: true,
            //         title: 'CoAP地锁详情',
            //     },
            // },
            // {
            //     path: '20009/detail',
            //     component: Detail20009,
            //     name: 'TempOneNetGeomagneticDetail20009',
            //     meta: {
            //         hidden: true,
            //         title: 'OneNet地磁详情',
            //     },
            // },
            // {
            //     path: '20011/detail',
            //     component: Detail20011,
            //     name: 'TempLinkWanGeomagneticDetail20009',
            //     meta: {
            //         hidden: true,
            //         title: 'LinkWan地磁详情',
            //     },
            // },
            // {
            //     path: '20012/detail',
            //     component: Detail20012,
            //     name: 'TempLinkWanGroundLockDetail20009',
            //     meta: {
            //         hidden: true,
            //         title: 'LinkWan地锁详情',
            //     },
            // },
            // {
            //     path: '20014/detail',
            //     component: Detail20014,
            //     name: 'TempOneNetGroundLockDetail20014',
            //     meta: {
            //         hidden: true,
            //         title: 'OneNet地锁详情',
            //     },
            // },
            // {
            //     path: '20015/detail',
            //     component: Detail20015,
            //     name: 'TempBKVGeomagneticDetail20014',
            //     meta: {
            //         hidden: true,
            //         title: 'BKV地磁详情',
            //     },
            // },
            // {
            //     path: '20017/detail',
            //     component: Detail20017,
            //     name: 'TempNewUDPGeomagneticDetail20017',
            //     meta: {
            //         hidden: true,
            //         title: '新UDP地磁详情',
            //     },
            // },
            // {
            //     path: '20018/detail',
            //     component: Detail20018,
            //     name: 'TempCoAPGeomagneticDetail20018',
            //     meta: {
            //         hidden: true,
            //         title: 'CoAP地磁详情',
            //     },
            // },
            // {
            //     path: '20020/detail',
            //     component: Detail20020,
            //     name: 'TempLoRaGeomagneticDetail20020',
            //     meta: {
            //         hidden: true,
            //         title: 'LoRa地磁详情',
            //     },
            // },
            // {
            //     path: '20021/detail',
            //     component: Detail20021,
            //     name: 'TempLoRaGroundLockDetail20021',
            //     meta: {
            //         hidden: true,
            //         title: 'LoRa地锁详情',
            //     },
            // },
            // {
            //     path: '20023/detail',
            //     component: Detail20023,
            //     name: 'TempAEPGeomagneticDetail20023',
            //     meta: {
            //         hidden: true,
            //         title: 'AEP地磁详情',
            //     },
            // },
            // {
            //     path: '20024/detail',
            //     component: Detail20024,
            //     name: 'TempLoraWanGeomagneticDetail20024',
            //     meta: {
            //         hidden: true,
            //         title: 'LoraWan地磁详情',
            //     },
            // },
            // {
            //     path: '20025/detail',
            //     component: Detail20025,
            //     name: 'TempTingjiandanCoAPMagnetDetectorDetail20025',
            //     meta: {
            //         hidden: true,
            //         title: '停简单CoAP地磁详情',
            //     },
            // },
            // {
            //     path: '20027/detail',
            //     component: Detail20027,
            //     name: 'TempOneNet-BKVGeomagneticDetail20027',
            //     meta: {
            //         hidden: true,
            //         title: 'OneNet-BKV地磁详情',
            //     },
            // },
            // {
            //     path: '20028/detail',
            //     component: Detail20028,
            //     name: 'TempAEPGeomagneticDetail20028',
            //     meta: {
            //         hidden: true,
            //         title: 'AEP地磁详情',
            //     },
            // },
            // {
            //     path: '20029/detail',
            //     component: Detail20029,
            //     name: 'TempCoAPBKVGeomagneticDetail20029',
            //     meta: {
            //         hidden: true,
            //         title: 'CoAP BKV地磁详情',
            //     },
            // },
            // {
            //     path: '20030/detail',
            //     component: Detail20030,
            //     name: 'TempTingjiandanIOTPlatform20030',
            //     meta: {
            //         hidden: true,
            //         title: '停简单物联网平台',
            //     },
            // },
        ]
    },

    {
        // 管理端-车位列表
        path: '/park-list-admin',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-chewei",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/park/list.vue'),
                name: 'ParkAdminList',
                meta: {
                    title: '车位列表',
                },
            },
            {
                path: 'detail',
                component: () => import('@/views/park/detail.vue'),
                name: 'ParkAdminDetail',
                meta: {
                    hidden: true,
                    title: '车位变更详情',
                },
            },
        ]
    },
    {
        // 用户端-车位列表
        path: '/park-list-customer',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-chewei",
            roles: [USER_TYPE.USER],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/client/park/list.vue'),
                name: 'ParkCustomerList',
                meta: {
                    title: '车位列表',
                },
            },
            {
                path: 'detail',
                component: () => import('@/views/client/park/detail.vue'),
                name: 'ParkCustomerDetail',
                meta: {
                    hidden: true,
                    title: '车位变更详情',
                },
            },
        ]
    },

    {
        // 管理端-告警列表
        path: '/alarm-list',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-gaojing1",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/alarm/list.vue'),
                name: 'AlarmList',
                meta: {
                    title: '告警列表',
                },
            },
        ]
    },
    
    {
        // 用户端-告警列表
    	path: '/client-alarm-list',
    	component: Index,
        meta: {
            title: '',
            is_leaf: true,
            roles: [USER_TYPE.USER],
            icon: "icon-gaojing1",
            user_auth: ['flag_alarm_enable'],
        },
    	children: [
    		{
                path: '', 
                component: () => import('@/views/client/alarm/list.vue'), 
                name: 'ClientAlarmList',
                meta: {
                    is_leaf: true,
                    title: i18n.t('alarm.list'),
                },
            },
    	]
    },

    {
        // 管理端-用户管理
        path: '/user-list',
        component: Index,
        meta: {
            title: i18n.t('user.userManage'),
            icon: "icon-yonghu",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/user/user-list.vue'),
                name: 'UserList',
                meta: {
                    title: i18n.t('user.userList'),
                },
            },
            {
                path: 'second-level',
                component: SecondLevelUserList,
                name: 'SecondLevelUserList',
                meta: {
                    title: i18n.t('user.secondaryUserList'),
                },
            },
        ]
    },
    {
        // 用户端-二级用户列表
        path: '/client-user',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            roles: [USER_TYPE.USER],
            user_auth: ['flag_child_user_enable'],
            icon: "icon-yonghu",
        },
        children: [
            {
                path: '',
                component: SecondLevelUserList,
                name: 'ClientSecondLevelUserList',
                meta: {
                    title: i18n.t('user.secondaryUserList'),
                },
            },
        ]
    },

    {
        // 管理端-分组管理
        path: '/group-list',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-fenzuguanli",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/group/list.vue'),
                name: 'GroupList',
                meta: {
                    title: i18n.t('groupList.groupManagement'),
                },
            },
        ]
    },
    {
        // 用户端-分组列表
        path: '/client-group-list',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-fenzuguanli",
            roles: [USER_TYPE.USER],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/client/group/list.vue'),
                name: 'ClientGroupList',
                meta: {
                    title: i18n.t('device.groupList'),
                },
            },
        ]
    },

    {
        // 管理端-屏幕列表
        path: '/screen-list-admin',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-chewei",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: '',
                component: ScreenList,
                name: 'ScreenList',
                meta: {
                    title: '屏幕列表',
                },
            },
            {
                path: 'detail',
                component: ScreenDetail,
                name: 'ScreenDetail',
                meta: {
                    hidden: true,
                    title: '屏幕详情',
                },
            },
        ]
    },
    {
        // 用户端-屏幕列表
        path: '/screen-list-customer',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-chewei",
            roles: [USER_TYPE.USER],
        },
        children: [
            {
                path: '',
                component: ScreenList,
                name: 'CustomerScreenList',
                meta: {
                    title: '屏幕列表',
                },
            },
            {
                path: 'detail',
                component: ScreenDetail,
                name: 'CustomerScreenDetail',
                meta: {
                    hidden: true,
                    title: '屏幕详情',
                },
            },
        ]
    },

    {
        // 管理端-操作记录
        path: '/action-list',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-caozuojilu",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/action/list'),
                name: 'ActionList',
                meta: {
                    title: '操作记录',
                },
            },
        ]
    },

    {
        // 管理端-OTA列表
        path: '/ota-list',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-OTAlist",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/ota/ota-file-list.vue'),
                name: 'OTAList',
                meta: {
                    title: 'OTA列表',
                },
            },
        ]
    },

    {
        // 管理端-OTA任务
        path: '/update-task',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-renwu",
            roles: [USER_TYPE.ADMIN, USER_TYPE.USER],
            user_auth: ['flag_ota_task_enable'],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/update-task/list.vue'),
                name: 'OTATaskList',
                meta: {
                    title: 'OTA任务',
                },
            },
            {
                path: 'detail',
                component: () => import('@/views/update-task/detail.vue'),
                name: 'OTATaskDetail',
                meta: {
                    hidden: true,
                    title: 'OTA任务详情',
                },
            },
        ]
    },

    {
        // 管理端-下载参数任务
        path: '/device-task',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-renwu2",
            roles: [USER_TYPE.ADMIN, USER_TYPE.USER],
            user_auth: ['flag_device_task_enable'],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/device-task/list.vue'),
                name: 'ParameterDeliveryTask',
                meta: {
                    title: '下发参数任务',
                },
            },
            {
                path: 'detail',
                component: () => import('@/views/device-task/detail.vue'),
                name: 'DetailsAboutDeviceRelatedTasks',
                meta: {
                    hidden: true,
                    title: '设备相关任务详情',
                },
            },
        ]
    },

    {
        // 管理端-远程调试
        path: '/remote-debug',
        component: Index,
        meta: {
            title: '远程调试',
            icon: "icon-yuanchengtiaoshi",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: 'magnet-detector',
                component: () => import('@/views/remote-debug/magnet-detector.vue'),
                name: 'RemoteDebugMagnetDetector',
                meta: {
                    title: '地磁',
                },
            },
        ]
    },
   
    {
        // 管理端-小程序配置
        path: '/admin-app-profile',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-renwu2",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/app-profile/admin-app-profile.vue'),
                name: 'SmallProgramConfiguration',
                meta: {
                    title: '小程序配置',
                },
            },
        ]
    },

    {
        // 管理端和用户端-标签 
        path: '/label',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-label",
            roles: [USER_TYPE.ADMIN, USER_TYPE.USER],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/label/list.vue'),
                name: 'LabelList',
                meta: {
                    title: i18n.t('label.labelList'),
                },
            },
        ]
    },

    {
        // 管理端-管理员列表
        path: '/admin-list',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-guanliyuan",
            roles: [USER_TYPE.ADMIN],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/user/admin-list.vue'),
                name: 'AdminList',
                meta: {
                    title: '管理员列表',
                },
            },
        ]
    },

    {
        // 用户端-开放平台
        path: '/help',
        component: Index,
        meta: {
            title: '',
            is_leaf: true,
            icon: "icon-renwu2",
            roles: [USER_TYPE.USER],
        },
        children: [
            {
                path: '',
                component: () => import('@/views/user/user-develop.vue'),
                name: 'OpenPlatform',
                base: '对接信息',
                meta: {
                    title: '开放平台',
                },
            },
        ]
    },

    {
        // 显示pdf
        path: '/show-pdf',
        component: () => import('@/views/show-pdf/show-pdf.vue'),
        pathname: 'pdf',
        name: 'PDF',
        meta: {
            title: '',
            hidden: true,
        },
    },
];

export default routes;