<template>
    <div class="home-container">
        <div class="home-index home-outer-div">
            <div class="home-inner-title b-b">
                <span class="title">{{$t("home.accessDevice")}}</span>
                <el-select v-model="value" placeholder="请选择" class="fr" style="display: none">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="index-content">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="8" :lg="6">
                        <div class="content-inner">
                            <img src="../../assets/img/home/all.png">
                            <div class="inner-block">
                                <h2 class="value">{{ deviceCount.total || '-' }}</h2>
                                <span class="key">{{$t("home.deviceCount")}}</span>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="6">
                        <div class="content-inner">
                            <img src="../../assets/img/home/inline.png">
                            <div class="inner-block">
                                <h2 class="value">{{ deviceCount.online || '-' }}</h2>
                                <span class="key">{{$t("home.deviceOnline")}}</span>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="6">
                        <div class="content-inner">
                            <img src="../../assets/img/home/outline.png">
                            <div class="inner-block">
                                <h2 class="value">{{ deviceCount.offline || '-' }}</h2>
                                <span class="key">{{$t("home.deviceOffline")}}</span>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="6">
                        <div class="content-inner">
                            <img src="../../assets/img/home/warn.png">
                            <div class="inner-block">
                                <h2 class="value">{{ deviceCount.warn || '-' }}</h2>
                                <span class="key">{{$t("home.deviceWarn")}}</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="home-index home-outer-div">
            <div class="home-inner-title b-b">
                <span class="title">{{$t("home.abnormal")}}</span>
            </div>
            <div class="index-content">
                <el-row>
                    <!-- <el-col :xs="24" :sm="12" :md="8" :lg="6">
                        <div class="content-inner" @click="routerChange('user')">
                            <img src="../../assets/img/home/outline.png">
                            <div class="inner-block">
                                <h2 class="value">{{ deviceCount.userErrCount || '-' }}</h2>
                                <span class="key">{{$t("home.userAbnormalCount")}}</span>
                            </div>
                        </div>
                    </el-col> -->
                    <el-col :xs="24" :sm="12" :md="8" :lg="6">
                        <div class="content-inner" @click="routerChange('group')">
                            <img src="../../assets/img/home/outline.png">
                            <div class="inner-block">
                                <h2 class="value">{{ deviceCount.groupErrCount || '-' }}</h2>
                                <span class="key">{{$t("home.groupAbnormalCount")}}</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="home-user-list">
            <el-table :data="userList" border v-loading="loading">
                <el-table-column prop="id" label="ID" min-width="80"></el-table-column>
                <el-table-column prop="username" label="用户名" min-width="120"></el-table-column>
                <el-table-column prop="device_off_line_all" label="离线总数" min-width="120"></el-table-column>
                <el-table-column prop="device_off_line_1" label="当天离线数" min-width="120"></el-table-column>
                <el-table-column prop="device_off_line_7" label="七天离线数" min-width="120"></el-table-column>
            </el-table>
            <el-pagination @current-change="pageChange" :current-page="currentPage"
                           :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
        <div class="home-chart-log" style="display: none;">
            <el-row :gutter="20">
                <el-col :span="17">
                    <div class="home-chart home-outer-div">
                        <div class="home-inner-title b-b">
                            <!-- <div class="inner-title-block"> -->
                            <span class="title">设备趋势</span>
                            <el-select v-model="value" placeholder="请选择" style="margin-left:20px">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- </div> -->
                            <div class="select-time fr">
                                <span :class="{active:chartIndex.time == 1}" @click="chartIndex.time = 1">一个月</span>
                                <span :class="{active:chartIndex.time == 2}" @click="chartIndex.time = 2">三个月</span>
                                <span :class="{active:chartIndex.time == 3}" @click="chartIndex.time = 3">半年</span>
                            </div>


                        </div>
                        <div class="home-chart-content" ref="home-chart" style="height:350px;">

                        </div>
                    </div>
                </el-col>
                <el-col :span="7">
                    <div class="home-log home-outer-div">
                        <div class="home-inner-title">
                            <span class="title">平台监控日志</span>
                        </div>
                        <div class="log-content">
                            <div class="log-list">
                                <div class="log-item">
                                    <div class="item-floor-1">
                                        <span class="id">设备ID：863703037673279</span>
                                        <img src="../../assets/img/home/log.png" class="fr">
                                    </div>
                                    <span class="time">2018-11-26 12:34:12</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>

        </div>


    </div>
</template>


<script>
    import echarts from "echarts";
    import mapGetters from 'vuex'
    import Core from '@/core'

    export default {
        name: "home",
        data() {
            return {
                value: '',
                options: [],
                chartIndex: {
                    time: 1,
                    type: ''
                },
                homeChart: '',

                deviceCount: {
                    total: '',
                    online: '',
                    offline: '',
                    warn: '',
                    userErrCount: '',
                    groupErrCount: '',
                },
                userList: [],
                loading: false,
                // 分页配置
                currentPage: 1,
                total: 0,
                pageSize: 10,
                userType: Core.Util.getUserType(),
                USER_TYPE: Core.Const.USER_TYPE,
            }
        },
        mounted() {
            this.initChart();
            this.loadDeviceCountStat();
            this.getUserList()
        },
        methods: {
            pageChange(val) {
                this.currentPage = val
                this.getUserList()
            },
            async getUserList() {
                try {
                    this.loading = true
                    let res = await Core.Api.User.listByStatus(this.currentPage, this.pageSize)
                    this.userList = res.user_list;
                    this.total = res.count;
                } catch (err) {
                    this.$message.error(err.message)
                } finally {
                    this.loading = false
                }
            },
            loadDeviceCountStat() {
                let that = this;
                // if (Core.Util.getUserType() === Core.Const.USER_TYPE.USER) {
                //     return
                // }
                Core.Api.Stat.getHomeDeviceCount().then(res => {
                    console.log("loadDeviceCountStat  res:", res)
                    let total = parseInt(res['device_count']);
                    let online = parseInt(res['device_online_count']);
                    let offline = total - online;
                    if (offline < 0) {
                        offline = '-'
                    }

                    that.deviceCount.total = total;
                    that.deviceCount.online = online;
                    that.deviceCount.offline = offline;
                    that.deviceCount.userErrCount = parseInt(res['user_err_count']);
                    that.deviceCount.groupErrCount = parseInt(res['group_err_count']);
                });
            },

            initChart() {
                this.homeChart = echarts.init(this.$refs['home-chart']);
                let distributionDay = [1, 2, 3];
                let distributionViewCount = [1, 2, 3];
                let distributionTurnover = [4, 5, 1];
                let distributionPromotion = [7, 8, 9];
                let chartOption = {
                    legend: {
                        data: ["在线设备", "离线设备", '告警设备'],
                        right: 20,
                        top: 20,
                        textStyle: {
                            color: '#8E949C'
                        },
                        itemWidth: 20,
                        itemHeight: 6,
                        itemGap: 10,
                    },
                    xAxis: {
                        type: "category",
                        data: distributionDay,
                        axisLine: {
                            lineStyle: {
                                color: "#BFBFBF"
                            },
                        },
                        axisTick: {
                            alignWithLabel: true,

                        },
                        axisLabel: {
                            color: '#545454',
                        },
                    },
                    tooltip: {
                        trigger: "axis"
                    },
                    yAxis: {
                        type: "value",
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#8E9AAD"
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#E5E9EF",
                                type: 'dotted'
                            }
                        },
                        axisLabel: {
                            color: '#545454',
                        },
                        minInterval: 1
                    },
                    grid: {
                        left: 42,
                        right: 20,
                        top: 60,
                        bottom: 40,
                    },
                    series: [
                        {
                            name: "在线设备",
                            type: "line",
                            data: distributionViewCount,
                            color: "#1890FF",
                        },
                        {
                            name: "离线设备",
                            type: "line",
                            data: distributionTurnover,
                            color: "#CE3EEB"
                        },
                        {
                            name: "告警设备",
                            type: "line",
                            data: distributionPromotion,
                            color: "#FFB451"
                        },
                    ]
                };
                this.homeChart.setOption(chartOption)
                window.onresize = this.homeChart.resize;
            },
            routerChange(key) {
                switch (key) {
                    case 'user':
                        this.$router.push(`user-list?device_online_status=${Core.Const.DEVICE.ONLINE_STATUS.OFF}`)
                        break;
                    case 'group':
                        this.$router.push(`group-list?device_online_status=${Core.Const.DEVICE.ONLINE_STATUS.OFF}`)
                        break;
                    default:
                        break;
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    .chart {
        margin: 10px;
        height: 700px;
        background: #E9ECEF;
    }

    .home-container {
        margin: 20px;

        .home-outer-div {
            background: #fff;
            margin-bottom: 20px;
            .home-inner-title {
                height: 60px;
                line-height: 60px;
                padding: 0 20px;

                &.b-b {
                    border-bottom: 1px solid #EEF1F5;
                    line-height: 59px;
                }

                .title {
                    font-size: 14px;
                    color: #34383E;
                    font-weight: 600;
                }
            }

            &.home-index {
                .index-content {
                    min-height: 124px;

                    .content-inner {
                        // width: 25%;
                        height: 100%;
                        display: inline-block;
                        vertical-align: middle;
                        padding: 30px 0 30px 20px;

                        img {
                            width: 68px;
                            height: 68px;
                            vertical-align: top;
                        }

                        .inner-block {
                            width: calc(100% - 88px);
                            display: inline-block;
                            vertical-align: top;
                            margin-left: 20px;

                            .key {
                                font-size: 12px;
                                color: #8E9AAD;
                                display: inline-block;
                                margin-top: 6px;
                            }

                            .value {
                                font-size: 28px;
                                color: #34383E;
                            }
                        }
                    }
                }
            }

        }

        .home-user-list {
            .el-pagination {
                margin-top: 10px;
                text-align: right;
            }
        }

        .home-chart-log {
            margin-top: 20px;

            .home-chart {
                .select-time {
                    span {
                        width: 71px;
                        height: 30px;
                        display: inline-block;
                        background: #FFFFFF;
                        border: 1px solid #DCE2E6;
                        font-size: 12px;
                        color: #50555D;
                        // line-height: 28px;
                        line-height: 28px;
                        text-align: center;
                        cursor: pointer;

                        &.active {
                            background: #08B76B;
                            color: #FFFFFF;
                        }

                        &:first-child {
                            // border-radius: 2px 0 0 2px;
                            // border-right: none;
                            border: {
                                radius: 2px 0 0 2px;
                                right: none;
                            }
                        }

                        &:last-child {
                            border-radius: 0 2px 2px 0;
                            border-left: none;
                        }
                    }
                }
            }

            .home-log {
                .log-content {
                    height: 350px;

                    .log-list {
                        padding: 0 20px 0 20px;

                        .log-item {
                            height: 58px;
                            padding: 10px 7px 10px 10px;
                            background: #F5F7F9;
                            border-radius: 2px;

                            .item-floor-1 {
                                margin-bottom: 4px;

                                .id {
                                    font-size: 12px;
                                    color: #34383E;
                                    vertical-align: middle;
                                }

                                img {
                                    width: 12px;
                                    height: 12px;
                                    vertical-align: middle;
                                }
                            }

                            .time {
                                font-size: 12px;
                                color: #8E949C;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }


</style>
