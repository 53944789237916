<template>
    <el-row :span="24" class="first-header">
        <el-col :span="12" class="logo-warp">
            <img class="logo-img" v-if="lang === 'zh'" src="../../assets/img/logo-zh.png" />
            <img class="logo-img" v-else src="../../assets/img/logo-en.png" />
            <div class="userType">
                <div v-if="lang === 'zh'">
                    <span v-if="userType === 1">用户端</span>
                    <span v-else>管理端</span>
                </div>
                <div v-else>
                    <span v-if="userType === 1">User</span>
                    <span v-else>Admin</span>
                </div>
            </div>
        </el-col>
        <el-col :span="12">
            <div class="header-info">
                <img class="header-icon" src="../../assets/img/icon/icon-lang.png">
                <div class="lang-select">
                    <el-dropdown trigger="click" size="mini" @command="languageChange">
                        <span class="show-info">{{language}}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="zh">中文</el-dropdown-item>
                            <el-dropdown-item command="en">English</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <img class="header-icon" :src="config.userAvatar ||'static/icon-avatar.png'">
                <div class="user-out">
                    <el-dropdown trigger="click" size="mini" @command="userOption">
                        <span class="show-info">
                            {{config.userName}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="key">用户信息</el-dropdown-item>
                            <el-dropdown-item command="logout">{{$t('home.out')}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </el-col>

        <el-dialog title="用户信息" :visible.sync="editModalVisible" custom-class="edit-dialog">
            <div>
                <div style="margin-bottom: 15px">
                    <span style="font-size: 16px; font-weight:blod; margin-right: 15px;color:black;">uid:</span>
                    <span style="font-size: 16px">{{ this.config.uid }}</span>
                </div>
                <div>
                    <span style="font-size: 16px; font-weight:blod; margin-right: 15px;color: black;">key:</span>
                    <span style="font-size: 16px">{{ this.config.key }}</span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="closeDialog()">确认</el-button>
            </div>
        </el-dialog>
    </el-row>
</template>

<script>
import Core from '@/core'
import EditLabel from '../../components/edit-label'
import { useStore, mapState } from 'vuex'
export default {
    name: 'app-header',
    components: { EditLabel },
    props: {
        routeName: {
            type: String
        },
        config: {
            type: Object
        }
    },
    watch: {
        // 'config.userName': function (val) {
        //     this.username = this.config.userName;
        // },
        // 'username': function (val) {
        //     this.username = this.config.userName;
        // }
    },
    data() {
        return {
            lang: 'zh',
            language: '',
            username: this.config.userName,
            uid: this.config.uid,
            key: this.config.key,
            editModalVisible: false,
            userType: Core.Data.getUserType(),
        }
    },
    mounted() {
        this.getCurrentLang()
        this.judgeLanguage()
        if(this.userType == 2) {
            this.getUserList()
        }
        // console.log("data -> this.config", this.config)
    },
    methods: {
        getCurrentLang() {
            this.lang = Core.Data.getLang()
        },
        judgeLanguage() {
            if (this.lang === 'en') {
                this.language = 'English'
            } else {
                this.language = '中文'
            }
        },

        languageChange(val) {
            if (val === 'en') {
                i18n.locale = 'en'
                Core.Data.setLang('en')
                document.body.classList.remove(`lang-zh`)
                document.body.classList.add(`lang-en`)
            } else {
                i18n.locale = 'zh'
                Core.Data.setLang('zh')
                document.body.classList.remove(`lang-en`)
                document.body.classList.add(`lang-zh`)
            }
            location.reload()
        },

        userOption(val) {
            if (val === 'logout') {
                this.$confirm(
                    i18n.t('device.outSystem'),
                    i18n.t('device.prompt'),
                    {
                        confirmButtonText: i18n.t('device.done'),
                        cancelButtonText: i18n.t('device.cancel'),
                        type: 'warning'
                    }
                )
                    .then(() => {
                        Core.Data.setUser({})
                        Core.Data.removeToken()
                        this.$router.push({ path: '/login' })
						Core.Util.setAuthority({});
                        this.$message({
                            type: 'success',
                            message: i18n.t('home.success')
                        })
                    })
                    .catch(() => {
                        console.log('canceled')
                    })
            }

            if (val === 'key') {
                console.log(this.config.uid)
                console.log(this.config.key);
                 this.editModalVisible = true
            }
        },
        closeDialog() {
            this.editModalVisible = false
        },
        getUserList() {
            Core.Api.User.allList()
                .then(res => {
                    // console.log("header res", res.user_list);
                    this.$store.commit('setUserList', res.user_list)
                })
                
        }
    }
}
</script>

<style scoped lang="scss">
.first-header {
    height: 50px;
    background: #3f4855;
    margin-top: -1px;
    border-bottom: 1px solid #525b68;

    .logo-warp {
        font-size: 0;
        padding: 13px 0 13px 20px;
        display: flex;
        align-items: center;

        .logo-img {
            height: 24px;
        }
        .userType {
            min-width: 46px;
            margin-left: 10px;
            font-size: 16px;
            color: #fff;
            font-weight: 600;
            background-color: #4A90E2;
            padding: 3px 5px;
            border-radius: 3px;
            position: relative;
            bottom: -1px;
        }
    }

    .header-info {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        height: 50px;
        align-items: center;

        .header-icon {
            width: 18px;
            height: 18px;
            margin-left: 20px;
        }

        .show-info {
            color: #dce2e8;
            margin-left: 5px;
            cursor: pointer;
        }

        .dropdown-menu__item {
            height: 50px;
        }
    }
}
</style>

<style lang="scss">
body > .el-dropdown-menu {
    .el-dropdown-menu__item {
        width: 100px;
    }
}
</style>
