<template>
    <div class="screen-list">
        <div class="park-info">
            <div class="info">
                <div class="row-content">
                    <div class="row-item">
                        <div class="title">屏幕名称:</div>
                        <div class="content">{{ screenDetail.name || '-' }}</div>
                    </div>
                    <div class="row-item">
                        <div class="title">屏幕序号:</div>
                        <div class="content">{{ screenDetail.sort | ScreenSort }}</div>
                    </div>
                </div>
                <div class="row-content">
                    <div class="row-item">
                        <div class="title">屏幕状态:</div>
                        <div class="content">{{ screenDetail.status | ScreenStatus }}</div>
                    </div>
                    <div class="row-item">
                        <div class="title">屏幕格式:</div>
                        <div class="content">{{ screenDetail.format | ScreenFormat }}</div>
                    </div>
                </div>
                <div class="row-content" v-if="idleParkList">
                    <div class="row-item">
                        <div class="title">总车位数:</div>
                        <div class="content">{{ idleParkList.park_all_count }}</div>
                    </div>
                    <div class="row-item">
                        <div class="title">使用车位数:</div>
                        <div class="content">{{ idleParkList.use_park_count }}</div>
                    </div>
                </div>
                <div class="row-content">
                    <div class="row-item">
                        <div class="title" v-if="idleParkList">空闲车位数:</div>
                        <div class="content">{{ idleParkList.idle_park_count }}</div>
                    </div>
                    <div class="row-item">
                        <div class="title">绑定卡号:</div>
                        <div class="content">{{ screenDetail.monitor_card_no || '-' }}</div>
                    </div>
                </div>
                <div class="row-content">
                    <div class="row-item">
                        <div class="title">屏幕宽度:</div>
                        <div class="content">{{ screenDetail.width }}</div>
                    </div>
                    <div class="row-item">
                        <div class="title">屏幕高度:</div>
                        <div class="content">{{ screenDetail.height || '-' }}</div>
                    </div>
                </div>
                <div class="row-content">
                    <div class="row-item">
                        <div class="title">字体颜色:</div>
                        <div class="content">{{ screenDetail.color | ScreenColor }}</div>
                    </div>
                    <div class="row-item">
                        <div class="title">最后一次推送:</div>
                        <div class="content">
                            <el-button @click="showFrameDialog(screenDetail)">查看</el-button>
                            <el-button @click="send">推送</el-button>
                        </div>
                    </div>
                </div>
                <div class="row-content">
                    <div class="row-item">
                        <div class="title">字体大小:</div>
                        <div class="content">{{ screenDetail.font_size | FontFormat }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="screen-list-container flex-container">
            <el-tabs v-model="activeName" class="tab-content" type="card">
                <el-button type="primary" class="bindPark" @click="bindPark">绑定车位</el-button>
                <el-tab-pane label="绑定车位列表" name="first">
                    <distribute-table
                        ref="bindParkTable"
                        :screenId="screenId"
                        @getIdlePark="getIdlePark"
                    ></distribute-table>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!--绑定弹窗-->
        <el-dialog title="绑定车位" width="70%"
                   :visible.sync="bindvisible"
                   v-if="bindvisible"
                   :destroy-on-close="true"
                   @close="closeBindvisible"
        >
            <bind-table
                :id="parseInt(screenId)"
                :userId="parseInt(screenDetail.user_id)"
                @getIdlePark="getIdlePark"
                @closeBindvisible="closeBindvisible"
                @getBindPark="getBindPark"
            ></bind-table>
        </el-dialog>

        <!-- 查看推送 -->
        <el-dialog
            title="最后一次推送记录"
            :visible.sync="dialogVisible"
            custom-class="json-dialog"
            width="600px"
        >
            <div class="content">
                <div class="pre-warp">
                    <pre>{{ frame }}</pre>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core'
import DistributeTable from './components/DistributeTable.vue'
import BindTable from "./components/BindTable";
export default {
    components: {
        DistributeTable, BindTable
    },

    data() {
        return {
            list: [],
            // 分页配置
            currentPage: 1,
            total: 0,
            pageSize: 20,
            screenId: 0,
            screenDetail: {},
            activeName: 'first',
            idleParkList: 0, // 空闲车位数
            // 绑定
            bindvisible: false,
            isBindTable: false,
            dialogVisible: false,
            frame: ''
        }
    },

    mounted: function () {
        console.log('mounted');
        this.screenId = this.$route['query']['id']
        console.log('screenId: ', this.screenId);
        this.getDetail();
        this.getIdlePark(); // 空闲车位

    },

    methods: {
        // 车位数
        async getIdlePark() {
            let res = await Core.Api.Screen.idlePark(this.screenId)
            this.idleParkList = res
            console.log('this.idleParkList: ', this.idleParkList);
        },

        async getDetail() {
            let res = await Core.Api.Screen.detail(this.screenId)
            this.screenDetail = res
            console.log('this.screenDetail', this.screenDetail);
        },

        pageChange(val) {
            this.currentPage = val
            this.getList()
        },
        // 绑定车位
        bindPark() {
            this.isBindTable = true
            this.bindvisible = true
        },
        closeBindvisible() {
            this.bindvisible = false
            this.isBindTable = false
            Core.Data.set('bindList', [])
        },
        // 更新绑定车位列表
        getBindPark() {
            setTimeout(() => {
                this.$refs.bindParkTable.getBindParkList(1)
            }, 200)
        },
        // 推送
        showFrameDialog(frame) {
            this.dialogVisible = true;
            this.frame = frame.last_active_data ? JSON.parse(frame.last_active_data) : "";
            console.log('this.frame: ', this.frame);
        },
        send() {
            let item = this.screenDetail.last_active_data ? JSON.parse(this.screenDetail.last_active_data) : ''
            console.log('item: ', item);
            if (item && item.device_uid) {
                Core.Api.Screen.sendStatus(item.device_uid, item.space, item.status)
                    .then(res => {
                        console.log("res", res);
                        this.$message.success('推送成功')
                    })
                    .catch(err => {
                        console.log("err", err);
                        this.$message.error('推送失败')
                    })
            }
        },
    }
}
</script>

<style scoped lang="scss">
.screen-list {
    height: 100%;
    // padding: 20px;
    .park-info {
        padding: 20px 20px;
        background: #ffffff;
        margin-bottom: 20px;
        .info {
            border: 1px solid #eaedf0;
            background: #f5f7f9;
            padding: 20px;
            border-radius: 4px;
            .row-content {
                font-size: 0;
                margin-bottom: 14px;
                .row-item {
                    display: inline-block;
                    width: 50%;
                    .title {
                        font-size: 12px;
                        color: #8e949c;
                        min-width: 120px;
                        display: inline-block;
                    }
                    .content {
                        font-size: 12px;
                        color: #50555d;
                        display: inline-block;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        // .detector {}
    }
    .screen-list-container {
        margin: 20px;
        // border: 1px solid #e0e7ef;
        border-radius: 4px;
        overflow: hidden;
        .title {
            padding: 10px;
            background: #ffffff;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
        }
        height: 100%;
        .screen-list-table {
            background: #ffffff;
            position: relative;
            overflow: auto;
            .line-status-span {
                padding: 2px 10px;
                background: #f0f3f6;
                font-size: 12px;
                border-radius: 12px;
                display: inline-block;
                &.offline {
                    color: #50555d;
                }
                &.online {
                    color: #08b76b;
                }
            }
            .park-status {
                .dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    display: inline-block;
                    background: #39a4f4;
                    margin-right: 4px;
                    vertical-align: middle;
                }
                .text {
                    vertical-align: middle;
                    color: #39a4f4;
                }
                &.no-car {
                    .dot {
                        background: #afb6c0;
                    }
                    .text {
                        color: #afb6c0;
                    }
                }
            }
        }
        // .flex-footer {
        //     border-top: 1px solid #e0e7ef;
        // }
        .flex-footer.h-80 {
            height: 80px;
        }
        .tab-content {
            background-color: #fff;
            border-radius: 4px;
            overflow: hidden;
            position: relative;
        }
        .bindPark {
            margin-left: 20px;
            margin-bottom: 10px;
        }
    }
}
</style>

<style lang="scss">
.screen-list-container {
    .el-tabs__header.is-top {
        background-color: #f5f7f9;
    }
    .el-tabs__content {
        border-top: 0;
        border-radius: 0 0 4px 4px;
    }
}
</style>
