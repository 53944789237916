<template>
	<div class="device-detail-container">
		<div class="device-detail flex-container">
			<!-- 设备信息模板 -->
			<DeviceInfo ref="DeviceInfo" v-if="space" @mounted="handleComponentsMounted('DeviceInfo')" />

			<el-row class="item-panel panel-list" :span="10">
				<div class="button-wrapper" v-if="activeName === 'frame_list' || activeName === 'relay_log_list'">
					<el-button @click="refresh" type="success"> {{ $t('device.refresh') }} </el-button>
				</div>
				<el-tabs v-model="activeName" class="tab-content" type="card">
					<el-tab-pane :label="$t('device.detail')" name="info_list">
						<el-row class="btns">
							<el-button type="primary" @click="handleDistribute(device)">
								<i class="icon-distribute"></i>
								{{ $t('device.distribution') }}
							</el-button>
							<el-button type="primary" icon="el-icon-edit" @click="handleEditInfo(device)">{{ $t('device.edit') }}</el-button>
							<el-button type="danger" class="pull-right" icon="el-icon-delete" @click="handleDelete(device)"> {{ $t('device.delete') }} </el-button>
						</el-row>

						<!-- 基本信息 -->
						<div class="device-info-panel">
							<div class="title-row">
								<label class="title-text">{{ $t('device.detail') }}</label>
							</div>
							<div class="info-panel-warp">
								<InfoList :infoList="baseInfoList" />
							</div>
						</div>

						<!-- 即时信息 -->
						<div class="device-info-panel" v-if="SPACE_TYPE_MARK.SHOW_IMMEDIATE_INFO.includes(space)">
							<div class="title-row">
								<label class="title-text">{{ $t('device.instantDetail') }}</label>
							</div>
							<div class="info-panel-warp">
								<InfoList :infoList="immediateInfoList" />
							</div>
						</div>

						<!-- 三轴统计信息 -->
						<div class="device-info-panel" v-if="SPACE_TYPE_MARK.SHOW_TRIAXIAL_INFO.includes(space)">
							<div class="title-row">
								<label class="title-text">{{ $t('device.triaxialStatistics') }}</label>
							</div>
							<div class="info-panel-warp">
								<InfoList :infoList="triaxialInfoList" />
							</div>
						</div>

						<div class="device-info-panel equipment-attribute-panel" v-if="SPACE_TYPE_MARK.SHOW_EQUIPMENT_ATTRIBUTE.includes(space)">
							<div class="title-row">
								<label class="title-text">{{ $t('device.equipmentAttribute') }}</label>
								<el-button type="success" class="equipment-updata-btn" @click="equipAttrUpdate" :disabled="!equipAttrUpdateAble">{{ $t('device.sendParameter') }}</el-button>
							</div>
							<div class="info-panel-warp">
								<EquipmentAttribute ref="EquipmentAttribute" v-if="space" :uid="uid" :space="space" @changeEquipAttrUpdateAble="handleEquipAttrUpdateAble" />
							</div>
						</div>

						<!-- 推送信息 -->
						<div class="device-info-panel">
							<div class="title-row">
								<label class="title-text">{{ $t('device.pushInformation') }}</label>
							</div>
							<RelayConfigList v-if="space" :uid="uid" :space="space" />
						</div>
					</el-tab-pane>

					<!-- 帧信息 -->
					<el-tab-pane :label="$t('device.frame')" name="frame_list" v-if="SPACE_TYPE_MARK.SHOW_FRAME_LIST.includes(space)">
						<FrameList ref="FrameList" v-if="space" :space="space" :uid="uid" />
					</el-tab-pane>

					<el-tab-pane :label="$t('device.upData')" name="uplink_frame_list" v-if="SPACE_TYPE_MARK.SHOW_UPLINK_FRAME_LIST.includes(space)">
						<UplinkFrameList ref="UplinkFrameList" v-if="space" :space="space" :uid="uid" />
					</el-tab-pane>

					<el-tab-pane :label="$t('device.upDataV2')" name="new_uplink_frame_list" v-if="SPACE_TYPE_MARK.SHOW_NEW_UPLINK_FRAME_LIST.includes(space)">
						<NewUplinkFrameList ref="NewUplinkFrameList" v-if="space" :space="space" :uid="uid" />
					</el-tab-pane>

					<el-tab-pane :label="$t('device.historicalData')" name="frame_history_list" v-if="SPACE_TYPE_MARK.SHOW_FRAME_HISTORY_LIST.includes(space)">
						<FrameHistoryList ref="FrameHistoryList" v-if="space" :space="space" :uid="uid" />
					</el-tab-pane>

					<el-tab-pane :label="$t('device.downCommand')" name="command_list" v-if="SPACE_TYPE_MARK.SHOW_COMMAND_LIST.includes(space)">
						<CommandList ref="CommandList" :uid="uid" :space="space" v-if="activeName === 'command_list' && space" />
					</el-tab-pane>

					<el-tab-pane :label="$t('device.pushLog')" name="relay_log_list">
						<RelayLogList ref="RelayLogList" :uid="uid" />
					</el-tab-pane>

					<el-tab-pane :label="$t('device.operate')" name="action">
						<div class="mg-15">
							<el-button type="success" @click="handleRelay">{{ $t('device.simulatedDataReport') }}</el-button>

							<!-- 查询、设置 -->
							<template v-if="SPACE_TYPE_MARK.SHOW_ACTION_QUERY_SET.includes(space)">
								<el-button type="primary" @click="showQueryDialog">{{ $t('device.query') }}</el-button>
								<el-button type="primary" @click="showSetDialog">{{ $t('device.setting') }}</el-button>
							</template>

							<!-- 触发多项功能 -->
							<template v-if="SPACE_TYPE_MARK.SHOW_ACTION_TRIGGER.includes(space)">
								<el-row class="search-row" style="margin: 15px 0">
									<el-button type="success" @click="triggerCalibrate">{{ $t('device.touchOffCorrect') }}</el-button>
									<el-button type="success" @click="triggerHeartbeat">{{ $t('device.touchOffHeartbeat') }}</el-button>
									<el-button type="success" @click="triggerCalibrateHeartbeat">{{ $t('device.touchOffCorrectAndHeartbeat') }}</el-button>
									<el-button type="success" @click="triggerReboot">{{ $t('device.touchOffRestart') }}</el-button>
									<el-button type="success" @click="getConfig">{{ $t('device.getSetting') }}</el-button>
									<el-button type="success" @click="showSetConfigDialog">{{ $t('device.baseSetting') }}</el-button>
									<el-button type="success" @click="showSetEnvironmentTrackTimeAndFrequencyPointDialog">{{ $t('device.settingEnvironment') }}</el-button>
								</el-row>
								<el-row class="search-row" style="margin: 15px 0">
									<el-button type="success" @click="enableRadarReport">{{ $t('device.turnOnRadarReport') }}</el-button>
									<el-button type="success" @click="disableRadarReport">{{ $t('device.shutDownRadarReport') }}</el-button>
								</el-row>
							</template>

							<!-- OneNet地磁 -->
							<template v-if="space === SPACE_TYPE.ONE_NET">
								<el-button type="success" @click="handleWriteResOffline">{{ $t('device.issueInstruction') }}</el-button>
							</template>
						</div>
					</el-tab-pane>

					<el-tab-pane :label="$t('device.dataExport')" name="export_list" v-if="SPACE_TYPE_MARK.SHOW_EXPORT_LIST.includes(space)">
						<div class="mg-15">
							<FrameExportList ref="FrameExportList" v-if="space" :space="space" :uid="uid" />
						</div>
					</el-tab-pane>

					<el-tab-pane :label="$t('device.alarmList')" name="alarm_list">
						<AlarmList ref="AlarmList" v-if="space" :uid="uid" :space="space" />
					</el-tab-pane>

					<el-tab-pane :label="$t('device.remoteDebug')" name="remote_debug_detector">
						<RemoteDebugDetector :device-id="device.uid" :last-active-time="device.last_active_time" />
					</el-tab-pane>

					<el-tab-pane :label="$t('device.map')" name="map" v-if="device && device.latitude && device.longitude">
						<NewMap :device="device" ref="Map" v-if="space" />
					</el-tab-pane>
				</el-tabs>
			</el-row>
		</div>

		<!-- 基本信息-编辑弹窗 -->
		<el-dialog :title="$t('device.update')" custom-class="edit-dialog" :visible.sync="editModalVisible" @close="editDialogCloseCallback">
			<el-form :label-position="'left'" label-width="110px" ref="deviceForm" :model="deviceForm">
				<el-form-item :label="$t('device.ID') + ':'" prop="park_no">
					<span>{{ deviceForm.uid }}</span>
				</el-form-item>
				<el-form-item :label="$t('detectorList.parkNo') + ':'" prop="park_no">
					<el-input :placeholder="$t('common.pleaseInput')" v-model="deviceForm.park_no"></el-input>
				</el-form-item>
				<el-form-item :label="$t('detectorList.address') + ':'" prop="address">
					<el-input :placeholder="$t('common.pleaseInput')" v-model="deviceForm.address"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="editDevice()">{{ $t('device.done') }}</el-button>
			</div>
		</el-dialog>

		<!-- 基本信息-分配弹窗 -->
		<el-dialog :title="$t('device.updateToUser')" custom-class="edit-dialog" :visible.sync="distributeModalVisible">
			<el-form :label-position="'left'" label-width="110px" ref="userForm" :model="userForm">
				<el-form-item :label="$t('device.user') + ':'" prop="current_user_name">
					{{ userForm.current_user_name }}
				</el-form-item>
				<el-form-item :label="$t('device.groupName') + ':'" prop="current_group_name">
					{{ userForm.current_group_name }}
				</el-form-item>
				<el-form-item :label="$t('device.targetUser')" prop="target_user_id">
					<el-select v-model="userForm.target_user_id" filterable default-first-option clearable class="form-control" :placeholder="$t('detectorList.select')">
						<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('device.groupName')">
					<el-select v-model="userForm.target_group_id" filterable default-first-option clearable class="form-control" :placeholder="$t('detectorList.select')">
						<el-option v-for="item in userGroupList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="distributeDevice('userForm')">{{ $t('device.done') }}</el-button>
			</div>
		</el-dialog>

		<!-- 基本信息-删除弹窗 -->
		<el-dialog :title="$t('device.deleteDevice')" custom-class="edit-dialog" :visible.sync="deleteModalVisible">
			<p style="padding: 20px 0">{{ $t('device.reminder') }}</p>
			<el-form :label-position="'left'" label-width="110px" ref="deleteForm" :model="deleteForm">
				<el-form-item :label="$t('device.passWord') + ':'" prop="password" :rules="[{ required: true, message: $t('device.passWord') + $t('device.notNull') }]">
					<el-input type="password" :placeholder="$t('device.notNull')" v-model="deleteForm.password"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="deleteDevice('deleteForm')">{{ $t('device.done') }}</el-button>
			</div>
		</el-dialog>

		<!-- 操作-模拟数据上报弹窗 -->
		<el-dialog :title="$t('device.simulatedDataReport')" custom-class="edit-dialog" :visible.sync="relayModalVisible" @close="relayDialogCloseCallback">
			<el-form :label-position="'left'" ref="relayForm" label-width="110px" :model="relayForm">
				<el-form-item :label="$t('device.frameType')" v-if="SPACE_TYPE_MARK.SHOW_RELAY_TYPE.includes(space)">
					<el-radio-group v-model="relayForm.type">
						<el-radio :label="1">{{ $t('device.dataFrame') }}</el-radio>
						<el-radio :label="2">{{ $t('device.heartbeatFrame') }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('device.cartStatus')">
					<el-radio-group v-model="relayForm.state">
						<el-radio :label="0">{{ $t('device.nonCar') }}</el-radio>
						<el-radio :label="1">{{ $t('device.car') }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('device.voltage')" v-if="SPACE_TYPE_MARK.SHOW_RELAY_VOLTAGE.includes(space)">
					<el-input type="text" :placeholder="$t('common.voltageUnitmV')" v-model="relayForm.voltage"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="relayDeviceData('relayForm')">{{ $t('device.send') }}</el-button>
			</div>
		</el-dialog>

		<!-- 操作-数据上报结果弹窗 -->
		<el-dialog :title="$t('device.pushResult')" with="70%" :visible.sync="responseDialogVisible">
			<div v-for="(item, key) in relayResponse" class="pt-15" :key="key">
				<div v-if="key === 'result' || key === 'endpoint'">
					<p style="font-size: 14px" v-if="key === 'result'">{{ $t('device.pushResult') }}：</p>
					<p style="font-size: 14px" v-if="key === 'endpoint'">{{ $t('device.pushAddress') }}：</p>
				</div>
				<div v-else>
					<p style="font-size: 14px">
						{{ key }}
					</p>
				</div>

				<div style="max-height: 400px; overflow: auto">
					<label>{{ item }}</label>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="responseDialogVisible = false">{{ $t('device.done') }}</el-button>
			</div>
		</el-dialog>

		<!-- 操作-查询弹窗-->
		<el-dialog :title="$t('device.query')" custom-class="edit-dialog" :visible.sync="queryDialogVisible">
			<BkvSelector :keyList="queryKeyList" :bkv="queryBKVHex" ref="query-bkv-selector" />
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="writeQueryCommand()">{{ $t('device.done') }}</el-button>
			</div>
		</el-dialog>

		<!-- 操作-设置弹窗 -->
		<el-dialog :title="$t('device.setting')" custom-class="edit-dialog" :visible.sync="setDialogVisible">
			<BkvEditor :keyList="setKeyList" :bkv="setBKVHex" ref="set-bkv-editor" />
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="writeSetCommand()">{{ $t('device.issue') }}</el-button>
			</div>
		</el-dialog>

		<!-- 操作-基础设置 -->
		<el-dialog :title="$t('device.baseSetting')" custom-class="edit-dialog" :visible.sync="setConfigModalVisible" @close="setConfigDialogCloseCallback">
			<el-form :label-position="'left'" ref="configForm" label-width="120px" :model="configForm">
				<el-form-item :label="$t('device.openTime') + ':'" prop="on_decision_time" :rules="[{ required: true, message: $t('device.openTime') + $t('device.notNull') }]">
					<el-input v-model="configForm.on_decision_time"></el-input>
				</el-form-item>
				<el-form-item :label="$t('device.closeTime') + ':'" prop="off_decision_time" :rules="[{ required: true, message: $t('device.closeTime') + $t('device.notNull') }]">
					<el-input v-model="configForm.off_decision_time"></el-input>
				</el-form-item>
				<el-form-item :label="$t('device.sensitivity') + ':'" prop="sensitivity" :rules="[{ required: true, message: $t('device.sensitivity') + $t('device.notNull') }]">
					<el-input v-model="configForm.sensitivity"></el-input>
				</el-form-item>
				<el-form-item :label="$t('device.heartbeatIntervalTime') + ':'" prop="heartbeat_time" :rules="[{ required: true, message: $t('device.heartbeatIntervalTime') + $t('device.notNull') }]">
					<el-input v-model="configForm.heartbeat_time"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="writeDataSetConfig('configForm')">{{ $t('device.setting') }}</el-button>
			</div>
		</el-dialog>

		<!-- 操作-设置环境跟踪值和频点 -->
		<el-dialog :title="$t('device.settingEnvironment')" custom-class="edit-dialog" :visible.sync="setEnvironmentTrackTimeAndFrequencyPointModalVisible">
			<el-form :label-position="'left'" ref="setEnvironmentTrackTimeAndFrequencyPointForm" label-width="110px" :model="setEnvironmentTrackTimeAndFrequencyPointForm">
				<el-form-item :label="$t('device.environmentTrackTime') + ':'" prop="environment_track_time" :rules="[{ required: true, message: $t('device.environmentTrackTime') + $t('device.notNull') }]">
					<el-input-number v-model="setEnvironmentTrackTimeAndFrequencyPointForm.environment_track_time"></el-input-number>
				</el-form-item>
				<el-form-item :label="$t('device.frequencyPoint') + ':'" prop="frequency_point" :rules="[{ required: true, message: $t('device.frequencyPoint') + $t('device.notNull') }]">
					<el-input-number v-model="setEnvironmentTrackTimeAndFrequencyPointForm.frequency_point"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="writeDataSetEnvironmentTrackTimeAndFrequencyPoint('setEnvironmentTrackTimeAndFrequencyPointForm')">{{ $t('device.setting') }}</el-button>
			</div>
		</el-dialog>

		<!-- 操作-下发缓存指令弹窗 -->
		<el-dialog :title="$t('device.sendCacheInstruction')" custom-class="edit-dialog" :visible.sync="writeResOfflineModalVisible">
			<el-form :label-position="'left'" ref="relayForm" label-width="110px" :model="writeResOfflineForm">
				<el-form-item label="objId">
					<el-input type="number" v-model="writeResOfflineForm.objId" :placeholder="$t('common.pleaseInput')" />
				</el-form-item>
				<el-form-item label="objInstId">
					<el-input type="number" v-model="writeResOfflineForm.objInstId" :placeholder="$t('common.pleaseInput')" />
				</el-form-item>
				<el-form-item label="mode">
					<el-input type="number" v-model="writeResOfflineForm.mode" :placeholder="$t('common.pleaseInput')" />
				</el-form-item>
				<el-form-item label="timeout">
					<el-input type="number" v-model="writeResOfflineForm.timeout" :placeholder="$t('common.pleaseInput')" />
				</el-form-item>
				<el-form-item label="triggerMsg">
					<el-input type="number" v-model="writeResOfflineForm.triggerMsg" :placeholder="$t('common.pleaseInput')" />
				</el-form-item>
				<el-form-item label="resId">
					<el-input type="number" v-model="writeResOfflineForm.resId" :placeholder="$t('common.pleaseInput')" />
				</el-form-item>
				<el-form-item label="resValue">
					<el-input type="text" v-model="writeResOfflineForm.resValue" :placeholder="$t('common.pleaseInput')" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="writeResOffline()">{{ $t('device.send') }}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Core from '@/core';
import GetKeys from './js/getKey/index';

const SPACE_TYPE = Core.Const.SPACE.TYPE;
const SPACE_TYPE_MARK = Core.Const.SPACE.TYPE_MARK;

export default {
	name: 'DeviceDetail',
	components: {
		DeviceInfo: () => import('@/components/panel/DeviceInfo.vue'),
		EquipmentAttribute: () => import('@/components/panel/EquipmentAttribute.vue'),

		InfoList: () => import('@/components/device/InfoList'),
		AlarmList: () => import('@/components/device/AlarmList.vue'),
		CommandList: () => import('@/components/device/CommandList.vue'),
		RemoteDebugDetector: () => import('@/components/device/RemoteDebugDetector.vue'),
		NewMap: () => import('@/components/device/Map.vue'),

		RelayLogList: () => import('@/components/relay/LogList.vue'),
		RelayConfigList: () => import('@/components/relay/ConfigList.vue'),

		FrameList: () => import('@/components/frame/FrameList.vue'),
		UplinkFrameList: () => import('@/components/frame/UplinkFrameList.vue'),
		NewUplinkFrameList: () => import('@/components/frame/NewUplinkFrameList.vue'),
		FrameExportList: () => import('@/components/frame/ExportList.vue'),
		FrameHistoryList: () => import('@/components/frame/HistoryList.vue'),

		BkvEditor: () => import('@/components/bkv/Editor.vue'),
		BkvSelector: () => import('@/components/bkv/Selector'),
	},
	data() {
		return {
			SPACE_TYPE,
			SPACE_TYPE_MARK,

			device: {},
			outer_client_detail: {},
			client: {},

			space: '',
			/**
			 *
			 * 已迁移(已上线)：
			 * 20000    // 虚拟分组设备
			 * 20015    // UDP-BKV地磁
			 * 20023    // AEP地磁
			 *
			 * 已迁移(未上线)：
			 * 20006    // CoAP地磁
			 * 20009    // OneNet地磁
			 * 20025    // 停简单CoAP地磁
			 * 20028    // AEP-BKV地磁-多租户
			 * 20029    // 新CoAP-BKV地磁
			 * 20030    // 停简单物联网平台
			 * 20033    // Chirpstack
			 *
			 *
			 * 看最后活动时间  已不在使用
			 * 注：最新的那一条，最后活动时间在24年以前(不包括24年)
			 * 所以未迁移  如果以后有使用再迁移
			 * 20003,   // UDP
			 * 20004,   // 流量
			 * 20005,   // DTU
			 * 20011,   // LinkWan地磁
			 * 20013,   // OceanConnect
			 * 20017,   // 新UDP地磁
			 * 20018,   // CoAP-BKV地磁
			 * 20020,   // LoRa地磁
			 * 20024,   // Lierda LoraWan地磁
			 * 20027,   // OneNet-BKV地磁
			 * 20031,   // 南鹏OneNet
			 * 20032,   // Lora-CLAA
			 *
			 * 地锁：
			 * 看最后活动时间  已不在使用
			 * 注：最新的那一条，最后活动时间在24年以前(不包括24年)
			 * 所以未迁移  如果以后有使用再迁移
			 * 迁移在建议新建一个地锁详情页
			 * 此文件是地磁详情页
			 * 20007,   // CoAP地锁
			 * 20012,   // LinkWan地锁
			 * 20014,   // OneNet地锁
			 * 20021,   // LoRa地锁
			 */

			activeName: 'info_list',
			/**
			 * info_list              基本信息
			 * frame_list             帧信息
			 * uplink_frame_list      上行数据
			 * new_uplink_frame_list  上行数据v2
			 * frame_history_list     历史数据
			 * command_list           下行命令
			 * relay_log_list         推送记录
			 * action                 操作
			 * export_list            数据导出
			 * alarm_list             告警列表
			 * remote_debug_detector  远程调试
			 * map                    地图
			 */
			id: '',
			uid: '',

			baseInfoList: [], // 基本信息列表
			immediateInfoList: [], // 即时信息列表
			triaxialInfoList: [], // 三轴统计信息列表

			// 模拟推送
			relayModalVisible: false,
			relayForm: {
				type: 1,
				state: 0,
				voltage: 3600,
			},
			relayResponse: {},
			responseDialogVisible: false,

			// 基本信息
			editModalVisible: false,
			deviceForm: {
				id: '',
				uid: '',
				park_no: '',
				address: '',
			},
			// 删除
			deleteModalVisible: false,
			deleteForm: {
				id: '',
				password: '',
			},
			// 分配
			distributeModalVisible: false,
			userForm: {
				device_id: '',
				current_user_name: '',
				current_group_name: '',
				target_user_id: '',
				target_group_id: '',
			},

			userList: [],
			userGroupList: [],

			// 查询key
			queryKeyForm: {
				key: '',
			},
			queryKeyList: [],
			queryBKVHex: '',

			queryDialogVisible: false,

			// 设置key
			setDialogVisible: false,
			setKeyList: [],
			setBKVHex: '',

			canOperate: true,

			// 基础设置
			setConfigModalVisible: false,
			configForm: {
				on_decision_time: '',
				off_decision_time: '',
				sensitivity: '',
				heartbeat_time: '',
			},

			// 设置环境跟踪值和频点
			setEnvironmentTrackTimeAndFrequencyPointModalVisible: false,
			setEnvironmentTrackTimeAndFrequencyPointForm: {
				environment_track_time: '',
				frequency_point: '',
			},

			// 下发指令
			writeResOfflineModalVisible: false,
			writeResOfflineForm: {
				objId: '',
				objInstId: '',
				mode: 1,
				timeout: 25,
				triggerMsg: '',
				resId: '',
				resValue: '',
			},

			// 设备属性所有都合法 且有值才可以点击
			equipAttrUpdateAble: false,
		};
	},
	watch: {
		activeName(val) {
			this.getTabInfo();
		},
		'userForm.target_user_id': 'getUserGroupList',
	},

	created() {
		this.uid = this.$route.query['uid'];
		let meta = this.$route.meta || {};
		this.space = meta.space || SPACE_TYPE.VIRTUAL_GROUPING;
		console.log('this.space:', this.space);
		switch (this.space) {
			case SPACE_TYPE.VIRTUAL_GROUPING: // 20000(20000)
			case SPACE_TYPE.UDP_BKV: // 20015(UDP-BKV地磁)
			case SPACE_TYPE.AEP: // 20023(AEP地磁)
			case SPACE_TYPE.AEP_BKV: // 20028(AEP-BKV地磁-多租户)
			case SPACE_TYPE.NEW_COAP_BKV: // 20029(新CoAP-BKV地磁)
			case SPACE_TYPE.TING_JIAN_DAN_IOT_PLATFORM: // 20030(停简单物联网平台)
				this.activeName = 'uplink_frame_list';
				break;
			case SPACE_TYPE.COAP: // 20006(CoAP地磁)
			case SPACE_TYPE.ONE_NET: // 20009(OneNet地磁)
			case SPACE_TYPE.TING_JIAN_DAN_COAP: // 20025(停简单CoAP地磁)
			case SPACE_TYPE.CHIRPSTACK: // 20033(Chirpstack)
				this.activeName = 'frame_list';
				break;
			default:
				this.activeName = 'info_list';
				break;
		}
	},

	mounted() {
		this.getDeviceDetail();
		this.getClientDetail();
		this.getUserList();
		this.getTabInfo();
	},
	methods: {
		async getDeviceDetail() {
			try {
				let funcName = 'Device' + this.space;
				let res = await Core.Api[funcName].getDeviceDetail({ uid: this.uid });
				console.log('getDeviceDetail  res:', res);
				let device = res['device'];
				device['status_text'] = Core.Util.isOff(device.last_active_time);
				device['cart_status'] = Core.Util.parkStateType(device.p1);
				this.id = device.id;
				this.device = device;

				this.getBaseInfoList(this.device);

				this.$nextTick(() => {
					if (this.$refs['DeviceInfo']) {
						this.$refs['DeviceInfo'].getDeviceInfoList(this.device, this.space);
					}
				});

				switch (this.space) {
					case SPACE_TYPE.COAP:
						// 20006(CoAP地磁)
						this.getTriaxialInfoList(this.device);
						this.getOuterClientDetail();
						break;
					case SPACE_TYPE.ONE_NET:
						// 20009(OneNet地磁)
						this.getOuterClientDetail();
						break;
					default:
						break;
				}
			} catch (err) {
				this.$message.error(err.message);
			}
		},

		// 组件加载完毕
		handleComponentsMounted(key) {
			switch (key) {
				case 'DeviceInfo':
					if (this.$refs['DeviceInfo']) {
						this.$refs['DeviceInfo'].getDeviceInfoList(this.device, this.space);
					}
					break;
			}
		},

		async getOuterClientDetail() {
			try {
				let res = {};
				switch (this.space) {
					case SPACE_TYPE.COAP: // 20006(CoAP地磁)
					case SPACE_TYPE.TING_JIAN_DAN_COAP: // 20025(停简单CoAP地磁)
						res = await Core.Api.Frame.getOuterClientDetail(this.space, this.uid);
						break;
					case SPACE_TYPE.ONE_NET:
						// 20009(OneNet地磁)
						res = await Core.Api.Fengine20009.getOuterDeviceDetail(this.uid);
						break;
					default:
						break;
				}
				this.outer_client_detail = res.device || {};
				this.device.outer_client_detail = res.device || {};
				this.$nextTick(() => {
					if (this.$refs['DeviceInfo']) {
						this.$refs['DeviceInfo'].getDeviceInfoList(this.device, this.space);
					}
				});
			} catch (err) {
				this.$message.error(err.message);
			}
		},

		// 基本信息
		async getBaseInfoList(device) {
			let data = [
				{
					key: 'imei',
					name: 'IMEI',
				},
				{
					key: 'imsi',
					name: 'IMSI',
				},

				SPACE_TYPE_MARK.SHOW_ICCID.includes(this.space)
					? {
							key: 'iccid',
							name: 'ICCID',
					  }
					: '',

				{
					key: 'nccid',
					name: 'NCCID',
					filter: 'NCCIDFilter',
				},

				SPACE_TYPE_MARK.SHOW_NCCID2.includes(this.space)
					? {
							key: 'nccid_2',
							name: 'NCCID_2',
							filter: 'NCCIDFilter',
					  }
					: '',

				SPACE_TYPE_MARK.SHOW_SIM_STATUS.includes(this.space)
					? {
							key: 'sim_status',
							name: this.$t('device.useSIMCard'),
							filter: 'simStateFilter',
					  }
					: '',

				SPACE_TYPE_MARK.SHOW_SIM_CONFIG.includes(this.space)
					? {
							key: 'sim_config',
							name: this.$t('device.configureSIMCard'),
							filter: 'simConfigFilter',
					  }
					: '',

				{
					key: 'address',
					name: this.$t('device.address'),
				},

				SPACE_TYPE_MARK.SHOW_PARK_LOT.includes(this.space)
					? {
							key: 'park_lot',
							name: this.$t('device.parkLot'),
					  }
					: '',

				{
					key: 'park_no',
					name: this.$t('device.parkNo'),
				},
				{
					key: 'firmware_version',
					name: this.$t('device.firmwareVersion'),
				},
				{
					key: 'hardware_version',
					name: this.$t('device.hardwareVersion'),
				},
				{
					key: 'bluetooth_version',
					name: this.$t('device.bluetoothVersion'),
				},
				{
					key: 'sensitivity',
					name: this.$t('device.sensitivity'),
				},
				{
					key: 'track_ratio',
					name: this.$t('device.trackRatio'),
				},
				{
					key: 'anti_interference_type',
					name: this.$t('device.antiInterferenceType'),
				},
				{
					key: 'heartbeat_interval',
					name: this.$t('device.heartbeatInterval'),
				},
				{
					key: 'nb_conn_station_id',
					name: this.$t('device.nbConnStationId'),
				},
			];

			data = data.filter((item) => item != '');

			this.baseInfoList = Core.Util.getInfoList(data, device) || [];

			// 200023 需要添加pci（次数）
			switch (this.space) {
				case SPACE_TYPE.AEP:
					try {
						let str = await this.getFrameList();
						this.baseInfoList.push({
							name: this.$t('device.pciNumberOfTimes'),
							value: str,
						});
					} catch (err) {
						this.baseInfoList.push({
							name: this.$t('device.pciNumberOfTimes'),
							value: '-',
						});
					}
					break;

				default:
					break;
			}
			// console.log('getBaseInfoList  this.baseInfoList:', this.baseInfoList);
		},
		// 获取帧信息
		async getFrameList() {
			let res = await Core.Api.Frame.getFrameList(1, 10, this.uid, this.space, 2, '2,130', Date.parse(new Date()) / 1000 - 86400 * 7, 0);
			// console.log("getFrameList  res:", res)
			let info = {};
			res.frame_list.forEach((item) => {
				// console.log("getFrameList  item:", item)
				let pci = item.frame.conn_signal_pci;
				// console.log("getFrameList  pci:", pci)
				if (info[pci]) {
					info[pci] = info[pci] + 1;
				} else {
					info[pci] = 1;
				}
			});
			// console.log('getFrameList',info);
			let keys = Object.keys(info);
			let str = '';
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				if (i === 0) {
					str = str + `${key}（${info[key]}）`;
				} else {
					str = str + ` ${key}（${info[key]}）`;
				}
			}
			// console.log("getFrameList  str:", str)

			return str;
		},

		async getClientDetail() {
			// TODO   20000 不需要获取帧信息
			if (this.space === SPACE_TYPE.VIRTUAL_GROUPING) {
				this.getImmediateInfoList(this.client);
				return;
			}

			try {
				let res = {};
				switch (this.space) {
					case SPACE_TYPE.COAP: // 20006(CoAP地磁)
					case SPACE_TYPE.ONE_NET: // 20009(OneNet地磁)
					case SPACE_TYPE.TING_JIAN_DAN_COAP: // 20025(停简单CoAP地磁)
					case SPACE_TYPE.CHIRPSTACK: // 20033(Chirpstack)
						// 不显示即时信息
						// res = await Core.Api.Frame.getClientDetail(this.space, this.uid);
						// this.client = res.device;
						break;
					case SPACE_TYPE.VIRTUAL_GROUPING: // 20000(虚拟分组设备)
					case SPACE_TYPE.UDP_BKV: // 20015(UDP-BKV地磁)
					case SPACE_TYPE.AEP: // 20023(AEP地磁)
					case SPACE_TYPE.AEP_BKV: // 20028(AEP-BKV地磁-多租户)
					case SPACE_TYPE.NEW_COAP_BKV: // 20029(新CoAP-BKV地磁)
						// case SPACE_TYPE.TING_JIAN_DAN_IOT_PLATFORM: // 20030(停简单物联网平台)  注释，不调取接口
						let funcName = 'Fengine' + this.space;
						res = await Core.Api[funcName].getClientDetail(this.uid);
						this.client = res.device;
						break;
					default:
						break;
				}
				this.getImmediateInfoList(this.client);
			} catch (e) {
				console.log(e);
			}
		},

		// 即时信息
		getImmediateInfoList(device) {
			let data = [
				{
					key: '',
					name: this.$t('device.parkState'),
					filter: 'p20015ParkState',
				},
				{
					key: '',
					name: this.$t('device.waterLevel'),
					filter: '',
				},
				{
					key: '',
					name: this.$t('device.X'),
					filter: 'p20015Attr',
					param: ['x'],
				},
				{
					key: '',
					name: this.$t('device.Y'),
					filter: 'p20015Attr',
					param: ['y'],
				},
				{
					key: '',
					name: this.$t('device.Z'),
					filter: 'p20015Attr',
					param: ['z'],
				},
				{
					key: '',
					name: this.$t('device.radarMark'),
					filter: '',
				},
				{
					key: '',
					name: this.$t('device.radarResult'),
					filter: '',
				},
				{
					key: '',
					name: this.$t('device.voltage'),
					filter: 'p20015Attr',
					param: ['voltage'],
				},
				{
					key: '',
					name: this.$t('device.deviceState'),
					filter: 'p20015ParkState',
				},
				{
					key: '',
					name: this.$t('device.temperature'),
					filter: 'p20015Attr',
					param: ['temperature'],
				},
				{
					key: '',
					name: this.$t('device.sirenEvent'),
					filter: '',
				},
				{
					key: '',
					name: this.$t('device.rssi'),
					filter: 'p20015Attr',
					param: ['conn_signal_strength'],
				},
				{
					key: '',
					name: this.$t('device.snr'),
					filter: 'p20015Attr',
					param: ['snr'],
				},
			];

			this.immediateInfoList = Core.Util.getFengineInfoList(data, device) || [];
			// console.log('getImmediateInfoList  this.immediateInfoList:', this.immediateInfoList);
		},

		// 三轴统计信息
		getTriaxialInfoList(device) {
			let data = [
				{
					key: 'x_max',
					name: 'X_MAX',
					filter: '',
				},
				{
					key: 'x_min',
					name: 'X_MIN',
					filter: '',
				},
				{
					key: 'y_max',
					name: 'Y_MAX',
					filter: '',
				},
				{
					key: 'y_min',
					name: 'Y_MIN',
					filter: '',
				},
				{
					key: 'z_max',
					name: 'Z_MAX',
					filter: '',
				},
				{
					key: 'z_min',
					name: 'Z_MIN',
					filter: '',
				},
				{
					key: 'xyz_status',
					name: this.$t('device.triaxialState'),
					filter: 'xyzStateFilter',
				},
				{
					key: 'frame_count',
					name: 'frame_count',
					filter: '',
				},
			];

			this.triaxialInfoList = Core.Util.getInfoList(data, device) || [];
			// console.log('getTriaxialInfoList  this.triaxialInfoList:', this.triaxialInfoList);
		},

		getTabInfo(page = 1) {
			console.log('getTabInfo  this.activeName:', this.activeName);
			// 重新获取数据  列表从第一页重新获取
			switch (this.activeName) {
				case 'info_list':
					this.getDeviceDetail();
					break;
				case 'frame_list':
					if (this.$refs.FrameList) {
						this.$refs.FrameList.getClientFrameList(true);
					}
					break;
				case 'uplink_frame_list':
					if (this.$refs.UplinkFrameList) {
						this.$refs.UplinkFrameList.getClientFrameList(true);
					}
					break;
				case 'new_uplink_frame_list':
					if (this.$refs.NewUplinkFrameList) {
						this.$refs.NewUplinkFrameList.refresh();
					}
					break;
				case 'frame_history_list':
					if (this.$refs.FrameHistoryList) {
						this.$refs.FrameHistoryList.refresh();
					}
					break;
				case 'command_list':
					if (this.$refs.CommandList) {
						this.$refs.CommandList.refresh();
					}
					break;
				case 'relay_log_list':
					if (this.$refs.RelayLogList) {
						this.$refs.RelayLogList.refresh();
					}
					break;
				case 'export_list':
					if (this.$refs.FrameExportList) {
						this.$refs.FrameExportList.refresh();
					}
					break;
				case 'alarm_list':
					if (this.$refs.AlarmList) {
						this.$refs.AlarmList.getAlarmList();
					}
					break;
				default:
					break;
			}
		},

		refresh() {
			this.getTabInfo();
		},

		handleRelay() {
			this.relayModalVisible = true;
		},

		relayDialogCloseCallback() {
			Object.assign(this.relayForm, this.$options.data().relayForm);
		},

		relayDeviceData(formName) {
			// TODO   20000 不需要获取帧信息
			if (this.space === SPACE_TYPE.VIRTUAL_GROUPING) {
				return;
			}

			this.$refs[formName].validate(async (valid) => {
				if (!valid) {
					return false;
				}

				try {
					let data = {};
					let res = {};
					switch (this.space) {
						case SPACE_TYPE.VIRTUAL_GROUPING: // 20000(虚拟分组设备)
						case SPACE_TYPE.UDP_BKV: // 20015(UDP-BKV地磁)
						case SPACE_TYPE.AEP: // 20023(AEP地磁)
						case SPACE_TYPE.AEP_BKV: // 20028(AEP-BKV地磁-多租户)
						case SPACE_TYPE.NEW_COAP_BKV: // 20029(新CoAP-BKV地磁)
						case SPACE_TYPE.TING_JIAN_DAN_IOT_PLATFORM: // 20030(停简单物联网平台)
						case SPACE_TYPE.CHIRPSTACK: // 20033(Chirpstack)
							let funcName = 'Fengine' + this.space;
							data = {
								uid: this.uid,
								type: this.relayForm.type,
								state: this.relayForm.state,
							};

							// 20029(新CoAP-BKV地磁)  多传电压
							if (SPACE_TYPE.NEW_COAP_BKV === this.space) {
								data = {
									...data,
									voltage: this.relayForm.voltage,
								};
							}

							res = await Core.Api[funcName].simulateRelay(data);
							this.$message.success(this.$t('device.sendSuccess'));
							this.relayModalVisible = false;
							break;
						case SPACE_TYPE.COAP: // 20006(CoAP地磁)
						case SPACE_TYPE.ONE_NET: // 20009(OneNet地磁)
						case SPACE_TYPE.TING_JIAN_DAN_COAP: // 20025(停简单CoAP地磁)
							data = {
								space: this.space,
								device_id: this.uid,
								state: this.relayForm.state,
							};
							res = await Core.Api.Fegoit.relay(data);
							this.$message.success(this.$t('device.sendSuccess'));
							this.relayModalVisible = false;
							if (this.$refs.FrameList) {
								this.$refs.FrameList.getClientFrameList();
							}
							this.relayResponse = {};
							if (res.result_list && res.result_list.length > 0) {
								this.relayResponse = res.result_list[0];

								this.$nextTick(() => {
									this.responseDialogVisible = true;
								});
							}
							break;
						default:
							break;
					}
				} catch (err) {
					console.log('relayDeviceData err:', err);
					this.$message.error(err.message);
				}
			});
		},

		//distribute
		handleDistribute(row) {
			let currentUserName = (row.device && row.device.user_name) || '-';
			let currentGroupName = (row.device && row.device.group_name) || '-';
			this.userForm = {
				uid: row.uid,
				current_user_name: currentUserName,
				current_group_name: currentGroupName,
				target_user_id: '',
				target_group_id: '',
			};
			this.distributeModalVisible = true;
		},
		async distributeDevice() {
			try {
				let form = this.userForm;
				let funcName = 'Device' + this.space;
				await Core.Api[funcName].saveTargetUser(form.uid, form.target_user_id, form.target_group_id);
				this.getDeviceDetail();
				this.distributeModalVisible = false;
			} catch (err) {
				console.log('distributeDevice  err:', err);
				this.$message.error(err.message);
			}
		},

		//edit dialog
		handleEditInfo(row) {
			this.deviceForm = {
				id: row.id,
				uid: row.uid,
				address: row.address,
				park_no: row.park_no,
			};
			this.editModalVisible = true;
		},
		editDialogCloseCallback() {
			Object.assign(this.deviceForm, this.$options.data().deviceForm);
		},
		async editDevice() {
			try {
				let funcName = 'Device' + this.space;
				await Core.Api[funcName].updateBaseInfo(this.deviceForm.id, this.deviceForm.park_no, this.deviceForm.address);
				this.editModalVisible = false;
				this.getDeviceDetail();
			} catch (err) {
				console.log('editDevice  err:', err);
				this.$message.error(err.message);
			}
		},

		//delete
		handleDelete(row) {
			this.deleteForm.id = row.id;
			this.deleteForm.password = '';
			this.deleteModalVisible = true;
		},
		async deleteDevice() {
			try {
				let password = this.deleteForm.password.trim();
				if (password !== Core.Const.NET.DEVICE_DELETE_PASSWORD) {
					throw {
						message: this.$t('message.passwordErrTryAgain'),
					};
				}

				let funcName = 'Device' + this.space;

				await Core.Api[funcName].deleteDevice(this.deleteForm.id);

				this.$message.success(this.$t('message.deleteSuccessfully'));
				this.getDeviceList();
				this.deleteModalVisible = false;
			} catch (err) {
				console.log('deleteDevice  err:', err);
				this.$message.error(err.message);
			}
		},

		async getUserList() {
			try {
				let res = await Core.Api.User.allList();
				this.userList = res.user_list;
			} catch (err) {
				this.$message.error(err.message);
			}
		},
		async getGroupList() {
			try {
				let res = await Core.Api.Group.listForAdmin(this.searchForm.user_id, 0);
				this.groupList = res.group_list;
			} catch (err) {
				this.$message.error(err.message);
			}
		},
		async getUserGroupList() {
			try {
				let res = await Core.Api.Group.listForAdmin(this.userForm.target_user_id, 0);
				this.userGroupList = res.group_list;
			} catch (err) {
				this.$message.error(err.message);
			}
		},

		showQueryDialog() {
			this.queryKeyForm = { key: '' };
			this.queryDialogVisible = true;
			let list = GetKeys.getQueryKeys(this.space);
			this.queryKeyList = Core.Const.getBKV20015Keys(list);
		},

		writeQueryCommand() {
			// TODO   20000 不需要获取帧信息
			if (this.space === SPACE_TYPE.VIRTUAL_GROUPING) {
				return;
			}

			let bkv = this.$refs['query-bkv-selector'].getBKV();
			if (!bkv) {
				this.$message.error(this.$t('common.pleaseSelect') + 'Key');
				return;
			}

			let funcName = 'Fengine' + this.space;
			Core.Api[funcName].query(this.uid, bkv).then((res) => {
				this.queryDialogVisible = false;
				this.$message.success(this.$t('device.success'));
			});
		},

		showSetDialog() {
			this.setKeyForm = { key: '', value: '' };
			this.setDialogVisible = true;
			let list = GetKeys.getSetKeys(this.space);
			this.setKeyList = Core.Const.getBKV20015Keys(list);
		},
		writeSetCommand() {
			// TODO   20000 不需要获取帧信息
			if (this.space === SPACE_TYPE.VIRTUAL_GROUPING) {
				return;
			}

			let bkv = this.$refs['set-bkv-editor'].getBKV();
			if (!bkv) {
				this.$message.error(this.$t('common.pleaseSelect') + 'Key');
				return;
			}

			let funcName = 'Fengine' + this.space;
			Core.Api[funcName].set(this.uid, bkv).then((res) => {
				this.setDialogVisible = false;
				this.$message.success(this.$t('device.success'));
			});
		},

		// 触发
		triggerCalibrate() {
			if (!this.canOperate) {
				this.$message.warning(this.$t('device.later'));
				return;
			}
			this.canOperate = false;
			let funcName = 'Fengine' + this.space;
			Core.Api[funcName]
				.triggerCalibrate(this.uid)
				.then((res) => {
					this.canOperate = true;
					this.$message.success(this.$t('device.success'));
				})
				.catch((err) => {
					this.canOperate = true;
				});
		},
		triggerHeartbeat() {
			if (!this.canOperate) {
				this.$message.warning(this.$t('device.later'));
				return;
			}
			this.canOperate = false;
			let funcName = 'Fengine' + this.space;
			Core.Api[funcName]
				.triggerHeartbeat(this.uid)
				.then((res) => {
					this.canOperate = true;
					this.$message.success(this.$t('device.success'));
				})
				.catch((err) => {
					this.canOperate = true;
				});
		},
		triggerCalibrateHeartbeat() {
			if (!this.canOperate) {
				this.$message.warning(this.$t('device.later'));
				return;
			}
			this.canOperate = false;
			let funcName = 'Fengine' + this.space;
			Core.Api[funcName]
				.triggerCalibrateAndHeartbeat(this.uid)
				.then((res) => {
					this.canOperate = true;
					this.$message.success(this.$t('device.success'));
				})
				.catch((err) => {
					this.canOperate = true;
				});
		},
		triggerReboot() {
			if (!this.canOperate) {
				this.$message.warning(this.$t('device.later'));
				return;
			}
			this.canOperate = false;
			let funcName = 'Fengine' + this.space;
			Core.Api[funcName]
				.reboot(this.uid)
				.then((res) => {
					this.canOperate = true;
					this.$message.success(this.$t('device.success'));
				})
				.catch((err) => {
					this.canOperate = true;
				});
		},

		// 获取设置
		getConfig() {
			if (!this.canOperate) {
				this.$message.warning(this.$t('device.later'));
				return;
			}
			this.canOperate = false;
			let funcName = 'Fengine' + this.space;
			Core.Api[funcName]
				.getConfig(this.id)
				.then((res) => {
					this.canOperate = true;
					this.$message.success(this.$t('device.success'));
				})
				.catch((err) => {
					this.canOperate = true;
				});
		},

		//基础设置
		showSetConfigDialog() {
			this.setConfigModalVisible = true;
		},
		setConfigDialogCloseCallback() {
			Object.assign(this.configForm, this.$options.data().configForm);
		},
		writeDataSetConfig(formName) {
			this.$refs[formName].validate((valid) => {
				if (!valid) return false;

				if (!this.canOperate) {
					this.$message.warning(this.$t('device.later'));
					return;
				}
				this.canOperate = false;
				let funcName = 'Fengine' + this.space;

				Core.Api[funcName]
					.setConfig(this.uid, this.configForm.on_decision_time, this.configForm.off_decision_time, this.configForm.sensitivity, this.configForm.heartbeat_time)
					.then((res) => {
						this.canOperate = true;
						this.$message.success(this.$t('device.success'));
						this.setConfigModalVisible = false;
					})
					.catch((err) => {
						this.canOperate = true;
					});
			});
		},

		//设置环境变量和频点
		showSetEnvironmentTrackTimeAndFrequencyPointDialog() {
			this.setEnvironmentTrackTimeAndFrequencyPointModalVisible = true;
			this.setEnvironmentTrackTimeAndFrequencyPointForm = {
				environment_track_time: '',
				frequency_point: '',
			};
		},
		writeDataSetEnvironmentTrackTimeAndFrequencyPoint(formName) {
			console.log(formName);
			this.$refs[formName].validate((valid) => {
				if (!valid) return false;

				if (!this.canOperate) {
					this.$message.warning(this.$t('device.later'));
					return;
				}
				this.canOperate = false;
				let funcName = 'Fengine' + this.space;
				Core.Api[funcName]
					.setEnvironmentTrackTimeAndFrequencyPoint(this.uid, this.setEnvironmentTrackTimeAndFrequencyPointForm.environment_track_time, this.setEnvironmentTrackTimeAndFrequencyPointForm.frequency_point)
					.then((res) => {
						this.canOperate = true;
						this.$message.success(this.$t('device.success'));
						this.setEnvironmentTrackTimeAndFrequencyPointModalVisible = false;
					})
					.catch((err) => {
						this.canOperate = true;
					});
			});
		},

		// 雷达
		enableRadarReport() {
			if (!this.canOperate) {
				this.$message.warning(this.$t('device.later'));
				return;
			}
			this.canOperate = false;
			let funcName = 'Fengine' + this.space;
			Core.Api[funcName]
				.enableRadarReport(this.uid, '1')
				.then((res) => {
					this.canOperate = true;
					this.$message.success(this.$t('device.success'));
				})
				.catch((err) => {
					this.canOperate = true;
				});
		},
		disableRadarReport() {
			if (!this.canOperate) {
				this.$message.warning(this.$t('device.later'));
				return;
			}
			this.canOperate = false;
			let funcName = 'Fengine' + this.space;
			Core.Api[funcName]
				.enableRadarReport(this.uid, '2')
				.then((res) => {
					this.canOperate = true;
					this.$message.success(this.$t('device.success'));
				})
				.catch((err) => {
					this.canOperate = true;
				});
		},

		// 下发缓存指令
		handleWriteResOffline() {
			this.writeResOfflineModalVisible = true;
		},
		async writeResOffline() {
			try {
				let objId = this.writeResOfflineForm.objId;
				if (objId === '') {
					throw {
						message: this.$t('common.pleaseFillComplete'),
					};
				}

				let objInstId = this.writeResOfflineForm.objInstId;
				if (objInstId === '') {
					throw {
						message: this.$t('common.pleaseFillComplete'),
					};
				}

				let mode = this.writeResOfflineForm.mode;
				if (mode === '') {
					throw {
						message: this.$t('common.pleaseFillComplete'),
					};
				}

				let timeout = this.writeResOfflineForm.timeout;
				if (timeout === '') {
					throw {
						message: this.$t('common.pleaseFillComplete'),
					};
				}

				let triggerMsg = this.writeResOfflineForm.triggerMsg;
				if (triggerMsg === '') {
					throw {
						message: this.$t('common.pleaseFillComplete'),
					};
				}

				let resId = this.writeResOfflineForm.resId;
				if (resId === '') {
					throw {
						message: this.$t('common.pleaseFillComplete'),
					};
				}

				let resValue = this.writeResOfflineForm.resValue;
				if (resValue === '') {
					throw {
						message: this.$t('common.pleaseFillComplete'),
					};
				}

				await Core.Api.Fengine20009.writeResOffline(this.uid, objId, objInstId, mode, timeout, triggerMsg, resId, 1, resValue);

				this.$message.success(this.$t('message.issueSuccessfully'));
			} catch (err) {
				this.$message.error(err.message);
			}
		},

		// 设备属性   下发参数
		equipAttrUpdate() {
			if (this.$refs.EquipmentAttribute) {
				this.$refs.EquipmentAttribute.equipAttrUpdate();
			}
		},
		handleEquipAttrUpdateAble(equipAttrUpdateAble) {
			this.equipAttrUpdateAble = equipAttrUpdateAble;
		},
	},
};
</script>

<style scoped lang="scss">
.device-detail-container {
	height: 100%;
	.device-detail {
		height: 100%;

		.device-frame-list-table {
			box-flex: 1;
			-webkit-box-flex: 1;
			-moz-box-flex: 1;
			flex: 1;
			-webkit-flex: 1;
			overflow: auto;
			.btns {
				margin: 0 15px 15px;
			}
		}
	}

	.panel-list {
		margin: 0 20px;
		position: relative;
		background: #fff;
		.btn-flash {
			position: absolute;
			right: 22px;
			top: 7px;
			z-index: 2;
		}
		&.item-panel {
			position: relative;
			margin: 20px;
			box-sizing: border-box;

			.tab-content {
				position: relative;
			}

			&:first-child {
				.row-title {
					border-top: none;
				}
			}
			.row-title {
				height: 50px;
				padding: 0 30px;
				overflow: hidden;
				border-top: 1px solid rgb(240, 240, 246);

				.title {
					font-size: 14px;
					line-height: 50px;
					color: #03cc75;
					font-weight: bold;
				}
			}

			.row-content {
				overflow: hidden;
				background: #fbfbfc;
				.row-item {
					width: 50%;
					float: left;
					height: 40px;
					padding-left: 30px;
					line-height: 40px;
					border-top: 1px solid rgb(240, 240, 246);

					&:nth-child(2n) {
						border-left: 1px solid rgb(240, 240, 246);
					}

					.title {
						font-weight: bold;
						font-size: 12px;
						color: #a9a9b1;
						float: left;
						width: 155px;
					}

					.content {
						font-size: 12px;
						color: #4d4d4d;
						font-weight: bold;
						float: left;
					}
					.action {
						float: right;
						width: 50px;
						height: 50px;
					}
				}
			}
		}
		.btns {
			padding: 0 30px;
		}
		.device-info-panel {
			padding: 30px;
			.title-row {
				border-bottom: 2px solid #ecf0f5;
				margin-bottom: 20px;
				.title-text {
					font-size: 14px;
					color: #34383e;
					font-weight: 500;
					padding-bottom: 10px;
					border-bottom: 2px solid #08b76b;
					display: inline-block;
				}
			}
			.info-panel-warp {
				border: 1px solid #dce2e8;
				border-bottom: none;
				.info-row {
					border-bottom: 1px solid #dce2e8;
					font-size: 0;
					.info-item {
						width: 50%;
						display: inline-block;
						border-right: 1px solid #dce2e8;
						padding: 0 20px;
						vertical-align: middle;
						& + .info-item {
							border-right: none;
						}
						.info-item-title {
							display: inline-block;
							width: 100px;
							font-size: 12px;
							color: #8e949c;
							height: 44px;
							line-height: 44px;
							vertical-align: middle;
						}
						.info-item-value {
							display: inline-block;
							font-size: 12px;
							color: #50555d;
							height: 44px;
							line-height: 44px;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
	.btn-row {
		padding: 15px 0;
	}
	.pb-40 {
		padding-bottom: 40px;
	}

	.bg-white {
		background: white;
	}

	.plug-list-table {
		margin-bottom: 20px;
	}
	.ml-20 {
		margin-left: 20px;
	}
	.button-wrapper {
		position: absolute;
		right: 40px;
		top: 8px;
		z-index: 10;
	}

	i.icon-distribute {
		display: inline-block;
		background: url('../../assets/img/icon/icon-distribute-1.png') no-repeat center;
		background-size: 12px 12px;
		width: 12px;
		height: 12px;
		vertical-align: middle;
	}

	.mg-15 {
		margin: 15px;
	}
	.btn-a {
		color: #0000ff;
		cursor: pointer;
	}

	.equipment-attribute-panel {
		padding-top: 10px;
		.equipment-updata-btn {
			position: absolute;
			right: 30px;
		}
	}
}
</style>
