<template>
	<div id="user-list" class="custom-list-container">
		<div class="user-list-container flex-container">
			<el-row class="search-row">
				<el-row class="search-row-base">
					<el-col :span="20">
						<div class="search-item">
							<div class="search-label">{{ $t('search.userName') }}：</div>
							<el-input v-model="searchForm.username" :placeholder="$t('common.pleaseInput')" style="width: 240px"></el-input>
						</div>
						<div class="search-item">
							<div class="search-label">{{ $t('search.description') }}：</div>
							<el-input v-model="searchForm.desc" :placeholder="$t('common.pleaseInput')" style="width: 240px"></el-input>
						</div>
						<div class="search-item">
							<div class="search-label">{{ $t('search.companyName') }}：</div>
							<el-input v-model="searchForm.company" :placeholder="$t('common.pleaseInput')" style="width: 240px"></el-input>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="pull-right">
							<el-button type="primary" @click="getUserChildList" v-if="!searchExtVisible">{{ $t('common.query') }}</el-button>
							<!-- 用户端有清空  没有高级查询功能 -->
							<el-button @click="searchExtVisible = !searchExtVisible" v-if="isAdmin">{{ searchExtVisible ? $t('search.ordinarySearch') : $t('search.advancedSearch') }} </el-button>
							<el-button @click="clearSearchOption" v-else>{{ $t('common.empty') }}</el-button>
						</div>
					</el-col>
				</el-row>
				<!-- 高级查询 -->
				<el-row class="search-row-extra" v-show="searchExtVisible">
					<el-row>
						<div class="search-item">
							<label class="search-label"> {{ $t('search.firstUser') }}： </label>
							<el-select v-model="searchForm.parent_id" filterable default-first-option clearable class="search-value" :placeholder="$t('common.pleaseSelect')">
								<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"> </el-option>
							</el-select>
						</div>
					</el-row>
					<el-row class="search-opera-row">
						<el-button type="primary" @click="getUserChildList">{{ $t('common.query') }}</el-button>
						<el-button @click="clearSearchOption">{{ $t('common.empty') }}</el-button>
					</el-row>
				</el-row>
			</el-row>

			<div class="table-container">
				<el-table :data="userChildList" border v-loading="loading.table" max-height="100%">
					<el-table-column prop="id" :label="$t('table.ID')" width="80"></el-table-column>
					<el-table-column prop="username" :label="$t('table.userName')" width="120"></el-table-column>
					<el-table-column :label="$t('table.strategy')" width="150">
						<template slot-scope="scope">
							{{ scope.row.relay_strategy | strategyType }}
						</template>
					</el-table-column>
					<el-table-column :label="$t('table.pushUrl')">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" :content="'' + scope.row.event_endpoint" placement="top-start">
								<span>{{ scope.row.event_endpoint }}</span>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column prop="parent_user_name" :label="$t('table.AssociateFirstUserName')" width="120" v-if="isAdmin"></el-table-column>
					<el-table-column prop="remark" :label="$t('table.remark')"></el-table-column>
					<el-table-column prop="uid" :label="$t('table.uid')"></el-table-column>
					<el-table-column prop="desc" :label="$t('table.description')"></el-table-column>
					<el-table-column prop="company" :label="$t('table.companyName')"></el-table-column>
					<el-table-column prop="address" :label="$t('table.projectAddress')"></el-table-column>
					<el-table-column prop="secret_key" :label="$t('table.key')">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" :content="'' + scope.row.secret_key" placement="top-start">
								<span>{{ scope.row.secret_key }}</span>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column :label="$t('table.createTime')" width="180">
						<template slot-scope="scope">
							{{ scope.row.create_time | timeFormat }}
						</template>
					</el-table-column>
					<el-table-column :label="$t('table.operate')" fixed="right" width="150">
						<template slot-scope="scope">
							<span-btn type="edit" :tip="$t('table.edit')" @click="handleEdit(scope.$index, scope.row)"></span-btn>
							<!-- <span-btn type="export" :tip="$t('table.export')" @click="handleExport(scope.row)"></span-btn> -->
							<icon-btn type="fenzuguanli" :tip="$t('table.distributeGroup')" @click="handleDistributeGroup(scope.row)"></icon-btn>
							<span-btn type="delete" :tip="$t('table.delete')" @click="handleDelete(scope.$index, scope.row)"></span-btn>
							<el-dropdown trigger="click" size="small">
								<div class="btn-more"></div>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item @click.native.stop="goToGroupList(scope.row)" v-if="isAdmin"> {{ $t('table.groupList') }} </el-dropdown-item>
									<el-dropdown-item @click.native.stop="handleReset(scope.$index, scope.row)"> {{ $t('table.resetPassword') }} </el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="flex-footer">
				<div class="flex-footer-content">
					<el-row>
						<el-col :span="8" class="pt-6">
							<el-button type="success" @click="handleEdit"> {{ $t('user.addSecondaryUser') }} </el-button>
						</el-col>
						<el-col :span="8" class="text-center" v-if="total > 0">
							<el-pagination @current-change="pageChange" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total"> </el-pagination>
						</el-col>
						<el-col :span="8"></el-col>
					</el-row>
				</div>
			</div>
		</div>

		<!--编辑弹窗-->
		<el-dialog :title="editTitle + $t('user.secondaryUser')" custom-class="edit-dialog" :visible.sync="editModalVisible" @close="dialogCloseCallback">
			<el-form :label-position="'left'" ref="userForm" label-width="110px" :model="userForm" class="dialog-form" :rules="rules">
				<el-form-item :label="$t('form.userName') + ':'" prop="username">
					<el-input v-model="userForm.username" :placeholder="$t('common.pleaseInput')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('form.strategy') + ':'" prop="relay_strategy">
					<el-select v-model="userForm.relay_strategy" filterable default-first-option clearable class="form-control" :placeholder="$t('common.pleaseSelect')">
						<el-option v-for="item in strategyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="editTitle == $t('form.add')" :label="$t('form.password') + ':'" prop="password">
					<el-input v-model="userForm.password" :placeholder="$t('common.pleaseInput')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('form.companyName') + ':'" prop="company">
					<el-input v-model="userForm.company" :placeholder="$t('common.pleaseInput')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('form.projectAddress') + ':'" prop="address">
					<el-input v-model="userForm.address" :placeholder="$t('common.pleaseInput')"></el-input>
				</el-form-item>
				<template v-if="isAdmin">
					<el-form-item :label="$t('form.associateFirstUser') + ':'" prop="parent_id">
						<el-select v-model="userForm.parent_id" filterable default-first-option clearable class="form-control" :placeholder="$t('common.pleaseSelect')" :disabled="!!userForm.id">
							<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('form.appProfile') + ':'" prop="app_profile_id">
						<el-select v-model="userForm.app_profile_id" filterable default-first-option clearable class="form-control" :placeholder="$t('common.pleaseSelect')">
							<el-option v-for="item in appProfileListAll" :key="item.id" :label="item.name" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
				</template>
				<el-form-item :label="$t('form.remark') + ':'" prop="remark">
					<el-input v-model="userForm.remark" :placeholder="$t('common.pleaseInput')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('form.pushUrl') + ':'" prop="event_endpoint">
					<el-input v-model="userForm.event_endpoint" :placeholder="$t('common.pleaseInput')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('form.description') + ':'" prop="event_endpoint">
					<el-input v-model="userForm.desc" :placeholder="$t('common.pleaseInput')"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="saveUser('userForm')" v-loading="loading.edit">{{ $t('form.done') }}</el-button>
			</div>
		</el-dialog>

		<!--重置密码弹窗-->
		<el-dialog :title="$t('form.resetPassword')" custom-class="edit-dialog" :visible.sync="resetModalVisible" @close="resetDialogCloseCallback">
			<el-form :label-position="'left'" ref="resetForm" label-width="110px" :model="resetForm" :rules="resetRules">
				<el-form-item :label="$t('form.userName') + ':'" prop="username">
					{{ resetForm.username }}
				</el-form-item>
				<el-form-item :label="$t('form.password') + ':'" prop="password">
					<el-input v-model="resetForm.password" :placeholder="$t('common.pleaseInput')"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="resetUser('resetForm')" v-loading="loading.resetPassword">{{ $t('form.done') }}</el-button>
			</div>
		</el-dialog>

		<!--分配分组弹窗-->
		<el-dialog :title="$t('form.distributeGroup')" custom-class="edit-dialog" :visible.sync="distributeGroupModalVisible" @close="distributeGroupDialogCloseCallback">
			<el-form :label-position="'left'" ref="distributeGroupForm" label-width="110px" :model="distributeGroupForm">
				<el-form-item :label="$t('form.userName') + ':'" prop="username">
					{{ distributeGroupForm.username }}
				</el-form-item>
				<el-form-item :label="$t('form.selectGroup') + ':'" prop="group_ids">
					<el-select v-model="distributeGroupForm.group_ids" remote multiple collapse-tags filterable default-first-option clearable class="form-control" :placeholder="$t('common.pleaseSelect')" :loading="loading.group">
						<el-option v-for="item in distributeGroupList" :key="item.id" :label="item.name" :value="item.id + ''"> </el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="distributeGroup('distributeGroupForm')" v-loading="loading.distributeGroup">{{ $t('form.done') }}</el-button>
			</div>
		</el-dialog>

		<delete-dialog :visible.sync="deleteModalVisible" :propTitleText="$t('message.deleteUserSure')" :contentText="$t('message.deleteUserTip')" @confirm="deleteUser"> </delete-dialog>
	</div>
</template>

<script>
import Core from '@/core';
import spanBtn from '@/components/span-btn';
import iconBtn from '@/components/icon-btn';
import deleteDialog from '@/components/delete-dialog';
import * as Util from '@/core/js/util';
const { deviceOnlineStatusFilter, deviceOnlineTimeThresholdFilter } = Util;

export default {
	components: {
		spanBtn,
		iconBtn,
		deleteDialog,
	},
	data() {
		return {
			isAdmin: Core.Data.getUserType() === Core.Const.USER_TYPE.ADMIN,
			searchForm: {
				username: '',
				desc: '',
				company: '',
				device_online_status: '',
				parent_id: '', // 一级用户id
			},
			loading: {
				table: false,
				edit: false,
				delete: false,
				resetPassword: false,
				distributeGroup: false,
			},
			userChildList: [],

			userForm: {
				id: '',
				username: '',
				relay_strategy: '',
				password: '',
				remark: '',
				event_endpoint: '',
				desc: '',
				app_profile_id: '',
				company: '',
				address: '',
				parent_id: '',
			},
			editTitle: '',
			editModalVisible: false,

			resetModalVisible: false,
			resetForm: {
				id: '',
				username: '',
				password: '',
			},

			// 高级查询
			searchExtVisible: false,

			// 分页配置
			currentPage: 1,
			total: 0,
			pageSize: 20,

			strategyList: Core.Util.getStrategyList(),

			// 删除弹窗
			deleteUserId: '',
			deleteModalVisible: false,

			appProfileListAll: '',
			checkGetListTime: '',

			deviceStatusList: Object.values(Core.Const.DEVICE.ONLINE_STATUS_MAP),

			userList: [], // 一级用户列表

			// 分配分组
			distributeGroupModalVisible: false,
			distributeGroupForm: {
				id: '',
				username: '',
				group_ids: [],
			},
			distributeGroupList: [],
		};
	},
	computed: {
		rules() {
			let rules = {
				username: [{ required: true, message: this.$t('message.userNameNotEmpty') }],
				relay_strategy: [{ required: true, message: this.$t('message.strategyNotEmpty') }],
				password: [{ required: true, message: this.$t('message.passwordNotEmpty') }],
				company: [{ required: true, message: this.$t('message.companyNameNotEmpty') }],
				address: [{ required: true, message: this.$t('message.projectAddressNotEmpty') }],
			};
			if (this.isAdmin) {
				rules = {
					...rules,
					parent_id: [{ required: true, message: this.$t('message.associateFirstUserNotEmpty') }],
				};
			}
			return rules;
		},
		resetRules() {
			return {
				password: [{ required: true, message: this.$t('message.passwordNotEmpty') }],
			}
		},
	},
	mounted() {
		try {
			if (this.$route['query']['device_online_status']) {
				this.searchForm.device_online_status = Number(this.$route['query']['device_online_status']);
			}
		} catch (error) {
			console.log('mounted  error:', error);
		}

		// 管理员端获取用户列表
		if (this.isAdmin) {
			this.getUserList();
		}

		this.getUserChildList();
		this.getAppProfileListAll();
	},
	methods: {
		deviceOnlineStatusFilter,
		deviceOnlineTimeThresholdFilter,
		// 获取一级用户名
		async getUserList() {
			try {
				let res = await Core.Api.User.allList({
					flag_child_user_enable: 1, // 查开启创建二级用户功能的一级用户
				});
				this.userList = res.user_list;
			} catch (err) {
				this.$message.error(err.message);
			}
		},
		async getGroupList(row) {
			try {
				let form = {};
				if (this.isAdmin) {
					form.user_id = row.parent_id || '';
				}
				let res = await Core.Api.Group.allUserGroupList(form);
				this.distributeGroupList = res.group_list || [];
			} catch (err) {
				this.$message.error(err.message);
			}
		},

		async getAppProfileListAll() {
			let page = 1;
			let list = [];
			// 为了防止队列过长 之前的队列与之后的队列冲突，  采取：任务开始时间进行时间赋值 每次请求结束对比时间，时间不一致则return
			let transactionId = new Date().getTime();
			this.checkGetListTime = transactionId;
			while (true) {
				let res = await Core.Api.AppProfile.list('', page++);
				if (transactionId !== this.checkGetListTime) {
					return;
				}
				if (res.list.length === 0) {
					console.log('appProfileListAll', this.appProfileListAll);
					return;
				}
				list = [...list, ...res.list];
				this.appProfileListAll = list;
			}
		},
		async getUserChildList(page) {
			try {
				if (page >= 1) {
					this.currentPage = page;
				}
				this.loading.table = true;
				let res = await Core.Api.User.listChild({
					...this.searchForm,
					page: this.currentPage,
				});
				console.log('getUserChildList  res:', res);
				this.userChildList = res.user_list;
				this.total = res.count;
			} catch (err) {
				this.$message.error(err.message);
			} finally {
				this.loading.table = false;
			}
		},
		clearSearchOption: function () {
			Object.assign(this.searchForm, this.$options.data().searchForm);
			this.getUserChildList();
		},
		saveUser(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (!valid) return false;

				try {
					if (isNaN(this.userForm.relay_strategy)) {
						this.$message.warning(this.$t('message.strategyNumerical'));
						return false;
					}
					console.log('object', this.userForm.event_endpoint);
					if (this.loading.edit) return;
					this.loading.edit = true;

					let res = await Core.Api.User.saveChild(this.userForm);
					this.$message.success(this.$t('message.updateSuccess'));
					this.getUserChildList();
					this.editModalVisible = false;
				} catch (err) {
					this.$message.warning(err.message);
				} finally {
					this.loading.edit = false;
				}
			});
		},
		resetUser(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (!valid) return false;

				try {
					if (this.loading.resetPassword) return;
					this.loading.resetPassword = true;
					let res = await Core.Api.User.resetPassword({
						user_id: this.resetForm.id,
						password: this.resetForm.password,
					});
					this.$message.success(this.$t('message.resetPasswordSuccess'));
					this.getUserChildList();
					this.resetModalVisible = false;
				} catch (err) {
					this.$message.error(err.message);
				} finally {
					this.loading.resetPassword = false;
				}
			});
		},
		dialogCloseCallback() {
			this.$refs.userForm.resetFields();
			Object.assign(this.userForm, this.$options.data().userForm);
		},
		resetDialogCloseCallback() {
			this.$refs.resetForm.resetFields();
			Object.assign(this.resetForm, this.$options.data().resetForm);
		},
		handleDistributeGroup(row) {
			this.distributeGroupModalVisible = true;
			if (row) {
				this.distributeGroupForm.username = row.username || '';
				this.distributeGroupForm.id = row.id || '';
			}
			// 获取父级用户下所有分组
			this.getGroupList(row);
			// 获取当前账号下关联的分组id
			this.getUserGroupIdList(row);
		},
		async getUserGroupIdList(row) {
			try {
				let res = await Core.Api.UserGroup.allIdList({
					child_user_id: row.id,
				});
				// console.log("getUserGroupIdList  res:", res);
				let list = res.id_list || [];
				// 转成字符串数组
				if (list.length > 0) {
					this.distributeGroupForm.group_ids = list.map((item) => item + '');
				}
			} catch (err) {
				this.$message.error(err.message);
			}
		},
		distributeGroupDialogCloseCallback() {
			this.$refs.distributeGroupForm.resetFields();
			Object.assign(this.distributeGroupForm, this.$options.data().distributeGroupForm);
		},
		pageChange(val) {
			this.currentPage = val;
			this.getUserChildList();
		},
		handleEdit(index, row) {
			if (row) {
				console.log(row.event_endpoint);
				let keys = Object.keys(this.userForm) || [];
				for (var i = 0; i < keys.length; i++) {
					let key = keys[i];
					this.userForm[key] = row[key] || '';
				}
				// 需要单独处理的
				this.userForm.relay_strategy = row.relay_strategy == 0 ? '' : row.relay_strategy;
				this.userForm.app_profile_id = row.app_profile_id ? row.app_profile_id : '';
			}

			this.editTitle = row ? this.$t('form.edit') : this.$t('form.add');
			this.editModalVisible = true;
		},
		handleReset(index, row) {
			this.resetForm = {
				id: row.id,
				username: row.username,
				password: '',
			};
			this.resetModalVisible = true;
		},
		handleDelete(index, row) {
			this.deleteUserId = row.id;
			this.deleteModalVisible = true;
		},

		async deleteUser() {
			try {
				if (!this.deleteUserId) {
					throw { message: this.$t('common.invalid') + 'user_id' };
				}
				if (this.loading.delete) return;
				this.loading.delete = true;
				await Core.Api.User.delete(this.deleteUserId);
				this.$message.success(this.$t('message.deleteSuccessfully'));
				this.getUserChildList();
				this.deleteModalVisible = false;
			} catch (err) {
				this.$message.error(err.message);
			} finally {
				this.deleteUserId = '';
				this.loading.delete = false;
			}
		},

		goToGroupList(row) {
			// 默认用户端
			let path = '/client-group-list';
			// 客户端
			if (this.isAdmin) {
				path = '/group-list';
			}
			this.$router.push({
				path,
				query: { username: row.username },
			});
		},

		// 导出
		handleExport(row) {
			try {
				let userId = row.id;
				if (!userId) {
					throw { message: this.$t('common.invalid') + 'user_id' };
				}
				let form = {
					user_id: userId,
				};

				Core.Util.handleExport('device/export-by-user-id', form);
			} catch (err) {
				this.$message.error(err.message);
			}
		},

		// 分配分组
		distributeGroup(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (!valid) return false;

				try {
					if (this.loading.distributeGroup) return;
					this.loading.distributeGroup = true;
					let group_ids = this.distributeGroupForm.group_ids.join(',');
					let res = await Core.Api.UserGroup.save({
						child_user_id: this.distributeGroupForm.id,
						group_ids,
					});
					this.$message.success(this.$t('message.allocationGroupSuccess'));
					this.getUserChildList();
					this.distributeGroupModalVisible = false;
				} catch (err) {
					this.$message.error(err.message);
				} finally {
					this.loading.distributeGroup = false;
				}
			});
		},
	},
};
</script>

<style scoped lang="scss">
#user-list {
	padding: 20px;
	.user-list-container {
		height: 100%;
	}
	.el-dropdown {
		cursor: pointer;
		position: relative;
		top: -2px;
		vertical-align: middle;
		height: 22px;
		margin-left: 12px;
	}
	.btn-more {
		width: 9px;
		height: 22px;
		background: url('../../assets/img/icon/icon-more-btn.png') no-repeat center;
		background-size: 9px 22px;
		&:hover {
			background: url('../../assets/img/icon/icon-more-btn-active.png') no-repeat center;
			background-size: 9px 22px;
		}
	}
}
</style>
