import Const from "../js/const";

export function getUpgradeType(type) {
    const TYPE = Const.OTATaskTypeList
    switch (type) {
        case TYPE.TYPE_DISTANCE: return '远程升级';
            break;
        case TYPE.TYPE_BLUETOOTH: return '蓝牙升级';
            break;
        default: return '-';
    }
}

export function deviceType(type) {
    return Const.SPACE_TYPE[parseInt(type)];
}

export function deviceStatus(device, detail) {
    if (detail === 'en') {
        return device.status === 0 ? "OffLine" : "OnLine"
    } else {
        return device.status === 0 ? "离线" : "在线"
    }
}

export function deviceSIMStatus(device, detail) {
    if (detail === 'en') {
        return device.status === 0 ? "Halt" : "Normal"
    } else {
        return device.status === 0 ? "停机" : "正常"
    }
}

export function parkType(device, detail) {
    if (detail === 'en') {
        return device.park_type === 2 ? "Non Font" :
            device.park_type === 1 ? "'一'Font" : '-'
    } else {
        return device.park_type === 2 ? "非字型" :
            device.park_type === 1 ? "一字型" : '-'
    }
}

export function initJoinMAC(mac) {
    if (!mac) return
    var checkMAC = /[A-F0-9]{2}/g;
    let match = mac.match(checkMAC)
    if (match.length === 6) {
        mac = match.join(':')
        return mac
    } else {
        return
    }
}

export function isOff(timestamp) {
    if (timestamp == 0) {
        return '离线';
    }

    let now = Date.parse(new Date());
    let difference = now - timestamp;

    if (difference > 86400) {
        return '离线';
    } else {
        return '在线';
    }
}

export function parkStateType(type, detail) {
    if (detail === 'en') {
        if (type === undefined || !type) {
            return 'Non car';
        }
        return Const.PARK_STATE_TYPE.EN[parseInt(type)];
    } else {
        if (type === undefined || !type) {
            return '无车';
        }
        return Const.PARK_STATE_TYPE.ZH[parseInt(type)];
    }
}

export function lockStateType(type) {
    if (type === undefined) {
        return '-';
    }
    return Const.LOCK_STATE_TYPE[parseInt(type)];
}

export function strategyType(type, detail) {
    type = parseInt(type);
    if (detail === 'en') {
        return Const.STRATEGY_TYPE.EN[type];
    } else {
        return Const.STRATEGY_TYPE.ZH[type];
    }
}

//  虚拟
export function virtualStrategyType(type, detail) {
    type = parseInt(type);
    if (detail === 'en') {
        return Const.VIRTUAL_STRATEGY_TYPE.EN[type];
    } else {
        return Const.VIRTUAL_STRATEGY_TYPE.ZH[type];
    }
}

export function spaceType(type, lang) {
    if (lang === 'en') {
        return Const.SPACE_TYPE.EN[parseInt(type)];
    } else {
        return Const.SPACE_TYPE.ZH[parseInt(type)];
    }
}

export function frameEventType(type) {
    if (type <= 0 || type > 6) {
        return type
    }
    return Const.FRAME_EVENT_TYPE[parseInt(type)];
}

export function eventNumFilter(eventListStr, split = ";") {
    if (eventListStr) {
        if (eventListStr instanceof Array) {
            return eventListStr.length
        } else {
            let result = eventListStr.split(split);
            return result.length
        }
    } else {
        return 0;
    }
}

export function commandStatus(v) {
    if (!Number.isInteger(v)) {
        return '-'
    }

    v = parseInt(v);
    let typeName = '';
    switch (v) {
        case 0: typeName = '等待中'; break;
        case 100: typeName = '已下发'; break;
        case 200: typeName = '已回应'; break;
        case -100: typeName = '下发失败'; break;
    }

    return typeName;
}
export function commandType(val, space) {
    val = Number(val);
    space = Number(space);
    if (!Number.isInteger(val) || !Number.isInteger(space)) {
        return '-'
    }
    let commandName = '';
    switch (space) {
        case 20015:
            switch (val) {
                case 1:
                    commandName = '查询';
                    break;
                case 2:
                    commandName = '设置';
                    break;
                case 3:
                    commandName = '升级';
                    break;
                default:
                    break;
            }
            break;
        case 20018:
            switch (val) {
                case 1:
                    commandName = '查询';
                    break;
                case 2:
                    commandName = '设置';
                    break;
                case 3:
                    commandName = '升级';
                    break;
                default:
                    break;
            }
            break;
        case 20023:
            switch (val) {
                case 1:
                    commandName = '查询';
                    break;
                case 2:
                    commandName = '设置';
                    break;
                case 3:
                    commandName = '升级';
                    break;
                default:
                    break;
            }
            break;
        case 20027:
            switch (val) {
                case 1:
                    commandName = '查询';
                    break;
                case 2:
                    commandName = '设置';
                    break;
                case 3:
                    commandName = '升级';
                    break;
                default:
                    break;
            }
            break;
        case 20028:
            switch (val) {
                case 1:
                    commandName = '查询';
                    break;
                case 2:
                    commandName = '设置';
                    break;
                case 3:
                    commandName = '升级';
                    break;
                default:
                    break;
            }
            break;
        case 20029:
            switch (val) {
                case 1:
                    commandName = '查询';
                    break;
                case 2:
                    commandName = '设置';
                    break;
                case 3:
                    commandName = '升级';
                    break;
                default:
                    break;
            }
            break;
        default:
            commandName = val;
            break;
    }
    return commandName
        ;
}

export function relayLogResponseStatus(status) {
    switch (status) {
        case 1: {
            return "成功"
        } break;
    }
    return "失败"
}

export function p20003FrameType(frame) {
    let typeName = '-';
    let type = frame['type'];
    let subType = frame['sub_type'];
    switch (type) {
        case 2: typeName = '注册帧'; break;
        case 3: typeName = '反注册帧'; break;
        default:
            typeName = "数据帧-" + type
    }
    switch (subType) {
        case 1: typeName = '数据帧'; break;
        case 2: typeName = '心跳帧'; break;
    }

    return typeName;
}

export function p20004FrameType(frame) {
    let typeName = '-';
    let type = frame['type'];
    switch (type) {
        case 1: typeName = '流量帧'; break;
        case 2: typeName = '注册帧'; break;
        case 3: typeName = '反注册帧'; break;
        default:
            typeName = "数据帧-" + type
    }

    return typeName;
}

export function p20006FrameType(frame, detail) {
    let typeName = '-';
    let type = frame['type'];
    let subType = frame['sub_type'];
    if (detail === 'en') {
        switch (type) {
            case 2: typeName = 'Register Frame'; break;
            default:
                typeName = "Data frame-" + type
        }
        switch (subType) {
            case 1: typeName = 'Data Frame'; break;
            case 2: typeName = 'Heartbeat Frame'; break;
        }
    } else {
        switch (type) {
            case 2: typeName = '寄存器帧'; break;
            default:
                typeName = "数据帧-" + type
        }
        switch (subType) {
            case 1: typeName = '数据帧'; break;
            case 2: typeName = '心跳帧'; break;
        }
    }

    return typeName;
}

export function p20007FrameType(frame) {
    let typeName = '-';
    let type = frame['type'];
    let subType = frame['sub_type'];
    switch (type) {
        case 1: typeName = '数据帧'; break;
        case 2: typeName = '告警帧'; break;
        case 3: typeName = '心跳帧'; break;
        case 4: typeName = '配置帧'; break;
        case 5: typeName = '透传帧'; break;
        default:
            typeName = "数据帧-" + type
    }

    return typeName;
}

export function p20009FrameType(frame) {
    let typeName = '-';
    let type = frame['type'];
    let subType = frame['sub_type'];
    switch (type) {
        case 1: typeName = '数据帧'; break;
        case 2: typeName = '心跳帧'; break;
        default:
            typeName = "数据帧-" + type
    }

    return typeName;
}

export function p20011FrameType(frame) {
    let typeName = '-';
    let type = frame['type'];
    let subType = frame['sub_type'];
    switch (type) {
        case 1: typeName = '数据帧'; break;
        case 2: typeName = '心跳帧'; break;
        default:
            typeName = "数据帧-" + type
    }

    return typeName;
}

export function p20012FrameType(frame) {
    let typeName = '-';
    let type = frame['type'];
    let subType = frame['sub_type'];
    switch (type) {
        case 1: typeName = '数据帧'; break;
        case 2: typeName = '告警帧'; break;
        case 3: typeName = '心跳帧'; break;
        case 4: typeName = '配置帧'; break;
        case 5: typeName = '透传帧'; break;
        default:
            typeName = "数据帧-" + type
    }

    return typeName;
}

export function p20013FrameType(frame) {
    let typeName = '-';
    let type = frame['type'];
    let subType = frame['sub_type'];
    switch (type) {
        case 2: typeName = '寄存器帧'; break;
        default:
            typeName = "数据帧-" + type
    }
    switch (subType) {
        case 1: typeName = '数据帧'; break;
        case 2: typeName = '心跳帧'; break;
    }

    return typeName;
}

export function p20020FrameType(frame) {
    let typeName = '-';
    let type = frame['type'];
    let subType = frame['sub_type'];
    if (frame.hasOwnProperty("_legacy")) {
        switch (type) {
            case 1: typeName = '[兼容]数据帧'; break;
            case 2: typeName = '[兼容]心跳帧'; break;
            default:
                typeName = "[兼容]数据帧-" + type
        }
    } else {
        switch (type) {
            case 1: typeName = '属性帧'; break;
            case 2: typeName = '事件帧'; break;
            case 3: typeName = '心跳帧'; break;
            case 4: typeName = '调试帧'; break;
            default:
                typeName = "数据帧-" + type
        }
    }


    return typeName;
}

export function p20021FrameType(frame) {
    let typeName = '-';
    let type = frame['type'];
    let subType = frame['sub_type'];
    switch (type) {
        case 1: typeName = '数据帧'; break;
        case 2: typeName = '告警帧'; break;
        case 3: typeName = '心跳帧'; break;
        case 4: typeName = '配置帧'; break;
        case 5: typeName = '透传帧'; break;
        default:
            typeName = "数据帧-" + type
    }

    return typeName;
}

export function relayLogExecutionTime(log) {
    if (!Number.isInteger(log.request_time) || !Number.isInteger(log.response_time)) {
        return '-';
    }
    let requestTime = parseInt(log.request_time);
    let responseTime = parseInt(log.response_time);
    let executionTime = responseTime - requestTime;
    if (executionTime < 0) {
        return '-';
    }

    return executionTime + 'ms';
}

export function keyName(key) {
    console.log(key.replace("_", " "));
    return key.replace("_", " ")
    //.replace(key[0], key[0].toLowerCase())
}

// 屏幕颜色
export function ScreenColor(val) {
    let LIST = Const.SCREEN.COLOR
    switch (parseInt(val)) {
        case LIST.RED: return "红色"
        case LIST.GREEN: return "绿色"
        case LIST.YELLOW: return "黄色"
        case LIST.BLUE: return "蓝色"
        case LIST.PURPOSE: return "紫色"
        case LIST.CYAN: return "青色"
        case LIST.WHITE: return "白色"
    }
}
// 字体大小
export function FontFormat(val) {
    switch (parseInt(val)) {
        case 1: return "16点"
        case 2: return "24点"
        case 3: return "32点"
    }
    return ""
}
// 屏幕格式
export function ScreenFormat(val) {
    if (!val && val == '') {
        return '-'
    } else if (val && val != '') {
        let MAP = Const.SCREEN.FORMAT_MAP
        let ARROWLIST = Const.SCREEN.ARROW_LIST
        let list = []
        val.split(",").map(item => {
            if (item === "number") {
                list.push("数字")
                // } else if(item == "↑" || item == "↓" || item == "←" || item == "→") {
            } else if (ARROWLIST.indexOf(item) != -1) {
                list.push("箭头")
            } else { list.push("汉字") }
        })
        let handledItem = list.join(",")
        return handledItem
    }
}

// 屏幕状态
export function ScreenStatus(val) {
    let MAP = Const.SCREEN.STATUS_MAP
    return MAP[val] || '-'
}

// 绑定车位状态
export function BindParkStatus(val) {
    let MAP = Const.BIND_PARK.STATUS_MAP
    switch (val) {
        case MAP.USING: {
            return '使用中'
        }; break;
        case MAP.NOTUSED: {
            return '未使用'
        }; break;
    }
}

// 屏幕序号
export function ScreenSort(val) {
    if (val == -1 || !val) {
        return '-'
    } else {
        return val
    }
}

export function batteryPercent(frame) {
    let batteryLevel = frame['battery_level'];
    if (batteryLevel !== undefined) {
        return batteryLevel + '%';
    }
    let voltage = frame['battery_voltage'];
    if (voltage !== undefined) {
        if (voltage > 3600) {
            voltage = 3600;
        }
        if (voltage < 0) {
            voltage = 0;
        }

        let percent = (voltage * 100) / 3600;
        return percent.toFixed(2) + '%';
    }
}

// conn_signal_ecl 为空时显示  心跳帧 -> 0  其他的 -> -
export function getConnSignalEclShow(frame) {
    let val = '-';

    let type = frame['type'];
    if (!Number.isInteger(type)) {
        return '-'
    }

    type = parseInt(type);
    let frameType = type & 0x0F;

    if (frame['conn_signal_ecl']) {
        val = frame['conn_signal_ecl']
        return
    }

    if (lang === 'en') {
        switch (frameType) {
            case 1: val = '-'; break;
            case 2: val = '0'; break;
            case 3: val = '-'; break;
            case 4: val = '-'; break;
            case 6: val = '-'; break;
            case 8: val = '-'; break;
            case 9: val = '-'; break;
            default:
                val = '-'
        }
    } else {
        switch (frameType) {
            case 1: val = '-'; break;   // 事件帧
            case 2: val = '0'; break;   // 心跳帧
            case 3: val = '-'; break;   // 设置帧
            case 4: val = '-'; break;   // 属性帧
            case 6: val = '-'; break;   // OTA帧
            case 8: val = '-'; break;   // 报警帧
            case 9: val = '-'; break;   // 测试帧
            default:
                val = '-'   // 数据帧
        }
    }
    return val
}

/* ========== nccid ========== */
export function NCCIDFilter(val) {
    if (!val) return '-';

    /**
     * NCCID卡号第5/6位
     * 电信的卡 03 11          
     * 联通的卡 01 06 09
     * 如果不是上述这些 显示 中国移动
     */
    let result = val.substring(4, 6);
    let operatorName = '';
    switch (result) {
        case '03':
        case '11':
            operatorName = '电信';
            break;
        case '01':
        case '06':
        case '09':
            operatorName = '联通';
            break;
        default:
            operatorName = '移动';
            break;
    }
    return `${val}（${operatorName}）`
}
/* ========== nccid ========== */

/* ========== SIM卡 ========== */
// SIM卡配置
export function simConfigFilter(val, to = 'text') {
    const MAP = Const.DEVICE.SIM_CFG_MAP;
    const ITEM = MAP[val + ''] || {};
    return ITEM[to] || '-';
}
// SIM卡当前使用状态
export function simStateFilter(val, to = 'text') {
    const MAP = Const.DEVICE.SIM_STATE_MAP;
    const ITEM = MAP[val + ''] || {};
    return ITEM[to] || '-';
}
/* ========== SIM卡 ========== */

/* ========== 三轴状态 ========== */
// 三轴状态
export function xyzStateFilter(val) {
    let text = '';
    switch (val) {
        case 1:
            text = '正常';
            break;

        case 2:
            text = '异常';
            break;

        case 0:
            text = '-';
            break;
        default:
            text = '未知';
            break;
    }
    return text;
}
/* ========== 三轴状态 ========== */