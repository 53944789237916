<template>
    <div class="device-detail-container">
        <div class="device-detail flex-container">
            <div class="device-info">
                <div class="device-info-content">
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">{{$t('device.ID')}}：</div>
                            <div class="content">{{device.uid}}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">{{$t('device.type')}}：</div>
                            <div class="content">{{device.device_type | deviceType}}</div>
                        </div>
                    </div>
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">{{$t('device.space')}}：</div>
                            <div class="content">{{space}}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">{{$t('device.deviceStatus')}}：</div>
                            <div class="content">
                                {{device['status_text'] }}
                            </div>
                        </div>
                    </div>
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">{{$t('device.createTime')}}：</div>
                            <div class="content">{{device["create_time"] | timeFormat}}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">{{$t('device.lastActiveTime')}}：</div>
                            <div class="content">{{device.last_active_time | timeFormat}}</div>
                        </div>
                    </div>
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">{{$t('device.groupName')}}：</div>
                            <div class="content">{{device.device && device.device.group_name || "-" }}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">{{$t('device.user')}}：</div>
                            <div class="content">{{device.device && device.device.user_name || "-" }}</div>
                        </div>
                    </div>
                </div>
                <div class="clear-20"></div>


            </div>
            <div class="clear-20"></div>

            <el-row class="item-panel panel-list" :span="10">
                <div class="button-wrapper" v-if="activeName === 'second' || activeName === 'third'">
                    <el-button @click="refresh" type="success">
                        {{$t('device.refresh')}}
                    </el-button>
                </div>
                <el-tabs v-model="activeName"  class="tab-content" type="card">
                    <el-tab-pane :label="$t('device.detail')" name="first">
                        <div class="device-info-panel">
                            <div class="title-row">
                                <label class="title-text">{{$t('device.detail')}}</label>
                            </div>
                            <div class="clear-20"></div>
                            <div class="info-panel-warp">
                                <device-prop :propList="basePropList"></device-prop>
                            </div>
                        </div>
                        <div class="device-info-panel">
                            <div class="title-row">
                                <label class="title-text">{{$t('device.pushLog')}}</label>
                            </div>
                            <div class="clear-20"></div>
                            <relay-config :uid="uid" :space="space"></relay-config>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('device.frame')" name="second">
                        <div class="device-frame-list-table">


                            <el-table :data="frameList" v-loading="loading">
                                <el-table-column :label="$t('device.reportedTime')" width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.time | timeFormat}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.eventTime')" width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.frame | p20020FrameType}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.cartStatus')" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.state | parkStateType}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.X')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.x !== undefined ? scope.row.frame.x : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.Y')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.y !== undefined ? scope.row.frame.y : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.Z')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.z !== undefined ? scope.row.frame.z : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.voltage')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.voltage !== undefined ? scope.row.frame.voltage :
                                        '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.temperature')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.temperature !== undefined ?
                                        scope.row.frame.temperature : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.warn')" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.warn !== undefined ? scope.row.frame.warn : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.data')">
                                    <template slot-scope="scope">
                                        <a class="link"
                                           @click="showFrameDialog(scope.row.frame)">{{$t('device.view')}}</a>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="flex-footer">
                            <div class="flex-footer-content">
                                <el-row>
                                    <el-col :span="24" class="text-center" v-if="total > 0">
                                        <el-pagination
                                                @current-change="pageChange"
                                                :current-page="currentPage"
                                                :page-size="pageSize"
                                                layout="total, prev, pager, next, jumper"
                                                :total="total">
                                        </el-pagination>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="$t('device.pushLog')" name="third">
                        <relay-log :uid="uid"></relay-log>
                    </el-tab-pane>

                    <el-tab-pane :label="$t('device.operate')" name="fourth">
                        <div class="mg-15">
                            <el-button type="success" @click="handleRelay">{{$t('device.simulatedDataReport')}}</el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-row>

        </div>

        <el-dialog :title="$t('device.data')" :visible.sync="dialogVisible" custom-class="json-dialog" width="600px">
            <div class="content">
                <div class="pre-warp">
                    <pre>{{frame}}</pre>
                </div>
            </div>
        </el-dialog>

        <!--推送数据弹窗-->
        <el-dialog :title="$t('device.pushData')" :visible.sync="jsonDataDialogVisible" custom-class="json-dialog" width="600px">
            <div class="content">
                <div class="pre-warp">
                    <pre>{{jsonData}}</pre>
                </div>
            </div>
        </el-dialog>

        <!--模拟数据上报弹窗-->
        <el-dialog :title="$t('device.simulatedDataReport')" custom-class="edit-dialog" :visible.sync="relayModalVisible" @close="relayDialogCloseCallback">
            <el-form :label-position="'left'" ref="relayForm" label-width="110px" :model="relayForm">
                <el-form-item :label="$t('device.cartStatus')">
                    <el-radio-group v-model="relayForm.state">
                        <el-radio :label="0">{{$t('device.nonCar')}}</el-radio>
                        <el-radio :label="1">{{$t('device.car')}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="relayDeviceData('relayForm')">{{$t('device.send')}}</el-button>
            </div>
        </el-dialog>

        <!--数据上报结果弹窗-->
        <el-dialog :title="$t('device.pushResult')" with="70%" :visible.sync="responseDialogVisible">
            <div v-for="(item, key) in relayResponse" class="pt-15">
                <div  v-if="key === 'result' ||key === 'endpoint' ">
                    <p style="font-size: 14px" v-if="key === 'result'">{{$t('device.pushResult')}}：</p>
                    <p style="font-size: 14px" v-if="key === 'endpoint'">{{$t('device.pushAddress')}}：</p>
                </div>
                <div v-else>
                    <p style="font-size: 14px" >
                        {{key}}
                    </p>
                </div>

                <div style="max-height: 400px; overflow: auto">
                    <label>{{item}}</label>
                </div>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="responseDialogVisible = false">{{$t('device.done')}}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script type="text/ecmascript-6">

    import Core from '@/core'
    import deleteDialog from '@/components/delete-dialog'
    import spanBtn from '@/components/span-btn'
    import relayConfig from '@/components/relay-config'
    import relayLog from '@/components/relay-log'
    import deviceProp from '@/components/device-prop'

    export default {
        name: "device-detail-20032",
        components: {deleteDialog, spanBtn, relayConfig, relayLog, deviceProp},
        data() {
            return {
                device: {},
                device20032: {},
                client: {},

                space: '20032',

                Core: Core,
                activeName: 'first',
                id: '',
                uid: '',
                dialogVisible: false,
                frame: '',

                basePropList: [],

                // 分页配置
                total: 0,
                currentPage: 1,
                pageSize: 10,
                frameList: [],
                logList: [],
                loading: false,

                frameType: this.$t('device.all'),


                // 推送记录
                jsonDataDialogVisible: false,
                jsonData: '',

                // 模拟推送
                relayModalVisible: false,
                relayForm: {
                    state: 0
                },
                relayResponse: {},
                responseDialogVisible: false,

                userList: [],
                userGroupList: [],


                // 导出数据 时间
                export_time: "",
                exportDialogVisible: false,
                exportTaskList: [],
                exportListTotal: 0,
                exportListCurrentPage: 1,
                interval: '',

                canOperate: true,

            }
        },
        watch: {
            activeName: function (val) {
                this.getTabInfo(val);
            },

        },
        mounted: function () {
            this.uid = this.$route.query["uid"];
            this.getDeviceDetail(this.uid);
            // this.getClientDetail();
            this.getTabInfo(this.activeName);
        },
        methods: {
            async getDeviceDetail(uid) {
                let res = await Core.Api.Device20032.getDeviceDetail(uid);
                let device = res['device'];
                device['status_text'] = Core.Util.isOff(device.last_active_time);
                device['cart_status'] = Core.Util.parkStateType(device.p1);
                this.id = device.id;
                this.device = device;
                this.prepareBasePropList(device);
            },

            prepareBasePropList(device) {
                let propList = [];

                let pushProp = function (key, name) {
                    if (!device.hasOwnProperty(key)) {
                        return
                    }

                    propList.push({
                        name: name,
                        value: device[key]
                    })
                };

                pushProp('imei', 'IMEI');
                pushProp('imsi', 'IMSI');
                pushProp('iccid', 'ICCID');
                pushProp('nccid', 'NCCID');
                pushProp('address', this.$t('device.address'));
                pushProp('park_no', this.$t('device.parkNo'));
                pushProp('firmware_version', this.$t('device.firmwareVersion'));
                pushProp('hardware_version', this.$t('device.hardwareVersion'));
                pushProp('bluetooth_version', this.$t('device.bluetoothVersion'));
                pushProp('sensitivity', this.$t('device.sensitivity'));
                pushProp('track_ratio', this.$t('device.trackRatio'));
                pushProp('anti_interference_type', this.$t('device.antiInterferenceType'));
                pushProp('heartbeat_interval', this.$t('device.heartbeatInterval'));
                pushProp('nb_conn_station_id', this.$t('device.nbConnStationId'));
                this.basePropList = propList;
            },

            async getClientDetail() {
                let res =  await Core.Api.Frame.getClientDetail(this.space, this.uid);
                this.client = res.device;
                console.log(this.client)
            },


            async getClientFrameList() {
                try {
                    this.loading = true;
                    let res = await Core.Api.Frame.getFrameListForSpace(this.uid, this.space, "", this.currentPage);
                    this.frameList = res.frame_list;
                    this.total = res.count;
                } catch (err) {
                    console.log("getClientFrameList  err:", err)
                } finally {
                    this.loading = false;
                }
                
            },

            // 推送记录
            async getRelayList() {
                let res = await Core.Api.RelayLog.getLogList(this.currentPage, this.uid);
                this.logList = res.log_list;
                this.total = res.count;

            },

            pageChange(val) {
                this.currentPage = val;
                this.getTabInfo(this.activeName, val);
            },



            getTabInfo(type, page = 1) {
                this.currentPage = page;
                switch (type) {
                    case 'first':
                        break;
                    case 'second':
                        this.getClientFrameList();
                        break;
                    case 'third':
                        this.getRelayList();
                        break;
                    default:
                        break;
                }
            },

            refresh() {
                this.currentPage = 1;
                switch (this.activeName) {
                    case "second": {
                        this.frameList = [];
                        this.getClientFrameList(this.currentPage);
                    }
                        break;
                    case "third": {
                        this.logList = [];
                        this.getRelayList(this.currentPage);

                    }
                        break;
                }
            },

            showFrameDialog(frame) {
                this.dialogVisible = true;
                if (frame.data) {
                    frame.data = JSON.parse(frame.data);
                }
                this.frame = frame;
            },

            showDataDialog(data) {
                this.jsonDataDialogVisible = true;
                this.jsonData = data
            },

            handleRelay() {
                this.relayModalVisible = true;
            },

            relayDialogCloseCallback() {
                this.relayForm = {
                    state: 0
                };
            },

            relayDeviceData(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (!valid)
                    {
                        return false;
                    }

                    let data = {
                        space: this.space,
                        device_id: this.uid,
                        state: this.relayForm.state
                    };

                    try {
                        let res = await Core.Api.Fegoit.relay(data);

                        this.$message.success(this.$t('device.sendSuccess'));
                        this.getClientFrameList(this.dataActiveName, 1);
                        this.relayModalVisible = false;
                        this.relayResponse = "";
                        if (res.result_list && res.result_list.length > 0) {
                            this.relayResponse = res.result_list[0];

                            this.$nextTick(() => {
                                this.responseDialogVisible = true;
                            })
                        }
                    } catch (err) {
                        this.$message.error(err.message);
                    }
                });
            },

        }
    }
</script>

<style scoped lang="scss">
    .device-detail-container {
        height: 100%;
        .device-detail {
            height: 100%;
            .device-info {
                background: #ffffff;
                padding: 20px;
                .device-info-content {
                    border: 1px solid #EAEDF0;
                    background: #F5F7F9;
                    padding: 6px 20px;
                    border-radius: 4px;
                    .row-content {
                        font-size: 0;
                        margin: 14px 0;
                        .row-item {
                            display: inline-block;
                            width: 50%;
                            .title {
                                font-size: 12px;
                                color: #8E949C;
                                min-width: 120px;
                                display: inline-block;
                            }
                            .content {
                                font-size: 12px;
                                color: #50555D;
                                display: inline-block;
                            }
                        }
                    }
                }
            }

            .device-frame-list-table {
                box-flex: 1;
                -webkit-box-flex: 1;
                -moz-box-flex: 1;
                flex: 1;
                -webkit-flex: 1;
                overflow: auto;
                .btns {
                    margin: 0 15px 15px;
                }
            }
        }

        .panel-list {
            margin: 0 20px;
            position: relative;
            background: #fff;
            .btn-flash {
                position: absolute;
                right: 22px;
                top: 7px;
                z-index: 2;
            }
            .item-panel {
                position: relative;

                .tab-content {
                    padding: 0 20px;
                    position: relative;
                }

                &:first-child {
                    .row-title {
                        border-top: none;
                    }
                }
                .row-title {
                    height: 50px;
                    padding: 0 30px;
                    overflow: hidden;
                    border-top: 1px solid rgb(240, 240, 246);

                    .title {
                        font-size: 14px;
                        line-height: 50px;
                        color: #03CC75;
                        font-weight: bold;
                    }
                }

                .row-content {
                    overflow: hidden;
                    background: #FBFBFC;
                    .row-item {
                        width: 50%;
                        float: left;
                        height: 40px;
                        padding-left: 30px;
                        line-height: 40px;
                        border-top: 1px solid rgb(240, 240, 246);

                        &:nth-child(2n) {
                            border-left: 1px solid rgb(240, 240, 246);
                        }

                        .title {
                            font-weight: bold;
                            font-size: 12px;
                            color: #A9A9B1;
                            float: left;
                            width: 155px;
                        }

                        .content {
                            font-size: 12px;
                            color: #4D4D4D;
                            font-weight: bold;
                            float: left;
                        }
                        .action {
                            float: right;
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }
            .device-info-panel {
                padding: 30px;
                .title-row {
                    border-bottom: 2px solid #ECF0F5;
                    .title-text {
                        font-size: 14px;
                        color: #34383E;
                        font-weight: 500;
                        padding-bottom: 10px;
                        border-bottom: 2px solid #08B76B;
                        display: inline-block;
                    }
                }
                .info-panel-warp {
                    border: 1px solid #DCE2E8;
                    border-bottom: none;
                    .info-row {
                        border-bottom: 1px solid #DCE2E8;
                        font-size: 0;
                        .info-item {
                            width: 50%;
                            display: inline-block;
                            border-right: 1px solid #DCE2E8;
                            padding: 0 20px;
                            vertical-align: middle;
                            & + .info-item {
                                border-right: none;
                            }
                            .info-item-title {
                                display: inline-block;
                                width: 100px;
                                font-size: 12px;
                                color: #8E949C;
                                height: 44px;
                                line-height: 44px;
                                vertical-align: middle;

                            }
                            .info-item-value {
                                display: inline-block;
                                font-size: 12px;
                                color: #50555D;
                                height: 44px;
                                line-height: 44px;
                                vertical-align: middle;
                            }
                        }
                    }
                }

            }
        }
        .btn-row {
            padding: 15px 0;
        }
        .pb-40 {
            padding-bottom: 40px;
        }

        .bg-white {
            background: white;
        }

        .plug-list-table {
            margin-bottom: 20px;
        }
        .ml-20 {
            margin-left: 20px;
        }
        .button-wrapper {
            position: absolute;
            right: 40px;
            top: 8px;
            z-index: 10;
        }

        i.icon-distribute {
            display: inline-block;
            background: url("../../../assets/img/icon/icon-distribute-1.png") no-repeat center;
            background-size: 12px 12px;
            width: 12px;
            height: 12px;
            vertical-align: middle;
        }

        .mg-15 {
            margin: 15px;
        }

    }
</style>

