import Config from './config';

const Const = {
	ERROR: {
		ALREADY_EXISTS: 6,
		INVALID_USER_NAME: 5,
		ERROR_PASSWORD: 3,
		ERROR_NETWORK: -10000,
		ERROR_INVALID: -1,
		ERROR_PARAM_NOT_SET: 1,
		ERROR_TOKEN_INVALID: 2,
		ERROR_LOGIN_FAIL: 3,
		ERROR_WRONG_PARAM: 4,
		ERROR_NOT_EXIST: 5,
		ERROR_EXIST: 6,
		ERROR_ORG_NOT_EXIST: 7,
		ERROR_ORG_MEMBER_NOT_EXISTS: 8,
		ERROR_REGISTER: 9,
		ERROR_USER_NOT_EXISTS: 10,
		ERROR_PHONE_HAS_BEEN_TAKEN: 11,
		ERROR_BIND_USER_BIND_EXISTS: 12,
		ERROR_WRONG_TYPE: 13,
		ERROR_SAVE_ERROR: 14,
		ERROR_ACTION_NOT_ALLOWED: 15,
		ERROR_WRONG_VERIFICATION_CODE: 16,
		ERROR_SEND_PHONE_VCODE_TOO_OFTEN: 17
	},
	SYSTEM: {
		LOG_LEVEL_ERROR: 1,
		LOG_LEVEL_WARN: 2,
		LOG_LEVEL_INFO: 3,
		LOG_LEVEL_TRACE: 4,
		LOG_LEVEL_DEBUG: 5
	},
	NET: {
		END_POINT: Config.endpoint,
		END_DEV_POINT: 'https://api-dev.v2.airjoy.fegiot.com',
		TOOL_URL_POINT: 'https://api.ajaiot.com',


		END_TEST_POINT: 'http://api.test.fegiot.com/client/1',
		END_TEST_POINT_ADMIN: 'http://api.test.fegiot.com/admin/1',
		END_FEGOIT_POINT: 'http://api.relay.fegiot.com',

		API_LINK: 'http://api.ajaiot.com/static/api.html',
		API_URL_POINT: 'http://api.ajaiot.com',
		DEVICE_DELETE_PASSWORD: "5117007",

		UPLOAD_DEVICE_URL: 'https://api.ajaiot.com/admin/1/device/import',
		UPLOAD_TEST_DEVICE_URL: 'http://api.test.fegiot.com/admin/1/device/import',


		OTA_FILE_UPLOAD_END_POINT: 'http://118.31.40.16:7909/file/ota-file-upload',
		OTA_FILE_URL_PREFIX: "http://fegiot-ota.oss-cn-hangzhou.aliyuncs.com/",

		// 导出url
		EXPORT_URL: 'http://airjoy.oss-cn-hangzhou.aliyuncs.com/export'
	},

	DATA: {
		KEY_PREFIX: 'airjoy.iot.admin.data.',
		KEY_TOKEN: 'token',
		KEY_USER: 'user',
		KEY_USER_TYPE: 'user-type',
		KEY_LANG: 'airjoy.iot.admin.lang',
		KEY_AUTHORITY: 'airjoy.iot.authority',
	},

	// 地图
	MAP: {
		TENCENT_KEY: 'M4EBZ-UV63M-GP36H-6F4V5-UGHA2-LOBN5',
	},

	ONLINE_STATUS_TYPE: {
		0: '离线',
		1: '在线'
	},

	DEVICE: {
		ONLINE_STATUS: {  // 在线状态
			ON: 1,  // 在线
			OFF: 0,  // 离线
		},
		ONLINE_STATUS_MAP: {
			'0': { value: 0, text: '离线' },
			'1': { value: 1, text: '在线' },
		},
		ACTIVE_TIME_THRESHOLD: 1, // 默认1个小时
		// SIM卡配置
		SIM_CFG_MAP: {
			'1': { value: 1, text: '卡1' },
			'2': { value: 2, text: '卡2' },
			'3': { value: 3, text: '自动' },
		},
		// SIM卡当前使用状态
		SIM_STATE_MAP: {
			'1': { value: 1, text: '卡1' },
			'2': { value: 2, text: '卡2' },
		},
	},

	PARK_STATE_TYPE: {
		EN: {
			0: 'Non car',
			1: 'car',
			2: 'Sensor Exception',
			3: 'OverflowError'
		},
		ZH: {
			0: '无车',
			1: '有车',
			2: '传感器异常',
			3: '数据溢出'
		}
	},

	MAGNET_STATE_TYPE: {
		0: '无车',
		1: '有车',
	},

	LOCK_STATE_TYPE: {
		0: '解锁',
		1: '上锁',
	},

	FRAME_TYPE: {
		1: '状态帧',
		2: '心跳帧'
	},

	STRATEGY_TYPE: {
		EN: {
			0: '-',
			1: 'Streamlining',
			2: 'Heartbeat',
			3: 'Autograph',
			4: 'Reduction (Nine Bit Token)',
			5: 'Reduction (including voltage)',
			6: 'Geomagnetism Signature V2',
			7: 'Geomagnetism Foundation',
			101: 'Geomagnetic Cloud Lake',
			105: 'Geomagnetism Hangzhou Kaaishi',
			501: 'Ground lock Foundation',
			1003: 'Geomagnetism China Mobile',
			100001: 'North League UDP',
			103: 'Geomagnetic Orange',
			107: 'Geomagnetic Orange V1.1',
			108: 'Geomagnetic Orange V2.0',
			109: 'Geomagnetic Hangzhou Road Parking',
		},
		ZH: {
			0: '-',
			1: '[1] 精简',
			2: '[2] 心跳',
			3: '[3] 签名',
			4: '[4] 精简(九比特token)',
			5: '[5] 精简(包含电压)',
			6: '[6] 地磁-签名V2',
			7: '[7] 地磁-基础',
			101: '[101] 地磁-云泊',
			105: '[105] 地磁-杭州卡艾视',
			501: '[501] 地锁-基础',
			1003: '[1003] 地磁-中国移动',
			100001: '[100001] 北联UDP',
			103: '[103] 地磁-优橙',
			106: '[106] 地磁-亿车',
			107: '[107] 地磁-优橙v1.1',
			108: '[108] 地磁-优橙v2.0',
			109: '[109] 地磁-杭州道路停车',
			110: '[110] 地磁-武汉',
			112: '[112] 地磁-富士',
			113: '[113] 地磁-玄极',
			114: '[114] 地磁-溧水',
			115: '[115] 地磁-易辕',
			117: '[117] 地磁-大华',
			118: '[118] 地磁-大华-海外',
			119: '[119] 地磁-南威',
			121: '[121] 地磁-浦江',
			122: '[122] 地磁-铜陵',
			123: '[123] 地磁-眉山',
			124: '[124] 地磁-方新',
			125: '[125] 地磁-大华-2',
			126: '[126] 地磁-物管家',
			127: '[127] 地磁-华码',
			128: '[128] 地磁-移远汇聚',
			130: '[130] 地磁-浦江云星宇',
			131: '[131] 地磁-大华ICC',
			132: '[132] 地磁-建德',
			133: '[133] 地磁-莱斯',
			134: '[134] 地磁-方格尔-2',
			135: '[135] 地磁-亿车-V2',
			136: '[136] 地磁-青岛',
			137: '[137] 地磁-天津智慧社区',
			138: '[138] 地磁-振华',
			139: '[139] 地磁-洛阳',
			140: '[140] 地磁-大华',
			141: '[141] 地磁-大华B8820',
			142: '[142] 地磁-云网',
			143: '[143] 地磁-成都交投',
			144: '[144] 地磁-泊客',
			145: '[145] 地磁-上海软杰',
			146: '[146] 地磁-许昌',
			149: '[149] 地磁-云南丘北',
			150: '[150] 地磁-百度',
			151: '[151] 地磁-凯达尔',
			153: '[153] 地磁-东菱',
			154: '[154] 地磁-大华-3',
			155: '[155] 东莞诚泊',
			157: '[157] 车服云',
			158: '[158] 信电',
		}
	},

	VIRTUAL_STRATEGY_TYPE: {
		EN: {
			600: '[600] 地磁-AEP',
			601: '[601] 地磁-LoRaWAN',
			602: '[602] 地磁-StarWAN',
			603: '[603] 地磁-UDP',
		},
		ZH: {
			600: '[600] 地磁-AEP',
			601: '[601] 地磁-LoRaWAN',
			602: '[602] 地磁-StarWAN',
			603: '[603] 地磁-UDP',
		}
	},

	GROUP: {
		TYPE: {
			// 类型 - 1虚拟 2非虚拟
			VIRTUAL: 1,  // 虚拟
			NON_VIRTUAL: 2,  // 非虚拟
		},
		TYPE_MAP: {
			'1': { id: 1, name: '虚拟', key: 'virtual' },
			'2': { id: 2, name: '非虚拟', key: 'nonVirtual' },
		}
	},

	STRATEGY_TYPE_LIST: [
		{ id: 1, name: '[1] 地磁-精简' },
		{ id: 2, name: '[2] 地磁-心跳' },
		{ id: 3, name: '[3] 地磁-签名' },
		{ id: 4, name: '[4] 地磁-精简(九比特token)' },
		{ id: 5, name: '[5] 地磁-精简(包含电压)' },
		{ id: 6, name: '[6] 地磁-签名V2' },
		{ id: 7, name: '[7] 地磁-基础' },
		{ id: 101, name: '[101] 地磁-云泊' },
		{ id: 105, name: '[105] 地磁-杭州卡艾视' },
		{ id: 501, name: '[501] 地锁-基础' },
		{ id: 1003, name: '[1003] 地磁-中国移动' },
		{ id: 100001, name: '[100001] 北联UDP' },
		{ id: 103, name: '[103] 地磁-优橙' },
		{ id: 106, name: '[106] 地磁-亿车' },
		{ id: 107, name: '[107] 地磁-优橙v1.1' },
		{ id: 108, name: '[108] 地磁-优橙v2.0' },
		{ id: 109, name: '[109] 地磁-杭州道路停车' },
		{ id: 110, name: '[110] 地磁-武汉' },
		{ id: 112, name: '[112] 地磁-富士' },
		{ id: 113, name: '[113] 地磁-玄极' },
		{ id: 114, name: '[114] 地磁-溧水' },
		{ id: 115, name: '[115] 地磁-易辕' },
		{ id: 117, name: '[117] 地磁-大华' },
		{ id: 118, name: '[118] 地磁-大华-海外' },
		{ id: 119, name: '[119] 地磁-南威' },
		{ id: 121, name: '[121] 地磁-浦江' },
		{ id: 122, name: '[122] 地磁-铜陵' },
		{ id: 123, name: '[123] 地磁-眉山' },
		{ id: 124, name: '[124] 地磁-方新' },
		{ id: 125, name: '[125] 地磁-大华-2' },
		{ id: 126, name: '[126] 地磁-物管家' },
		{ id: 127, name: '[127] 地磁-华码' },
		{ id: 128, name: '[128] 地磁-移远汇聚' },
		{ id: 130, name: '[130] 地磁-浦江云星宇' },
		{ id: 131, name: '[131] 地磁-大华ICC' },
		{ id: 132, name: '[132] 地磁-建德' },
		{ id: 133, name: '[133] 地磁-莱斯' },
		{ id: 134, name: '[134] 地磁-方格尔-2' },
		{ id: 135, name: '[135] 地磁-亿车-V2' },
		{ id: 136, name: '[136] 地磁-青岛' },
		{ id: 137, name: '[137] 地磁-天津智慧社区' },
		{ id: 138, name: '[138] 地磁-振华' },
		{ id: 139, name: '[139] 地磁-洛阳' },
		{ id: 140, name: '[140] 地磁-大华' },
		{ id: 141, name: '[141] 地磁-大华B8820' },
		{ id: 142, name: '[142] 地磁-云网' },
		{ id: 143, name: '[143] 地磁-成都交投' },
		{ id: 144, name: '[144] 地磁-泊客' },
		{ id: 145, name: '[145] 地磁-上海软杰' },
		{ id: 146, name: '[146] 地磁-许昌' },
		{ id: 149, name: '[149] 地磁-云南丘北' },
		{ id: 150, name: '[150] 地磁-百度' },
		{ id: 151, name: '[151] 地磁-凯达尔' },
		{ id: 153, name: '[153] 地磁-东菱' },
		{ id: 154, name: '[154] 地磁-大华-3' },
		{ id: 155, name: '[155] 东莞诚泊' },
		{ id: 157, name: '[157] 车服云' },
		{ id: 158, name: '[158] 信电' },
	],

	VIRTUAL_STRATEGY_TYPE_LIST: [
		// 从 600 开始定义 
		{ id: 600, name: '[600] 地磁-AEP' },
		{ id: 601, name: '[601] 地磁-LoRaWAN' },
		{ id: 602, name: '[602] 地磁-StarWAN' },
		{ id: 603, name: '[603] 地磁-UDP' },
	],

	STRATEGY_TYPE_JSON_KEY: [
		{ id: 158, strategy_keys: ["username", "password", "park_lot"] },
		{ id: 157, strategy_keys: ["app_id", "key"] },
		{ id: 153, strategy_keys: ["token"] },
		{ id: 151, strategy_keys: ["area_code"] },
		{ id: 149, strategy_keys: ["key"] },
		{ id: 145, strategy_keys: ["username", "password"] },
		{ id: 142, strategy_keys: ["app_key", "app_secret", "park_id"] },
		{ id: 140, strategy_keys: ["client_id", "client_secret", "park_lot"] },
		{ id: 141, strategy_keys: ["client_id", "client_secret", "park_lot"] },
		{ id: 138, strategy_keys: ["app_id", "app_secret"] },
		{ id: 137, strategy_keys: ["app_id", "app_secret"] },
		{ id: 136, strategy_keys: ["app_id", "secret"] },
		{ id: 135, strategy_keys: ["app_id", "com_id", "request_key"] },
		{ id: 134, strategy_keys: ["company_code"] },
		{ id: 133, strategy_keys: ["secret"] },
		{ id: 132, strategy_keys: ["key"] },
		{ id: 131, strategy_keys: ["client_id", "client_secret", "park_lot"] },
		{ id: 128, strategy_keys: ["username", "password", "park_lot"] },
		{ id: 126, strategy_keys: ["username", "password"] },
		{ id: 123, strategy_keys: ["endpoint_event", "endpoint_heartbeat", "app_id", "public_key", "private_key", "aes_key"] },
		{ id: 121, strategy_keys: ["app_id", "public_key", "private_key", "aes_key"] },
		{ id: 117, strategy_keys: ["client_id", "client_secret", "park_lot"] },
		{ id: 119, strategy_keys: ["app_id", "app_secret", "park_lot", "vendor"] },
		{ id: 110, strategy_keys: ["app_key", "user_key"] },
		{ id: 112, strategy_keys: ["park_lot"] },
		{ id: 104, strategy_keys: ["api_key", "api_secret"] },
		{ id: 106, strategy_keys: ["app_id", "com_id", "request_key"] },
		{ id: 10003, strategy_keys: ["park_lot_id"] },
		{ id: 108, strategy_keys: ["account", "private_key"] },
		{ id: 7, strategy_keys: ["token"] }
	],

	// 虚拟  山西策略
	VIRTUAL_STRATEGY_TYPE_JSON_KEY: [
		// , space: 20023
		{
			id: 600,
			// strategy_keys: ["ncdp_server_host", "ncdp_server_port", "set_aep_method", "aep_app_key", "aep_app_secret", "aep_product_id", "aep_master_key", "aep_endpoint"] 
			strategy_keys: [
				{
					key: "ncdp_server_host",
					prefix: ''
				},
				{
					key: "ncdp_server_port",
					prefix: ''
				},
				{
					key: "set_aep_method",
					prefix: ''
				},
				{
					key: "aep_app_key",
					prefix: ''
				},
				{
					key: "aep_app_secret",
					prefix: ''
				},
				{
					key: "aep_product_id",
					prefix: ''
				},
				{
					key: "aep_master_key",
					prefix: ''
				},
				{
					key: "aep_endpoint",
					prefix: ''
				},
			],
		},
		// { id: 601, strategy_keys: ["lora_ch_mask", "lora_dwell_time"] },
		{
			id: 601,
			strategy_keys: [
				{
					key: "lora_ch_mask",
					prefix: '(16进制)'
				},
				{
					key: "lora_dwell_time",
					prefix: '(10进制)'
				},
				{
					key: "lora_region",
					prefix: '(16进制)'
				},
			]
		},
		{
			id: 602, strategy_keys: []
		},
		{
			id: 603, strategy_keys: [
				{
					key: "udp_server_host",
					prefix: ''
				},
				{
					key: "udp_server_port",
					prefix: ''
				},
			]
		},
	],

	VIRTUAL_STRATEGY_TYPE_MARK: {
		appProfileId: [601, 602]
	},

	VIRTUAL_SPACE_TYPE_MAP: {
		"600": { id: 600, space: 20000 },
		"601": { id: 601, space: 20000 },
		"602": { id: 602, space: 20000 },
		"603": { id: 603, space: 20000 },
	},

	STRATEGY_TYPE_LIST_IN_DEMO: [
		{ id: 7, name: '地磁-基础' }
	],

	// 接入类型
	SPACE: {
		TYPE: {
			// 地磁
			VIRTUAL_GROUPING: '20000',           // 虚拟分组设备
			UDP: '20003',                      // UDP
			FLOW: '20004',                      // 流量
			DTU: '20005',                      // DTU
			COAP: '20006',                      // CoAP地磁
			ONE_NET: '20009',                    // OneNet地磁
			LINK_WAN: '20011',                   // LinkWan地磁
			OCEAN_CONNECT: '20013',              // OceanConnect
			UDP_BKV: '20015',                    // UDP-BKV地磁
			NEW_UDP: '20017',                    // 新UDP地磁
			COAP_BKV: '20018',                   // CoAP-BKV地磁
			LORA_WAN: '20020',                   // LoRa地磁
			AEP: '20023',                        // AEP地磁
			LIERDA_LORA_WAN: '20024',            // Lierda LoraWan地磁
			TING_JIAN_DAN_COAP: '20025',         // 停简单CoAP地磁
			ONE_NET_BKV: '20027',                // OneNet-BKV地磁
			AEP_BKV: '20028',                    // AEP-BKV地磁-多租户
			NEW_COAP_BKV: '20029',               // 新CoAP-BKV地磁 
			TING_JIAN_DAN_IOT_PLATFORM: '20030', // 停简单物联网平台
			NAN_PENG_ONENET: '20031',            // 南鹏OneNet
			LORA_CLAA: '20032',                  // Lora-CLAA
			CHIRPSTACK: '20033',                 // Chirpstack

			// 地锁
			COAP_LOCK: '20007',                 // CoAP地锁
			LINK_WAN_LOCK: '20012',              // LinkWan地锁
			ONE_NET_LOCK: '20014',               // OneNet地锁
			LORA_WAN_LOCK: '20021',              // LoRa地锁
		},
		// 非虚拟设备类型列表
		NON_VIRTUAL_SPACE_TYPE_LIST: [
			{ id: 20003, value: '[20003] 地磁' },
			{ id: 20004, value: '[20004] 流量地磁' },
			{ id: 20005, value: '[20005] DTU' },
			{ id: 20006, value: '[20006] CoAP地磁' },
			{ id: 20007, value: '[20007] CoAP地锁' },
			{ id: 20009, value: '[20009] OneNet地磁' },
			{ id: 20011, value: '[20011] LinkWan地磁' },
			{ id: 20012, value: '[20012] LinkWan地锁' },
			{ id: 20014, value: '[20014] OneNet地锁' },
			{ id: 20015, value: '[20015] UDP-BKV地磁' },
			{ id: 20017, value: '[20017] 新UDP地磁' },
			{ id: 20018, value: '[20018] CoAP-BKV地磁' },
			{ id: 20020, value: '[20020] Lora地磁' },
			{ id: 20021, value: '[20021] Lora地锁' },
			{ id: 20023, value: '[20023] AEP地磁' },
			{ id: 20024, value: '[20024] LoraWan地磁' },
			{ id: 20025, value: '[20025] 停简单CoAP地磁' },
			{ id: 20027, value: '[20027] OneNet-BKV地磁' },
			{ id: 20028, value: '[20028] AEP-BKV地磁-多租户' },
			{ id: 20029, value: '[20029] CoAP-BKV地磁' },
			{ id: 20030, value: '[20030] 停简单物联网平台' },
			{ id: 20031, value: '[20031] 南鹏OneNet' },
			{ id: 20032, value: '[20032] Lora-CLAA' },
			{ id: 20033, value: '[20033] Chirpstack' },
		],
		TYPE_MARK: {
			/* ========== 设备详情 ========== */
			SHOW_IMMEDIATE_INFO: ['20000', '20015', '20023', '20028', '20029', '20030'],  // 显示即时信息
			SHOW_TRIAXIAL_INFO: ['20006'],   // 显示三轴统计信息
			SHOW_EQUIPMENT_ATTRIBUTE: ['20033'],   // 显示设备属性

			SHOW_FRAME_LIST: ['20006', '20009', '20025', '20033'],      // 显示帧信息
			FRAME_FILTER_20006: ['20006', '20009', '20025'], // 20006设备的帧数据过滤处理
			FRAME_FILTER_20020: ['20033'], // 20020设备的帧数据过滤处理

			SHOW_UPLINK_FRAME_LIST: ['20000', '20015', '20023', '20028', '20029', '20030'],    // 显示上行数据
			SHOW_NEW_UPLINK_FRAME_LIST: ['20000', '20015', '20023', '20028', '20029', '20030'],   // 显示上行数据V2
			SHOW_FRAME_HISTORY_LIST: ['20000', '20023'],   // 显示历史数据
			SHOW_COMMAND_LIST: ['20000', '20015', '20023', '20028', '20029', '20030'],   // 显示下行命令
			SHOW_EXPORT_LIST: ['20006', '20015', '20023', '20028', '20029', '20030'],   // 显示数据导出

			SHOW_RELAY_TYPE: ['20000', '20015', '20023', '20028', '20029', '20030', '20033'],  // 模拟数据上报时  显示帧类型
			SHOW_RELAY_VOLTAGE: ['20029'], // 模拟数据上报时  显示电压

			SHOW_ICCID: ['20000', '20006', '20009', '20025', '20029', '20030', '20033'],   // 基本信息显示iccid
			SHOW_PARK_LOT: ['20000', '20006', '20023', '20028', '20029', '20030', '20033'],   // 基本信息显示park_lot
			SHOW_NCCID2: ['20015', '20023', '20028'],   // 基本信息显示nccid_2
			SHOW_SIM_STATUS: ['20015', '20023', '20028'],   // 基本信息显示sim_status
			SHOW_SIM_CONFIG: ['20015', '20023', '20028'],   // 基本信息显示sim_config

			SHOW_ACTION_QUERY_SET: ['20000', '20015', '20023', '20028', '20029', '20030'],   // 设置显示查询和设置 
			SHOW_ACTION_TRIGGER: ['20006', '20025'],   // 设置显示触发多项功能

			NOT_CUSTOM_EXPORT: ['20028', '20030'],   // 数据导出不显示自定义导出

			NOT_UPDATE_PARK_TYPE: ['20007', '20012', '20014', '20021'],  // 不支持修改车位类型的设备  地锁

			SHOW_ANALYSIS_DATA: ['20015', '20023', '20028'],  // 下行命令  显示解析数据
			/* ========== 设备详情 ========== */
		},
		CLIENT_TYPE_MARK: {
			/* ========== 设备详情 ========== */
			SHOW_IMMEDIATE_INFO: ['20000', '20015', '20029', '20023', '20028', '20030'],  // 显示即时信息

			SHOW_FRAME_LIST: ['20006', '20009', '20015', '20023', '20025', '20028', '20029', '20030', '20033'],      // 显示帧信息
			FRAME_FILTER_20006: ['20006', '20009', '20025'], // 20006设备的帧数据过滤处理
			FRAME_FILTER_20015: ['20015', '20023', '20028', '20029', '20030'], // 20015设备的帧数据过滤处理
			FRAME_FILTER_20020: ['20033'], // 20020设备的帧数据过滤处理

			SHOW_UPLINK_FRAME_LIST: ['20000'],    // 显示上行数据
			SHOW_NEW_UPLINK_FRAME_LIST: ['20000', '20015', '20023', '20028', '20030'],   // 显示上行数据V2
			SHOW_FRAME_HISTORY_LIST: ['20000'],   // 显示历史数据
			SHOW_COMMAND_LIST: [],   // 显示下行命令
			SHOW_ALARM_LIST: ['20000'],   // 显示告警列表

			SHOW_RELAY_TYPE: ['20000', '20023', '20029'],  // 模拟数据上报时  显示帧类型

			SHOW_ICCID: ['20000', '20006', '20009', '20025', '20029', '20030', '20033'],   // 基本信息显示iccid
			SHOW_PARK_LOT: ['20000'],   // 基本信息显示park_lot
			SHOW_NCCID2: ['20015', '20023', '20028'],   // 基本信息显示nccid_2
			SHOW_SIM_STATUS: ['20015', '20023', '20028'],   // 基本信息显示sim_status
			SHOW_SIM_CONFIG: ['20015', '20023', '20028'],   // 基本信息显示sim_config

			SHOW_ACTION_TRIGGER_CALIBRATE: ['20006', '20015', '20023', '20025', '20028', '20029'],   // 设置显示  只有触发校准
			SHOW_ACTION_TRIGGER: ['20030'],   // 设置显示触发多项功能
			SHOW_ACTION_QUERY_SET: ['20000'],   // 设置显示查询和设置 
			/* ========== 设备详情 ========== */
		},
	},

	SPACE_TYPE: {
		EN: {
			20003: '[20003] UDP',
			20004: '[20004] Flow',
			20005: '[20005] DTU',
			20006: '[20006] CoAP Magnet Detector',
			20007: '[20007] CoAP Magnet Lock',
			20009: '[20009] OneNet Magnet Detector',
			20011: '[20011] LinkWan Magnet Detector',
			20012: '[20012] LinkWan Magnet Lock',
			20013: '[20013] OceanConnect',
			20014: '[20014] OneNet',
			20015: '[20015] UDP-BKV',
			20017: '[20017] NewUDP',
			20018: '[20018] CoAP-BKV',
			20020: '[20020] LoraWan',
			20021: '[20021] LoraWan',
			20023: '[20023] AEP Magnet Detector',
			20024: '[20024] LoraWan',
			20025: '[20025] TingJianDan CoAP',
			20027: '[20027] OneNet BKV',
			20028: '[20028] AEP BKV Magnet Detector',
			20029: '[20029] CoAP BKV Magnet Detector',
			20030: '[20030] 停简单物联网平台',
			20031: '[20031] 南鹏OneNet',
			20032: '[20032] Lora-CLAA',
			20033: '[20033] Chirpstack',


			// 虚拟分组设备
			20000: '[20000] 虚拟分组设备'
		},
		ZH: {
			20003: '[20003] UDP',
			20004: '[20004] 流量',
			20005: '[20005] DTU',
			20006: '[20006] CoAP地磁',
			20007: '[20007] CoAP地锁',
			20009: '[20009] OneNet地磁',
			20011: '[20011] LinkWan地磁',
			20012: '[20012] LinkWan地锁',
			20013: '[20013] OceanConnect',
			20014: '[20014] OneNet地锁',
			20015: '[20015] UDP-BKV地磁',
			20017: '[20017] 新UDP地磁',
			20018: '[20018] CoAP-BKV地磁',
			20020: '[20020] LoRa地磁',
			20021: '[20021] LoRa地锁',
			20023: '[20023] AEP地磁',
			20024: '[20024] LoraWan地磁',
			20025: '[20025] 停简单CoAP地磁',
			20027: '[20027] OneNet-BKV地磁',
			20028: '[20028] AEP-BKV地磁-多租户',
			20029: '[20029] CoAP-BKV地磁',
			20030: '[20030] 停简单物联网平台',
			20031: '[20031] 南鹏OneNet',
			20032: '[20032] Lora-CLAA',
			20033: '[20033] Chirpstack',


			// 虚拟分组设备
			20000: '[20000] 虚拟分组设备'
		},
	},

	// 所有设备类型列表
	SPACE_TYPE_LIST: [
		{ id: 20000, value: '[20000] 虚拟分组设备' },

		{ id: 20003, value: '[20003] 地磁' },
		{ id: 20004, value: '[20004] 流量地磁' },
		{ id: 20005, value: '[20005] DTU' },
		{ id: 20006, value: '[20006] CoAP地磁' },
		{ id: 20007, value: '[20007] CoAP地锁' },
		{ id: 20009, value: '[20009] OneNet地磁' },
		{ id: 20011, value: '[20011] LinkWan地磁' },
		{ id: 20012, value: '[20012] LinkWan地锁' },
		{ id: 20014, value: '[20014] OneNet地锁' },
		{ id: 20015, value: '[20015] UDP-BKV地磁' },
		{ id: 20017, value: '[20017] 新UDP地磁' },
		{ id: 20018, value: '[20018] CoAP-BKV地磁' },
		{ id: 20020, value: '[20020] Lora地磁' },
		{ id: 20021, value: '[20021] Lora地锁' },
		{ id: 20023, value: '[20023] AEP地磁' },
		{ id: 20024, value: '[20024] LoraWan地磁' },
		{ id: 20025, value: '[20025] 停简单CoAP地磁' },
		{ id: 20027, value: '[20027] OneNet-BKV地磁' },
		{ id: 20028, value: '[20028] AEP-BKV地磁-多租户' },
		{ id: 20029, value: '[20029] CoAP-BKV地磁' },
		{ id: 20030, value: '[20030] 停简单物联网平台' },
		{ id: 20031, value: '[20031] 南鹏OneNet' },
		{ id: 20032, value: '[20032] Lora-CLAA' },
		{ id: 20033, value: '[20033] Chirpstack' },
	],

	SPACE_NAME: {
		GEO: 20003,
		DATA_GEO: 20004,
		DTU: 20005,
		COAP: 20006,
		COAP_LOCK: 20007,
	},

	FRAME_EVENT_TYPE: {
		1: '进有车',
		2: '出有车',
		3: '主地磁有车',
		4: '从地磁有车',
		5: '主地磁传感器故障',
		6: '从地磁传感器故障'
	},

	USER_TYPE: {
		USER: 1,
		ADMIN: 2
	},

	EXPORT_TASK_STATUS: {
		"0": "初始化",
		"100": "进行中",
		"200": "成功",
		"-1": "失败",
	},

	DEVICE_SORT_TYPE: {
		1: '创建时间倒序',
		2: '创建时间顺序',
		3: '活动时间倒序',
		4: '活动时间顺序',
		5: '电池电压倒序',
		6: '电池电压顺序',
		7: '信号强度倒序',
		8: '信号强度顺序',
		9: '地址倒序',
		10: '地址顺序',
		11: '车位号倒序',
		12: '车位号顺序',
		13: '车位状态倒序',
		14: '车位状态顺讯',
		15: '设备类型倒序',
		16: '设备类型顺序',
		17: '设备id倒序',
		18: '设备id顺序',
		19: '用户id倒序',
		20: '用户id顺序',
		21: '历史帧数量倒序',
		22: '历史帧数量顺序',
		23: '七日帧数量倒序',
		24: '七日帧数量顺序',
		25: '固件版本倒序',
		26: '固件版本顺序',
		27: '硬件版本倒序',
		28: '硬件版本顺序',
	},

	DEVICE_SORT_PROP_LIST: ['create_time', 'active_time', 'battery_voltage', 'rssi', 'address',
		'park_no', 'p1', "device_type", 'uid', 'user_id',
		'frame_02_count', 'frame_02_count_7d', 'firmware_version', 'hardware_version',
		'snr', 'nb_reboot_count', 'mcu_reset_count', 'heartbeat_interval', 'park_type', 'label_list'],

	DEVICE_TYPE_LIST: {
		20003: "[20003] 地磁",
		20006: "[20006] CoAP地磁",
		20007: "[20007] CoAP地锁",
		20009: "[20009] OneNet地磁",
		20011: "[20011] LinkWan地磁",
		20012: "[20012] LinkWan地锁",
		20014: "[20014] OneNet地锁",
		20018: "[20018] CoAP-BKV地磁",
		20020: "[20020] LoRa地磁",
		20021: "[20021] LoRa地锁",
		20023: "[20023] AEP-BKV地磁",
		20027: "[20027] OneNet-BKV地磁",
		20028: "[20028] AEP-BKV地磁-多租户",
		20029: "[20029] CoAP-BKV地磁-多租户",
		20031: "[20031] OneNet-BKV地磁-南鹏",
		20032: "[20032] Lora-CLAA地磁",
		20033: "[20033] Chirpstack地磁",
	},

	DETECTOR_SPACE_LIST: [
		{ value: 20000, label: '[20000] 虚拟分组设备' },
		{ value: 20003, label: "[20003] UDP" },
		// { value: 20004, label: "[20004] 流量"},
		{ value: 20006, label: "[20006] CoAP" },
		{ value: 20009, label: "[20009] OneNet" },
		{ value: 20011, label: "[20011] LinkWan" },
		{ value: 20015, label: "[20015] UDP-BKV" },
		// { value: 20017, label: "[20017] 新UDP地磁" },
		{ value: 20018, label: "[20018] CoAP-BKV" },
		{ value: 20020, label: "[20020] Lora" },
		{ value: 20023, label: "[20023] AEP" },
		{ value: 20024, label: "[20024] Lierda LoraWan" },
		{ value: 20025, label: "[20025] 停简单CoAP" },
		{ value: 20027, label: "[20027] OneNet-BKV" },
		{ value: 20028, label: '[20028] AEP-BKV地磁-多租户' },
		{ value: 20029, label: '[20029] CoAP-BKV地磁' },
		{ value: 20030, label: '[20030] 停简单物联网平台' },
		{ value: 20031, label: '[20031] 南鹏OneNet' },
		{ value: 20032, label: '[20032] Lora-CLAA' },
		{ value: 20033, label: '[20033] Chirpstack' },

	],

	// 车位类型
	PARK: {
		TYPE: {
			ONE: 1,  // 一字型
			NON: 2,  // 非字型
		},
		TYPE_LIST: [
			{
				name: '一字型',
				id: 1
			},
			{
				name: '非字型',
				id: 2
			},
		],
	},

	CREATE_SPACE_LIST: [
		{
			name: '[20006] CoAP地磁',
			space: '20006'
		},
		{
			name: '[20009] OneNet地磁',
			space: '20009'
		},
		{
			name: '[20015] BKV地磁',
			space: '20015'
		},
		{
			name: '[20017] 新UDP地磁',
			space: '20017'
		},
		{
			name: '[20018] CoAP-BKV地磁',
			space: '20018'
		},
		{
			name: '[20020] LoRa地磁',
			space: '20020'
		},
		{
			name: '[20023] AEP地磁',
			space: '20023'
		},
		{
			name: '[20024] Lierda LoraWan',
			space: '20024'
		},
		{
			name: '[20025] 停简单CoAP地磁',
			space: '20025'
		},
		{
			name: '[20027] OneNet-BKV地磁',
			space: '20027'
		},
		{
			name: '[20028] AEP地磁-多租户',
			space: '20028'
		},
		{
			name: '[20029] CoAP BKV 地磁',
			space: '20029'
		},

		{
			name: '[20030] 停简单物联网平台',
			space: '20030'
		},

		{
			name: '[20031] 南鹏OneNet-BKV',
			space: '20031'
		},

		{
			name: '[20032] Lora-CLAA',
			space: '20032'
		},

		{
			name: '[20033] Chirpstack',
			space: '20033'
		},
	],
	LOCK_SPACE_LIST: [
		{ value: 20007, label: "[20007] CoAP" },
		{ value: 20012, label: "[20012] LinkWan" },
		{ value: 20014, label: "[20014] OneNet" },
		{ value: 20021, label: "[20021] Lora" },
	],

	BKV_KEY_MAP: {
		0x02: '版本',
		0x03: 'IMEI',
		0x04: '时间戳',
		0x05: '流水号',
		0x06: '车位状态',
		0x07: 'x',
		0x08: 'y',
		0x09: 'z',
		0x0A: 'x_base',
		0x0B: 'y_base',
		0x0C: 'z_base',
		0x0D: '重传次数',
		0x0E: '事件序列号',
		0x0F: 'csq',
		0x10: 'NB连接参数 信噪比SNR',
		0x11: '温度（℃）',
		0x12: '电池电压',
		0x30: 'NB连接参数 信号强度RSRP',
		0x31: 'NB连接参数 连接质量RSRQ',
		0x32: 'NB连接参数 信号覆盖等级ECL',
		0x33: 'NB连接参数 设备所在小区ID',
		0x34: 'NB连接参数 小区PCI',
		0x36: 'NB连接参数 频点',
		0x37: 'NB模组重启次数',
		0x38: 'MCU重启次数',
		0x39: '设备状态',
		0x3A: '电池电量',
		0x3B: '设备从启动NB模组到上线的时间(s)',
		0x3C: '设备重启寄存机',
		0x70: '硬件版本',
		0x71: '软件版本',
		0x72: '生产信息',
		0x73: '应用场景',
		0x74: '有车决策时间',
		0x75: '无车决策时间',
		0x76: '灵敏度',
		0x77: '环境跟踪值',
		0x78: '心跳时间',
		0x79: 'NB模组通讯波特率',
		0x7C: '蓝牙MAC',
		0x7D: '开启检测时间',
		0x7E: '关闭检测时间',
		0x7A: 'IMSI',
		0x7B: 'NCCID',
		0x7F: 'NB硬件版本',
		0x80: 'NB软件版本',
		0x81: 'nband',
		0x82: 'APN_net',
		0x83: 'TAU_net',
		0x84: 'Active_time_net',
		0x85: 'APN_set',
		0x86: 'TAU_set',
		0x87: 'Active_time_set',
	},

	OTA_TASK_STATUS_TEXT: {
		'100': "初始化",
		'200': "升级中",
		'300': "暂停",
		'400': '完成',
		'-100': '停止'
	},
	OTA_TASK_MEMBER_STATUS_TEXT: {
		'100': "初始化",
		'150': "已触发升级",
		'200': "升级已确认",
		'300': "升级中",
		'400': '成功',
		'-100': '失败',
		'-201': '获取升级文件信息失败',
		'-202': '本地暂存升级文件失败',
		'-299': '升级确认未知错误',
		'-301': '获取缓存文件失败',
		'-302': '获取分片数据失败',
		'-401': '升级失败',
		'-402': '校验文件错误',
		'-403': '升级结束未知错误',
	},

	OTA_TASK_STATUS: {
		"INIT": 100,
		"UPGRADE": 200,
		"PAUSE": 300,
		"SUCCESS": 400,
		'FAIL': -100,

	},

	BKV_20015_KEY: {
		EN: [
			{ key: 0x20, type: 'float32', permission: 'rw', name: 'radarDivVal', },
			{ key: 0x21, type: 'uint32', permission: 'rw', name: 'radarCalibDelay', },
			{ key: 0x22, type: 'float32', permission: 'rw', name: 'peakGreat2CarOn', },
			{ key: 0x23, type: 'float32', permission: 'rw', name: 'peak2CarOnWater', },
			{ key: 0x24, type: 'float32', permission: 'rw', name: 'peakNCarOnWater', },
			{ key: 0x25, type: 'float32', permission: 'rw', name: 'peakDiffEdge1Water', },
			{ key: 0x26, type: 'float32', permission: 'rw', name: 'peakDiffEdge2Water', },
			{ key: 0x27, type: 'float32', permission: 'rw', name: 'carOnAcreageI', },
			{ key: 0x28, type: 'float32', permission: 'rw', name: 'carOnAcreageQ', },

			{ key: 0x30, type: 'int16', permission: 'r', name: 'signalStrength', },
			{ key: 0x31, type: 'int16', permission: 'r', name: 'linkQuality', },
			{ key: 0x32, type: 'int8', permission: 'r', name: 'signalECL', },
			{ key: 0x33, type: 'int32', permission: 'r', name: 'signalCellID', },
			{ key: 0x34, type: 'int16', permission: 'r', name: 'signalPCI', },
			{ key: 0x36, type: 'uint16', permission: 'r', name: 'signalEar', },
			{ key: 0x37, type: 'uint16', permission: 'r', name: 'NB_restart_times', },
			{ key: 0x38, type: 'uint16', permission: 'r', name: 'MCU_reset_times', },
			{ key: 0x39, type: 'uint8', permission: 'r', name: 'Device_status', },
			{ key: 0x3A, type: 'uint8', permission: 'r', name: 'battery_level', },
			{ key: 0x3B, type: 'uint16', permission: 'r', name: 'Equipment_online_time', },
			{ key: 0x3C, type: 'uint8', permission: 'r', name: 'Restart_register', },

			{ key: 0x50, type: 'uint8', permission: 'w', name: 'Trigger_calibration', },
			{ key: 0x51, type: 'uint8', permission: 'w', name: 'Trigger_heartbeat', },
			{ key: 0x52, type: 'uint8', permission: 'w', name: 'Trigger_MCU_restart', },
			{ key: 0x53, type: 'uint8', permission: 'w', name: 'Trigger_NB_module_restart', },
			{ key: 0x54, type: 'uint8', permission: 'w', name: 'Triggered_sleep', },
			{ key: 0x55, type: 'uint8', permission: 'w', name: 'Clear_auto_calibration_cache', },
			{ key: 0x56, type: 'uint8', permission: 'w', name: 'Factory_recovery_of_auto_calibration_parameters', },
			{ key: 0x57, type: 'uint8', permission: 'w', name: 'Factory_reset', },

			{ key: 0x68, type: 'uint32', permission: 'wr', name: 'mag_extra_radar_thd', },
			{ key: 0x69, type: 'uint16', permission: 'wr', name: 'mag_extra_radar_per_s', },
			{ key: 0x6A, type: 'uint16', permission: 'wr', name: 'mag_extra_radar_cnt_max', },
			{ key: 0x6B, type: 'uint32', permission: 'wr', name: 'mag_extra_radar_mag_time_max', },
			{ key: 0x6C, type: 'float32', permission: 'wr', name: 'radar_stable_rate_max', },
			{ key: 0x6D, type: 'float32', permission: 'wr', name: 'radar_stable_rate_sum', },
			{ key: 0x6E, type: 'uint32', permission: 'wr', name: 'mag_stable_extra', },

			{ key: 0x70, type: 'string', permission: 'r', name: 'MCU_hardware_version', },
			{ key: 0x71, type: 'string', permission: 'r', name: 'MCU_software_version', },
			{ key: 0x72, type: 'string', permission: 'r', name: 'production_information', },

			{ key: 0x73, type: 'uint8', permission: 'rw', name: 'Application_scenario', },
			{ key: 0x74, type: 'uint8', permission: 'rw', name: 'Car_ownership_decision_time', },
			{ key: 0x75, type: 'uint8', permission: 'rw', name: 'No_car_decision_time', },
			{ key: 0x76, type: 'uint8', permission: 'rw', name: 'Decision_sensitivity', },
			{ key: 0x77, type: 'uint8', permission: 'rw', name: 'Environment_trace_value', },
			{ key: 0x78, type: 'uint16', permission: 'rw', name: 'Heartbeat_time', },
			{ key: 0x79, type: 'uint8', permission: 'rw', name: 'Baud_rate', },
			{ key: 0x7C, type: 'hex', permission: 'r', name: 'Bluetooth_MAC', },
			{ key: 0x7D, type: 'uint16', permission: 'rw', name: 'Working_on_time', },
			{ key: 0x7E, type: 'uint16', permission: 'rw', name: 'Work_off_time', },

			{ key: 0x7A, type: 'string', permission: 'r', name: 'IMSI', },
			{ key: 0x7B, type: 'string', permission: 'r', name: 'NCCID', },
			{ key: 0x7F, type: 'string', permission: 'r', name: 'NB_hardware_version', },
			{ key: 0x80, type: 'string', permission: 'r', name: 'NB_software_version', },
			{ key: 0x81, type: 'string', permission: 'r', name: 'nband', },
			{ key: 0x82, type: 'string', permission: 'r', name: 'APN_net', },
			{ key: 0x83, type: 'string', permission: 'r', name: 'TAU_net', },
			{ key: 0x84, type: 'string', permission: 'r', name: 'Active_time_net', },
			{ key: 0x85, type: 'string', permission: 'r', name: 'APN_set', },
			{ key: 0x86, type: 'string', permission: 'r', name: 'TAU_set', },
			{ key: 0x87, type: 'string', permission: 'r', name: 'Active_time_set', },
			{ key: 0x88, type: 'string', permission: 'r', name: 'MCU_local_KEY', },
			{ key: 0x89, type: 'uint8', permission: 'rw', name: 'Device_function', },

			{ key: 0xC6, type: 'uint16', permission: 'w', name: 'light_func_en', },
			{ key: 0xC7, type: 'uint16', permission: 'w', name: 'light_sample_per_ms', },
			{ key: 0xC8, type: 'uint16', permission: 'w', name: 'light_stable_sample_cnt_thd', },
			{ key: 0xC9, type: 'uint16', permission: 'w', name: 'light_stable_thd_h', },
			{ key: 0xCB, type: 'uint16', permission: 'w', name: 'light_stable_thd_m', },
			{ key: 0xCC, type: 'uint16', permission: 'w', name: 'light_stable_thd_l', },
			{ key: 0xCD, type: 'uint16', permission: 'w', name: 'light_car_change_thd_h', },
			{ key: 0xCE, type: 'uint16', permission: 'w', name: 'light_car_change_thd_m', },
			{ key: 0xCF, type: 'uint16', permission: 'w', name: 'light_car_change_thd_l', },

		],
		ZH: [
			{ key: 0x20, type: 'float32', permission: 'rw', name: 'radarDivVal', },
			{ key: 0x21, type: 'uint32', permission: 'rw', name: 'radarCalibDelay', },
			{ key: 0x22, type: 'float32', permission: 'rw', name: 'peakGreat2CarOn', },
			{ key: 0x23, type: 'float32', permission: 'rw', name: 'peak2CarOnWater', },
			{ key: 0x24, type: 'float32', permission: 'rw', name: 'peakNCarOnWater', },
			{ key: 0x25, type: 'float32', permission: 'rw', name: 'peakDiffEdge1Water', },
			{ key: 0x26, type: 'float32', permission: 'rw', name: 'peakDiffEdge2Water', },
			{ key: 0x27, type: 'float32', permission: 'rw', name: 'carOnAcreageI', },
			{ key: 0x28, type: 'float32', permission: 'rw', name: 'carOnAcreageQ', },

			{ key: 0x30, type: 'int16', permission: 'r', name: 'signalStrength', },
			{ key: 0x31, type: 'int16', permission: 'r', name: 'linkQuality', },
			{ key: 0x32, type: 'int8', permission: 'r', name: 'signalECL', },
			{ key: 0x33, type: 'int32', permission: 'r', name: 'signalCellID', },
			{ key: 0x34, type: 'int16', permission: 'r', name: 'signalPCI', },
			{ key: 0x36, type: 'uint16', permission: 'r', name: 'signalEar', },
			{ key: 0x37, type: 'uint16', permission: 'r', name: 'NB重启次数', },
			{ key: 0x38, type: 'uint16', permission: 'r', name: 'MCU复位次数', },
			{ key: 0x39, type: 'uint8', permission: 'r', name: '设备状态', },
			{ key: 0x3A, type: 'uint8', permission: 'r', name: '电池电量', },
			{ key: 0x3B, type: 'uint16', permission: 'r', name: '设备上线时间', },
			{ key: 0x3C, type: 'uint8', permission: 'r', name: '重启寄存器', },

			{ key: 0x40, type: 'int16', permission: 'r', name: '雷达扰动时间', },
			{ key: 0x41, type: 'uint16', permission: 'r', name: '雷达开启地磁阀值', },
			{ key: 0x42, type: 'uint16', permission: 'r', name: '直接有车地磁阀值', },
			{ key: 0x43, type: 'uint16', permission: 'r', name: '直接无车地磁阀值', },
			{ key: 0x44, type: 'uint32', permission: 'r', name: '雷达地磁跟踪稳态时间', },
			{ key: 0x45, type: 'uint8', permission: 'r', name: '雷达地磁跟踪百分比', },
			{ key: 0x46, type: 'uint16', permission: 'r', name: '雷达无车判断最低地磁值', },

			{ key: 0x49, type: 'float32', permission: 'rw', name: '雷达有水阀值（频点1）', },
			{ key: 0x4A, type: 'float32', permission: 'rw', name: '雷达有车阀值（频点2）', },
			{ key: 0x4B, type: 'float32', permission: 'rw', name: '地磁稳态阀值', },
			{ key: 0x4C, type: 'float32', permission: 'rw', name: '地磁边沿阀值', },

			{ key: 0x50, type: 'uint8', permission: 'w', name: '触发校准', },
			{ key: 0x51, type: 'uint8', permission: 'w', name: '校时', },
			{ key: 0x52, type: 'uint8', permission: 'w', name: '触发MCU重启', },
			{ key: 0x53, type: 'uint8', permission: 'w', name: '触发NB模组重启', },
			{ key: 0x54, type: 'uint8', permission: 'w', name: '触发睡眠', },
			{ key: 0x55, type: 'uint8', permission: 'w', name: '清空自动校准缓存', },
			{ key: 0x56, type: 'uint8', permission: 'w', name: '自动校准参数出厂恢复', },
			{ key: 0x57, type: 'uint8', permission: 'w', name: '设备恢复出厂设置', },
			{ key: 0x58, type: 'uint8', permission: 'w', name: '触发属性帧上报', },


			{ key: 0x68, type: 'uint32', permission: 'wr', name: '无车雷达动态监测地磁阈值', },
			{ key: 0x69, type: 'uint16', permission: 'wr', name: '无车雷达动态监测开启周期', },
			{ key: 0x6A, type: 'uint16', permission: 'wr', name: '无车雷达动态监测开启次数', },
			{ key: 0x6B, type: 'uint32', permission: 'wr', name: '无车雷达动态监测地磁窗口时间', },
			{ key: 0x6C, type: 'float32', permission: 'rw', name: '雷达稳态比率最大值', },
			{ key: 0x6D, type: 'float32', permission: 'rw', name: '雷达稳态比率累加和', },
			{ key: 0x6E, type: 'uint32', permission: 'wr', name: '地磁稳态对比阈值', },

			{ key: 0x70, type: 'string', permission: 'r', name: 'MCU硬件版本', },
			{ key: 0x71, type: 'string', permission: 'r', name: 'MCU软件版本', },
			{ key: 0x72, type: 'string', permission: 'r', name: '生产信息', },

			{ key: 0x73, type: 'uint8', permission: 'rw', name: '应用场景', },
			{ key: 0x74, type: 'uint8', permission: 'rw', name: '有车决策时间', },
			{ key: 0x75, type: 'uint8', permission: 'rw', name: '无车决策时间', },
			{ key: 0x76, type: 'uint8', permission: 'rw', name: '决策灵敏度', },
			{ key: 0x77, type: 'uint8', permission: 'rw', name: '环境跟踪值', },
			{ key: 0x78, type: 'uint16', permission: 'rw', name: '心跳时间', },
			{ key: 0x79, type: 'uint8', permission: 'rw', name: '波特率', },
			{ key: 0x7C, type: 'hex', permission: 'r', name: '蓝牙MAC', },
			{ key: 0x7D, type: 'uint16', permission: 'rw', name: '工作开启时间', },
			{ key: 0x7E, type: 'uint16', permission: 'rw', name: '工作关闭时间', },

			{ key: 0x7A, type: 'string', permission: 'r', name: 'IMSI', },
			{ key: 0x7B, type: 'string', permission: 'r', name: 'NCCID', },
			{ key: 0x7F, type: 'string', permission: 'r', name: 'NB硬件版本', },
			{ key: 0x80, type: 'string', permission: 'r', name: 'NB软件版本', },
			{ key: 0x81, type: 'string', permission: 'r', name: 'nband', },
			{ key: 0x82, type: 'string', permission: 'r', name: 'APN_net', },
			{ key: 0x83, type: 'string', permission: 'r', name: 'TAU_net', },
			{ key: 0x84, type: 'string', permission: 'r', name: 'Active_time_net', },
			{ key: 0x85, type: 'string', permission: 'r', name: 'APN_set', },
			{ key: 0x86, type: 'string', permission: 'r', name: 'TAU_set', },
			{ key: 0x87, type: 'string', permission: 'r', name: 'Active_time_set', },
			{ key: 0x88, type: 'string', permission: 'r', name: 'MCU本地KEY', },
			{ key: 0x89, type: 'uint8', permission: 'rw', name: '设备功能', },

			{ key: 0x90, type: 'uint32', permission: 'rw', name: '稳态开启采集延时', },
			{ key: 0x91, type: 'uint32', permission: 'rw', name: '稳态开启采集次数', },
			{ key: 0x92, type: 'uint8', permission: 'rw', name: '稳态开启采集组数', },
			{ key: 0x93, type: 'uint8', permission: 'rw', name: '起效组数', },
			{ key: 0x94, type: 'uint16', permission: 'rw', name: '单轴阈值', },
			{ key: 0x95, type: 'uint16', permission: 'rw', name: '基值起效阈值', },
			{ key: 0x96, type: 'uint8', permission: 'rw', name: '权重系数', },
			{ key: 0x97, type: 'uint8', permission: 'rw', name: '自动校准功能开关', },
			{ key: 0x98, type: 'uint16', permission: 'rw', name: '自动校准拟合阈值', },

			{ key: 0xC6, type: 'uint16', permission: 'w', name: '光感功能使能开关', },
			{ key: 0xC7, type: 'uint16', permission: 'w', name: '光感采集周期', },
			{ key: 0xC8, type: 'uint16', permission: 'w', name: '光感稳态采样个数', },
			{ key: 0xC9, type: 'uint16', permission: 'w', name: '高档位稳态阈值', },
			{ key: 0xCB, type: 'uint16', permission: 'w', name: '中档位稳态阈值', },
			{ key: 0xCC, type: 'uint16', permission: 'w', name: '低档位稳态阈值', },
			{ key: 0xCD, type: 'uint16', permission: 'w', name: '高档位车位切换阈值', },
			{ key: 0xCE, type: 'uint16', permission: 'w', name: '中档位车位切换阈值', },
			{ key: 0xCF, type: 'uint16', permission: 'w', name: '低档位车位切换阈值', },
		],
	},

	/**
	 * @param {Number[]} keyList
	 * @return {Object[]}
	 */
	getBKV20015Keys(keyList, lang) {
		let list = [];
		let BKV_20015_KEY = lang === 'en' ? this.BKV_20015_KEY.EN : this.BKV_20015_KEY.ZH
		for (let i in BKV_20015_KEY) {
			let keyItem = BKV_20015_KEY[i];
			if (keyList.includes(keyItem.key)) {
				list.push(keyItem)
			}
		}
		return list;
	},
	throughWriteCommandList: [
		{
			label: '获取固件版本',
			value: 'GetFwVer\r\n',
		},
		{
			label: '获取boot版本',
			value: 'GetBootVer\r\n',
		},
		{
			label: '获取产品型号',
			value: 'GetModelNum\r\n',
		},
		{
			label: '获取产品类型',
			value: 'GetDeviceType\r\n',
		},
		{
			label: '当前激活状态',
			value: 'GetActive\r\n',
		},
		{
			label: '当前通信方式',
			value: 'GetCommType\r\n',
		},
		{
			label: '触发一次心跳',
			value: 'TrigHeart=1\r\n',
		},
		{
			label: '触发一次事件',
			value: 'TrigEvent=1\r\n',
		},
		{
			label: '获取NCCID',
			value: 'GetNccid\r\n',
		},
		{
			label: '获取IMSI',
			value: 'GetImsi\r\n',
		},
		{
			label: '获取RTC',
			value: 'GetRTC\r\n',
		},
		{
			label: '触发雷达',
			value: 'trigradar=1\r\n',
		},
		{
			label: '获取雷达决策参数',
			value: 'radarDecisionPara\r\n',
		},
		{
			label: '获取雷达跟踪参数',
			value: 'getRadarTraceParameter\r\n',
		},
		{
			label: '获取有车到无车雷达开启最小值',
			value: 'getCarOffRadarMagThd\r\n',
		},
		{
			label: '获取当前地磁自校准索引和组数',
			value: 'GetCalibTrace\r\n',
		},
		{
			label: '获取当前地磁自校准参数',
			value: 'GetCalibTracePara\r\n',
		},
		{
			label: '获取地磁自校准组数',
			value: 'GetGroups\r\n',
		},
		{
			label: '获取地磁自校准数据有效组数',
			value: 'GetEfctGroups\r\n',
		},
		{
			label: '获取地磁自校准生效比例',
			value: 'GetEfctPercent\r\n',
		},
		{
			label: '获取组间数据生效差值阈值',
			value: 'GetEfctVle\r\n',
		},
		{
			label: '获取单轴数据生效差值阈值',
			value: 'GetEfctSglVle\r\n',
		},
		{
			label: '获取地磁自校准组间差值阈值',
			value: 'GetEfctGrpVle\r\n',
		},
		{
			label: '获取当前NB厂家信息',
			value: 'GetNbManufType\r\n',
		},
		{
			label: '获取当前缓存状态',
			value: 'GetDataBufState\r\n',
		},
		{
			label: '获取稳态无车判断配置状态',
			value: 'GetCarOffExtraJudgeEn\r\n',
		},
		{
			label: '获取雷达阀值',
			value: 'GetRadarThd\r\n',
		},
		{
			label: '恢复出厂设置',
			value: 'FactorySet\r\n',
		},
		{
			label: '前一个心跳的时间戳',
			value: 'GetHeartTimePre\r\n',
		},
		{
			label: '地磁值',
			value: 'GetMagData\r\n',
		},
		{
			label: '字符串',
			value: 'GetIP\r\n',
		},
		{
			label: '获取地磁稳态阀值',
			value: 'GetMagStableThd\r\n',
		},
		{
			label: '获取地磁边沿阀值',
			value: 'GetMagEdgeThd\r\n',
		},
		{
			label: '获取雷达有水频点1阀值',
			value: 'GetRadarWaterThdP1\r\n',
		},
		{
			label: '获取雷达有水频点2阀值',
			value: 'GetRadarCarOnThdP2\r\n',
		},
		{
			label: '获取无水的频点大于2以上有车阀值',
			value: 'GetRadarCarOnGreat2\r\n',
		},
		{
			label: '获取有水的频点2有车阀值',
			value: 'GetRadarCarOnWaterP2\r\n',
		},
		{
			label: '获取可能有水的有车阀值',
			value: 'GetRadarCarOnWaterPn\r\n',
		},
		{
			label: '获取有水频点波峰的海拔高度1',
			value: 'GetRadarEdge1\r\n',
		},
		{
			label: '获取有水频点波峰的海拔高度2',
			value: 'GetRadarEdge2\r\n',
		},
		{
			label: '获取面积算法的I通道有车阀值',
			value: 'GetRadarAcrI\r\n',
		},
		{
			label: '获取面积算法的Q通道有车阀值',
			value: 'GetRadarAcrQ\r\n',
		},
		{
			label: '获取所有雷达参数',
			value: 'GetRadarAll\r\n',
		},
		{
			label: '获取地磁稳态和边沿阀值',
			value: 'GetMagThd\r\n',
		},
		{
			label: '获取设备加密指令',
			value: 'GetEncEn\r\n',
		},
		{
			label: '获取雷达阈值比例',
			value: 'GetRadarDiv\r\n',
		},
		{
			label: '获取有车校准延时',
			value: 'GetRadarCalibDelay\r\n',
		},
		{
			label: '获取地磁芯片是否开启',
			value: 'GetMagEn\r\n',
		},
		{
			label: '获取地磁开启和关闭时间',
			value: 'GetMagOnOffTime\r\n',
		},
		{
			label: '获取地磁芯片状态',
			value: 'GetMagState\r\n',
		},
		{
			label: '触发地磁芯片初始化',
			value: 'ReinitMag\r\n',
		},
	],
	StandardWriteCommandList: [
		{
			label: '硬件版本',
			value: '硬件版本',
		},
		{
			label: 'BOOT版本',
			value: 'BOOT版本',
		},
		{
			label: '固件版本',
			value: '固件版本',
		},
		{
			label: 'IMEI号',
			value: 'IMEI号',
		},
		{
			label: '工作状态',
			value: '工作状态',
		},
		{
			label: '状态描述',
			value: '状态描述',
		},
		{
			label: '地磁校准',
			value: '地磁校准',
		},
		{
			label: '决策时间',
			value: '决策时间',
		},
		{
			label: '灵敏度',
			value: '灵敏度',
		},
		{
			label: '心跳时间',
			value: '心跳时间',
		},
		{
			label: '环境跟踪',
			value: '环境跟踪',
		},
		{
			label: '车位状态',
			value: '车位状态',
		},
		{
			label: 'XYZBASE',
			value: 'XYZBASE',
		},
		{
			label: 'XYZ',
			value: 'XYZ',
		},
		{
			label: 'XYZ流量',
			value: 'XYZ流量',
		},
		{
			label: '电池电压',
			value: '电池电压',
		},
		{
			label: '温度',
			value: '温度',
		},
		{
			label: '设备激活',
			value: '设备激活',
		},
		{
			label: '蓝牙硬件版本',
			value: '蓝牙硬件版本',
		},
		{
			label: '蓝牙BOOT版本',
			value: '蓝牙BOOT版本',
		},
		{
			label: '蓝牙固件版本',
			value: '蓝牙固件版本',
		},
		{
			label: '重启主控',
			value: '重启主控',
		},
		{
			label: 'RSSI',
			value: 'RSSI',
		},
		{
			label: '版本信息',
			value: '版本信息',
		},
		{
			label: '错误码',
			value: '错误码',
		},
		{
			label: '发送状态',
			value: '发送状态',
		},
		{
			label: '模块信号状态',
			value: '模块信号状态',
		},
		{
			label: 'APN',
			value: 'APN',
		},
		{
			label: '网络服务地址',
			value: '网络服务地址',
		},
		{
			label: '网络服务端口',
			value: '网络服务端口',
		},
		{
			label: 'UDP服务地址',
			value: 'UDP服务地址',
		},
		{
			label: 'UDP服务端口',
			value: 'UDP服务端口',
		},
	],
	IssueCommandKeyList: [
		{
			label: '设置决策时间',
			value: '设置决策时间',
		},
		{
			label: '设置地磁校准',
			value: '设置地磁校准',
		},
		{
			label: '设置灵敏度',
			value: '设置灵敏度',
		},
		{
			label: '设置心跳时间',
			value: '设置心跳时间',
		},
		{
			label: '设置环境跟踪',
			value: '设置环境跟踪',
		},
		{
			label: '设置网络服务地址',
			value: '设置网络服务地址',
		},
		{
			label: '设置网络服务端口',
			value: '设置网络服务端口',
		},
		{
			label: '设置UDP服务地址',
			value: '设置UDP服务地址',
		},
		{
			label: '设置UDP服务端口',
			value: '设置UDP服务端口',
		},
	],
	OTATaskTypeList: {
		TYPE_DISTANCE: 10,  //远程升级
		TYPE_BLUETOOTH: 20, //蓝牙升级
	},

	ALARM_STATUS_LIST: {
		STATUS_END: 0,		// 结束
		STATUS_ING: 1		// 告警中
	},

	// 告警
	ALARM: {
		// 告警类型
		TYPE_MAP: {
			"1": { value: 1, label: "设备低电压" },
			"2": { value: 2, label: "NB覆盖强度弱" },
			"3": { value: 3, label: "设备离线" },  // 超过13小时无任何数据上报
			"4": { value: 4, label: "接入平台延时" },
			"5": { value: 5, label: "磁传感器基值改变" },  // 基值改变
			"6": { value: 6, label: "金属等强磁干扰预警" },  // 无车拟合过大
			"7": { value: 7, label: "雷达覆盖预警" },  // 雷达次数预警
			"8": { value: 8, label: "异常重启预警" },  // 连续重启预警
			"11": { value: 11, label: "电压下降预警" }, 
		},
	},

	APP_PROFILE_TYPE: {
		standard: 1,
		transparent: 2,
	},
	APP_PROFILE_TYPE_LIST: [
		{
			name: '标准指令',
			value: 1,
		},
		{
			name: '透传指令',
			value: 2,
		}
	],

	// 屏幕
	SCREEN: {
		STATUS_MAP: {
			'0': '未使用',
			'1': '使用中',
			'2': '已连接',
			'3': '未连接',
		},
		STATUS_LIST: [
			{
				text: '未使用',
				id: 0,
			},
			{
				text: '使用中',
				id: 1,
			},
			{
				text: '已连接',
				id: 2,
			},
			{
				text: '未连接',
				id: 3,
			},
		],
		COLOR: {
			RED: 0,
			GREEN: 1,
			YELLOW: 2,
			BLUE: 3,
			PURPOSE: 4,
			CYAN: 5,
			WHITE: 6,
		},
		COLOR_LIST: [
			{
				text: '红色',
				id: 0,
			},
			{
				text: '绿色',
				id: 1,
			},
			{
				text: '黄色',
				id: 2,
			},
			{
				text: '蓝色',
				id: 3,
			},
			{
				text: '紫色',
				id: 4,
			},
			{
				text: '青色',
				id: 5,
			},
			{
				text: '白色',
				id: 6,
			}
		],
		FORMAT: {
			ARROW: 1,   // 箭头
			SINOGRAM: 2, //汉字
			NUMBER: 3, // 数字
		},
		FORMAT_MAP: {
			ARROW: 1,
			WORD: 2,
			NUMBER: 3,
		},
		FORMAT_LIST: [
			{
				text: '箭头',
				id: 1,
			},
			{
				text: '汉字',
				id: 2,
			},
			{
				text: '数字',
				id: 3,
			},
		],
		FONT_LIST: [
			{
				text: '16点',
				id: 1,
			},
			{
				text: '24点',
				id: 2,
			},
			{
				text: '32点',
				id: 3,
			},
		],
		FORMAT_LIST_Multi_Screen_List: [
			{
				text: '汉字',
				id: 2,
			},
			{
				text: '数字',
				id: 3,
			},
		],
		ARROW_LIST: ["↑", "↓", "←", "→"]
	},
	BIND_PARK: {
		STATUS_MAP: {
			USING: 1,
			NOTUSED: 0,
		},
	},

	// 操作记录
	ACTION: {
		TYPE: {
			CHANGE_DEVICE_GROUP: 1,   // 更改设备分组
			CHANGE_GROUP_INFORMATION: 2, // 更改分组信息
			CHANGE_DEVICE_PARKING_NUMBER: 3, // 更改设备车位号
			GEOMAGNETIC_ACTIVATION: 4, // 地磁激活
		},
		TYPE_MAP: {
			1: { text: '更改设备分组', value: 1 },
			2: { text: '更改分组信息', value: 2 },
			3: { text: '更改设备车位号', value: 3 },
			4: { text: '地磁激活', value: 4 },
		},
	}
}
export default Const;
