<template>
    <div class="device-detail-container">
        <div class="device-detail flex-container">
            <div class="device-info">
                <div class="device-info-content">
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">{{$t('device.ID')}}：</div>
                            <div class="content">{{device.uid}}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">{{$t('device.space')}}：</div>
                            <div class="content">{{device.space | spaceType(lang)}}</div>
                        </div>
                        <div class="clear-10"></div>
                        <div class="row-item">
                            <div class="title">{{$t('device.outerDeviceID')}}：</div>
                            <div class="content">{{device.outer_device_id || ''}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear-20"></div>

            <el-row class="item-panel panel-list" :span="10">
                <div class="button-wrapper" v-if="activeName === 'second' || activeName === 'third'">
                    <el-button @click="refresh" type="success">{{$t('device.refresh')}}</el-button>
                </div>
                <el-tabs v-model="activeName" class="tab-content" type="card">
                    <el-tab-pane :label="$t('device.detail')" name="tab-info">
                        <!--<el-row class="btns">-->
                            <!--<el-button type="primary" @click="handleDistribute(device)">-->
                                <!--<i class="icon-distribute"></i>-->
                                <!--{{$t('device.distribution')}}-->
                            <!--</el-button>-->
                            <!--<el-button type="primary" icon="el-icon-edit" @click="handleEditInfo(device)">-->
                                <!--{{$t('device.edit')}}-->
                            <!--</el-button>-->
                            <!--<el-button type="danger" class="pull-right" icon="el-icon-delete"-->
                                       <!--@click="handleDelete(device)">-->
                                <!--{{$t('device.delete')}}-->
                            <!--</el-button>-->
                        <!--</el-row>-->
                        <div class="device-info-panel">
                            <div class="title-row">
                                <label class="title-text">{{$t('device.device')}}</label>
                            </div>
                            <div class="clear-20"></div>
                            <div class="info-panel-warp">
                                <div class="info-row">
                                    <div class="info-item">
                                        <div class="info-item-title">{{$t('device.createTime')}}：</div>
                                        <div class="info-item-value">{{device["create_time"] | timeFormat}}</div>
                                    </div>
                                    <div class="info-item">
                                        <div class="info-item-title">{{$t('device.lastActiveTime')}}：</div>
                                        <div class="info-item-value">{{client.last_active_time | timeFormat}}</div>
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="info-item">
                                        <div class="info-item-title">{{$t('device.groupName')}}：</div>
                                        <div class="info-item-value">{{device.device && device.device.group_name || "-"
                                            }}
                                        </div>
                                    </div>
                                    <div class="info-item">
                                        <div class="info-item-title">{{$t('device.user')}}：</div>
                                        <div class="info-item-value">{{device.device && device.device.user_name || "-"
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="device-info-panel">
                            <div class="title-row">
                                <label class="title-text">{{$t('device.detail')}}</label>
                            </div>
                            <div class="clear-20"></div>

                            <div class="info-panel-warp">
                                <device-prop :propList="basePropList"></device-prop>
                            </div>
                        </div>
                        <div class="device-info-panel">
                            <div class="title-row">
                                <label class="title-text">{{$t('device.instantDetail')}}</label>
                            </div>
                            <div class="clear-20"></div>
                            <div class="info-panel-warp">
                                <div class="info-row">
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.parkState')}}：</label>
                                        <label class="info-item-value">{{client | p20015ParkState}}</label>
                                    </div>
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.waterLevel')}}：</label>
                                        <label class="info-item-value">-</label>
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.X')}}：</label>
                                        <label class="info-item-value">{{client | p20015Attr('x')}}</label>
                                    </div>
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.Y')}}：</label>
                                        <label class="info-item-value">{{client | p20015Attr('y')}}</label>
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.Z')}}：</label>
                                        <label class="info-item-value">{{client | p20015Attr('z')}}</label>
                                    </div>
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.radarMark')}}：</label>
                                        <label class="info-item-value">-</label>
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.radarResult')}}：</label>
                                        <label class="info-item-value">-</label>
                                    </div>
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.voltage')}}：</label>
                                        <label class="info-item-value">{{client | p20015Attr('voltage')}}</label>
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.deviceState')}}：</label>
                                        <label class="info-item-value">{{client | p20015ParkState}}</label>
                                    </div>
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.temperature')}}：</label>
                                        <label class="info-item-value">{{client | p20015Attr('temperature')}}</label>
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.sirenEvent')}}：</label>
                                        <label class="info-item-value">-</label>
                                    </div>
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.rssi')}}：</label>
                                        <label class="info-item-value">{{client|
                                            p20015Attr('conn_signal_strength')}}</label>
                                    </div>
                                </div>
                                <div class="info-row">
                                    <div class="info-item">
                                        <label class="info-item-title">{{$t('device.snr')}}：</label>
                                        <label class="info-item-value">{{client| p20015Attr('snr')}}</label>
                                    </div>
                                    <div class="info-item">
                                        <label class="info-item-title"></label>
                                        <label class="info-item-value"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="device-info-panel">
                            <div class="title-row">
                                <label class="title-text">{{$t('device.pushLog')}}</label>
                            </div>
                            <div class="clear-20"></div>
                            <relay-config :uid="uid" :space="space"></relay-config>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('device.upData')" name="tab-frame-list">
                        <div class="device-frame-list-table">
                            <el-table :data="frameList" max-height="500" v-loading="loading">
                                <el-table-column :label="$t('device.reportedTime')" width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.time | timeFormat}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.type')" width="120">
                                    <template slot="header" slot-scope="scope">
                                        <span class="el-dropdown-link">
                                                {{$t('device.type')}}
                                            </span>
                                    </template>

                                    <template slot-scope="scope">
                                        {{scope.row.frame | p20015FrameType(lang)}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.eventTime')" width="150">
                                    <template slot-scope="scope">
                                        {{scope.row.frame | frame2015EventTimeFormat }}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.detector')" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.frame | p20015ParkState(lang)}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.X')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['x'] | frame2015ClientXYZ }}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.Y')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['y'] | frame2015ClientXYZ }}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.Z')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['z'] | frame2015ClientXYZ }}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.baseX')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['x_base'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.baseY')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['y_base'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.baseZ')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['z_base'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.rssi')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['csq'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.signalQuality')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['snr'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.temperature')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['temperature'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.voltage')" width="80">
                                    <template slot-scope="scope">
                                        {{ scope.row.frame['battery_voltage'] ? (scope.row.frame['battery_voltage'] +
                                        'mv') : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.batteryLevel')" width="80">
                                    <template slot-scope="scope">
                                        {{ scope.row.frame['battery_level'] ? (scope.row.frame['battery_level'] + '%') :
                                        '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.evenSN')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['sn'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.frameId')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['frame_id'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.nbRebootCount')" width="120">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['nb_reboot_count'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.mcuRebootCount')" width="120">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['mcu_reboot_count'] || '-'}}
                                    </template>
                                </el-table-column>

                                <el-table-column :label="$t('device.deviceState')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['device_state'] || ''}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.0x3C')" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['0x3C'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="conn_signal_strength" width="150">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['conn_signal_strength'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="conn_link_quality" width="120">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['conn_link_quality'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="conn_signal_ecl" width="120">
                                    <!-- <template slot-scope="scope">
                                        {{ scope.row.frame['conn_signal_ecl'] || '-' }}
                                    </template> -->
                                    <template slot-scope="scope">
                                        {{ scope.row.frame | getConnSignalEclShow }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="conn_signal_cell_id" width="120">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['conn_signal_cell_id'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="conn_signal_pci" width="120">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['conn_signal_pci'] || '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="conn_signal_ear" width="120">
                                    <template slot-scope="scope">
                                        {{scope.row.frame['frequency_point']}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="debug_str" width="80">
                                    <template slot-scope="scope">
                                        <el-popover
                                                ref="popover1"
                                                placement="top"
                                                trigger="hover"
                                                :content="scope.row.frame['0xC0']">
                                        </el-popover>
                                        <span v-popover:popover1>{{scope.row.frame['0xC0']}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="debug_序列号" width="120">
                                    <template slot-scope="scope">
                                        {{scope.row.frame | p20015RawAttr(0xC2)}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.raw_c1')" width="160">
                                    <template slot-scope="scope">
                                        {{(scope.row.frame && scope.row.frame['raw_c1'] ) | timeFormat}}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('device.originalData')">
                                    <template slot-scope="scope">
                                        <a class="link" @click="showFrameDialog(scope.row.frame)" v-if="scope.row && scope.row.data">
                                            {{ scope.row.data }}</a>
                                    </template>
                                </el-table-column>

                            </el-table>
                        </div>
                        <div class="flex-footer">
                            <div class="flex-footer-content">
                                <el-row>
                                    <el-col :span="24" class="text-center" v-if="total > 0">
                                        <el-pagination
                                                @current-change="pageChange"
                                                :current-page="currentPage"
                                                :page-size="pageSize"
                                                layout="total, prev, pager, next, jumper"
                                                :total="total">
                                        </el-pagination>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="$t('device.pushLog')" name="tab-relay-log-list">
                        <relay-log :uid="uid"></relay-log>
                    </el-tab-pane>

                    <el-tab-pane :label="$t('device.operate')" name="tab-action">
                        <div class="mg-15">
                            <el-button type="primary" @click="showQueryDialog">{{$t('detectorList.query')}}</el-button>
                            <el-button type="primary" @click="showSetDialog">{{$t('device.setting')}}</el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-row>

        </div>

        <el-dialog :title="$t('device.data')" :visible.sync="dialogVisible" custom-class="json-dialog" width="600px">
            <div class="content">
                <div class="pre-warp">
                    <pre>{{frame}}</pre>
                </div>
            </div>
        </el-dialog>

        <!--推送数据弹窗-->
        <el-dialog :title="$t('device.pushData')" :visible.sync="jsonDataDialogVisible" custom-class="json-dialog"
                   width="600px">
            <div class="content">
                <div class="pre-warp">
                    <pre>{{jsonData}}</pre>
                </div>
            </div>
        </el-dialog>

        <!--模拟数据上报弹窗-->
        <el-dialog title="模拟数据上报" custom-class="edit-dialog" :visible.sync="relayModalVisible"
                   @close="relayDialogCloseCallback">
            <el-form :label-position="'left'" ref="relayForm" label-width="110px" :model="relayForm">
                <el-form-item label="车位状态">
                    <el-radio-group v-model="relayForm.state">
                        <el-radio :label="0">无车</el-radio>
                        <el-radio :label="1">有车</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="relayDeviceData('relayForm')">发送</el-button>
            </div>
        </el-dialog>

        <!--数据上报结果弹窗-->
        <el-dialog :title="$t('device.pushResult')" with="70%" :visible.sync="responseDialogVisible"><el-dialog :title="$t('device.pushResult')" with="70%" :visible.sync="responseDialogVisible">
            <div v-for="(item, key) in relayResponse" class="pt-15">
                <div  v-if="key === 'result' ||key === 'endpoint' ">
                    <p style="font-size: 14px" v-if="key === 'result'">{{$t('device.pushResult')}}：</p>
                    <p style="font-size: 14px" v-if="key === 'endpoint'">{{$t('device.pushAddress')}}：</p>
                </div>
                <div v-else>
                    <p style="font-size: 14px" >{{key}}</p>
                </div>
                <div style="max-height: 400px; overflow: auto">
                    <label>{{item}}</label>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="responseDialogVisible = false">{{$t('device.done')}}</el-button>
            </div>
        </el-dialog></el-dialog>

        <!--编辑弹窗-->
        <el-dialog :title="$t('device.update')" custom-class="edit-dialog"
                   :visible.sync="editModalVisible" @close="editDialogCloseCallback">
            <el-form :label-position="'left'" label-width="110px" ref="deviceForm" :model="deviceForm">
                <el-form-item :label="$t('device.ID')" prop="park_no">
                    <span>{{deviceForm.uid}}</span>
                </el-form-item>
                <el-form-item :label="$t('detectorList.parkNo')" prop="park_no">
                    <el-input :placeholder="$t('device.notNull')" v-model="deviceForm.park_no"></el-input>
                </el-form-item>
                <el-form-item :label="$t('detectorList.address')" prop="address">
                    <el-input :placeholder="$t('device.notNull')" v-model="deviceForm.address"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="editDevice()">{{$t('device.done')}}</el-button>
            </div>
        </el-dialog>

        <!--分配弹窗-->
        <!--<el-dialog :title="$t('device.updateToUser')" custom-class="edit-dialog" :visible.sync="distributeModalVisible">-->
            <!--<el-form :label-position="'left'" label-width="110px" ref="userForm" :model="userForm">-->
                <!--<el-form-item :label="$t('device.user')" prop="current_user_name">-->
                    <!--{{userForm.current_user_name}}-->
                <!--</el-form-item>-->
                <!--<el-form-item :label="$t('device.groupName')" prop="current_group_name">-->
                    <!--{{userForm.current_group_name}}-->
                <!--</el-form-item>-->
                <!--<el-form-item :label="$t('device.targetUser')" prop="target_user_id">-->
                    <!--<el-select v-model="userForm.target_user_id" filterable default-first-option clearable-->
                               <!--class="form-control" :placeholder="$t('detectorList.select')">-->
                        <!--<el-option-->
                                <!--v-for="item in userList"-->
                                <!--:key="item.id"-->
                                <!--:label="item.username"-->
                                <!--:value="item.id">-->
                        <!--</el-option>-->
                    <!--</el-select>-->
                <!--</el-form-item>-->
                <!--<el-form-item :label="$t('device.groupName')">-->
                    <!--<el-select v-model="userForm.target_group_id" filterable default-first-option clearable-->
                               <!--class="form-control" :placeholder="$t('detectorList.select')">-->
                        <!--<el-option-->
                                <!--v-for="item in userGroupList"-->
                                <!--:key="item.id"-->
                                <!--:label="item.name"-->
                                <!--:value="item.id">-->
                        <!--</el-option>-->
                    <!--</el-select>-->
                <!--</el-form-item>-->
            <!--</el-form>-->
            <!--<div slot="footer" class="dialog-footer">-->
                <!--<el-button type="success" @click="distributeDevice('userForm')">{{$t('device.done')}}</el-button>-->
            <!--</div>-->
        <!--</el-dialog>-->

        <!--删除弹窗-->
        <el-dialog :title="$t('device.deleteDevice')" custom-class="edit-dialog" :visible.sync="deleteModalVisible">
            <p style="padding: 20px 0;">{{$t('device.reminder')}}</p>
            <el-form :label-position="'left'" label-width="110px" ref="deleteForm" :model="deleteForm">
                <el-form-item :label="$t('device.passWord')" prop="password"
                              :rules="[{ required: true, message: this.$t('$t(device.notNull)')}]">
                    <el-input type="password" :placeholder="$t('device.notNull')" v-model="deleteForm.password"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="deleteDevice('deleteForm')">{{$t('device.done')}}</el-button>
            </div>
        </el-dialog>

        <!--查询弹窗-->
        <el-dialog :title="$t('detectorList.query')" custom-class="edit-dialog" :visible.sync="queryDialogVisible">
            <bkv-selector :keyList="queryKeyList" :bkv="queryBKVHex" ref="query-bkv-selector"></bkv-selector>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="writeQueryCommand()">{{$t('device.done')}}</el-button>
            </div>
        </el-dialog>

        <!--设置弹窗-->
        <el-dialog :title="$t('device.setting')" custom-class="edit-dialog" :visible.sync="setDialogVisible">
            <bkv-editor :keyList="setKeyList" :bkv="setBKVHex" ref="set-bkv-editor"></bkv-editor>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="writeSetCommand()">{{$t('device.send')}}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script type="text/ecmascript-6">

    import Vue from 'vue'
    import Core from '@/core'
    import deleteDialog from '@/components/delete-dialog'
    import spanBtn from '@/components/span-btn'
    import relayConfig from '@/components/relay-config'
    import bkvEditor from '@/components/bkv-editor'
    import bkvSelector from '@/components/bkv-selector'
    import relayLog from '@/components/relay-log'
    import deviceProp from '@/components/device-prop'


    export default {
        name: "device-detail-20024",
        components: {deleteDialog, spanBtn, relayConfig, bkvEditor, bkvSelector, relayLog, deviceProp},
        data() {
            return {
                device: {},
                device20012: {},
                client: {},

                space: '20024',

                Core: Core,
                activeName: 'tab-frame-list',
                // activeName: 'tab-info',
                id: '',
                uid: '',
                dialogVisible: false,
                frame: '',

                basePropList: [],

                // 分页配置
                total: 0,
                currentPage: 1,
                pageSize: 20,
                frameList: [],
                commandList: [],
                logList: [],
                loading: false,

                frameType: this.$t('device.all'),


                // 推送记录
                jsonDataDialogVisible: false,
                jsonData: '',

                // 模拟推送
                relayModalVisible: false,
                relayForm: {
                    state: 0
                },
                relayResponse: {},
                responseDialogVisible: false,

                // 基本信息
                editModalVisible: false,
                deviceForm: {
                    id: '',
                    uid: '',
                    park_no: '',
                    address: '',
                },
                // 删除
                deleteModalVisible: false,
                deleteForm: {
                    id: '',
                    password: '',
                },
                // 分配
                // distributeModalVisible: false,
                userForm: {
                    device_id: '',
                    current_user_name: '',
                    current_group_name: '',
                    target_user_id: '',
                    target_group_id: ''
                },


                // userList: [],
                userGroupList: [],


                // 数据导出
                exportTime: "",
                exportDialogVisible: false,
                exportTaskList: [],
                exportListTotal: 0,
                exportListCurrentPage: 1,
                exportInterval: '',

                // 查询key
                queryKeyForm: {
                    key: ''
                },
                queryKeyList: [],
                queryBKVHex: '',

                queryDialogVisible: false,

                // 设置key
                setDialogVisible: false,
                setKeyList: [],
                setBKVHex: '',

                lang: 'zh'
            }
        },
        watch: {
            activeName: function (val) {
                this.getTabInfo(val);
            },
            'userForm.target_user_id': 'getUserGroupList',

        },

        filters: {
            statusFormat(status) {
                let statusList = {
                    "0": "初始化",
                    "100": "进行中",
                    "200": "成功",
                    "-1": "失败",
                };
                return statusList[status + ''];
            },
        },

        destroyed() {
            clearInterval(this.exportInterval);
        },

        mounted: function () {
            this.uid = this.$route.query["uid"];
            this.initLang()
            this.getDeviceDetail(this.uid);
            this.getClientDetail();
            // this.getUserList();
            this.getTabInfo(this.activeName);
        },
        methods: {
	        initLang() {
		        this.lang = Core.Data.getLang()
	        },
            async getDeviceDetail(uid) {
                let res = await Core.Api.Device20024.getDeviceDetail(uid);
                let device = res['device'];
                device['status_text'] = Core.Util.isOff(device.last_active_time);
                device['cart_status'] = Core.Util.parkStateType(device.p1);
                this.id = device.id;
                this.device = device;
                this.prepareBasePropList(device);
            },

            prepareBasePropList(device) {
                let propList = [];

                let pushProp = function (key, name) {
                    if (!device.hasOwnProperty(key)) {
                        return
                    }

                    propList.push({
                        name: name,
                        value: device[key]
                    })
                };
                pushProp('imei', 'IMEI');
                pushProp('imsi', 'IMSI');
                pushProp('iccid', 'ICCID');
                pushProp('nccid', 'NCCID');
                pushProp('address', this.$t('device.address'));
                pushProp('park_no', this.$t('device.parkNo'));
                pushProp('firmware_version', this.$t('device.firmwareVersion'));
                pushProp('hardware_version', this.$t('device.hardwareVersion'));
                pushProp('bluetooth_version', this.$t('device.bluetoothVersion'));
                pushProp('sensitivity', this.$t('device.sensitivity'));
                pushProp('track_ratio', this.$t('device.trackRatio'));
                pushProp('anti_interference_type', this.$t('device.antiInterferenceType'));
                pushProp('heartbeat_interval', this.$t('device.heartbeatInterval'));
                pushProp('nb_conn_station_id', this.$t('device.nbConnStationId'));
                this.basePropList = propList;
            },
            async getClientDetail() {
                try {
                    // let res = await Core.Api.Fengine20024.getClientDetail(this.uid);
                    // this.client = res.device;
                } catch (e) {
                    console.log(e)
                }
            },
            async getClientFrameList() {
                try {
                    this.loading = true;
                    let res = await Core.Api.Fengine20024.getFrameList(this.uid, this.space, this.currentPage);
                    let frameList = [];
                    for (let i in res.frame_list) {
                        let frame = res.frame_list[i];
                        // frame['content_html'] = Vue.filter('frame20015Format')(frame);
                        frameList.push(frame);
                    }
                    this.frameList = frameList;
                    this.total = res.count;
                } catch (err) {
                    console.log("getClientFrameList  err:", err)
                } finally {
                    this.loading = false;
                }
                
            },

            // 推送记录
            async getRelayList() {
                let res = await Core.Api.RelayLog.getLogList(this.currentPage, this.uid);
                this.logList = res.log_list;
                this.total = res.count;
            },

            pageChange(val) {
                this.currentPage = val;
                this.getTabInfo(this.activeName, val);
            },

            getTabInfo(type, page = 1) {
                this.currentPage = page;
                switch (type) {
                    case 'tab-info':
                        break;
                    case 'tab-frame-list':
                        this.getClientFrameList();
                        break;
                    case 'tab-command-list':
                        break;
                    case 'tab-relay-log-list':
                        this.getRelayList();
                        break;
                    case 'tab-export':
                        this.exportListCurrentPage = 1;
                        this.getExportTaskList();
                        this.exportInterval = setInterval(() => {
                            this.getExportTaskList();
                        }, 2000);
                        break
                    default:
                        break;
                }
                if (type !== 'tab-5') {
                    clearInterval(this.exportInterval);
                }
            },

            refresh() {
                this.currentPage = 1;
                switch (this.activeName) {
                    case "tab-2": {
                        this.frameList = [];
                        this.getClientFrameList(this.currentPage);
                    }
                        break;
                    case "tab-3": {
                        this.logList = [];
                        this.getRelayList(this.currentPage);

                    }
                        break;
                }
            },

            showFrameDialog(frame) {
                this.dialogVisible = true;
                if (frame && frame.data) {
                    frame.data = JSON.parse(frame.data);
                }
                this.frame = frame;
            },

            showDataDialog(data) {
                this.jsonDataDialogVisible = true;
                this.jsonData = data
            },

            handleRelay() {
                this.relayModalVisible = true;
            },

            relayDialogCloseCallback() {
                this.relayForm = {
                    state: 0
                };
            },

            relayDeviceData(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (!valid) {
                        return false;
                    }

                    let data = {
                        space: this.space,
                        device_id: this.uid,
                        state: this.relayForm.state
                    };

                    try {
                        let res = await Core.Api.Fegoit.relay(data);

                        this.$message.success(this.$t('device.sendSuccess'));
                        this.getClientFrameList(this.dataActiveName, 1);
                        this.relayModalVisible = false;
                        this.relayResponse = "";
                        if (res.result_list && res.result_list.length > 0) {
                            this.relayResponse = res.result_list[0];

                            this.$nextTick(() => {
                                this.responseDialogVisible = true;
                            })
                        }
                    } catch (err) {
                        this.$message.error(err.message);
                    }
                });
            },

            //distribute
            // handleDistribute(row) {
            //     let currentUserName = row.device && row.device.user_name || '-';
            //     let currentGroupName = row.device && row.device.group_name || '-';
            //     this.userForm = {
            //         uid: row.uid,
            //         current_user_name: currentUserName,
            //         current_group_name: currentGroupName,
            //         target_user_id: '',
            //         target_group_id: ''
            //     };
            //     this.distributeModalVisible = true
            // },
            // async distributeDevice() {
            //     try {
            //         let form = this.userForm;
            //         await Core.Api.Device20024.saveTargetUser(form.uid, form.target_user_id, form.target_group_id)
            //         this.getDeviceDetail(this.uid);
            //         this.distributeModalVisible = false
            //     } catch (e) {
            //         console.log(e)
            //     }
            // },

            //edit dialog
            handleEditInfo(row) {
                this.deviceForm = {
                    id: row.id,
                    uid: row.uid,
                    address: row.address,
                    park_no: row.park_no,
                };
                this.editModalVisible = true;
            },
            editDialogCloseCallback() {
                this.deviceForm = {
                    id: '',
                    uid: '',
                    address: '',
                    park_no: '',
                };
            },
            async editDevice() {
                try {
                    await Core.Api.Device20024.updateBaseInfo(
                        this.deviceForm.id,
                        this.deviceForm.park_no,
                        this.deviceForm.address
                    );
                    this.editModalVisible = false;
                    this.getDeviceDetail(this.uid);
                } catch (e) {
                    console.log(e)
                }
            },

            //delete
            handleDelete(row) {
                this.deleteForm.id = row.id;
                this.deleteForm.password = "";
                this.deleteModalVisible = true;
            },
            deleteDevice() {
                let password = this.deleteForm.password.trim();
                if (password !== Core.Const.NET.DEVICE_DELETE_PASSWORD) {
                    this.$message({
                        type: 'error',
                        message: '密码错误, 请重试!'
                    });
                    return;
                }
                Core.Api.Device20024.deleteDevice(this.deleteForm.id)
                    .then(() => {
                        this.$message({
                            type: 'success',
                            message: this.$t('device.success')
                        });

                        this.getDeviceList();
                        this.deleteModalVisible = false;
                    })
                    .catch(() => {
                    });
            },

            // getUserList() {
            //     Core.Api.User.allList()
            //         .then(res => {
            //             this.userList = res.user_list;
            //         })
            //         .catch(err => {
            //             this.$message.error(err.message);
            //         });
            // },
            getGroupList() {
                Core.Api.Group.listForAdmin(this.searchForm.user_id, 0)
                    .then(res => {
                        this.groupList = res.group_list;
                    })
                    .catch(err => {
                        this.$message.error(err.message);
                    });
            },
            getUserGroupList() {
                Core.Api.Group.listForAdmin(this.userForm.target_user_id, 0)
                    .then(res => {
                        this.userGroupList = res.group_list;
                    })
                    .catch(err => {
                        this.$message.error(err.message);
                    });
            },

            // 数据导出
            handleExport() {
                if (!this.exportTime || this.exportTime[0] === null || this.exportTime[1] === null) {
                    this.$message.warning("请选择时间");
                    return;
                }
                let startTime = (this.exportTime[0].getTime() / 1000).toFixed(0);
                let endTime = (this.exportTime[1].getTime() / 1000).toFixed(0);
                if (endTime - startTime > 86400) {
                    this.$message.warning("时间间隔不能大于24小时");
                    return;
                }
                // todo  导出
                this.submitExportTask(startTime, endTime);
                // this.exportDialogVisible = false;
            },
            //获取该id的导出任务列表
            getExportTaskList() {
                Core.Api.Export.getExportListByDeviceId(this.uid, this.space, this.exportListCurrentPage).then(res => {
                    let list = res.task_list;
                    list.forEach(i => {
                        let data = i.data;
                        if (data) {
                            let dataObj = JSON.parse(data);
                            i["begin_time"] = dataObj.begin_time;
                            i["end_time"] = dataObj.end_time;
                        }
                    });
                    this.exportTaskList = list;
                    this.exportListTotal = res.count;
                })
            },
            exportListPageChange(val) {
                this.exportListCurrentPage = val;
                this.getExportTaskList();
            },
            //提交一个任务
            submitExportTask(startTime, endTime) {
                // Core.Api.Export.exportFrameFor200018(this.uid, startTime, endTime).then(res => {
                //     this.$message.success("后台导出任务正在进行，请稍等");
                // })
            },
            // 下载
            downloadExportFile(task) {
                let targetUrl = `http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/${task.file}`;
                // let tempWindow = window.open('_blank');
                // tempWindow.location=`http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/${task.file}`;

            },

            // table 下拉框点击
            handleCommand(command) {
                console.log("this. command:", command)
            },

            showQueryDialog() {
                this.queryKeyForm = {key: ''};
                this.queryDialogVisible = true;
                this.queryKeyList = Core.Const.getBKV20015Keys([
                    0x30, 0x31, 0x32, 0x3C, 0x34, 0x35, 0x36, 0x37, 0x38, 0x39, 0x3A, 0x3B, 0x3C,
                    0x70, 0x71, 0x72, 0x73, 0x74, 0x75, 0x76, 0x77, 0x78, 0x79, 0x7D, 0x7E,
                    0x7A, 0x7B, 0x7F, 0x80, 0x81, 0x82, 0x83, 0x84, 0x85, 0x86, 0x87, 0x88,
                    0x89
                ],this.lang)
            },

            writeQueryCommand() {
                let bkv = this.$refs['query-bkv-selector'].getBKV();
                if (!bkv) {
                    this.$message.error("请选择Key");
                    return
                }

                Core.Api.Fengine20024.query(this.uid, bkv).then(res => {
                    this.queryDialogVisible = false;
                    this.$message.success(this.$t('device.sendSuccess'));
                });
            },

            showSetDialog() {
                this.setKeyForm = {key: '', value: ''};
                this.setDialogVisible = true;
                this.setKeyList = Core.Const.getBKV20015Keys([
                    0x50, 0x51, 0x52, 0x53, 0x54, 0x55, 0x56, 0x57,
                    0x73, 0x74, 0x75, 0x76, 0x77, 0x78, 0x79, 0x7D, 0x7E,
                    0x89
                ])
            },
            writeSetCommand() {
                let bkv = this.$refs['set-bkv-editor'].getBKV();
                if (!bkv) {
                    this.$message.error("请选择Key");
                    return
                }

                Core.Api.Fengine20024.set(this.uid, bkv).then(res => {
                    this.setDialogVisible = false;
                    this.$message.success(this.$t('device.sendSuccess'));
                });
            },

        }
    }
</script>

<style scoped lang="scss">
    .device-detail-container {
        height: 100%;

        .device-detail {
            height: 100%;

            .device-info {
                background: #ffffff;
                padding: 20px;

                .device-info-content {
                    border: 1px solid #EAEDF0;
                    background: #F5F7F9;
                    padding: 6px 20px;
                    border-radius: 4px;

                    .row-content {
                        font-size: 0;
                        margin: 10px 0;

                        .row-item {
                            display: inline-block;
                            width: 50%;

                            .title {
                                font-size: 12px;
                                color: #8E949C;
                                min-width: 120px;
                                display: inline-block;
                            }

                            .content {
                                font-size: 12px;
                                color: #50555D;
                                display: inline-block;
                            }
                        }
                    }
                }
            }

            .device-frame-list-table {
                box-flex: 1;
                -webkit-box-flex: 1;
                -moz-box-flex: 1;
                flex: 1;
                -webkit-flex: 1;
                overflow: auto;

                .btns {
                    margin: 0 15px 15px;
                }
            }
        }

        .panel-list {
            margin: 0 20px;
            position: relative;
            background: #fff;

            .btn-flash {
                position: absolute;
                right: 22px;
                top: 7px;
                z-index: 2;
            }

            .item-panel {
                position: relative;

                .tab-content {
                    padding: 0 20px;
                    position: relative;
                }

                &:first-child {
                    .row-title {
                        border-top: none;
                    }
                }

                .row-title {
                    height: 50px;
                    padding: 0 30px;
                    overflow: hidden;
                    border-top: 1px solid rgb(240, 240, 246);

                    .title {
                        font-size: 14px;
                        line-height: 50px;
                        color: #03CC75;
                        font-weight: bold;
                    }
                }

                .row-content {
                    overflow: hidden;
                    background: #FBFBFC;

                    .row-item {
                        width: 50%;
                        float: left;
                        height: 40px;
                        padding-left: 30px;
                        line-height: 40px;
                        border-top: 1px solid rgb(240, 240, 246);

                        &:nth-child(2n) {
                            border-left: 1px solid rgb(240, 240, 246);
                        }

                        .title {
                            font-weight: bold;
                            font-size: 12px;
                            color: #A9A9B1;
                            float: left;
                            width: 155px;
                        }

                        .content {
                            font-size: 12px;
                            color: #4D4D4D;
                            font-weight: bold;
                            float: left;
                        }

                        .action {
                            float: right;
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }

            .btns {
                padding: 0 30px;
            }

            .device-info-panel {
                padding: 30px;

                .title-row {
                    border-bottom: 2px solid #ECF0F5;

                    .title-text {
                        font-size: 14px;
                        color: #34383E;
                        font-weight: 500;
                        padding-bottom: 10px;
                        border-bottom: 2px solid #08B76B;
                        display: inline-block;
                    }
                }

                .info-panel-warp {
                    border: 1px solid #DCE2E8;
                    border-bottom: none;

                    .info-row {
                        border-bottom: 1px solid #DCE2E8;
                        font-size: 0;

                        .info-item {
                            width: 50%;
                            display: inline-block;
                            border-right: 1px solid #DCE2E8;
                            padding: 0 20px;
                            vertical-align: middle;

                            & + .info-item {
                                border-right: none;
                            }

                            .info-item-title {
                                display: inline-block;
                                width: 100px;
                                font-size: 12px;
                                color: #8E949C;
                                height: 44px;
                                line-height: 44px;
                                vertical-align: middle;

                            }

                            .info-item-value {
                                display: inline-block;
                                font-size: 12px;
                                color: #50555D;
                                height: 44px;
                                line-height: 44px;
                                vertical-align: middle;
                            }
                        }
                    }
                }

            }
        }

        .btn-row {
            padding: 15px 0;
        }

        .pb-40 {
            padding-bottom: 40px;
        }

        .bg-white {
            background: white;
        }

        .plug-list-table {
            margin-bottom: 20px;
        }

        .ml-20 {
            margin-left: 20px;
        }

        .button-wrapper {
            position: absolute;
            right: 40px;
            top: 8px;
            z-index: 10;
        }

        i.icon-distribute {
            display: inline-block;
            background: url("../../../assets/img/icon/icon-distribute-1.png") no-repeat center;
            background-size: 12px 12px;
            width: 12px;
            height: 12px;
            vertical-align: middle;
        }

        .mg-15 {
            margin: 15px;
        }

        .btn-a {
            color: #0000ff;
            cursor: pointer;
        }

    }
</style>
