import EventEmitter from 'events'
import * as Util from '../js/util'

class FengineClient {
    constructor() {
        this.eventEmitter = new EventEmitter();
    }

    connect(path) {
        this.path = path;
        try {
            let that = this;

            if (this.socket != null) {
                try {
                    this.socket.close();
                } catch (e) { }
            }

            this.socket = new WebSocket(this.path);

            this.socket.addEventListener('open', (event) => {
                console.log(event);
                that.eventEmitter.emit('open', event);
            });

            this.socket.addEventListener('close', (event) => {
                console.log(event);
                that.eventEmitter.emit('close', event);
            });

            this.socket.addEventListener('message', (event) => {
                console.log(event);
                that.eventEmitter.emit('data', event.data);
            });

            this.socket.addEventListener('error', (event) => {
                console.log(event);
                that.eventEmitter.emit('error', event);
            });
			if (this.sendInterval) {
				clearInterval(this.sendInterval)
			}
            this.sendInterval = setInterval(() => {
                this.socket.send("tick");
            }, 5000)
        } catch (e) {
            console.log(e);
            this.eventEmitter.emit('error', e);
        }
    }

    close() {
        return new Promise((resolve, reject) => {
            try {
                clearInterval(this.sendInterval);

                if (!this.socket) {
                    return resolve()
                }
                this.socket.close();

                resolve();
            } catch (e) {
                console.log(e);
                resolve(e);
            }
        });
    }
}

export default FengineClient
