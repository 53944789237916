import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/style/base.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/style/index.scss';
import store from './store/index'
import i18n from './locales'
import filters from './core/filters';


import './assets/style/fonts/iconfont.css' 
Vue.config.productionTip = false;

// 全局过滤器
Object.keys(filters).forEach(filterName => {
    Vue.filter(filterName, filters[filterName])
})
Vue.prototype.$Filter = filters;

Vue.use(ElementUI);

import Map from './components/map.vue'
Vue.component('Map', Map)

import * as Util from './core/js/util'
Vue.prototype.$Util = Util;
Vue.prototype.$auth = Util.auth;

new Vue({
    store,
	i18n,
    router,
    render: h => h(App)
}).$mount('#app')
