<template>
    <div class="map-box" ref="mapBox">
        <div id="container" style="width: 100%;height: 100%;"></div>
    </div>
</template>

<script>
import Core from '@/core'
import { TMap } from '@/core/js/map'
const key = Core.Const.MAP.TENCENT_KEY

export default {
    props: {
        device: {
            type: Object,
            default: () => ({})
        }
    },
    components: {

    },
    created() {

    },
    data() {
        return {
            width: 1200,
            height: 800,
        }
    },
    mounted() {

    },
    watch: {
        "device": {
            immediate: true, // 立即执行
            deep: true, // 深度侦听复杂类型内变化
            handler(newVal, oldVal) {
                console.log("handler  newVal:", newVal)
                if (!Core.Util.isEmptyObj(newVal)) {
                    this.$nextTick(() => {
                        let width = this.$refs.mapBox.offsetWidth;
                        console.log("mounted  this.$refs.mapBox:", this.$refs.mapBox)
                        console.log("mounted  width:", width)
                        let height = this.$refs.mapBox.offsetHeight;
                        console.log("mounted  height:", height)
                    })
                    this.init()
                }
            }
        }
    },
    methods: {
        init() {
            console.log("init  latitude, longitude:", this.device)
            const { latitude, longitude } = this.device
            console.log("init  latitude, longitude:", latitude, longitude)
            if (!latitude || !longitude) {
                this.$message.error('设备经纬度有误')
                return
            }


            TMap(key).then(qq => {
                var center = new qq.maps.LatLng(latitude, longitude);
                var map = new qq.maps.Map(document.getElementById("container"), {
                    // 地图的中心地理坐标。
                    center: center,
                    zoom: 20
                });

                // 本地图片无法显示
                var anchor = new qq.maps.Point(15, 30)
                var size = new qq.maps.Size(30, 30)
                var origin = new qq.maps.Point(0, 0)
                var icon = new qq.maps.MarkerImage( Core.Const.NET.OTA_FILE_URL_PREFIX + 'airjoy-20016/4e94d72a3fc123c212d429248357337c619586f6ec8ce950ae5786968dbae2c2.png', size, origin, anchor, size);
                var marker = new qq.maps.Marker({
                    position: center,
                    map: map
                });
                marker.setIcon(icon);
                qq.maps.event.addListener(marker, "click", function () {
                    // alert("you clicked me")
                });
            });
        }
    }
}
</script>

<style scoped lang="scss">
.map-box {
    width: 100vw;
    height: 100vh;
    max-height: calc(100vh - 350px);
    max-width: 100%;
}
</style>