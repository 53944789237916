<template>
    <div class="remote-debug-detector">
        <el-row type="flex" justify="" align="center">
            <div class="ml-20 displayFlex">
                <el-button v-if="connectStatus !== 2" type="primary" @click="connectFengine">连接</el-button>
                <el-button v-else type="primary" @click="disconnectFengine">断开</el-button>
            </div>
            <div class="ml-20 displayFlex" v-if="connectStatus === 2">活跃时间: {{ lastActiveTime | timeFormat }}</div>
        </el-row>
        <div class="clear-20"></div>
        <div class="action-holder" v-if="connectStatus === 2">
			<div class="action-holder-content">
				<el-col :lg="6" :md="12" class="action-holder-item">
					<el-input placeholder="透传指令" v-model="command" class="input-with-select" clearable></el-input>
					<el-button type="primary" @click="writeProxyCommand(command)" class="input-with-btn" :disabled="magStatus">发送</el-button>
				</el-col>
				<el-col :lg="6" :md="12" class="action-holder-item">
					<el-select placeholder="请选择透传指令" v-model="throughWriteCommandValue"
						class="input-with-select" clearable filterable>
						<el-option
							v-for="(item, index) in throughWriteCommandList"
							:key="index"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-button type="primary" @click="throughWriteProxyCommand(throughWriteCommandValue)" class="input-with-btn" :disabled="magStatus">发送</el-button>
				</el-col>
				<el-col :lg="6" :md="12" class="action-holder-item">
					<el-select placeholder="请选择标准指令"
						v-model="standardWriteCommandValue"
						class="input-with-select"
						clearable
						filterable>
						<el-option
							v-for="(item, index) in standardWriteCommandList"
							:key="index"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-button class="input-with-btn" type="primary" @click="standardWriteProxyCommand(standardWriteCommandValue)" :disabled="magStatus">发送</el-button>
				</el-col>
				<el-col :lg="6" :md="12" class="action-holder-item">
					<el-button type="primary" @click="showSetDialog" :disabled="magStatus">设置标准指令</el-button>
					<el-button type="primary" @click="getMagStatus">{{magStatus ? '关闭' : '开始'}}干扰探测</el-button>
				</el-col>
			</div>
		</div>
		<div class="remote-debug-detector-holder panel-holder debug-holder" ref="remote-debug-detector-holder" v-loading="connectStatus === 1">
			<el-row class="item-panel" :span="10">
				<el-tabs v-model="activeName" class="tab-content" type="card" @tab-click="tabChange" v-if="connectStatus === 2">
					<el-tab-pane label="日志" name="log">
						<div class="log-list-holder tab-pane-holder">
							<div class="live-data-list" ref="live-data-list">
								<div class="data-item" v-for="(data,index) in liveDataList" :key="index">
									<span class="time">{{ data['time'] }}</span>
									<span class="content">{{ data['content'] }}</span>
								</div>
							</div>
						</div>
                        <el-button class="clear-btn" type="success" @click="liveDataList = []" v-if="liveDataList.length > 0">清空</el-button>
					</el-tab-pane>
					<el-tab-pane label="图表" name="graph">
						<div class="myChart-div" >
							<div id="myChart" class="myChart"></div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</el-row>
		</div>
        <el-dialog title="设置标准指令" custom-class="edit-dialog" :visible.sync="setDialogVisible" width="700px">
			<div class="magnet-detector-dialog">
				<div class="key">
					指令：
					<el-select placeholder="请选择标准指令"
							v-model="issueCommandKey"
							class="input-with-select"
							clearable
							filterable>
						<el-option
							v-for="(item, index) in issueCommandKeyList"
							:key="index"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="value">
					值：
					<el-input v-model="issueCommandValue" placeholder="请输入指令值" type="text"></el-input>（多个参数请用英文逗号","隔开）
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="writeSetCommand">下发</el-button>
			</div>
		</el-dialog>
    </div>
</template>

<script>
import Core from '@/core'
import FengineClient from "@/core/ws/ws";

import echarts from 'echarts'
import * as BLEFrame from "@/core/ws/ble-frame";
import moment from 'moment'
import * as Util from "@/core/js/util"
let myChart = null
let timeList = []

export default {
    name: "remote-debug-detector",
    props: {
        deviceId: String,
        lastActiveTime: [String,Number],
    },
    data() {
        return {
            currentPage: 1,
            activeName: 'log',

            connectStatus: 0,   // websocket连接状态 -1失败 0初始 1连接中 2连接成功
            fengineClient: new FengineClient(),
            liveDataList: [],
            liveDataHeight: 0,
            // lastActiveTime: 0,

            throughWriteCommandValue: '',
            throughWriteCommandList: Core.Const.throughWriteCommandList,
            standardWriteCommandValue: '',
            standardWriteCommandList: Core.Const.StandardWriteCommandList,
            command: '',
            connectFengineTimeout: 0,

            magStatus: false,
            magList: [],

            setDialogVisible: false,

            issueCommandKey: '',
            issueCommandKeyList: Core.Const.IssueCommandKeyList,
            issueCommandValue: '',
        }
    },
    mounted: async function () {
        this.setupFengineClient();
    },
    beforeDestroy() {
        this.magStatus = false
    },
    methods: {
        addLiveDataList(msg) {
            this.liveDataList.unshift(msg);
        },
        tabChange(e) {
            if (e.name === 'graph') {
                this.drawLine()
            }
        },
        drawLine() {
            myChart = echarts.init(document.getElementById('myChart'))
            let magList = this.magList
            let series = []
            let nameList = []
            magList.forEach(item => {
                item = item.split(',')
                if (item[0].indexOf('num=1') > -1) {
                    // console.log('单地磁')
                } else {
                    // console.log('三地磁')
                }
                for (let i = 1; i < item.length; i++) {
                    // 'num=1', 'x=1
                    let items = item[i].split('=')
                    if (!series[i - 1]) {
                        series[i - 1] = {
                            name: '',
                            type: 'line',
                            // stack: '总量',
                            data: []
                        }
                    }
                    let name = items[0]
                    let val = Number(items[1])
                    nameList.push(name)
                    series[i - 1].name = name
                    series[i - 1].data.push(val)
                }
            })
            // console.log('magList', magList)
            // console.log('series', series)
            // 绘制图表
            nameList = [...new Set(nameList)]
            console.log('nameList', nameList)
            console.log('series', series)
            myChart.setOption({
                title: {
                    text: '折线图堆叠'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: nameList
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: timeList
                },
                yAxis: {
                    type: 'value'
                },
                series: series
            });
        },
        async getMagStatus() {
            this.magStatus = !this.magStatus
            if (this.magStatus) {
                this.magList = []
                timeList = []
                await this.getMagData()
            }
        },
        async getMagData() {
            // await this.writeProxyCommand('GetMagBuf\r\n')
            await this.writeProxyCommand('GetMagData\r\n')
        },
        async throughWriteProxyCommand(buffer) {
            if (!buffer) {
                return
            }
            await this.writeProxyCommand(buffer)
        },
        async standardWriteProxyCommand(buffer) {
            if (!buffer) {
                return
            }
            switch (buffer) {
                case '硬件版本': await this.writeCommand(BLEFrame.makeFrameGetDeviceVersion()); break;
                case 'BOOT版本': await this.writeCommand(BLEFrame.makeFrameGetBOOTVersion()); break;
                case '固件版本': await this.writeCommand(BLEFrame.makeFrameGetFirmwareVersion()); break;
                case 'IMEI号': await this.writeCommand(BLEFrame.makeFrameGetIMEI()); break;
                case '工作状态': await this.writeCommand(BLEFrame.makeFrameGetStatus()); break;
                case '状态描述': await this.writeCommand(BLEFrame.makeFrameGetWorkDesc()); break;
                case '地磁校准': await this.writeCommand(BLEFrame.makeFrameCalibration()); break;
                case '决策时间': await this.writeCommand(BLEFrame.makeFrameDecisionTime()); break;
                case '灵敏度': await this.writeCommand(BLEFrame.makeFrameSensitivity()); break;
                case '心跳时间': await this.writeCommand(BLEFrame.makeFrameHeartbeatTime()); break;
                case '环境跟踪': await this.writeCommand(BLEFrame.makeFrameEnvironmentTracking()); break;
                case '车位状态': await this.writeCommand(BLEFrame.makeFrameGetCarportState()); break;
                case 'XYZBASE': await this.writeCommand(BLEFrame.makeFrameXYZBASE()); break;
                case 'XYZ': await this.writeCommand(BLEFrame.makeFrameXYZ()); break;
                case 'XYZ流量': await this.writeCommand(BLEFrame.makeFrameXYZFlow()); break;
                case '电池电压': await this.writeCommand(BLEFrame.makeFrameVBAT()); break;
                case '温度': await this.writeCommand(BLEFrame.makeFrameTemperature()); break;
                case '设备激活': await this.writeCommand(BLEFrame.makeFrameDeviceActivation()); break;
                case '蓝牙硬件版本': await this.writeCommand(BLEFrame.makeFrameBluetoothHardwareVersion()); break;
                case '蓝牙BOOT版本': await this.writeCommand(BLEFrame.makeFrameBluetoothBootVersion()); break;
                case '蓝牙固件版本': await this.writeCommand(BLEFrame.makeFrameBluetoothFirmwareVersion()); break;
                case '重启主控': await this.writeCommand(BLEFrame.makeFrameReset()); break;
                case 'RSSI': await this.writeCommand(BLEFrame.makeFrameGetRssi()); break;
                case '版本信息': await this.writeCommand(BLEFrame.makeFrameGetVersionMsg()); break;
                case '错误码': await this.writeCommand(BLEFrame.makeFrameGetError()); break;
                case '发送状态': await this.writeCommand(BLEFrame.makeFrameGetSendStatus()); break;
                case '模块信号状态': await this.writeCommand(BLEFrame.makeFrameGetModuleSignalStatus()); break;
                case 'APN': await this.writeCommand(BLEFrame.makeFrameGetAPN()); break;
                case '网络服务地址': await this.writeCommand(BLEFrame.makeFrameGetNCDPServer()); break;
                case '网络服务端口': await this.writeCommand(BLEFrame.makeFrameGetNCDPPort()); break;
                case 'UDP服务地址': await this.writeCommand(BLEFrame.makeFrameGetUDPServer()); break;
                case 'UDP服务端口': await this.writeCommand(BLEFrame.makeFrameGetUDPPort()); break;


                default : await this.writeProxyCommand(buffer); break
            }
        },

        async writeProxyCommand(command) {
            if (command === '') {
                return
            }

            await this.writeCommand(BLEFrame.makeDevice02FrameWrite(command))
        },

        async writeCommand(buffer) {
            let params = {
                id: this.deviceId,
                payload: BLEFrame.arrayBufferToHex(buffer),
                timeout: 0
            };
            try {
                this.addLiveDataList({
                    time: moment(new Date().getTime()).format('YYYY-MM-DD hh:mm:ss:SSS'),
                    content: BLEFrame.arrayBufferToHex(buffer)
                })
                await Core.Api.Fengine20022.route({
                    method: '/cmd/write',
                    params
                });
                if (!this.magStatus) {
                    this.$message.success('下发成功');
                    this.setDialogVisible = false;
                }
                this.scrollLiveDataToBottom()
            } catch (e) {
                console.log("writeCommand -> e", e)
                this.$message.error('下发失败');
                this.setDialogVisible = false;
            }
        },

        connectFengine() {
            this.fengineClient.connect(`ws://118.31.20.109:51022/p/20022/ws?client_id=${this.deviceId}`);
        },

        setupFengineClient() {
            let that = this;
            this.fengineClient.eventEmitter.on('open', () => {
                console.log('client opened');
                this.connectStatus = 2
                // setTimeout(()=> {
                //     if (this.liveDataHeight === 0 ) {
                //         this.liveDataHeight = this.$refs['remote-debug-detector-holder'].clientHeight - 80;
                //     }
                // })
            });
            this.fengineClient.eventEmitter.on('close', () => {
                console.log('client closed');
                if (this.connectStatus !== 0) {
                    if (this.connectFengineTimeout) {
                        clearTimeout(this.connectFengineTimeout)
                    }
                    this.connectFengineTimeout = setTimeout(() => {
                        this.connectStatus = 1
                        this.connectFengine();
                    }, 3000)
                }
            });
            this.fengineClient.eventEmitter.on('error', () => {
                console.log('client error');
                this.connectStatus = -1
                this.$message.error('连接失败')
            });
            this.fengineClient.eventEmitter.on('data', packet => {
                // this.lastActiveTime = new Date().getTime() / 1000;
                // this.$forceUpdate();
                try {
                    packet = JSON.parse(packet);
                    console.log("packet", packet);
                    let clientId = packet['client_id'];
                    if (clientId !== this.deviceId) {
                        return
                    }

                    let data = packet.data;
                    let payload = data.payload;
                    let time = data.time;

                    if (!payload) {
                        return;
                    }

                    let frame = BLEFrame.parseFrame(BLEFrame.hexToArrayBuffer(payload));
                    console.log('frame', frame);
                    this.addLiveDataList({
                        time: moment(time).format('YYYY-MM-DD hh:mm:ss:SSS'), // ''
                        payload: payload,
                        content: moment(new Date().getTime()).format('YYYY-MM-DD hh:mm:ss:SSS') + ' - ' + frame['_desc'],
                    })
                    // scroll to bottom
                    this.getFrameMagData(frame, time)
                    // elementLiveDataList.scrollTop = elementLiveDataList.scrollHeight + 200;
                    this.scrollLiveDataToBottom();
                } catch (e) {
                    console.log(e);
                }
            });
        },
        getFrameMagData(frame, time) {
            let data = frame['device_02_proxy_data_string']
            if (data && data.indexOf('num=') > -1 && data.indexOf('x=') > -1 && data.indexOf('y=') > -1 && data.indexOf('z=') > -1) {
                var next = true
            } else if (data && data.indexOf('num=') > -1 && data.indexOf('x1=') > -1 && data.indexOf('y1=') > -1 && data.indexOf('z1=') > -1) {
                var next = true
            }
            if (next) {
                this.magList.push(data)
                timeList.push(Core.Locutus.date('m/d H:i:s', time / 1000))
                if (this.magStatus) {
                    let time = setTimeout(() => {
                        clearTimeout(time)
                        this.getMagData()
                    }, 120)
                } else {
                }
                this.drawLine()
            }
        },
        scrollLiveDataToBottom() {
            setTimeout(() => {
                let elementLiveDataList = this.$refs['live-data-list'];
                if (elementLiveDataList) {
                    elementLiveDataList.scroll({
                        top: elementLiveDataList.scrollHeight + 120,
                        behavior: 'smooth'
                    })
                }
            },300)
        },

        disconnectFengine() {
            this.fengineClient.close();
            this.connectStatus = 0
            this.magStatus = false
        },

        showSetDialog() {
            this.setDialogVisible = true;
        },
        async writeSetCommand() {
            let issueCommandKey = this.issueCommandKey
            let issueCommandValue = this.issueCommandValue ? this.issueCommandValue.trim() : ''
            var bufHex = BLEFrame.getBufHex(issueCommandValue)
            console.log('bufHex', bufHex)
            switch (issueCommandKey) {
                case '设置决策时间': await this.writeCommand(BLEFrame.makeFrameSetDecisionTime(bufHex)); break;
                case '设置地磁校准': await this.writeCommand(BLEFrame.makeFrameSetCalibrate(bufHex)); break;
                case '设置灵敏度': await this.writeCommand(BLEFrame.makeFrameSetSensitivity(bufHex)); break;
                case '设置心跳时间': await this.writeCommand(BLEFrame.makeFrameSetHeartbeatTime(bufHex)); break;
                case '设置环境跟踪': await this.writeCommand(BLEFrame.makeFrameSetEnvironmentTracking(bufHex)); break;
                case '设置网络服务地址': await this.writeCommand(BLEFrame.makeFrameSetNcdpHost(bufHex)); break;
                case '设置网络服务端口': await this.writeCommand(BLEFrame.makeFrameSetNcdpPort(bufHex)); break;
                case '设置UDP服务地址': await this.writeCommand(BLEFrame.makeFrameSetUdpHost(bufHex)); break;
                case '设置UDP服务端口': await this.writeCommand(BLEFrame.makeFrameSetUdpPort(bufHex)); break;
            }
        },
    }
}
</script>

<style scoped lang="scss">
.remote-debug-detector {
    min-height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    align-content: space-between;
	.ml-20 {
		margin-left: 20px;
	}
    .displayFlex {
        display: flex;
        min-width: 100px;
        align-items: center;
    }

    .action-holder {
		padding: 10px 20px 16px;
		background-color: #fff;
		.action-holder-content {
			display: flex;
            flex-wrap: wrap;
            .action-holder-item {
                margin-bottom: 14px;
                .input-with-select {
                    width: 50%;
                    min-width: 180px;
                }
                .input-with-btn {
                    margin-left: 20px;
                }
            }
		}
	}
    .remote-debug-detector-holder {
        &.panel-holder {
            flex-grow: 1;
            margin: 0 20px;
            position: relative;
            background: #fff;
            .btn-flash {
                position: absolute;
                right: 22px;
                top: 7px;
                z-index: 2;
            }
            .item-panel {
                position: relative;
                .tab-content {
                    // padding: 0 20px;
                    position: relative;
                    .tab-pane-holder {
                        padding: 0 20px 20px;
                    }
                    .myChart-div {
                        padding: 0 20px 20px;
                        .myChart {
                            width: calc(100vw - 320px);
                            min-width: 700px;
                            height: 500px;
                        }
                    }
                }

                &:first-child {
                    .row-title {
                        border-top: none;
                    }
                }

            }
        }
        &.debug-holder {
            display: flex;
            justify-content: stretch;
            align-items: stretch;
            flex-direction: column;
        }

        .live-data-list {
            overflow-y: scroll;
            // height: 300px;
            * {
                color: #222;
                font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
                line-height: 20px;
            }
            .content {
                padding-left: 20px;
            }
        }
    }
    .tab-content {
        position: relative;
        .clear-btn {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .magnet-detector-dialog {
		display: flex;
		align-items: center;
		width: 100%;
		>div {
			display: flex;
			align-items: center;
			white-space:nowrap;
		}
		.key {
			margin-right: 30px;
		}
		.value {
			input {
				border: 1px solid #DCDFE6;
			}
		}
	}
}
</style>