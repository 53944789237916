<template>
    <div class="btn-span-content" @click="handleClick">
        <el-tooltip class="item" effect="dark" :content="tip" placement="top" popper-class="btn-tip">
            <i class="iconfont" :class="'icon-' + type"></i>
        </el-tooltip>
    </div>

</template>

<script>
    export default {
        name: "span-btn",
        props: {
           tip: '',
           type: '',
        },
        methods: {
            handleClick() {
                this.$emit('click')
            }
        }
    }
</script>

<style scoped lang="scss">
    .btn-span-content {
        display: inline-block;
        width: 22px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 50%;
        box-sizing: border-box;
        position: relative;
        top: 1px;
        background: $white;
        & + .btn-span-content {
            margin-left: 12px;
        }
        &:hover {
            background: $green;

            .iconfont {
                color: $white;
            }
        }
    }
    .iconfont {
        font-size: 13px;
        color: $green;

        &:hover {
            color: $white;
        }
    }
</style>
<style lang="scss" >
    .btn-tip {
        padding: 2px 10px;
        color: white;
        font-size: 12px;
        background: rgba(0, 0, 0, .1);
    }
</style>
