<template>
	<div id="screen-list">
		<div class="screen-list-container flex-container">
			<el-row class="search-row">
				<el-row class="search-row-base">
					<el-col :span="20">
						<div class="search-item">
							<label class="search-label">屏幕名称：</label>
							<el-input class="search-value" v-model="searchForm.name" placeholder="请输入屏幕名称" />
						</div>
						<div class="search-item">
							<label class="search-label">用户：</label>
							<el-select v-model="searchForm.user_id" filterable default-first-option clearable class="search-value" placeholder="请选择">
								<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"></el-option>
							</el-select>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="pull-right">
							<el-button type="primary" @click="simpleSearch">查询</el-button>
						</div>
					</el-col>
				</el-row>
			</el-row>
			<div class="screen-list-table table-container">
				<el-table :data="screenList" v-loading="loading" ref="screen-list-table" stripe @sort-change="sortChange" @selection-change="handleSelectionChange" :row-key="getRowKey" max-height="100%">
					<el-table-column label="屏幕名称" min-width="150" prop="name">
						<template slot-scope="scope">
							<router-link target="_blank" class="link" :to="{ path: '/screen-list-admin/detail?id=' + scope.row.id }">
								<span class="link">{{ scope.row.name || '-' }}</span>
							</router-link>
						</template>
					</el-table-column>
					<el-table-column label="屏幕序号" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.sort | ScreenSort }}</template>
					</el-table-column>

					<el-table-column label="卡号" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.monitor_card_no || '-' }}</template>
					</el-table-column>

					<el-table-column label="字体颜色" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.color | ScreenColor }}</template>
					</el-table-column>

					<el-table-column label="字体大小" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.font_size | FontFormat }}</template>
					</el-table-column>

					<el-table-column label="屏幕宽度" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.width || '-' }}</template>
					</el-table-column>

					<el-table-column label="屏幕高度" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.height || '-' }}</template>
					</el-table-column>

					<el-table-column label="屏幕格式" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.format | ScreenFormat }}</template>
					</el-table-column>

					<el-table-column label="屏幕状态" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.status | ScreenStatus }}</template>
					</el-table-column>

					<el-table-column label="空闲车位数" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.idle_park_count }}</template>
					</el-table-column>

					<el-table-column label="创建时间" min-width="150" prop="name">
						<template slot-scope="scope">{{ scope.row.create_time | timeFormat }}</template>
					</el-table-column>

					<el-table-column label="操作" fixed="right" width="80" align="center">
						<template slot-scope="scope">
							<!-- <span-btn type="export" tip="详情" @click="routerChange('detail',scope.row)"></span-btn> -->
							<span-btn type="edit" tip="编辑" @click="handleEdit(scope.row)"></span-btn>
							<span-btn type="delete" tip="删除" @click="handleDelete(scope.row)"></span-btn>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="flex-footer h-80">
				<div class="flex-footer-content">
					<el-row>
						<el-col :span="8" class="pt-3">
							<el-button type="success" @click="handleEdit()">添加屏幕</el-button>
						</el-col>
						<el-col :span="16" class="text-center" v-if="total > 0">
							<el-pagination class="text-right" @current-change="pageChange" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
						</el-col>
						<el-col :span="8" class="text-center" v-if="total > 0"></el-col>
					</el-row>
				</div>
			</div>
		</div>

		<!--删除弹窗-->
		<delete-dialog :visible.sync="deleteModalVisible" :propTitleText="titleText" @confirm="deleteScreen"></delete-dialog>

		<!--创建/编辑弹窗-->
		<el-dialog :title="editForm.id ? '编辑屏幕' : '创建屏幕'" custom-class="edit-dialog" :visible.sync="editModalVisible" @close="closeModalVisible">
			<el-form :label-position="'left'" label-width="110px" ref="editForm" :model="editForm">
				<el-form-item label="屏幕名称">
					<el-input v-model="editForm.name" placeholder="请输入屏幕名称"></el-input>
				</el-form-item>

				<el-form-item label="绑定卡号">
					<el-input v-model="editForm.monitor_card_no" placeholder="请输入绑定卡号" :disabled="editForm.id ? true : false"></el-input>
				</el-form-item>

				<!-- 序号 -->
				<el-form-item label="屏幕序号">
					<el-input v-model="editForm.sort" placeholder="请输入屏幕序号" type="number" @blur="monitorSortBlur()"></el-input>
				</el-form-item>

				<el-form-item label="字体颜色">
					<el-select v-model="editForm.color" placeholder="请选择颜色" style="width: 100%">
						<el-option v-for="item in screenColor" :key="item.id" :value="item.id" :label="item.text"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="屏幕格式">
					<el-select v-model="editForm.format" multiple placeholder="请选择屏幕格式" style="width: 100%" @change="formatChange">
						<el-option v-for="item in screenFormat" :key="item.id" :label="item.text" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="字体大小">
					<el-select v-model="editForm.font_size" placeholder="请选择字体大小" style="width: 100%" @change="formatChange">
						<el-option v-for="item in fontFormat" :key="item.id" :label="item.text" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="文字内容" v-if="isWord">
					<el-input v-model="editForm.word" placeholder="请输入屏幕内容" @input="wordChange($event)"></el-input>
				</el-form-item>

				<el-form-item label="箭头格式" v-if="isArrow">
					<el-select v-model="editForm.arrow" filterable default-first-option clearable class="form-control" placeholder="请选择箭头格式" @change="arrowChange">
						<el-option v-for="(item, index) in arrowList" :key="index" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="屏幕状态">
					<el-select v-model="editForm.status" filterable default-first-option clearable class="form-control" placeholder="请选择屏幕状态">
						<el-option v-for="item in screenStatus" :key="item.id" :label="item.text" :value="item.id">{{ item.text }}</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="屏幕宽度">
					<el-input v-model="editForm.width" placeholder="请输入屏幕宽度" type="number"></el-input>
				</el-form-item>

				<el-form-item label="屏幕高度">
					<el-input v-model="editForm.height" placeholder="请输入屏幕高度" type="number"></el-input>
				</el-form-item>

				<el-form-item label="绑定用户">
					<el-select v-model="editForm.user_id" filterable default-first-option clearable class="search-value" placeholder="请选择绑定用户" :disabled="Boolean(editForm.id)">
						<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="submitEditForm">确认</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Core from '@/core';
import spanBtn from '@/components/span-btn';
import deleteDialog from '@/components/delete-dialog';
import DistributeTable from './components/DistributeTable.vue';
import DeleteDialog from '@/components/delete-dialog';
import { ScreenFormat, ScreenColor, ScreenSort } from '@/core/filters/device';
export default {
	components: {
		spanBtn,
		deleteDialog,
		DistributeTable,
		DeleteDialog,
	},
	data() {
		return {
			spaceList: Core.Const.DETECTOR_SPACE_LIST,
			searchForm: {
				name: '',
				user_id: '',
			},
			userList: [],
			// search row
			keyword: '',
			searchSelect: 'uid',
			multipleSelection: [],
			// 分页配置
			currentPage: 1,
			total: 0,
			pageSize: 10,
			//帧数据
			frame: '',
			frameDialogVisible: false,
			// 基本信息
			screenForm: {
				id: '',
				space: '',
				uid: '',
				park_lot: '',
				park_no: '',
				address: '',
			},
			// 删除
			deleteModalVisible: false,
			deleteId: 0,
			//创建/编辑
			editForm: {
				id: 0,
				name: '',
				color: '',
				format: '',
				status: '',
				word: '',
				arrow: '',
				font_size: '',
			},
			editModalVisible: false,
			// 屏幕
			screenStatus: Core.Const.SCREEN.STATUS_LIST,
			screenList: [],
			loading: false,
			activeId: 0,
			screenColor: Core.Const.SCREEN.COLOR_LIST,
			singleScreenFormat: Core.Const.SCREEN.FORMAT_LIST,
			screenFormat: Core.Const.SCREEN.FORMAT_LIST,
			fontFormat: Core.Const.SCREEN.FONT_LIST,
			multiScreenFormat: Core.Const.SCREEN.FORMAT_LIST_Multi_Screen_List,
			titleText: '删除屏幕',
			arrowList: Core.Const.SCREEN.ARROW_LIST,
			isArrow: false, //是否选择箭头
			isWord: false, // 是否选择汉字
		};
	},

	mounted: function () {
		this.getScreenList();
		this.userList = this.$store.getters.getUserList;
	},

	methods: {
		//init get data
		async getScreenList(item) {
			try {
				this.loading = true;
				let res = await Core.Api.Screen.list(item === 'search' ? this.searchForm.name : '', item === 'search' ? this.searchForm.user_id : '', this.currentPage);
				console.log('getScreenList res', res);
				this.screenList = res.list;
				this.total = res.count;
				console.log('this.screenList: ', this.screenList);
			} catch (err) {
				console.log('getScreenList  err:', err);
			} finally {
				this.loading = false;
			}
		},
		pageChange(val) {
			this.currentPage = val;
			this.getScreenList();
		},
		handleSelectionChange(val) {
			console.log('***', val);
			this.multipleSelection = val;
			const ids = this.multipleSelection.map((item) => item.id).join(',');
			console.log('****', ids);
		},

		getRowKey(row) {
			return row.id;
		},

		editDialogCloseCallback() {
			this.screenForm = {
				id: '',
				space: '',
				uid: '',
				address: '',
				park_no: '',
			};
		},

		sortChange(sortItem) {
			let prop = sortItem.prop;
			let order = sortItem.order;
			this.sortType = Core.Util.sortType(prop, order);
			this.getScreenList();
		},

		formatChange() {
			console.log('this.editForm.format', this.editForm.format);
			let isArrow = false;
			let isWord = false;
			for (let i = 0; i < 3; i++) {
				this.editForm.format.map((item) => {
					if (item === 1) {
						isArrow = true;
						return;
					} else if (item === 2) {
						isWord = true;
						return;
					}
				});
			}
			this.isArrow = isArrow;
			this.isWord = isWord;
			if (!this.isArrow) {
				this.editForm.arrow = '';
			}
			if (!this.isWord) {
				this.editForm.word = '';
			}
		},

		//search 查询
		simpleSearch() {
			// this.searchForm['xyz_status'] = ""
			this.currentPage = 1;
			this.getScreenList('search');
		},

		// 创建/编辑
		handleEdit(val) {
			console.log('val: ', val);
			if (val) {
				// 编辑
				this.editForm = JSON.parse(JSON.stringify(val));
				this.editForm.color = parseInt(this.editForm.color);
				//  回显format
				let format = [];
				let FORMAT = Core.Const.SCREEN.FORMAT_MAP;
				val.format.split(',').forEach((item) => {
					if (item === 'number') {
						format.push(FORMAT.NUMBER);
						// } else if (item == "↑" || item == "↓" || item == "←" || item == "→") {
					} else if (this.arrowList.indexOf(item) != -1) {
						format.push(FORMAT.ARROW);
						this.$set(this.editForm, 'arrow', item);
					} else {
						format.push(FORMAT.WORD);
						this.$set(this.editForm, 'word', item);
					}
				});
				this.editForm.format = format;

				if (!this.editForm.sort) {
					this.screenStatus = this.singleScreenFormat;
				}
				// -1 是未传默认值不显示
				if (this.editForm.sort == -1) {
					this.$set(this.editForm, 'sort', null);
				}
				console.log('this.editForm: ', this.editForm);
				if (this.editForm.sort && this.editForm.sort != -1) {
					this.screenFormat = this.multiScreenFormat;
				} else if (!this.editForm.sort) {
					this.screenFormat = this.singleScreenFormat;
				}
				this.formatChange();
				console.log('11111111', this.editForm.sort);
			} else {
				// 新建
				this.editForm = {
					id: 0,
					color: '',
					name: '',
					format: '',
					status: '',
					width: null,
					height: null,
					word: '',
					arrow: '',
				};
			}
			this.editModalVisible = true;
		},
		submitEditForm() {
			let form = JSON.parse(JSON.stringify(this.editForm));
			console.log('form: ', form);
			if (!form.name) {
				this.$message.warning('请输入屏幕名称!');
				return;
			}
			if (!form.monitor_card_no) {
				this.$message.warning('请输入绑定卡号!');
				return;
			}

			// if(!form.sort) {
			//     this.$message.warning("请输入屏幕序号!")
			//     return
			// }

			if (!form.color && form.color !== 0) {
				this.$message.warning('请选择字体颜色!');
				return;
			}
			if (!form.format.length) {
				this.$message.warning('请选择屏幕格式!');
				return;
			}
			if (this.isWord && !form.word) {
				this.$message.warning('请输入屏幕文字内容!');
				return;
			}
			if (this.isArrow && !form.arrow) {
				this.$message.warning('请输入屏幕箭头格式!');
			}
			if (!form.status && form.status !== 0) {
				this.$message.warning('请选择屏幕状态!');
				return;
			}
			if (!form.width) {
				this.$message.warning('请输入屏幕宽度!');
				return;
			}
			if (!form.height) {
				this.$message.warning('请输入屏幕高度!');
				return;
			}
			// 未填序号默认传1
			if (!form.sort && form.sort !== 0) {
				form.sort = -1;
			}
			// 拼接formata
			let format = [];

			form.format.map((item) => {
				switch (item) {
					case 1:
						format.push(form.arrow);
						break;
					case 2:
						format.push(form.word);
						break;
					case 3:
						format.push('number');
						break;
				}
			});
			format = format.join(',');
			console.log('format: ', format);
			if (!form.id && !form.user_id) {
				this.$message.warning('请选择绑定用户!');
				return;
			}
			Core.Api.Screen.save(form.id, form.color, form.name, format, form.status, form.monitor_card_no, form.width, form.height, form.sort, form.user_id, form.font_size)
				.then(() => {
					this.$message.success(form.id ? '编辑成功！' : '创建成功！');
					this.getScreenList();
				})
				.catch((err) => {
					console.log('Screen.save err', err);
				});
			this.editModalVisible = false;
		},
		// 删除屏幕
		handleDelete(row) {
			this.deleteId = row.id;
			this.deleteModalVisible = true;
		},
		async deleteScreen() {
			await Core.Api.Screen.delete(this.deleteId).catch((err) => {
				console.log(object);
			});
			this.$message({
				type: 'success',
				message: '删除成功!',
			});
			this.getScreenList();
			this.deleteId = '';
			this.deleteModalVisible = false;
		},

		routerChange(index, row) {
			switch (index) {
				case 'detail': {
					let id = parseInt(row.id);
					this.$router.push(`screen-list-admin/detail?id=${id}`);
				}
			}
		},
		arrowChange() {
			this.$forceUpdate();
		},
		wordChange() {
			this.$forceUpdate();
		},
		// 屏幕序号事件（）
		monitorSortBlur() {
			console.log('this.editForm.sort', this.editForm.sort);
			console.log('isNaN(this.editForm.sort', isNaN(this.editForm.sort));
			if (this.editForm.sort + '' === '0') {
				this.editForm.sort = null;
				this.$message.warning('不能输入0!请重新输入');
				return;
			}
			if (this.editForm.sort) {
				console.log('this.editForm.sort:youzhi ', this.editForm.sort);
				if (this.editForm.sort < 0) {
					this.editForm.sort = null;
					this.$message.warning('不能输入负数!请重新输入');
					return;
				}
				console.log('this.editForm.sort: ', this.editForm.sort);
				console.log('editForm.format', this.editForm.format);
				this.screenFormat = this.multiScreenFormat;
				console.log('this.screenFormat: ', this.screenFormat);
				// 修改判断
				if (this.editForm.format.length !== 0) {
					if (this.editForm.format.indexOf(1) !== -1) {
						console.log('this.editForm.format.indexOf(1): ', this.editForm.format.indexOf(1));
						this.$message.warning('多卡号配置无法选择箭头格式，请重新选择');
						this.editForm.format = [];
					}
				}
			} else {
				this.screenFormat = this.singleScreenFormat;
				console.log('this.screenFormat: ', this.screenFormat);
			}
		},
		closeModalVisible() {
			this.editForm = {};
			console.log(' closethis.editForm : ', this.editForm);
		},
	},
};
</script>

<style scoped lang="scss">
#screen-list {
	height: 100%;
	padding: 20px;
	.screen-list-container {
		height: 100%;
		.screen-list-table {
			background: #ffffff;
			position: relative;
			overflow: auto;
			.all-select-holder {
				line-height: 30px;
				position: absolute;
				left: 10px;
				z-index: 10;
			}
			.line-status-span {
				padding: 2px 10px;
				background: #f0f3f6;
				font-size: 12px;
				border-radius: 12px;
				display: inline-block;
				&.offline {
					color: #50555d;
				}
				&.online {
					color: #08b76b;
				}
			}
			.park-status {
				.dot {
					width: 6px;
					height: 6px;
					border-radius: 50%;
					display: inline-block;
					background: #39a4f4;
					margin-right: 4px;
					vertical-align: middle;
				}
				.text {
					vertical-align: middle;
					color: #39a4f4;
				}
				&.no-car {
					.dot {
						background: #afb6c0;
					}
					.text {
						color: #afb6c0;
					}
				}
			}
		}
		.flex-footer {
			border-top: 1px solid #e0e7ef;
		}
		.flex-footer.h-80 {
			height: 80px;
		}
	}
	a.success {
		display: inline-block;
		color: #fff;
		background-color: #03cc75;
		border-color: #03cc75;
		line-height: 1;
		white-space: nowrap;
		cursor: pointer;
		-webkit-appearance: none;
		text-align: center;
		box-sizing: border-box;
		outline: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		padding: 7px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin: 0 10px;
	}
	.upload-screen {
		display: inline-block;
	}
}
</style>

<style lang="scss">
.edit-dialog {
	.el-select {
		width: 100%;
	}
}
</style>
