<template>
	<div class="device-detail-container">
		<div class="device-detail flex-container">
			<div class="device-info">
				<div class="device-info-content">
					<div class="row-content">
						<div class="row-item">
							<div class="title">车位号：</div>
							<div class="content">{{ device.park_no }}</div>
						</div>
						<div class="row-item">
							<div class="title">安装地址：</div>
							<div class="content">{{ device.address }}</div>
						</div>
						<div class="clear-10"></div>
						<div class="row-item">
							<div class="title">设备ID：</div>
							<div class="content">{{ device.uid }}</div>
						</div>
						<div class="row-item">
							<div class="title">接入类型：</div>
							<div class="content">{{ device.space | spaceType }}</div>
						</div>
						<div class="clear-10"></div>
						<div class="row-item">
							<div class="title">第三方平台ID：</div>
							<div class="content">{{ device.outer_device_id || '' }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clear-20"></div>

			<el-row class="item-panel panel-list" :span="10">
				<div class="button-wrapper" v-if="activeName === 'second' || activeName === 'third'">
					<el-button @click="refresh" type="success"> 刷新 </el-button>
				</div>
				<el-tabs v-model="activeName" class="tab-content" type="card">
					<el-tab-pane label="基本信息" name="tab-info">
						<el-row class="btns">
							<el-button type="primary" @click="handleDistribute(device)">
								<i class="icon-distribute"></i>
								分配
							</el-button>
							<el-button type="primary" icon="el-icon-edit" @click="handleEditInfo(device)">编辑</el-button>
							<el-button type="danger" class="pull-right" icon="el-icon-delete" @click="handleDelete(device)">删除 </el-button>
						</el-row>
						<div class="device-info-panel">
							<div class="title-row">
								<label class="title-text">设备</label>
							</div>
							<div class="clear-20"></div>
							<div class="info-panel-warp">
								<div class="info-row">
									<div class="info-item">
										<div class="info-item-title">创建时间：</div>
										<div class="info-item-value">{{ device['create_time'] | timeFormat }}</div>
									</div>
									<div class="info-item">
										<div class="info-item-title">最后活动时间：</div>
										<div class="info-item-value">{{ client.last_active_time | timeFormat }}</div>
									</div>
								</div>
								<div class="info-row">
									<div class="info-item">
										<div class="info-item-title">所属分组：</div>
										<div class="info-item-value">{{ (device.device && device.device.group_name) || '-' }}</div>
									</div>
									<div class="info-item">
										<div class="info-item-title">所属用户：</div>
										<div class="info-item-value">{{ (device.device && device.device.user_name) || '-' }}</div>
									</div>
								</div>
							</div>
						</div>

						<div class="device-info-panel">
							<div class="title-row">
								<label class="title-text">基本信息</label>
							</div>
							<div class="clear-20"></div>

							<div class="info-panel-warp">
								<device-prop :propList="basePropList"></device-prop>
							</div>
						</div>
						<div class="device-info-panel">
							<div class="title-row">
								<label class="title-text">即时信息</label>
							</div>
							<div class="clear-20"></div>
							<div class="info-panel-warp">
								<div class="info-row">
									<div class="info-item">
										<label class="info-item-title">车位状态：</label>
										<label class="info-item-value">{{ client | p20015ParkState }}</label>
									</div>
									<div class="info-item">
										<label class="info-item-title">水覆盖：</label>
										<label class="info-item-value">-</label>
									</div>
								</div>
								<div class="info-row">
									<div class="info-item">
										<label class="info-item-title">X轴：</label>
										<label class="info-item-value">{{ client | p20015Attr('x') }}</label>
									</div>
									<div class="info-item">
										<label class="info-item-title">Y轴：</label>
										<label class="info-item-value">{{ client | p20015Attr('y') }}</label>
									</div>
								</div>
								<div class="info-row">
									<div class="info-item">
										<label class="info-item-title">Z轴：</label>
										<label class="info-item-value">{{ client | p20015Attr('z') }}</label>
									</div>
									<div class="info-item">
										<label class="info-item-title">雷达已开启标记：</label>
										<label class="info-item-value">-</label>
									</div>
								</div>
								<div class="info-row">
									<div class="info-item">
										<label class="info-item-title">雷达结果：</label>
										<label class="info-item-value">-</label>
									</div>
									<div class="info-item">
										<label class="info-item-title">电压：</label>
										<label class="info-item-value">{{ client | p20015Attr('voltage') }}</label>
									</div>
								</div>
								<div class="info-row">
									<div class="info-item">
										<label class="info-item-title">设备状态：</label>
										<label class="info-item-value">{{ client | p20015ParkState }}</label>
									</div>
									<div class="info-item">
										<label class="info-item-title">温度：</label>
										<label class="info-item-value">{{ client | p20015Attr('temperature') }}</label>
									</div>
								</div>
								<div class="info-row">
									<div class="info-item">
										<label class="info-item-title">报警事件：</label>
										<label class="info-item-value">-</label>
									</div>
									<div class="info-item">
										<label class="info-item-title">信号强度：</label>
										<label class="info-item-value">{{ client | p20015Attr('conn_signal_strength') }}</label>
									</div>
								</div>
								<div class="info-row">
									<div class="info-item">
										<label class="info-item-title">信噪比：</label>
										<label class="info-item-value">{{ client | p20015Attr('snr') }}</label>
									</div>
									<div class="info-item">
										<label class="info-item-title"></label>
										<label class="info-item-value"></label>
									</div>
								</div>
							</div>
						</div>
						<div class="device-info-panel">
							<div class="title-row">
								<label class="title-text">推送信息</label>
							</div>
							<div class="clear-20"></div>
							<relay-config :uid="uid" :space="space"></relay-config>
						</div>
					</el-tab-pane>
					<el-tab-pane label="上行数据" name="tab-frame-list">
						<div class="device-frame-list-table">
							<!--<el-radio-group v-model="frameType" class="btns">-->
							<!--<el-radio-button label="全部"></el-radio-button>-->
							<!--<el-radio-button label="数据帧"></el-radio-button>-->
							<!--<el-radio-button label="心跳帧"></el-radio-button>-->
							<!--</el-radio-group>-->
							<el-table :data="frameList" max-height="500" v-loading="loading.frame">
								<el-table-column type="expand">
									<template slot-scope="scope">
										<div v-html="scope.row['content_html']"></div>
									</template>
								</el-table-column>
								<el-table-column label=" 上报时间" width="180">
									<template slot-scope="scope">
										{{ scope.row.time | timeFormat }}
									</template>
								</el-table-column>
								<el-table-column label="类型" width="100">
									<template slot="header">
										<span class="el-dropdown-link"> 帧类型 </span>
										<!-- <el-dropdown trigger="click" @command="handleCommand">
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item command="">全部</el-dropdown-item>
                                                    <el-dropdown-item command="1">心跳帧</el-dropdown-item>
                                                    <el-dropdown-item command="2">属性帧</el-dropdown-item>
                                                    <el-dropdown-item command="3">测试帧</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown> -->
									</template>

									<template slot-scope="scope">
										{{ scope.row.frame | p20015FrameType }}
									</template>
								</el-table-column>
								<el-table-column label="事件时间" width="150">
									<template slot-scope="scope">
										{{ scope.row.frame | frame2015EventTimeFormat }}
									</template>
								</el-table-column>
								<el-table-column label="地磁" width="100">
									<template slot-scope="scope">
										{{ scope.row.frame | p20015ParkState }}
									</template>
								</el-table-column>
								<el-table-column label="x轴" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['x'] | frame2015ClientXYZ }}
									</template>
								</el-table-column>
								<el-table-column label="y轴" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['y'] | frame2015ClientXYZ }}
									</template>
								</el-table-column>
								<el-table-column label="z轴" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['z'] | frame2015ClientXYZ }}
									</template>
								</el-table-column>
								<el-table-column label="基础x轴" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['x_base'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="基础y轴" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['y_base'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="基础z轴" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['z_base'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="SI信号强度" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['csq'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="SNR信噪比" width="100">
									<template slot-scope="scope">
										{{ scope.row.frame['snr'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="温度" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['temperature'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="电池电压" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['battery_voltage'] ? scope.row.frame['battery_voltage'] + 'mv' : '-' }}
									</template>
								</el-table-column>
								<el-table-column label="电池电量" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['battery_level'] ? scope.row.frame['battery_level'] + '%' : '-' }}
									</template>
								</el-table-column>
								<el-table-column label="事件序号" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['sn'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="流水号" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['frame_id'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="NB模块重启次数" width="120">
									<template slot-scope="scope">
										{{ scope.row.frame['nb_reboot_count'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="MCU重启次数" width="120">
									<template slot-scope="scope">
										{{ scope.row.frame['mcu_reboot_count'] || '-' }}
									</template>
								</el-table-column>

								<el-table-column label="设备状态" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['device_state'] || '' }}
									</template>
								</el-table-column>
								<el-table-column label="重启寄存器" width="80">
									<template slot-scope="scope">
										{{ scope.row.frame['0x3C'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="conn_signal_strength" width="150">
									<template slot-scope="scope">
										{{ scope.row.frame['conn_signal_strength'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="conn_link_quality" width="120">
									<template slot-scope="scope">
										{{ scope.row.frame['conn_link_quality'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="conn_signal_ecl" width="120">
									<!-- <template slot-scope="scope">
                                        {{ scope.row.frame['conn_signal_ecl'] || '-' }}
                                    </template> -->
									<template slot-scope="scope">
										{{ scope.row.frame | getConnSignalEclShow }}
									</template>
								</el-table-column>
								<el-table-column label="conn_signal_cell_id" width="120">
									<template slot-scope="scope">
										{{ scope.row.frame['conn_signal_cell_id'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="conn_signal_pci" width="120">
									<template slot-scope="scope">
										{{ scope.row.frame['conn_signal_pci'] || '-' }}
									</template>
								</el-table-column>
								<el-table-column label="conn_signal_ear" width="120">
									<template slot-scope="scope">
										{{ scope.row.frame['frequency_point'] }}
									</template>
								</el-table-column>
								<el-table-column label="debug_str" width="80">
									<template slot-scope="scope">
										<el-popover ref="popover1" placement="top" trigger="hover" :content="scope.row.frame['0xC0']"> </el-popover>
										<span v-popover:popover1>{{ scope.row.frame['0xC0'] }}</span>
									</template>
								</el-table-column>
								<el-table-column label="debug_序列号" width="120">
									<template slot-scope="scope">
										{{ scope.row.frame | p20015RawAttr(0xc2) }}
									</template>
								</el-table-column>
								<el-table-column label="扰乱时间" width="160">
									<template slot-scope="scope">
										{{ (scope.row.frame && scope.row.frame['raw_c1']) | timeFormat }}
									</template>
								</el-table-column>
								<el-table-column label="原始数据">
									<template slot-scope="scope">
										<a class="link" @click="showFrameDialog(scope.row.frame)"> {{ scope.row.data }}</a>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="flex-footer">
							<div class="flex-footer-content">
								<el-row>
									<el-col :span="24" class="text-center" v-if="total > 0">
										<el-pagination @current-change="pageChange" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total"> </el-pagination>
									</el-col>
								</el-row>
							</div>
						</div>
					</el-tab-pane>

					<el-tab-pane label="上行数据v2" name="tab-device-frame-list">
						<DeviceFrameList :space="space" :uid="uid"></DeviceFrameList>
					</el-tab-pane>

					<el-tab-pane label="下行命令" name="tab-command-list">
						<command-list :uid="uid" :space="space" v-if="activeName === 'tab-command-list'"></command-list>
					</el-tab-pane>

					<el-tab-pane label="推送记录" name="tab-relay-log-list">
						<relay-log :uid="uid"></relay-log>
					</el-tab-pane>

					<el-tab-pane label="操作" name="tab-action">
						<div class="mg-15">
							<el-button type="success" @click="handleRelay">模拟数据上报</el-button>
							<el-button type="primary" @click="showQueryDialog">查询</el-button>
							<el-button type="primary" @click="showSetDialog">设置</el-button>
						</div>
					</el-tab-pane>
					<el-tab-pane label="数据导出" name="tab-export">
						<div class="mg-15">
							<el-row>
								<el-col :span="18">
									<el-date-picker class="mr-10" v-model="exportTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
									<el-button type="primary" @click="handleExport" class="ml-10">提交下载任务</el-button>
								</el-col>
								<el-col :span="6">
									<el-button class="pull-right" icon="el-icon-refresh" @click="getExportTaskList">刷新任务状态</el-button>
								</el-col>
							</el-row>
							<div class="clear-10"></div>
							<el-row>
								<el-table :data="exportTaskList" border max-height="500" v-loading="loading.exportTask">
									<el-table-column label="ID" width="180">
										<template slot-scope="scope">
											{{ scope.row.id }}
										</template>
									</el-table-column>
									<el-table-column label="开始时间" width="180">
										<template slot-scope="scope">
											{{ scope.row.begin_time | timeFormat }}
										</template>
									</el-table-column>
									<el-table-column label="结束时间" width="180">
										<template slot-scope="scope">
											{{ scope.row.end_time | timeFormat }}
										</template>
									</el-table-column>
									<el-table-column label="状态" width="100">
										<template slot-scope="scope">
											{{ scope.row.status | statusFormat }}
										</template>
									</el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<!--http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/${task.file}-->
											<a class="btn-a" :href="'http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/' + scope.row.file" v-if="scope.row.file && scope.row.status === 200"> 下载 </a>
											<!--<a class="btn-a" type="text info" @click="downloadExportFile(scope.row)">下载</a>-->
										</template>
									</el-table-column>
								</el-table>
							</el-row>
							<div class="clear-10"></div>
							<el-row>
								<el-col :span="24" v-if="exportListTotal > 0">
									<el-pagination @current-change="exportListPageChange" :page-size="pageSize" layout="total, prev, pager, next" :total="exportListTotal"> </el-pagination>
								</el-col>
							</el-row>
						</div>
					</el-tab-pane>

					<el-tab-pane label="告警列表" name="alarm-list">
						<div>
							<el-table :data="alarmList" border max-height="500" v-loading="loading.alarm">
								<el-table-column label="告警类型" min-width="80">
									<template slot-scope="scope">
										<span>{{ scope.row.type | alarmType }}</span>
									</template>
								</el-table-column>
								<el-table-column label="告警内容">
									<template slot-scope="scope">
										{{ scope.row.message }}
									</template>
								</el-table-column>
								<el-table-column prop="data" label="数据" min-width="200"></el-table-column>
								<el-table-column label="发生时间" width="180">
									<template slot-scope="scope">
										{{ scope.row.create_time | timeFormat }}
									</template>
								</el-table-column>
							</el-table>
						</div>

						<div class="flex-footer">
							<div class="flex-footer-content">
								<el-row>
									<el-col :span="24" class="text-center" v-if="alarmListTotal > 0">
										<el-pagination @current-change="alarmPageChange" :current-page="alarmListCurrentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="alarmListTotal"> </el-pagination>
									</el-col>
								</el-row>
							</div>
						</div>
					</el-tab-pane>

					<el-tab-pane label="远程调试" name="remote-debug-detector">
						<remote-debug-detector :device-id="device.uid" :last-active-time="device.last_active_time"></remote-debug-detector>
					</el-tab-pane>

					<el-tab-pane label="地图" name="map" v-if="device && device.latitude && device.longitude">
						<Map :device="device" />
					</el-tab-pane>
				</el-tabs>
			</el-row>
		</div>

		<el-dialog title="帧数据" :visible.sync="dialogVisible" custom-class="json-dialog" width="600px">
			<div class="content">
				<div class="pre-warp">
					<pre>{{ frame }}</pre>
				</div>
			</div>
		</el-dialog>

		<!--模拟数据上报弹窗-->
		<el-dialog title="模拟数据上报" custom-class="edit-dialog" :visible.sync="relayModalVisible" @close="relayDialogCloseCallback">
			<el-form :label-position="'left'" ref="relayForm" label-width="110px" :model="relayForm">
				<el-form-item label="帧类型">
					<el-radio-group v-model="relayForm.type">
						<el-radio :label="1">数据帧</el-radio>
						<el-radio :label="2">心跳帧</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="车位状态">
					<el-radio-group v-model="relayForm.state">
						<el-radio :label="0">无车</el-radio>
						<el-radio :label="1">有车</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="relayDeviceData('relayForm')">发送</el-button>
			</div>
		</el-dialog>

		<!--数据上报结果弹窗-->
		<el-dialog title="推送结果" with="70%" :visible.sync="responseDialogVisible">
			<div v-for="(item, key) in relayResponse" :key="key" class="pt-15">
				<div v-if="key === 'result' || key === 'endpoint'">
					<p style="font-size: 14px" v-if="key === 'result'">推送结果：</p>
					<p style="font-size: 14px" v-if="key === 'endpoint'">推送地址：</p>
				</div>
				<div v-else>
					<p style="font-size: 14px">
						{{ key }}
					</p>
				</div>

				<div style="max-height: 400px; overflow: auto">
					<label>{{ item }}</label>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="responseDialogVisible = false">确认</el-button>
			</div>
		</el-dialog>

		<!--编辑弹窗-->
		<el-dialog title="更新基本信息" custom-class="edit-dialog" :visible.sync="editModalVisible" @close="editDialogCloseCallback">
			<el-form :label-position="'left'" label-width="110px" ref="deviceForm" :model="deviceForm">
				<el-form-item label="设备ID:" prop="park_no">
					<span>{{ deviceForm.uid }}</span>
				</el-form-item>
				<el-form-item label="车位号:" prop="park_no">
					<el-input placeholder="请填写车位号" v-model="deviceForm.park_no"></el-input>
				</el-form-item>
				<el-form-item label="安装地址:" prop="address">
					<el-input placeholder="请填写安装地址" v-model="deviceForm.address"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="editDevice()">确认</el-button>
			</div>
		</el-dialog>

		<!--分配弹窗-->
		<el-dialog title="更新到指定账户" custom-class="edit-dialog" :visible.sync="distributeModalVisible">
			<el-form :label-position="'left'" label-width="110px" ref="userForm" :model="userForm">
				<el-form-item label="当前用户名:" prop="current_user_name">
					{{ userForm.current_user_name }}
				</el-form-item>
				<el-form-item label="当前分组:" prop="current_group_name">
					{{ userForm.current_group_name }}
				</el-form-item>
				<el-form-item label="目标用户名" prop="target_user_id">
					<el-select v-model="userForm.target_user_id" filterable default-first-option clearable class="form-control" placeholder="请选择">
						<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="目标分组">
					<el-select v-model="userForm.target_group_id" filterable default-first-option clearable class="form-control" placeholder="请选择">
						<el-option v-for="item in userGroupList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="distributeDevice('userForm')">确认分配</el-button>
			</div>
		</el-dialog>

		<!--删除弹窗-->
		<el-dialog title="删除设备" custom-class="edit-dialog" :visible.sync="deleteModalVisible">
			<p style="padding: 20px 0">提示：必须填入密码才能删除该设备</p>
			<el-form :label-position="'left'" label-width="110px" ref="deleteForm" :model="deleteForm">
				<el-form-item label="密码:" prop="password" :rules="[{ required: true, message: '密码不能为空' }]">
					<el-input type="password" placeholder="请填写密码" v-model="deleteForm.password"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="deleteDevice('deleteForm')">确认</el-button>
			</div>
		</el-dialog>

		<!--查询弹窗-->
		<el-dialog title="查询" custom-class="edit-dialog" :visible.sync="queryDialogVisible">
			<bkv-selector :keyList="queryKeyList" :bkv="queryBKVHex" ref="query-bkv-selector"></bkv-selector>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="writeQueryCommand()">确认</el-button>
			</div>
		</el-dialog>

		<!--设置弹窗-->
		<el-dialog title="设置" custom-class="edit-dialog" :visible.sync="setDialogVisible">
			<bkv-editor :keyList="setKeyList" :bkv="setBKVHex" ref="set-bkv-editor"></bkv-editor>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" @click="writeSetCommand()">下发</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Vue from 'vue';
import Core from '@/core';
import deleteDialog from '@/components/delete-dialog';
import spanBtn from '@/components/span-btn';
import relayConfig from '@/components/relay-config';
import bkvEditor from '@/components/bkv-editor';
import bkvSelector from '@/components/bkv-selector';
import relayLog from '@/components/relay-log';
import deviceProp from '@/components/device-prop';
import remoteDebugDetector from '@/components/remote-debug-detector';
import DeviceFrameList from '@/components/device-frame-list';

export default {
	name: 'device-detail-20028',
	components: { deleteDialog, spanBtn, relayConfig, bkvEditor, bkvSelector, relayLog, deviceProp, remoteDebugDetector, DeviceFrameList, commandList: () => import('@/components/command-list') },
	data() {
		return {
			device: {},
			device20012: {},
			client: {},

			space: '20028',

			Core: Core,
			activeName: 'tab-frame-list',
			// activeName: 'tab-info',
			id: '',
			uid: '',
			dialogVisible: false,
			frame: '',

			basePropList: [],

			// 分页配置
			total: 0,
			currentPage: 1,
			pageSize: 20,
			frameList: [],
			logList: [],

			frameType: '全部',

			loading: {
				frame: false,
				exportTask: false,
				alarm: false,
			},

			// 模拟推送
			relayModalVisible: false,
			relayForm: {
				type: 1,
				state: 0,
			},
			relayResponse: {},
			responseDialogVisible: false,

			// 基本信息
			editModalVisible: false,
			deviceForm: {
				id: '',
				uid: '',
				park_no: '',
				address: '',
			},
			// 删除
			deleteModalVisible: false,
			deleteForm: {
				id: '',
				password: '',
			},
			// 分配
			distributeModalVisible: false,
			userForm: {
				device_id: '',
				current_user_name: '',
				current_group_name: '',
				target_user_id: '',
				target_group_id: '',
			},

			userList: [],
			userGroupList: [],

			// 数据导出
			exportTime: '',
			exportDialogVisible: false,
			exportTaskList: [],
			exportListTotal: 0,
			exportListCurrentPage: 1,
			exportInterval: '',

			// 告警列表
			alarmList: [],
			alarmListTotal: 0,
			alarmListCurrentPage: 1,

			// 查询key
			queryKeyForm: {
				key: '',
			},
			queryKeyList: [],
			queryBKVHex: '',

			queryDialogVisible: false,

			// 设置key
			setDialogVisible: false,
			setKeyList: [],
			setBKVHex: '',
		};
	},
	watch: {
		activeName: function (val) {
			this.getTabInfo(val);
		},
		'userForm.target_user_id': 'getUserGroupList',
	},

	filters: {
		statusFormat(status) {
			let statusList = {
				0: '初始化',
				100: '进行中',
				200: '成功',
				'-1': '失败',
			};
			return statusList[status + ''];
		},
	},

	destroyed() {
		clearInterval(this.exportInterval);
	},

	mounted: function () {
		this.uid = this.$route.query['uid'];
		this.getDeviceDetail(this.uid);
		this.getClientDetail();
		this.getUserList();
		this.getTabInfo(this.activeName);
	},
	methods: {
		async getDeviceDetail(uid) {
			let res = await Core.Api.Device20028.getDeviceDetail(uid);
			let device = res['device'];
			device['status_text'] = Core.Util.isOff(device.last_active_time);
			device['cart_status'] = Core.Util.parkStateType(device.p1);
			this.id = device.id;
			this.device = device;
			this.prepareBasePropList(device);
		},

		prepareBasePropList(device) {
			let data = [
				{
					key: 'imei',
					name: 'IMEI',
				},
				{
					key: 'imsi',
					name: 'IMSI',
				},
				// {
				// 	key: 'iccid',
				// 	name: 'ICCID',
				// },
				{
					key: 'nccid',
					name: 'NCCID',
					filter: 'NCCIDFilter',
				},
				{
					key: 'nccid_2',
					name: 'NCCID_2',
					filter: 'NCCIDFilter',
				},
				{
					key: 'sim_status',
					name: '当前使用sim卡',
					filter: 'simStateFilter',
				},
				{
					key: 'sim_config',
					name: '当前配置sim卡',
					filter: 'simConfigFilter',
				},
				{
					key: 'address',
					name: '安装地址',
				},
				{
					key: 'park_lot',
					name: '停车场',
				},
				{
					key: 'park_no',
					name: '车位标号',
				},
				{
					key: 'firmware_version',
					name: '固件版本',
				},
				{
					key: 'hardware_version',
					name: '硬件版本',
				},
				{
					key: 'bluetooth_version',
					name: '蓝牙版本',
				},
				{
					key: 'sensitivity',
					name: '灵敏度',
				},
				{
					key: 'track_ratio',
					name: '跟踪系数',
				},
				{
					key: 'anti_interference_type',
					name: '抗干扰类型',
				},
				{
					key: 'heartbeat_interval',
					name: '心跳周期',
				},
				{
					key: 'nb_conn_station_id',
					name: 'NB频点',
				},
			];

			this.basePropList = Core.Util.getInfoList(data, device) || [];
			// console.log("prepareBasePropList  this.basePropList:", this.basePropList)
		},

		async getClientDetail() {
			try {
				let res = await Core.Api.Fengine20028.getClientDetail(this.uid);
				this.client = res.device;
			} catch (e) {
				console.log(e);
			}
		},

		async getClientFrameList() {
			try {
				this.loading.frame = true;
				let res = await Core.Api.Fengine20028.getFrameList(this.uid, this.space, this.currentPage);
				let frameList = [];
				for (let i in res.frame_list) {
					let frame = res.frame_list[i];
					frame['content_html'] = Vue.filter('frame20015Format')(frame);
					frameList.push(frame);
				}
				this.frameList = frameList;
				this.total = res.count;
			} catch (err) {
				console.log('getClientFrameList  err:', err);
			} finally {
				this.loading.frame = false;
			}
		},

		// 推送记录
		async getRelayList() {
			let res = await Core.Api.RelayLog.getLogList(this.currentPage, this.uid);
			this.logList = res.log_list;
			this.total = res.count;
		},

		pageChange(val) {
			this.currentPage = val;
			this.getTabInfo(this.activeName, val);
		},

		getTabInfo(type, page = 1) {
			this.currentPage = page;
			switch (type) {
				case 'tab-info':
					break;
				case 'tab-frame-list':
					this.getClientFrameList();
					break;
				case 'tab-command-list':
					break;
				case 'tab-relay-log-list':
					this.getRelayList();
					break;
				case 'tab-export':
					this.exportListCurrentPage = 1;
					this.getExportTaskList();
					this.exportInterval = setInterval(() => {
						this.getExportTaskList();
					}, 2000);
					break;
				case 'alarm-list':
					this.alarmListCurrentPage = 1;
					this.getAlarmList();
					break;
				default:
					break;
			}
			if (type !== 'tab-5') {
				clearInterval(this.exportInterval);
			}
		},

		async getAlarmList() {
			try {
				this.loading.alarm = true;
				let res = await Core.Api.Alarm.alarmList(this.alarmListCurrentPage, '', '', this.uid, this.space);
				this.alarmList = res.alarm_list;
				this.alarmListTotal = res.count;
			} catch (err) {
				console.log('getAlarmList  err:', err);
			} finally {
				this.loading.alarm = false;
			}
		},

		alarmPageChange(val) {
			this.alarmListCurrentPage = val;
			this.getAlarmList();
		},

		refresh() {
			this.currentPage = 1;
			switch (this.activeName) {
				case 'tab-2':
					{
						this.frameList = [];
						this.getClientFrameList(this.currentPage);
					}
					break;
				case 'tab-3':
					{
						this.logList = [];
						this.getRelayList(this.currentPage);
					}
					break;
			}
		},

		showFrameDialog(frame) {
			this.dialogVisible = true;
			if (frame.data) {
				frame.data = JSON.parse(frame.data);
			}
			this.frame = frame;
		},

		handleRelay() {
			this.relayModalVisible = true;
		},

		relayDialogCloseCallback() {
			this.relayForm = {
				state: 0,
			};
		},

		relayDeviceData(formName) {
			this.$refs[formName].validate((valid) => {
				if (!valid) {
					return false;
				}

				Core.Api.Fengine20028.simulateRelay(this.uid, this.relayForm.type, this.relayForm.state)
					.then((res) => {
						this.$message.success('发送成功');
						this.relayModalVisible = false;
					})
					.catch((err) => {
						this.$message.error(err.message);
					});
			});
		},

		//distribute
		handleDistribute(row) {
			let currentUserName = (row.device && row.device.user_name) || '-';
			let currentGroupName = (row.device && row.device.group_name) || '-';
			this.userForm = {
				uid: row.uid,
				current_user_name: currentUserName,
				current_group_name: currentGroupName,
				target_user_id: '',
				target_group_id: '',
			};
			this.distributeModalVisible = true;
		},
		async distributeDevice() {
			try {
				let form = this.userForm;
				await Core.Api.Device20028.saveTargetUser(form.uid, form.target_user_id, form.target_group_id);
				this.getDeviceDetail(this.uid);
				this.distributeModalVisible = false;
			} catch (e) {
				console.log(e);
			}
		},

		//edit dialog
		handleEditInfo(row) {
			this.deviceForm = {
				id: row.id,
				uid: row.uid,
				address: row.address,
				park_no: row.park_no,
			};
			this.editModalVisible = true;
		},
		editDialogCloseCallback() {
			this.deviceForm = {
				id: '',
				uid: '',
				address: '',
				park_no: '',
			};
		},
		async editDevice() {
			try {
				await Core.Api.Device20028.updateBaseInfo(this.deviceForm.id, this.deviceForm.park_no, this.deviceForm.address);
				this.editModalVisible = false;
				this.getDeviceDetail(this.uid);
			} catch (e) {
				console.log(e);
			}
		},

		//delete
		handleDelete(row) {
			this.deleteForm.id = row.id;
			this.deleteForm.password = '';
			this.deleteModalVisible = true;
		},
		deleteDevice() {
			let password = this.deleteForm.password.trim();
			if (password !== Core.Const.NET.DEVICE_DELETE_PASSWORD) {
				this.$message({
					type: 'error',
					message: '密码错误, 请重试!',
				});
				return;
			}
			Core.Api.Device20028.deleteDevice(this.deleteForm.id)
				.then(() => {
					this.$message({
						type: 'success',
						message: '删除成功!',
					});

					this.getDeviceList();
					this.deleteModalVisible = false;
				})
				.catch(() => {});
		},

		getUserList() {
			Core.Api.User.allList()
				.then((res) => {
					this.userList = res.user_list;
				})
				.catch((err) => {
					this.$message.error(err.message);
				});
		},
		getGroupList() {
			Core.Api.Group.listForAdmin(this.searchForm.user_id, 0)
				.then((res) => {
					this.groupList = res.group_list;
				})
				.catch((err) => {
					this.$message.error(err.message);
				});
		},
		getUserGroupList() {
			Core.Api.Group.listForAdmin(this.userForm.target_user_id, 0)
				.then((res) => {
					this.userGroupList = res.group_list;
				})
				.catch((err) => {
					this.$message.error(err.message);
				});
		},

		// 数据导出
		handleExport() {
			if (!this.exportTime || this.exportTime[0] === null || this.exportTime[1] === null) {
				this.$message.warning('请选择时间');
				return;
			}
			let startTime = (this.exportTime[0].getTime() / 1000).toFixed(0);
			let endTime = (this.exportTime[1].getTime() / 1000).toFixed(0);
			if (endTime - startTime > 86400) {
				this.$message.warning('时间间隔不能大于24小时');
				return;
			}
			// todo  导出
			this.submitExportTask(startTime, endTime);
			// this.exportDialogVisible = false;
		},
		//获取该id的导出任务列表
		async getExportTaskList() {
			try {
				this.loading.exportTask = true;
				let res = await Core.Api.Export.getExportListByDeviceId(this.uid, this.space, this.exportListCurrentPage);

				let list = res.task_list;
				list.forEach((i) => {
					let data = i.data;
					if (data) {
						let dataObj = JSON.parse(data);
						i['begin_time'] = dataObj.begin_time;
						i['end_time'] = dataObj.end_time;
					}
				});
				this.exportTaskList = list;
				this.exportListTotal = res.count;
			} catch (err) {
				console.log('getExportTaskList  err:', err);
			} finally {
				this.loading.exportTask = false;
			}
		},
		exportListPageChange(val) {
			this.exportListCurrentPage = val;
			this.getExportTaskList();
		},
		//提交一个任务
		submitExportTask(startTime, endTime) {
			// Core.Api.Export.exportFrameFor200018(this.uid, startTime, endTime).then(res => {
			//     this.$message.success("后台导出任务正在进行，请稍等");
			// })
		},
		// 下载
		downloadExportFile(task) {
			let targetUrl = `http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/${task.file}`;
			// let tempWindow = window.open('_blank');
			// tempWindow.location=`http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/${task.file}`;
		},

		// table 下拉框点击
		handleCommand(command) {
			console.log('this. command:', command);
		},

		showQueryDialog() {
			this.queryKeyForm = { key: '' };
			this.queryDialogVisible = true;
			this.queryKeyList = Core.Const.getBKV20015Keys([
				0x20, 0x21, 0x22, 0x23, 0x24, 0x25, 0x26, 0x27, 0x28, 0x30, 0x31, 0x32, 0x3c, 0x34, 0x35, 0x36, 0x37, 0x38, 0x39, 0x3a, 0x3b, 0x3c, 0x40, 0x41, 0x42, 0x43, 0x44, 0x45, 0x46, 0x49, 0x4a, 0x4b, 0x4c, 0x70, 0x71, 0x72, 0x73, 0x74, 0x75,
				0x76, 0x77, 0x78, 0x79, 0x7d, 0x7e, 0x7a, 0x7b, 0x7f, 0x80, 0x81, 0x82, 0x83, 0x84, 0x85, 0x86, 0x87, 0x88, 0x89,
			]);
		},

		writeQueryCommand() {
			let bkv = this.$refs['query-bkv-selector'].getBKV();
			if (!bkv) {
				this.$message.error('请选择Key');
				return;
			}

			Core.Api.Fengine20028.query(this.uid, bkv).then((res) => {
				this.queryDialogVisible = false;
				this.$message.success('发送成功');
			});
		},

		showSetDialog() {
			this.setKeyForm = { key: '', value: '' };
			this.setDialogVisible = true;
			this.setKeyList = Core.Const.getBKV20015Keys([
				0x20, 0x21, 0x22, 0x23, 0x24, 0x25, 0x26, 0x27, 0x28, 0x40, 0x41, 0x42, 0x43, 0x44, 0x45, 0x46, 0x49, 0x4a, 0x4b, 0x4c, 0x50, 0x51, 0x52, 0x53, 0x54, 0x55, 0x56, 0x57, 0x58, 0x68, 0x69, 0x6a, 0x6b, 0x6c, 0x6d, 0x6e, 0x73, 0x74, 0x75,
				0x76, 0x77, 0x78, 0x79, 0x7d, 0x7e, 0x89, 0x90, 0x91, 0x92, 0x93, 0x94, 0x95, 0x96, 0x97, 0x98, 0xc6, 0xc7, 0xc8, 0xc9, 0xcb, 0xcc, 0xcd, 0xce, 0xcf,
			]);
		},
		writeSetCommand() {
			let bkv = this.$refs['set-bkv-editor'].getBKV();
			if (!bkv) {
				this.$message.error('请选择Key');
				return;
			}

			Core.Api.Fengine20028.set(this.uid, bkv).then((res) => {
				this.setDialogVisible = false;
				this.$message.success('发送成功');
			});
		},
	},
};
</script>

<style scoped lang="scss">
.device-detail-container {
	height: 100%;
	.device-detail {
		height: 100%;
		.device-info {
			background: #ffffff;
			padding: 20px;
			.device-info-content {
				border: 1px solid #eaedf0;
				background: #f5f7f9;
				padding: 6px 20px;
				border-radius: 4px;
				.row-content {
					font-size: 0;
					margin: 10px 0;
					.row-item {
						display: inline-block;
						width: 50%;
						.title {
							font-size: 12px;
							color: #8e949c;
							min-width: 120px;
							display: inline-block;
						}
						.content {
							font-size: 12px;
							color: #50555d;
							display: inline-block;
						}
					}
				}
			}
		}

		.device-frame-list-table {
			box-flex: 1;
			-webkit-box-flex: 1;
			-moz-box-flex: 1;
			flex: 1;
			-webkit-flex: 1;
			overflow: auto;
			.btns {
				margin: 0 15px 15px;
			}
		}
	}

	.panel-list {
		margin: 0 20px;
		position: relative;
		background: #fff;
		.btn-flash {
			position: absolute;
			right: 22px;
			top: 7px;
			z-index: 2;
		}
		.item-panel {
			position: relative;

			.tab-content {
				padding: 0 20px;
				position: relative;
			}

			&:first-child {
				.row-title {
					border-top: none;
				}
			}
			.row-title {
				height: 50px;
				padding: 0 30px;
				overflow: hidden;
				border-top: 1px solid rgb(240, 240, 246);

				.title {
					font-size: 14px;
					line-height: 50px;
					color: #03cc75;
					font-weight: bold;
				}
			}

			.row-content {
				overflow: hidden;
				background: #fbfbfc;
				.row-item {
					width: 50%;
					float: left;
					height: 40px;
					padding-left: 30px;
					line-height: 40px;
					border-top: 1px solid rgb(240, 240, 246);

					&:nth-child(2n) {
						border-left: 1px solid rgb(240, 240, 246);
					}

					.title {
						font-weight: bold;
						font-size: 12px;
						color: #a9a9b1;
						float: left;
						width: 155px;
					}

					.content {
						font-size: 12px;
						color: #4d4d4d;
						font-weight: bold;
						float: left;
					}
					.action {
						float: right;
						width: 50px;
						height: 50px;
					}
				}
			}
		}
		.btns {
			padding: 0 30px;
		}
		.device-info-panel {
			padding: 30px;
			.title-row {
				border-bottom: 2px solid #ecf0f5;
				.title-text {
					font-size: 14px;
					color: #34383e;
					font-weight: 500;
					padding-bottom: 10px;
					border-bottom: 2px solid #08b76b;
					display: inline-block;
				}
			}
			.info-panel-warp {
				border: 1px solid #dce2e8;
				border-bottom: none;
				.info-row {
					border-bottom: 1px solid #dce2e8;
					font-size: 0;
					.info-item {
						width: 50%;
						display: inline-block;
						border-right: 1px solid #dce2e8;
						padding: 0 20px;
						vertical-align: middle;
						& + .info-item {
							border-right: none;
						}
						.info-item-title {
							display: inline-block;
							width: 100px;
							font-size: 12px;
							color: #8e949c;
							height: 44px;
							line-height: 44px;
							vertical-align: middle;
						}
						.info-item-value {
							display: inline-block;
							font-size: 12px;
							color: #50555d;
							height: 44px;
							line-height: 44px;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
	.btn-row {
		padding: 15px 0;
	}
	.pb-40 {
		padding-bottom: 40px;
	}

	.bg-white {
		background: white;
	}

	.plug-list-table {
		margin-bottom: 20px;
	}
	.ml-20 {
		margin-left: 20px;
	}
	.button-wrapper {
		position: absolute;
		right: 40px;
		top: 8px;
		z-index: 10;
	}

	i.icon-distribute {
		display: inline-block;
		background: url('../../assets/img/icon/icon-distribute-1.png') no-repeat center;
		background-size: 12px 12px;
		width: 12px;
		height: 12px;
		vertical-align: middle;
	}

	.mg-15 {
		margin: 15px;
	}
	.btn-a {
		color: #0000ff;
		cursor: pointer;
	}
}
</style>

