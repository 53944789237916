<template>
    <div class="device-detail-container">
        <div class="device-detail flex-container">
            <div class="device-info">
                <div class="device-info-content">
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">车位号：</div>
                            <div class="content">{{device.park_no}}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">安装地址：</div>
                            <div class="content">{{device.address }}</div>
                        </div>
                    </div>
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">设备ID：</div>
                            <div class="content">{{device.uid}}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">接入类型：</div>
                            <div class="content">{{device.space | spaceType}}</div>
                        </div>
                    </div>
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">设备空间：</div>
                            <div class="content">{{space}}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">是否在线：</div>
                            <div class="content">
                                {{device['status_text'] }}
                            </div>
                        </div>
                    </div>
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">创建时间：</div>
                            <div class="content">{{device["create_time"] | timeFormat}}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">最后活动时间：</div>
                            <div class="content">{{device.last_active_time | timeFormat}}</div>
                        </div>
                    </div>
                    <div class="row-content">
                        <div class="row-item">
                            <div class="title">所属分组：</div>
                            <div class="content">{{device.device && device.device.group_name || "-" }}</div>
                        </div>
                        <div class="row-item">
                            <div class="title">所属用户：</div>
                            <div class="content">{{device.device && device.device.user_name || "-" }}</div>
                        </div>
                    </div>
                </div>
                <div class="clear-20"></div>
                <el-row>
                    <el-button type="primary" @click="handleDistribute(device)">
                        <i class="icon-distribute"></i>
                        分配
                    </el-button>
                    <el-button type="primary" icon="el-icon-edit" @click="handleEditInfo(device)">编辑</el-button>
                    <el-button class="pull-right" icon="el-icon-delete" @click="handleDelete(device)">删除
                    </el-button>
                </el-row>
            </div>
            <div class="clear-20"></div>

            <el-row class="item-panel panel-list" :span="10">
                <div class="button-wrapper" v-if="activeName === 'second' || activeName === 'third'">
                    <el-button @click="refresh" type="success">
                        刷新
                    </el-button>
                </div>
                <el-tabs v-model="activeName"  class="tab-content" type="card">
                    <el-tab-pane label="基本信息" name="first">
                        <div class="device-info-panel">
                            <div class="title-row">
                                <label class="title-text">基本信息</label>
                            </div>
                            <div class="clear-20"></div>

                            <div class="info-panel-warp">
                                <device-prop :propList="basePropList"></device-prop>
                            </div>

                        </div>
                        <div class="device-info-panel">
                            <div class="title-row">
                                <label class="title-text">推送信息</label>
                            </div>
                            <div class="clear-20"></div>
                            <relay-config :uid="uid" :space="space"></relay-config>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="帧信息" name="second">
                        <div class="device-frame-list-table">
                            <!--<el-radio-group v-model="frameType" class="btns">-->
                                <!--<el-radio-button label="全部"></el-radio-button>-->
                                <!--<el-radio-button label="数据帧"></el-radio-button>-->
                                <!--<el-radio-button label="心跳帧"></el-radio-button>-->
                            <!--</el-radio-group>-->

                            <el-table :data="frameList" v-loading="loading.frame">
                                <el-table-column label=" 上报时间" width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.time | timeFormat}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="类型" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.frame | p20009FrameType}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="地磁" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.magnet_state | parkStateType}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="锁状态" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.lock_state | lockStateType}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="x轴" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.x !== undefined ? scope.row.frame.x : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="y轴" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.y !== undefined ? scope.row.frame.y : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="z轴" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.z !== undefined ? scope.row.frame.z : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="电压" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.voltage !== undefined ? scope.row.frame.voltage : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="温度" width="80">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.temperature !== undefined ? scope.row.frame.temperature : '-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="报警事件" width="100">
                                    <template slot-scope="scope">
                                        {{scope.row.frame.warn !== undefined ? scope.row.frame.warn : '-'}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="原始数据">
                                    <template slot-scope="scope">
                                        <a class="link" @click="showFrameDialog(scope.row.frame)">
                                            {{ scope.row.data }}</a>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="flex-footer">
                            <div class="flex-footer-content">
                                <el-row>
                                    <el-col :span="24" class="text-center" v-if="total > 0">
                                        <el-pagination
                                                @current-change="pageChange"
                                                :current-page="currentPage"
                                                :page-size="pageSize"
                                                layout="total, prev, pager, next, jumper"
                                                :total="total">
                                        </el-pagination>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane label="推送记录" name="third">
                        <relay-log :uid="uid"></relay-log>
                    </el-tab-pane>

                    <el-tab-pane label="操作" name="fourth">
                        <div class="mg-15">
                            <el-button type="success" @click="handleRelay">模拟数据上报</el-button>
                            <el-row style="margin: 15px 0">
                                <el-button type="info" @click="writeResOpen">升锁</el-button>
                                <el-button type="info" @click="writeResClose">降锁</el-button>
                                <el-button type="info" @click="writeResCalibrate">校准</el-button>
                                <el-button type="info" @click="handleWriteRes">写资源</el-button>
                            </el-row>
                        </div>
                    </el-tab-pane>


                    <el-tab-pane label="告警列表" name="alarm-list">
                        <div>
                            <el-table :data="alarmList" border max-height="500" v-loading="loading.alarm">
                                <el-table-column label="告警类型" min-width="80">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.type |  alarmType}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="告警内容">
                                    <template slot-scope="scope">
                                        {{scope.row.message}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="data" label="数据"  min-width="200"></el-table-column>  
                                <el-table-column label="发生时间" width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.create_time | timeFormat}}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>

                        <div class="flex-footer">
                            <div class="flex-footer-content">
                                <el-row>
                                    <el-col :span="24" class="text-center" v-if="alarmListTotal > 0">
                                        <el-pagination
                                                @current-change="alarmPageChange"
                                                :current-page="alarmListCurrentPage"
                                                :page-size="10"
                                                layout="total, prev, pager, next, jumper"
                                                :total="alarmListTotal">
                                        </el-pagination>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="远程调试" name="remote-debug-detector">
                        <remote-debug-detector :device-id='device.uid' :last-active-time='device.last_active_time'></remote-debug-detector>
                    </el-tab-pane>
                    <el-tab-pane label="地图" name="map" v-if="device && device.latitude && device.longitude">
                        <Map :device="device" />
                    </el-tab-pane>
                </el-tabs>
            </el-row>

        </div>

        <el-dialog title="帧数据" :visible.sync="dialogVisible" custom-class="json-dialog" width="600px">
            <div class="content">
                <div class="pre-warp">
                    <pre>{{frame}}</pre>
                </div>
            </div>
        </el-dialog>

        <!--推送数据弹窗-->
        <el-dialog title="推送数据" :visible.sync="jsonDataDialogVisible" custom-class="json-dialog" width="600px">
            <div class="content">
                <div class="pre-warp">
                    <pre>{{jsonData}}</pre>
                </div>
            </div>
        </el-dialog>

        <!--模拟数据上报弹窗-->
        <el-dialog title="模拟数据上报" custom-class="edit-dialog" :visible.sync="relayModalVisible" @close="relayDialogCloseCallback">
            <el-form :label-position="'left'" ref="relayForm" label-width="110px" :model="relayForm">
                <el-form-item label="车位状态">
                    <el-radio-group v-model="relayForm.state">
                        <el-radio :label="0">无车</el-radio>
                        <el-radio :label="1">有车</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="relayDeviceData('relayForm')">发送</el-button>
            </div>
        </el-dialog>

        <!--数据上报结果弹窗-->
        <el-dialog title="推送结果" with="70%" :visible.sync="responseDialogVisible">
            <div v-for="(item, key) in relayResponse" class="pt-15" :key="key">
                <div  v-if="key === 'result' ||key === 'endpoint' ">
                    <p style="font-size: 14px" v-if="key === 'result'">
                        推送结果：
                    </p>
                    <p style="font-size: 14px" v-if="key === 'endpoint'">
                        推送地址：
                    </p>
                </div>
                <div v-else>
                    <p style="font-size: 14px" >
                        {{key}}
                    </p>
                </div>

                <div style="max-height: 400px; overflow: auto">
                    <label>{{item}}</label>
                </div>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="responseDialogVisible = false">确认</el-button>
            </div>
        </el-dialog>

        <!--编辑弹窗-->
        <el-dialog title="更新基本信息" custom-class="edit-dialog"
                   :visible.sync="editModalVisible" @close="editDialogCloseCallback">
            <el-form :label-position="'left'" label-width="110px" ref="deviceForm" :model="deviceForm">
                <el-form-item label="设备ID:" prop="park_no">
                    <span>{{deviceForm.uid}}</span>
                </el-form-item>
                <el-form-item label="车位号:" prop="park_no">
                    <el-input placeholder="请填写车位号" v-model="deviceForm.park_no"></el-input>
                </el-form-item>
                <el-form-item label="安装地址:" prop="address">
                    <el-input placeholder="请填写安装地址" v-model="deviceForm.address"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="editDevice()">确认</el-button>
            </div>
        </el-dialog>

        <!--分配弹窗-->
        <el-dialog title="更新到指定账户" custom-class="edit-dialog" :visible.sync="distributeModalVisible">
            <el-form :label-position="'left'" label-width="110px" ref="userForm" :model="userForm">
                <el-form-item label="当前用户名:" prop="current_user_name">
                    {{userForm.current_user_name}}
                </el-form-item>
                <el-form-item label="当前分组:" prop="current_group_name">
                    {{userForm.current_group_name}}
                </el-form-item>
                <el-form-item label="目标用户名" prop="target_user_id">
                    <el-select v-model="userForm.target_user_id" filterable default-first-option clearable
                               class="form-control" placeholder="请选择">
                        <el-option
                                v-for="item in userList"
                                :key="item.id"
                                :label="item.username"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="目标分组">
                    <el-select v-model="userForm.target_group_id" filterable default-first-option clearable
                               class="form-control" placeholder="请选择">
                        <el-option
                                v-for="item in userGroupList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="distributeDevice('userForm')">确认分配</el-button>
            </div>
        </el-dialog>

        <!--删除弹窗-->
        <el-dialog title="删除设备" custom-class="edit-dialog" :visible.sync="deleteModalVisible">
            <p style="padding: 20px 0;">提示：必须填入密码才能删除该设备</p>
            <el-form :label-position="'left'" label-width="110px" ref="deleteForm" :model="deleteForm">
                <el-form-item label="密码:" prop="password" :rules="[{ required: true, message: '密码不能为空'}]">
                    <el-input type="password" placeholder="请填写密码" v-model="deleteForm.password"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="deleteDevice('deleteForm')">确认</el-button>
            </div>
        </el-dialog>

        <!--写资源-->
        <el-dialog title="写资源" custom-class="edit-dialog" :visible.sync="writeResDialogVisible" width="400px">
            <el-form v-loading="writeResDialogLoading" :label-position="'left'" ref="relayForm" label-width="80px" :model="writeResForm">
                <el-form-item label="obj_id"><el-input v-model="writeResForm.objId" /></el-form-item>
                <el-form-item label="obj_inst_id"><el-input v-model="writeResForm.objInstId" /></el-form-item>
                <el-form-item label="res_id"><el-input v-model="writeResForm.resId" /></el-form-item>
                <el-form-item label="res_val"><el-input v-model="writeResForm.resValue" /></el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="writeRes()">执行</el-button>
            </div>
        </el-dialog>



    </div>
</template>

<script type="text/ecmascript-6">

    import Core from '@/core'
    import deleteDialog from '@/components/delete-dialog'
    import spanBtn from '@/components/span-btn'
    import relayConfig from '@/components/relay-config'
    import relayLog from '@/components/relay-log'
    import deviceProp from '@/components/device-prop'

    export default {
        name: "device-detail-20009",
        components: {deleteDialog, spanBtn, relayConfig, relayLog, deviceProp,
            remoteDebugDetector: () => import('@/components/remote-debug-detector')
        },
        data() {
            return {
                device: {},
                client: {},

                space: '20014',

                Core: Core,
                activeName: 'first',
                id: '',
                uid: '',
                dialogVisible: false,
                frame: '',

                basePropList: [],

                // 分页配置
                total: 0,
                currentPage: 1,
                pageSize: 10,
                frameList: [],
                logList: [],

                frameType: '全部',

                loading: {
                    frame: false,
                    alarm: false,
                },

                // 推送记录
                jsonDataDialogVisible: false,
                jsonData: '',

                // 模拟推送
                relayModalVisible: false,
                relayForm: {
                    state: 0
                },
                relayResponse: {},
                responseDialogVisible: false,

                // 基本信息
                editModalVisible: false,
                deviceForm: {
                    id: '',
                    uid: '',
                    park_no: '',
                    address: '',
                },
                // 删除
                deleteModalVisible: false,
                deleteForm: {
                    id: '',
                    password: '',
                },
                // 分配
                distributeModalVisible: false,
                userForm: {
                    device_id: '',
                    current_user_name: '',
                    current_group_name: '',
                    target_user_id: '',
                    target_group_id: ''
                },


                userList: [],
                userGroupList: [],


                // 写资源
                writeResDialogVisible: false,
                writeResDialogLoading: false,
                writeResForm: {
                    objId: '3200',
                    objInstId: '0',
                    resId: '5504',
                    resValue: '1'
                },


                canOperate: true,


                // 告警列表
                alarmList: [],
                alarmListTotal: 0,
                alarmListCurrentPage: 1,
            }
        },
        watch: {
            activeName: function (val) {
                this.getTabInfo(val);
            },
            'userForm.target_user_id': 'getUserGroupList',

        },
        mounted: function () {
            this.uid = this.$route.query["uid"];
            this.getDeviceDetail(this.uid);
            // this.getClientDetail();
            this.getUserList();
            this.getTabInfo(this.activeName);
        },
        methods: {
            async getDeviceDetail(uid) {
                let res = await Core.Api.Device20014.getDeviceDetail(uid);
                let device = res['device'];
                device['status_text'] = Core.Util.isOff(device.last_active_time);
                device['cart_status'] = Core.Util.parkStateType(device.p1);
                this.id = device.id;
                this.device = device;
                this.prepareBasePropList(device);
            },

            prepareBasePropList(device) {
                let propList = [];

                let pushProp = function (key, name) {
                    if (!device.hasOwnProperty(key)) {
                        return
                    }

                    propList.push({
                        name: name,
                        value: device[key]
                    })
                };

                pushProp('imei', 'IMEI');
                pushProp('imsi', 'IMSI');
                pushProp('iccid', 'ICCID');
                pushProp('nccid', 'NCCID');
                pushProp('address', '安装地址');
                pushProp('park_no', '车位标号');
                pushProp('firmware_version', '固件版本');
                pushProp('hardware_version', '硬件版本');
                pushProp('bluetooth_version', '蓝牙版本');
                pushProp('sensitivity', '灵敏度');
                pushProp('track_ratio', '跟踪系数');
                pushProp('anti_interference_type', '抗干扰类型');
                pushProp('heartbeat_interval', '心跳周期');
                pushProp('nb_conn_station_id', 'NB频点');

                // console.log('[basePropList]', propList);
                this.basePropList = propList;
            },

            async getClientDetail() {
                let res =  await Core.Api.Frame.getClientDetail(this.space, this.uid);
                this.client = res.device;
                console.log(this.client)
            },


            async getClientFrameList() {
                try {
                    this.loading.frame = true;
                    let res = await Core.Api.Frame.getFrameListForSpace(this.uid, this.space, "", this.currentPage);
                    this.frameList = res.frame_list;
                    this.total = res.count;
                } catch (err) {
                    console.log("getClientFrameList  err:", err)
                } finally {
                    this.loading.frame = false;
                }
               
            },

            // 推送记录
            async getRelayList() {
                let res = await Core.Api.RelayLog.getLogList(this.currentPage, this.uid);
                this.logList = res.log_list;
                this.total = res.count;

            },

            pageChange(val) {
                this.currentPage = val;
                this.getTabInfo(this.activeName, val);
            },



            getTabInfo(type, page = 1) {
                this.currentPage = page;
                switch (type) {
                    case 'first':
                        break;
                    case 'second':
                        this.getClientFrameList();
                        break;
                    case 'third':
                        this.getRelayList();
                        break;
                    case 'alarm-list':
                        this.alarmListCurrentPage = 1;
                        this.getAlarmList()
                        break    
                    default:
                        break;
                }
            },

            async getAlarmList() {
                try {
                    this.loading.alarm = true;
                    let res = await Core.Api.Alarm.alarmList(
                        this.alarmListCurrentPage,
                        '',
                        '',
                        this.uid,
                        this.space
                    )
                    this.alarmList = res.alarm_list
                    this.alarmListTotal = res.count
                } catch (err) {
                    console.log("getAlarmList  err:", err)
                } finally {
                    this.loading.alarm = false;
                }
            },

            alarmPageChange(val) {
                this.alarmListCurrentPage = val 
                this.getAlarmList()
            },

            refresh() {
                this.currentPage = 1;
                switch (this.activeName) {
                    case "second": {
                        this.frameList = [];
                        this.getClientFrameList(this.currentPage);
                    }
                        break;
                    case "third": {
                        this.logList = [];
                        this.getRelayList(this.currentPage);

                    }
                        break;
                }
            },

            showFrameDialog(frame) {
                this.dialogVisible = true;
                if (frame.data) {
                    frame.data = JSON.parse(frame.data);
                }
                this.frame = frame;
            },

            showDataDialog(data) {
                this.jsonDataDialogVisible = true;
                this.jsonData = data
            },

            handleRelay() {
                this.relayModalVisible = true;
            },

            relayDialogCloseCallback() {
                this.relayForm = {
                    state: 0
                };
            },

            relayDeviceData(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (!valid)
                    {
                        return false;
                    }

                    let data = {
                        space: this.space,
                        device_id: this.uid,
                        state: this.relayForm.state
                    };

                    try {
                        let res = await Core.Api.Fegoit.relay(data);

                        this.$message.success("发送成功");
                        this.getClientFrameList(this.dataActiveName, 1);
                        this.relayModalVisible = false;
                        this.relayResponse = "";
                        if (res.result_list && res.result_list.length > 0) {
                            this.relayResponse = res.result_list[0];

                            this.$nextTick(() => {
                                this.responseDialogVisible = true;
                            })
                        }
                    } catch (err) {
                        this.$message.error(err.message);
                    }
                });
            },

            //distribute
            handleDistribute(row) {
                let currentUserName = row.device && row.device.user_name || '-';
                let currentGroupName = row.device && row.device.group_name || '-';
                this.userForm = {
                    uid: row.uid,
                    current_user_name: currentUserName,
                    current_group_name: currentGroupName,
                    target_user_id: '',
                    target_group_id: ''
                };
                this.distributeModalVisible = true
            },
            async distributeDevice() {
                try {
                    let form = this.userForm;
                    await Core.Api.Device20014.saveTargetUser(form.uid, form.target_user_id, form.target_group_id)
                    this.getDeviceDetail(this.uid);
                    this.distributeModalVisible = false
                }
                catch (e) {
                    console.log(e)
                }
            },

            //edit dialog
            handleEditInfo(row) {
                this.deviceForm = {
                    id: row.id,
                    uid: row.uid,
                    address: row.address,
                    park_no: row.park_no,
                };
                this.editModalVisible = true;
            },
            editDialogCloseCallback() {
                this.deviceForm = {
                    id: '',
                    uid: '',
                    address: '',
                    park_no: '',
                };
            },
            async editDevice() {
                try {
                    await Core.Api.Device20014.updateBaseInfo(
                        this.deviceForm.id,
                        this.deviceForm.park_no,
                        this.deviceForm.address
                    );
                    this.editModalVisible = false;
                    this.getDeviceDetail(this.uid);
                } catch (e) {
                    console.log(e)
                }
            },

            //delete
            handleDelete(row) {
                this.deleteForm.id = row.id;
                this.deleteForm.password = "";
                this.deleteModalVisible = true;
            },
            deleteDevice() {
                let password = this.deleteForm.password.trim();
                if (password !== Core.Const.NET.DEVICE_DELETE_PASSWORD) {
                    this.$message({
                        type: 'error',
                        message: '密码错误, 请重试!'
                    });
                    return;
                }
                Core.Api.Device20014.deleteDevice(this.deleteForm.id)
                    .then(() => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });

                        this.getDeviceList();
                        this.deleteModalVisible = false;
                    })
                    .catch(() => {
                    });
            },

            getUserList() {
                Core.Api.User.allList()
                    .then(res => {
                        this.userList = res.user_list;
                    })
                    .catch(err => {
                        this.$message.error(err.message);
                    });
            },
            getGroupList() {
                Core.Api.Group.listForAdmin(this.searchForm.user_id, 0)
                    .then(res => {
                        this.groupList = res.group_list;
                    })
                    .catch(err => {
                        this.$message.error(err.message);
                    });
            },
            getUserGroupList() {
                Core.Api.Group.listForAdmin(this.userForm.target_user_id, 0)
                    .then(res => {
                        this.userGroupList = res.group_list;
                    })
                    .catch(err => {
                        this.$message.error(err.message);
                    });
            },


            // 写资源
            handleWriteRes() {
                this.writeResDialogVisible = true;
            },
            writeRes() {
                this.writeResDialogLoading = true;
                Core.Api.Fengine20014.writeRes(this.id, this.writeResForm.objId, this.writeResForm.objInstId, this.writeResForm.resId, this.writeResForm.resValue).then(res => {
                    this.$message.success("执行成功");
                }).catch(err => {
                    console.log(err);
                    // this.$message.success("执行失败");
                }).then(() => {
                    this.writeResDialogLoading = false;
                    this.writeResDialogVisible = false;
                });
            },
            writeResOpen() {
                Core.Api.Fengine20014.writeRes(this.id, '3200', '0', '5504', '1').then(res => {
                    this.$message.success("执行升锁成功");
                }).catch(err => {
                    console.log(err);
                });
            },
            writeResClose() {
                Core.Api.Fengine20014.writeRes(this.id, '3200', '0', '5504', '2').then(res => {
                    this.$message.success("执行降锁成功");
                }).catch(err => {
                    console.log(err);
                });
            },
            writeResCalibrate() {
                Core.Api.Fengine20014.writeRes(this.id, '3200', '0', '5504', '3').then(res => {
                    this.$message.success("执行校准成功");
                }).catch(err => {
                    console.log(err);
                });
            },



        }
    }
</script>

<style scoped lang="scss">
    .device-detail-container {
        height: 100%;
        .device-detail {
            height: 100%;
            .device-info {
                background: #ffffff;
                padding: 20px;
                .device-info-content {
                    border: 1px solid #EAEDF0;
                    background: #F5F7F9;
                    padding: 6px 20px;
                    border-radius: 4px;
                    .row-content {
                        font-size: 0;
                        margin: 14px 0;
                        .row-item {
                            display: inline-block;
                            width: 50%;
                            .title {
                                font-size: 12px;
                                color: #8E949C;
                                min-width: 120px;
                                display: inline-block;
                            }
                            .content {
                                font-size: 12px;
                                color: #50555D;
                                display: inline-block;
                            }
                        }
                    }
                }
            }

            .device-frame-list-table {
                box-flex: 1;
                -webkit-box-flex: 1;
                -moz-box-flex: 1;
                flex: 1;
                -webkit-flex: 1;
                overflow: auto;
                .btns {
                    margin: 0 15px 15px;
                }
            }
        }

        .panel-list {
            margin: 0 20px;
            position: relative;
            background: #fff;
            .btn-flash {
                position: absolute;
                right: 22px;
                top: 7px;
                z-index: 2;
            }
            .item-panel {
                position: relative;

                .tab-content {
                    padding: 0 20px;
                    position: relative;
                }

                &:first-child {
                    .row-title {
                        border-top: none;
                    }
                }
                .row-title {
                    height: 50px;
                    padding: 0 30px;
                    overflow: hidden;
                    border-top: 1px solid rgb(240, 240, 246);

                    .title {
                        font-size: 14px;
                        line-height: 50px;
                        color: #03CC75;
                        font-weight: bold;
                    }
                }

                .row-content {
                    overflow: hidden;
                    background: #FBFBFC;
                    .row-item {
                        width: 50%;
                        float: left;
                        height: 40px;
                        padding-left: 30px;
                        line-height: 40px;
                        border-top: 1px solid rgb(240, 240, 246);

                        &:nth-child(2n) {
                            border-left: 1px solid rgb(240, 240, 246);
                        }

                        .title {
                            font-weight: bold;
                            font-size: 12px;
                            color: #A9A9B1;
                            float: left;
                            width: 155px;
                        }

                        .content {
                            font-size: 12px;
                            color: #4D4D4D;
                            font-weight: bold;
                            float: left;
                        }
                        .action {
                            float: right;
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
            }
            .device-info-panel {
                padding: 30px;
                .title-row {
                    border-bottom: 2px solid #ECF0F5;
                    .title-text {
                        font-size: 14px;
                        color: #34383E;
                        font-weight: 500;
                        padding-bottom: 10px;
                        border-bottom: 2px solid #08B76B;
                        display: inline-block;
                    }
                }
                .info-panel-warp {
                    border: 1px solid #DCE2E8;
                    border-bottom: none;
                    .info-row {
                        border-bottom: 1px solid #DCE2E8;
                        font-size: 0;
                        .info-item {
                            width: 50%;
                            display: inline-block;
                            border-right: 1px solid #DCE2E8;
                            padding: 0 20px;
                            vertical-align: middle;
                            & + .info-item {
                                border-right: none;
                            }
                            .info-item-title {
                                display: inline-block;
                                width: 100px;
                                font-size: 12px;
                                color: #8E949C;
                                height: 44px;
                                line-height: 44px;
                                vertical-align: middle;

                            }
                            .info-item-value {
                                display: inline-block;
                                font-size: 12px;
                                color: #50555D;
                                height: 44px;
                                line-height: 44px;
                                vertical-align: middle;
                            }
                        }
                    }
                }

            }
        }
        .btn-row {
            padding: 15px 0;
        }
        .pb-40 {
            padding-bottom: 40px;
        }

        .bg-white {
            background: white;
        }

        .plug-list-table {
            margin-bottom: 20px;
        }
        .ml-20 {
            margin-left: 20px;
        }
        .button-wrapper {
            position: absolute;
            right: 40px;
            top: 8px;
            z-index: 10;
        }

        i.icon-distribute {
            display: inline-block;
            background: url("../../assets/img/icon/icon-distribute-1.png") no-repeat center;
            background-size: 12px 12px;
            width: 12px;
            height: 12px;
            vertical-align: middle;
        }

        .mg-15 {
            margin: 15px;
        }

    }
</style>

