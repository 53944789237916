import ajax from './http';
import qs from 'qs'
import Core from '../../index';
import Const from '../const'
import * as Util from '../util'

class ApiBase {
	constructor(baseUrl) {
		this.baseUrl = baseUrl;
	}

	static stringify(obj) {
		// transformObjectToUrlencodedData
		let p = [];
		for (let key in obj) {
			if (obj[key] == null || obj[key] == undefined) {
				p.push(`${encodeURIComponent(key)}=`);
			} else {
				p.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
			}
		}
		return p.join('&');
	}

	/**
	 * @param {Array} config [接口名, 参数1, 参数2, ...]
	 * @param {String} moduleName 模块名称
	 * @param {String} functionName 方法名称
	 * @param {Any} args
	 */
	post(config, moduleName, functionName, ...args) {
		const Tool = ['Tool'];   // tool
		const Fegoit = ['Fegoit'];  // fegoit
		const Route = ['route'];  // 下发参数
		const Oc = ['Oc'];  // oc

		let fullUrl = '';
		let form = Object.create(null);
		let apiName = config[0];
		let params = config.slice(1);
		let token = Core.Data.getToken()

		// 获取api路径
		let apiRoute = Util.getApiRoute();
		
		if (Route.includes(functionName)) {
			// 与设备连接 远程调试 url上添加token
			fullUrl = `${this.baseUrl}/${apiRoute}/${apiName}?token=${token}`;
		} else if (Tool.includes(moduleName)) {
			// url不同  TOOL_URL_POINT
			fullUrl = `${Const.NET.TOOL_URL_POINT}/${apiName}`;
			form.token = token;
			console.log("post tool fullUrl:", fullUrl)
		} else if (Fegoit.includes(moduleName)) {
			// url不同  END_FEGOIT_POINT
			fullUrl = `${Const.NET.END_FEGOIT_POINT}/${apiName}`;
			form.token = token;
			console.log("post fegoit fullUrl:", fullUrl)
		} else if (Oc.includes(moduleName)) {
			// url不同  API_URL_POINT
			fullUrl = `${Const.NET.API_URL_POINT}/${apiName}`;
			form.token = token;
			console.log("post tool fullUrl:", fullUrl)
		} else {
			// 正常请求
			
			fullUrl = `${this.baseUrl}/${apiRoute}/${apiName}`;
			form.token = token;
		}

		if (args.length === 1 && args[0] !== undefined && args[0] !== null && Object.getPrototypeOf(args[0]) === Object.prototype) {
			// 仅有一个参数 且该参数为对象 -> 新的传参方式 直接合并到 form上
			Object.assign(form, args[0]);
			for (const key in form) {
				if (form[key] == null || form[key] == undefined) {
					form[key] = '';
				}
			}
		} else {
			// 传统传参方式 -> 通过循环config[1,len] 将参数设置到 form上
			for (let i = 0; i < params.length; i++) {
				const param = args[i] == null || args[i] == undefined ? '' : args[i];
				form[params[i]] = param;
			}
		}
		let data = qs.stringify(Util.clone(form), {arrayFormat: 'repeat', allowDots: true})

		// Content-Type
		let contentType = {
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
		}
		
		if (Route.includes(functionName)) {
			// Content-Type 不同
			contentType = {
				'Content-Type': 'application/json'
			}
			data = form
		} 

		console.log(`%c ${apiName} => ${fullUrl}?${ApiBase.stringify(form)} ${contentType}`, 'color:#006CFF;background:#006cff2a;');
		// return ajax.post(fullUrl, qs.stringify(form), contentType);
		return ajax.post(fullUrl, data, contentType);
	}
}

class Api extends ApiBase {
	constructor(baseUrl, apiList) {
		super(baseUrl);
		for (const moduleName in apiList) {
			this[moduleName] = Object.create(null);
			const module = apiList[moduleName];
			for (const functionName in module) {
				const config = module[functionName];
				this[moduleName][functionName] = ((config) => {
					return (...args) => {
						return super.post(config, moduleName, functionName, ...args);
					};
				})(config);
			}
		}
	}
}

export default Api;
