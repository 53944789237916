import * as Util from "../js/util";
import Const from "../js/const";
import Locutus from "../js/locutus";

export function timeFormat(timestamp, format) {
    if (!timestamp) {
        return '-';
    }

    if (!format) {
        format = 'Y-m-d H:i:s';
    }
    return Locutus.date(format, timestamp);
}


export function alarmType(val, to = 'label') {
    const MAP = Const.ALARM.TYPE_MAP;
    const ITEM = MAP[val + ''] || {};
    return ITEM[to] || '-';
}

export function alarmStatus(val) {
	switch (val) {
		case Const.ALARM_STATUS_LIST.STATUS_END:
			return "结束"
		case Const.ALARM_STATUS_LIST.STATUS_ING:
			return "告警中"
		default:
			break;
	}
}
export function digitLength(data, length) {
    return Locutus.sprintf('%0' + length + 'd', data);
}

export function currencyFormat(money, type) {
    money = parseInt(money);
    if (money % 100 === 0 && !type) {
        return money / 100;
    }
    return Locutus.sprintf('%.2f', parseInt(money) / 100);
}
