<template>
    <div class="bkv-selector">
        <div class="clear-10"></div>
        <el-checkbox-group v-model="itemList">
            <el-checkbox v-for="(item, index) of keyList" :key="index" :label="item.key" @change="updateKV(item)">{{ item | keyName }} {{item.kv | kv}}</el-checkbox>
        </el-checkbox-group>
        <div class="clear-10"></div>
        <div class="bkv" v-if="itemList.length > 0">BKV: {{ bkv }}</div>
    </div>
</template>

<script>
    import BKV from '@/core/js/bkv'

    export default {
        name: "bkv-editor",
        props: {
            keyList: {
                type: Array,
                default: function () {
                    return [];
                }
            },
        },
        data() {
            return {
                itemList: [],
                bkv: '',
            }
        },
        filters: {
            keyName: function(itemKey) {
                 return '0x' + itemKey.key.toString(16).toUpperCase() + ' ' + itemKey.name;
            },
            kv: function (value) {
                if (!value) {
                    return '';
                }

                return '' + value;
            }
        },
        methods: {
            getKeyDef(key) {
                key = parseInt(key);
                for (let i in this.keyList) {
                    let keyItem = this.keyList[i];
                    if (keyItem.key === key) {
                        return keyItem;
                    }
                }
            },

            updateKV(keyItem) {
                console.log(this.itemList, keyItem, this.itemList.includes(keyItem.key));

                if (!this.itemList.includes(keyItem.key)) {
                    keyItem.kv = '';
                    console.log("clear", keyItem.key, )
                } else {
                    keyItem.kv = BKV.bufferToHex(new BKV.KV(keyItem.key, new Uint8Array(0)).pack()).toUpperCase();
                }

                let bkv = new BKV.BKV();
                for (let i in this.itemList) {
                    let key = this.itemList[i];
                    let keyDef = this.getKeyDef(key);
                    if (!keyDef) {
                        continue
                    }

                    bkv.add(new BKV.KV(key, new Uint8Array(0)));
                }

                this.bkv = BKV.bufferToHex(bkv.pack())
            },

            getBKV() {
                return this.bkv;
            }
        }
    }
</script>

<style scoped lang="scss">
    .bkv-selector {
        /*padding: 20px;*/

        * {
            font-family: Consolas, "Courier New", monospace;
            font-size: 14px;
            color: #555;
        }

        label.el-checkbox {
            display: block;
            margin: 0;
            padding: 3px 0;
        }

        .bkv {
            word-break: break-all;
        }
    }

</style>
