<template>
  <div class="edit-label-container">
    <input v-if="isEdit" v-model="childLabelValue" @blur="handleBlurInput" ref="input" @input="onInput(childLabelValue)" class="input-style"/>
    <label v-else v-text="content" @click="clickLabel" class="label-style"> </label>
  </div>

</template>
<script>
  export default {
    name: 'editLabel',
    props: {
      content: '',
      contentKey: '',
    },
      data: function ()
      {
        return {
            isEdit: 0,
            childLabelValue: this.content,
        }
     },
      watch: {
          isEdit: function (val) {
              if(val) {
                  this.$nextTick(() => {
                      this.$refs['input'].focus()
                  });
              }
          },

      },
      methods: {
          clickLabel(){
              this.isEdit = true;
              this.childLabelValue = this.content;
          },

          handleBlurInput() {
              this.isEdit = false;
              if (this.childLabelValue.toString !== this.content.toString) {
                  this.$emit('change', this.childLabelValue, this.contentKey);
              }
          },

          onInput(val) {
              this.$emit('update:content', val)
          }
      }

  }
</script>
<style scoped lang="scss">
  .edit-label-container {
    display: inline-block;
    .label-style {
      color: blue;
      cursor: pointer;
      min-width: 20px;
      display: inline-block;
    }
    .input-style {
      width: 100%;
    }
  }
</style>
