<template>
    <div class="frame-export-task-list">
        <div class="mg-15">
            <el-row>
                <el-col :span="18">
                    <el-date-picker
                        class="mr-10"
                        v-model="exportTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <el-button type="primary" @click="handleAllExport" class="ml-10">提交下载任务</el-button>
                    <el-button type="info" @click="handleExport" class="ml-10">提交下载任务-自定义</el-button>
                </el-col>
                <el-col :span="6">
                    <el-button class="pull-right" icon="el-icon-refresh" @click="getExportTaskList">刷新任务状态</el-button>
                </el-col>
            </el-row>
            <div class="clear-10"></div>
            <el-row>
                <el-table :data="taskList" border max-height="500" v-loading="loading">
                    <el-table-column label="ID" width="180">
                        <template slot-scope="scope">
                            {{ scope.row.id }}
                        </template>
                    </el-table-column>
                    <el-table-column label="开始时间" width="180">
                        <template slot-scope="scope">
                            {{ scope.row.begin_time | timeFormat }}
                        </template>
                    </el-table-column>
                    <el-table-column label="结束时间" width="180">
                        <template slot-scope="scope">
                            {{ scope.row.end_time | timeFormat }}
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.status | statusFormat }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <!--http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/${task.file}-->
                            <a class="btn-a"
                               :href="'http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/' + scope.row.file"
                               v-if="scope.row.file && scope.row.status === 200">
                                下载
                            </a>
                            <!--<a class="btn-a" type="text info" @click="downloadExportFile(scope.row)">下载</a>-->
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            <div class="clear-10"></div>
            <el-row>
                <el-col :span="24" v-if="taskCount > 0">
                    <el-pagination
                        @current-change="exportListPageChange"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="taskCount">
                    </el-pagination>
                </el-col>
            </el-row>
            <el-dialog :visible="selectField" width="30%" title="选择字段">
                <el-form ref="form" label-width="100px" style="margin-top: 20px">
                    <el-form-item label="选择字段">
                        <el-select v-model="field" multiple filterable placeholder="请选择"
                                   style="width: 80%;background: white">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="selectField = false">取 消</el-button>
                <el-button type="primary" @click="handleConfirm">确 定</el-button>
            </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Core from '@/core'

export default {
    name: "relay-config",
    props: {
        space: String,
        uid: String,
    },
    data() {
        return {
            loading: true,

            // 数据导出
            exportTime: "",
            exportDialogVisible: false,
            taskList: [],
            taskCount: 0,
            currentPage: 1,
            exportInterval: '',
            selectField: false,
            options: [],
            field: [],
            fieldAll: ''
        }
    },
    mounted: function () {
        this.getExportTaskList();
        this.option()
    },
    watch: {
        uid: function (val) {
            this.refresh();
        }
    },

    filters: {
        statusFormat: function (value) {
            switch (value) {
                case 0:
                    return "已提交";
                case 100:
                    return "进行中";
                case 200:
                    return "成功";
                case -1:
                    return "失败"
            }

            return `未知[${value}]`
        }
    },

    methods: {
        async refresh() {
            this.loading = true;
            this.taskList = [];
            this.taskCount = 0;
            await this.getExportTaskList();
        },
        // 根据设备空间下载
        option() {
            switch (this.space) {
                case '20006':
                    return this.options = [
                        {value: 'receive_time', label: '上报时间'},
                        {value: 'event_time', label: '事件时间'},
                        {value: 'type', label: 'type'},
                        {value: 'sub_type', label: 'sub_type'},
                        {value: 'magnet_state', label: 'magnet_state'},
                        {value: 'frame_id', label: 'frame_id'},
                        {value: 'magnet_x', label: 'magnet_x'},
                        {value: 'magnet_y', label: 'magnet_y'},
                        {value: 'magnet_z', label: 'magnet_z'},
                        {value: 'x_base', label: 'x_base'},
                        {value: 'y_base', label: 'y_base'},
                        {value: 'z_base', label: 'z_base'},
                        {value: 'resend_count', label: 'resend_count'},
                        {value: 'sn', label: 'sn'},
                        {value: 'csq', label: 'csq'},
                        {value: 'snr', label: 'snr'},
                        {value: 'temperature', label: 'temperature'},
                        {value: 'battery_voltage', label: 'battery_voltage'},
                        {value: 'conn_signal_strength', label: 'conn_signal_strength'},
                        {value: 'conn_signal_ecl', label: 'conn_signal_ecl'},
                        {value: 'conn_signal_cell_id', label: 'conn_signal_cell_id'},
                        {value: 'conn_signal_pci', label: 'conn_signal_pci'},
                        {value: 'conn_signal_ear', label: 'conn_signal_ear'},
                        {value: 'reboot_count', label: 'reboot_count'},
                        {value: 'reset_count', label: 'reset_count'},
                        {value: 'state', label: 'state'},
                        {value: 'battery_level', label: 'battery_level'},
                        {value: 'mcu_rst_register', label: 'mcu_rst_register'},
                        {value: 'debug_str', label: 'debug_str'},
                        {value: 'debug_sn', label: 'debug_sn'},
                        {value: 'disturb_time', label: 'disturb_time'}
                    ];

                case '20015':
                    return this.options = [
                        {value: 'receive_time', label: '上报时间'},
                        {value: 'event_time', label: '事件时间'},
                        {value: 'type', label: 'type'},
                        {value: 'sub_type', label: 'sub_type'},
                        {value: 'magnet_state', label: 'magnet_state'},
                        {value: 'frame_id', label: 'frame_id'},
                        {value: 'magnet_x', label: 'magnet_x'},
                        {value: 'magnet_y', label: 'magnet_y'},
                        {value: 'magnet_z', label: 'magnet_z'},
                        {value: 'x_base', label: 'x_base'},
                        {value: 'y_base', label: 'y_base'},
                        {value: 'z_base', label: 'z_base'},
                        {value: 'resend_count', label: 'resend_count'},
                        {value: 'sn', label: 'sn'},
                        {value: 'csq', label: 'csq'},
                        {value: 'snr', label: 'snr'},
                        {value: 'temperature', label: 'temperature'},
                        {value: 'battery_voltage', label: 'battery_voltage'},
                        {value: 'conn_signal_strength', label: 'conn_signal_strength'},
                        {value: 'conn_signal_ecl', label: 'conn_signal_ecl'},
                        {value: 'conn_signal_cell_id', label: 'conn_signal_cell_id'},
                        {value: 'conn_signal_pci', label: 'conn_signal_pci'},
                        {value: 'conn_signal_ear', label: 'conn_signal_ear'},
                        {value: 'reboot_count', label: 'reboot_count'},
                        {value: 'reset_count', label: 'reset_count'},
                        {value: 'state', label: 'state'},
                        {value: 'battery_level', label: 'battery_level'},
                        {value: 'mcu_rst_register', label: 'mcu_rst_register'},
                        {value: 'debug_str', label: 'debug_str'},
                        {value: 'debug_sn', label: 'debug_sn'},
                        {value: 'disturb_time', label: 'disturb_time'}
                    ];

                case '20023':
                    return this.options = [
                        {value: 'receive_time', label: '上报时间'},
                        {value: 'event_time', label: '事件时间'},
                        {value: 'type', label: 'type'},
                        {value: 'sub_type', label: 'sub_type'},
                        {value: 'magnet_state', label: 'magnet_state'},
                        {value: 'frame_id', label: 'frame_id'},
                        {value: 'magnet_x', label: 'magnet_x'},
                        {value: 'magnet_y', label: 'magnet_y'},
                        {value: 'magnet_z', label: 'magnet_z'},
                        {value: 'x_base', label: 'x_base'},
                        {value: 'y_base', label: 'y_base'},
                        {value: 'z_base', label: 'z_base'},
                        {value: 'resend_count', label: 'resend_count'},
                        {value: 'sn', label: 'sn'},
                        {value: 'csq', label: 'csq'},
                        {value: 'snr', label: 'snr'},
                        {value: 'temperature', label: 'temperature'},
                        {value: 'battery_voltage', label: 'battery_voltage'},
                        {value: 'conn_signal_strength', label: 'conn_signal_strength'},
                        {value: 'conn_signal_ecl', label: 'conn_signal_ecl'},
                        {value: 'conn_signal_cell_id', label: 'conn_signal_cell_id'},
                        {value: 'conn_signal_pci', label: 'conn_signal_pci'},
                        {value: 'conn_signal_ear', label: 'conn_signal_ear'},
                        {value: 'reboot_count', label: 'reboot_count'},
                        {value: 'reset_count', label: 'reset_count'},
                        {value: 'state', label: 'state'},
                        {value: 'battery_level', label: 'battery_level'},
                        {value: 'mcu_rst_register', label: 'mcu_rst_register'},
                        {value: 'debug_str', label: 'debug_str'},
                        {value: 'debug_sn', label: 'debug_sn'},
                        {value: 'disturb_time', label: 'disturb_time'}
                    ];

                case '20029':
                    return this.options = [
                        {value: 'receive_time', label: '上报时间'},
                        {value: 'event_time', label: '事件时间'},
                        {value: 'type', label: 'type'},
                        {value: 'sub_type', label: 'sub_type'},
                        {value: 'magnet_state', label: 'magnet_state'},
                        {value: 'frame_id', label: 'frame_id'},
                        {value: 'magnet_x', label: 'magnet_x'},
                        {value: 'magnet_y', label: 'magnet_y'},
                        {value: 'magnet_z', label: 'magnet_z'},
                        {value: 'x_base', label: 'x_base'},
                        {value: 'y_base', label: 'y_base'},
                        {value: 'z_base', label: 'z_base'},
                        {value: 'resend_count', label: 'resend_count'},
                        {value: 'sn', label: 'sn'},
                        {value: 'csq', label: 'csq'},
                        {value: 'snr', label: 'snr'},
                        {value: 'temperature', label: 'temperature'},
                        {value: 'battery_voltage', label: 'battery_voltage'},
                        {value: 'conn_signal_strength', label: 'conn_signal_strength'},
                        {value: 'conn_signal_ecl', label: 'conn_signal_ecl'},
                        {value: 'conn_signal_cell_id', label: 'conn_signal_cell_id'},
                        {value: 'conn_signal_pci', label: 'conn_signal_pci'},
                        {value: 'conn_signal_ear', label: 'conn_signal_ear'},
                        {value: 'reboot_count', label: 'reboot_count'},
                        {value: 'reset_count', label: 'reset_count'},
                        {value: 'state', label: 'state'},
                        {value: 'battery_level', label: 'battery_level'},
                        {value: 'mcu_rst_register', label: 'mcu_rst_register'},
                        {value: 'debug_str', label: 'debug_str'},
                        {value: 'debug_sn', label: 'debug_sn'},
                        {value: 'disturb_time', label: 'disturb_time'},
                        {value: 'light_data_list', label: 'light_data_list'},
                    ];

                default:
                    return this.options

            }
        },
        //获取该id的导出任务列表
        async getExportTaskList() {
            if (!this.uid || this.uid === '') {
                return
            }

            try {
                this.loading = true;
                let res = await Core.Api.Export.getExportListByDeviceId(this.uid, this.space, this.currentPage)
                let list = res.task_list;
                list.forEach(i => {
                    let data = i.data;
                    if (data) {
                        let dataObj = JSON.parse(data);
                        i["begin_time"] = dataObj.begin_time;
                        i["end_time"] = dataObj.end_time;
                    }
                });
                this.taskList = list;
                this.taskCount = res.count;
            } catch (error) {
                console.log("getExportTaskList  error:", error)
            } finally {
                this.loading = false;
            }
            
        },

        exportListPageChange(val) {
            this.currentPage = val;
            this.getExportTaskList();
        },

        // 数据导出
        handleExport() {
            if (!this.exportTime || this.exportTime[0] === null || this.exportTime[1] === null) {
                this.$message.warning("请选择时间");
                return;
            }
            this.selectField = true
        },
        // 全部字段导出
        handleAllExport() {
            if (!this.exportTime || this.exportTime[0] === null || this.exportTime[1] === null) {
                this.$message.warning("请选择时间");
                return;
            }

            let startTime = (this.exportTime[0].getTime() / 1000).toFixed(0);
            let endTime = (this.exportTime[1].getTime() / 1000).toFixed(0);
            if (endTime - startTime > 86400 * 7) {
                this.$message.warning("时间间隔不能大于24小时");
                return;
            }
            //  导出
            this.submitExportTask(startTime, endTime);
        },

        // 将选择的字段拼接成字符串
        getTextByOptions() {
            let str = "";
            //遍历field的选中元素
            for (let i = 0; i < this.field.length; i++) {
                str += this.field[i] + ",";
            }
            if (str.length > 0) {
                str = str.substr(0, str.length - 1);
            }
            return str;
        },

        //数据导出（新） 可选字段导出
        handleConfirm() {
            if (this.field === undefined || this.field.length <= 0) {
                this.$message.warning("请选择导出字段")
                return;
            }

            let startTime = parseInt(this.exportTime[0].getTime() / 1000);
            let endTime = parseInt(this.exportTime[1].getTime() / 1000);
            if (endTime - startTime > 86400 * 7) {
                this.$message.warning("时间间隔不能大于24小时");
                return;
            }
            //  导出
            this.submitExportTask(startTime, endTime);
            this.selectField = false
            // this.exportDialogVisible = false;
        },

        //提交一个任务
        async submitExportTask(startTime, endTime) {
            let fields = '';
            if (this.field !== undefined && this.field.length > 0) {
                fields = this.getTextByOptions();
            }

            await Core.Api.Export.exportDetectorFrame(this.space, this.uid, startTime, endTime, fields);
            await this.getExportTaskList();
            this.$message.success("后台导出任务正在进行，请稍等");
        },

        // 下载
        downloadExportFile(task) {
            let targetUrl = `http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/${task.file}`;
            // let tempWindow = window.open('_blank');
            // tempWindow.location=`http://airjoy.oss-cn-hangzhou.aliyuncs.com/export/${task.file}`;
        },
    }

}
</script>

<style lang="scss">
.relay-log-list {
    overflow: hidden;

    .row {

    }

    .row.warning {
        /*opacity: 0.5;*/
        background: #F56C6C55;
    }

}

</style>
