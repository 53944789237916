<template>
	<div class="login-container">
		<div class="login-header">
			<div>
				<img v-if="lang === 'zh'" src="../assets/img/logo-zh.png" class="logo"/>
				<img v-else src="../assets/img/logo-en.png" class="logo"/>
			</div>
			<div class="lang-select">
				<img class="user-avatar" src="../assets/img/icon/icon-lang.png"
					 style="margin-left: 15px;width: 24px;height: 24px">
				<div class="button-container">
					<el-select v-model="lang" placeholder="请选择" @change="languageChange">
						<el-option v-for="(item,index) in langList"
								   :key="item.id"
								   :label="item.name"
								   :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="login-form-container" @keyup.enter="login">
			<div class="img-icon">
				<img src="../assets/img/login/icon-admin-active.png" class="img" @click="clickIcon"
					 v-if="type === 'admin'"/>
				<img src="../assets/img/login/icon-user-active.png" class="img" @click="clickIcon" v-else/>
				<p class="title">{{ $t('device.icon') }}</p>
			</div>
			<div class="form-container">
				<form>
					<el-input class="input" :placeholder="$t('device.loginUser')"
							  v-model="loginForm.username"/>
					<el-input class="input" :placeholder="$t('device.loginPassWord')" v-model="loginForm.password"
							  type="password"/>
				</form>
			</div>
			<div class="button-container">
				<el-button type="primary" class="btn-login" @click="login" :loading="loading">{{ $t('device.login') }}</el-button>
			</div>
		</div>
	</div>
</template>


<script>
	import Core from "@/core";

	const USER_TYPE = Core.Const.USER_TYPE;

	export default {
		data() {
			return {
				type: "admin",
				loginForm: {
					username: "",
					password: ""
				},
				clickTime: "",
				clickCount: 0,
				lang: 'zh',
				langList: [
					{id: 1, name: '中文', value: 'zh'},
					{id: 2, name: 'English', value: 'en'},
				],
				loading: false,
			};
		},
		mounted() {
			this.getCurrentLang()
		},
		created() {
			this.type = Core.Util.getUserType() === USER_TYPE.ADMIN ? 'admin' : 'user';
			// this.type = 'user';

			Core.Data.setUserType(Core.Util.getUserType());


			// 本地用localhost访问就是用户，ip就是admin
			if (process.env.NODE_ENV !== 'production' &&
					process.env.NODE_ENV !== 'test' &&
					typeof console !== 'undefined'
			) {
				console.log('开发模式', process.env.NODE_ENV)
				let host = window.location.hostname;
				switch (host) {
					case 'localhost': {
						this.type = 'user'  // 用户
						Core.Data.setUserType(USER_TYPE.USER);
					}
						break;
					default: {
						this.type = 'admin'  // 管理员
						Core.Data.setUserType(USER_TYPE.ADMIN);
					}
						break;
				}
			}
		},
		methods: {
			getCurrentLang() {
				let lang = Core.Data.getLang();
				this.lang = lang
			},
			languageChange(val) {
				if (val === 'en') {
					i18n.locale = 'en';
					Core.Data.setLang('en');
					document.body.classList.remove(`lang-zh`)
					document.body.classList.add(`lang-en`)

				} else {
					i18n.locale = 'zh';
					Core.Data.setLang('zh');
					document.body.classList.remove(`lang-en`)
					document.body.classList.add(`lang-zh`)
				}
			},

			async login() {
				try {
					let username = this.loginForm.username.trim();
					let password = this.loginForm.password.trim();
					if (!username) {
						this.$message({
							type: "warning",
							message: this.$t('device.pleaseUserName')
						});

						return;
					}

					if (!password) {
						this.$message({
							type: "warning",
							message: this.$t('device.pleasePassword')
						});
						return;
					}

					// 根据路由参数判断是否为用户登录  userType === 'user'为用户	权限最高所以最终判断
					let userType = this.$route.query.userType
					console.log('userType', userType, userType == 'user')
					if (userType == 'user') {
						this.type = 'user'  // 用户
						Core.Data.setUserType(USER_TYPE.USER);
					}

					let loginFun = this.type === 'admin' ? Core.Api.Admin.login : Core.Api.User.login;

					this.loading = true;

					let res = await loginFun(username, password);

					this.loading = false;

					Core.Data.setToken(res.token);
					let userInfo = this.type === 'admin' ? res['admin'] : res['user'];
					Core.Data.setUser(userInfo);
					this.$router.push("home");

					// 设置权限
					this.setAuthority(userInfo);
				} catch (err) {
					console.log("login err:", err);
					this.$message({
						type: "error",
						message: "用户名或者密码错误!"
					});
					this.loading = false;
				}
				
			},

			// 设置权限
			setAuthority(user) {
				console.log("setAuthority  user:", user);
				let authority = {};
				if (Core.Data.getUserType() === USER_TYPE.USER) {
					authority.user = true
					let flagList = ['flag_child_user_enable', 'flag_signal_adjust','flag_alarm_enable', 'flag_ota_task_enable', 'flag_device_task_enable'];
					for (var i = 0; i < flagList.length; i++) {
						const key = flagList[i];
						authority[key] = user[key] === 1 ? true : false;
					}
				} else if (Core.Data.getUserType() === USER_TYPE.ADMIN) {
					authority.admin = true
				}
				Core.Util.setAuthority(authority)
			},

			clickIcon() {
				let hostList = ['v2.airjoy.fegiot.com', 'v2.admin.airjoy.fegiot.com', 'v2.airjoy.fegiot.com', 'v2.ajaiot.com'];
				if (hostList.indexOf(window.location.host) > -1) {
					return;
				}
				let nowTime = new Date().getTime();
				if (this.clickCount) {
					if (nowTime - this.clickTime < 1000) {
						this.clickCount++
					} else {
						this.clickCount = 1;
					}
				} else {
					this.clickCount = 1;
				}
				this.clickTime = nowTime;

				if (this.clickCount >= 3) {
					this.type = this.type === 'admin' ? "user" : "admin";
					this.type = this.type === 'admin' ? "user" : "admin";
					this.clickCount = 0
				}
			},
		},


	};
</script>

<style scoped lang="scss">
	.login-container {
		height: 100%;
		width: 100%;
		background: rgb(50, 57, 67);
		position: relative;
		border-top: 1px solid rgb(50, 57, 67);

		.login-header {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			height: 60px;
			background: #3F4855;
			border-bottom: 1px solid #2D2F31;
			padding: 17px 20px;
			display: flex;
			justify-content: space-between;

			.logo {
				height: 26px;
				width: auto;
			}

			.lang-select {
				display: flex;

			}
		}

		.login-form-container {
			width: 320px;
			min-height: 320px;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.checkbox {
				text-align: center;

				.check-box-item {
					display: inline-block;
					cursor: pointer;

					.img {
						width: 70px;
						height: 70px;
					}

					.label-text {
						margin-top: 12px;
						color: #8E9AAD;
					}
				}

				&:hover {
				}
			}

			.page-title {
				text-align: center;
				color: #E9ECEF;
				font-size: 28pt;
			}

			.img-icon {
				text-align: center;

				.img {
					width: 90px;
					height: 90px;
				}

				.title {
					margin-top: 10px;
					font-size: 18px;
					color: #20C48B
				}
			}

			.form-container {
				margin-top: 20px;

				.input {
					& + .input {
						margin-top: 16px;
					}
				}
			}

			.button-container {
				margin-top: 30px;
			}
		}

		.color-green {
			color: #20C48B !important;
		}
	}
</style>

<style lang="scss">
	.login-container {
		.login-header {
			.lang-select {
				.user-avatar {
					margin-right: 10px;
				}

				.el-select {
					.el-input {
						.el-input__inner {
							background: transparent;
							border: none;
							padding: 0;
							color: white;
							width: 80px;
						}
					}
				}
			}
		}

		.form-container {
			.input {
				& + .input {
					margin-top: 16px;
				}

				.el-input__inner {
					height: 40px;
					line-height: 40px;
					border-radius: 2px;
				}

			}
		}

		.button-container {
			.btn-login {
				width: 320px;
				border-radius: 3px;

				span {
					height: 28px;
					line-height: 28px;
					font-size: 14px;
				}
			}
		}
	}

</style>
