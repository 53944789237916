import * as base from './base.js'
import * as device from './device.js'
import * as device20015 from './device_20015'
import * as ota from './ota'
import * as action from './action.js'

const filters = {
    ...base,
    ...device,
    ...device20015,
    ...ota,
    ...action,
};

export default filters;