<template>
    <div class="container">
        <app-header :routeName="$route.name"
                    :config="config"></app-header>
        <div class="main">
            <app-sidebar :router="$router"
                :config="config"></app-sidebar>
            <app-content></app-content>
        </div>
    </div>
</template>
<script>
    import Core from '@/core'

    import AppHeader from './layout/header'
    import AppSidebar from './layout/sidebar'
    import AppContent from './layout/content'

    export default {
        data() {
            return {
                config: {
                    name: '',
                    userName: '',
                    userAvatar: '',
                    type:'',
                    uid: '',
                    key: ''
                },
            }
        },
        mounted() {
            let user = Core.Data.getUser()
            if (!user || !user.username) {
                this.$router.push('')
                return
            }

            this.config.userName = user.name ? user.name : user.username
            this.config.type=user.type
            this.config.uid = user.uid
            this.config.key = user.secret_key
        },
        methods: {

        },
        components: {
            AppHeader,
            AppSidebar,
            AppContent
        }
    }
</script>

<style scoped lang="scss">
    .container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .main {
            flex: 1;
            display: flex;
            flex-direction: row;
            overflow: hidden;
        }
    }
</style>
